import React from "react"
import {
    Backdrop,
    Badge,
    Button,
    CircularProgress,
    Dialog,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Typography
} from "@material-ui/core"
import { ConfirmTransition } from "../../utils/tableUtils"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import { useQuery, useMutation } from "@apollo/client"
import { ErrorNotification } from "../Notifications/Notifications"
import { useFormik } from "formik"
import * as Yup from "yup"
import { LOAD_ALL_SERVICES } from "../../querys/admin/platformServicesQueries"
import {
    CREATE_MARKETPLACE_SERVICE,
    DELETE_MARKETPLACE_SERVICE,
    LOAD_MARKETPLACE_SERVICES,
} from "../../querys/admin/marketplaceQueries"
import { Cancel } from "@material-ui/icons"
import TagOutlineIcon from "mdi-react/TagOutlineIcon"
import DoneIcon from "mdi-react/DoneIcon"

const MarketplaceServicesEditForm = ({ openDialog, handleClose, marketplace }) => {
    const notifyError = (message) => ErrorNotification("Marketplace services", message)

    const initialValues = {
        service: "",
    }

    const validationSchema = Yup.object({
        service: Yup.object().required("You must select a service"),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values, { setFieldError }) => {
            await createMarketplaceService({
                variables: {
                    marketplace: marketplace.node.id,
                    service: values.service.node.id,
                },
            })
        },
    })

    //marketplace services
    const { data: marketplaceServices } = useQuery(LOAD_MARKETPLACE_SERVICES, {
        variables: {
            id: marketplace.node.id,
        },
        onError: () => {
            notifyError("Unable to load marketplace services, try again")
        },
    })

    //all services
    const { data: serviceOptions } = useQuery(LOAD_ALL_SERVICES, {
        onError: () => {
            notifyError("Unable to load existing services, try again")
        },
    })

    const [createMarketplaceService, { loading: loadingCreate }] = useMutation(CREATE_MARKETPLACE_SERVICE, {
        onCompleted: (data) => {
            if (data?.createMarketplaceService?.marketplaceService?.id) {
                formik.resetForm()
            }
        },
        onError: () => {
            notifyError("Something were wrong while marketplace service creation, try again")
        },
        refetchQueries: [
            {
                query: LOAD_MARKETPLACE_SERVICES,
                variables: {
                    id: marketplace.node.id,
                },
            },
        ],
    })

    const [deleteMarketplaceService, { loading: loadingDelete }] = useMutation(DELETE_MARKETPLACE_SERVICE, {
        onCompleted: (data) => {
            if (data?.deleteMarketplaceService?.found) {
                formik.resetForm()
            }
        },
        onError: () => {
            notifyError("Something were wrong while marketplace service deletion, try again")
        },
        refetchQueries: [
            {
                query: LOAD_MARKETPLACE_SERVICES,
                variables: {
                    id: marketplace.node.id,
                },
            },
        ],
    })

    async function handleRemoveService(id) {
        await deleteMarketplaceService({
            variables: {
                id: id,
            },
        })
    }

    const ServiceTag = ({ id, service }) => (
        <Badge
            overlap={"circular"}
            badgeContent={<Cancel fontSize={"small"} style={{ color: "rgb(255, 0, 0)", cursor: "pointer" }} />}
            onClick={() => handleRemoveService(id)}
        >
            <Grid
                style={{
                    display: "inline-flex",
                    margin: "5px",
                    backgroundColor: "rgba(170, 170, 170, 0.8)",
                    padding: "2px 4px",
                    borderRadius: "5px 2px 2px 2px",
                    border: "1px solid rgba(130, 130, 130)",
                    fontSize: 14,
                }}
            >
                <Grid item>
                    <TagOutlineIcon size={14} style={{ color: "white", marginRight: "3px" }} />
                </Grid>
                <Grid item style={{ marginRight: "3px" }}>
                    <Typography variant={"caption"} style={{ color: "white", fontWeight: "600" }}>
                        {service}
                    </Typography>
                </Grid>
            </Grid>
        </Badge>
    )

    return (
        <Dialog
            open={openDialog}
            TransitionComponent={ConfirmTransition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Services for {marketplace?.node?.fullName}</Typography>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {marketplaceServices?.marketplace?.marketplaceMs?.edges?.length > 0 &&
                            marketplaceServices.marketplace.marketplaceMs.edges.map((item) => (
                                <ServiceTag key={item.node.id} id={item.node.id} service={item.node.service.name} />
                            ))}
                    </Grid>

                    <Grid container item xs={12} spacing={2}>
                        <Backdrop
                            open={loadingCreate || loadingDelete}
                            style={{ zIndex: 1000, display: "flex", alignItems: "center", justifyContent: "center" }}
                        >
                            <CircularProgress color={"primary"} size={40} />
                        </Backdrop>
                        <Grid item xs={9}>
                            <TextField
                                name="service"
                                label="Service"
                                variant={"filled"}
                                size={"small"}
                                fullWidth
                                select
                                {...formik.getFieldProps("service")}
                                error={formik.errors.service && formik.touched.service}
                                helperText={formik.errors.service && formik.touched.service && formik.errors.service}
                            >
                                <MenuItem value={""}>
                                    <b>select none</b>
                                </MenuItem>
                                {serviceOptions?.allService?.edges?.map((option) => (
                                    <MenuItem key={option.node.id} value={option}>
                                        {option.node.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={3}>
                            <IconButton color={"primary"} onClick={formik.handleSubmit} disabled={!formik.isValid}>
                                <DoneIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </MuiDialogContent>
            <MuiDialogActions>
                <Button onClick={handleClose} color="secondary">
                    Close
                </Button>
            </MuiDialogActions>
        </Dialog>
    )
}

export default MarketplaceServicesEditForm
