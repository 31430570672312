import { grayColor, whiteColor } from "assets/jss/material-dashboard-react.js"

const dashboardMainButtonStyle = {
    outsideWrapper: {
        display: "inline-flex",
        borderRadius: "5px",
        position: "relative",
    },

    dragIcon: {
        position: "absolute",
        top: 0,
        left: 0,
        color: "white",
    },

    container: {
        border: `1px solid ${grayColor[2]}`,
        backgroundColor: grayColor[1],
        borderRadius: "5px",
        width: "200px",
        height: "100px",
        color: whiteColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
}

export default dashboardMainButtonStyle
