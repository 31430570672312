import React from "react";
import {
    Button,
    Dialog,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
    useMediaQuery
} from "@material-ui/core";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import {ConfirmTransition} from "utils/tableUtils";

export default function PermissionsDialog(props) {
    const {open, setOpenDialog, permissionsItem} = props

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpenDialog(false)
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={ConfirmTransition}
            keepMounted
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Group permissions</Typography>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                <Grid item xs={12}>
                    {
                        permissionsItem
                            && <List dense>
                            {permissionsItem.map(item =>
                                <ListItem key={item.node.id} divider>
                                    <ListItemText
                                        primary={item.node.name}
                                        secondary={item.node.codename}
                                    />
                                </ListItem>
                            )}
                        </List>
                    }
                </Grid>
            </MuiDialogContent>
            <MuiDialogActions>
                <Button onClick={handleClose} color="primary">
                    Ok
                </Button>
            </MuiDialogActions>
        </Dialog>
    )
}
