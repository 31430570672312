/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
//icons
import ViewDashboardOutlineIcon from "mdi-react/ViewDashboardOutlineIcon"
import ShoppingOutlineIcon from "mdi-react/ShoppingOutlineIcon"
import CategoryOutlineIcon from "mdi-react/CategoryOutlineIcon"
import ShopIcon from "mdi-react/ShopIcon"
import ShopOutlineIcon from "mdi-react/ShopOutlineIcon"
import FlagOutlineIcon from "mdi-react/FlagOutlineIcon"
import StateMachineIcon from "mdi-react/StateMachineIcon"
import RibbonIcon from "mdi-react/RibbonIcon"
import ColorizeIcon from "mdi-react/ColorizeIcon"
import BarcodeIcon from "mdi-react/BarcodeIcon"
import TuneVerticalIcon from "mdi-react/TuneVerticalIcon"
import WeightKgIcon from "mdi-react/WeightKgIcon"
import SphereDiameterOutlineIcon from "mdi-react/SphereDiameterOutlineIcon"
import UserCardDetailsIcon from "mdi-react/UserCardDetailsIcon"
import UserBadgeIcon from "mdi-react/UserBadgeIcon"
import SettingsIcon from "mdi-react/SettingsIcon"
import InformationCircleOutlineIcon from "mdi-react/InformationCircleOutlineIcon"
import TruckDeliveryOutlineIcon from "mdi-react/TruckDeliveryOutlineIcon"

// core components/views for Admin layout
import CountryPage from "views/Admin/Country/CountryPage"
import CountryForm from "views/Admin/Country/CountryForm"
import AdminMarketplacePage from "views/Admin/Marketplace/MarketplacePage"
import AdminMarketplaceForm from "views/Admin/Marketplace/MarketplaceForm"
import PlatformServicePage from "views/Admin/PlatformService/PlatformServicePage"
import PlatformServiceForm from "views/Admin/PlatformService/PlatformServiceForm"
import ConditionPage from "views/Admin/Condition/ConditionPage"
import ConditionForm from "views/Admin/Condition/ConditionForm"
import MaterialPage from "views/Admin/Material/MaterialPage"
import MaterialForm from "views/Admin/Material/MaterialForm"
import ColorPage from "views/Admin/Color/ColorPage"
import ColorForm from "views/Admin/Color/ColorForm"
import ProductIdTypePage from "views/Admin/ProductIdType/ProductIdTypePage"
import ProductIdTypeForm from "views/Admin/ProductIdType/ProductIdTypeForm"
import VariationFieldPage from "views/Admin/VariationField/VariationFieldPage"
import VariationFieldForm from "views/Admin/VariationField/VariationFieldForm"
import WeightUnitPage from "views/Admin/WeightUnit/WeightUnitPage"
import WeightUnitForm from "views/Admin/WeightUnit/WeightUnitForm"
import DimensionUnitPage from "views/Admin/DimensionUnit/DimensionUnitPage"
import DimensionUnitForm from "views/Admin/DimensionUnit/DimensionUnitForm"
import UserPage from "views/Admin/User/UserPage"
import UserForm from "views/Admin/User/UserForm"
import PermissionsGroupPage from "views/Admin/User/AuthGroup/PermissionsGroupPage"
import PermissionsGroupForm from "views/Admin/User/AuthGroup/PermissionsGroupForm"
import ConfigurationPage from "views/Admin/Configuration/ConfigurationPage"

// core components/views for SellerLayout layout
import DashboardPage from "views/Seller/Dashboard/Dashboard.js"
import ProductPage from "views/Seller/Product/ProductPage"
import CategoryPage from "views/Seller/Category/CategoryPage"
import CategoryForm from "views/Seller/Category/CategoryForm"
import MarketplacePage from "views/Seller/Marketplace/MarketplacePage"
import MarketplaceForm from "views/Seller/Marketplace/MarketplaceForm"
import Profile from "views/Seller/AuthUser/Profile"
import Login from "views/App/Login"
import PasswordChange from "views/Seller/AuthUser/PasswordChange"
import EbayConnectionResponse from "components/Seller/Apis connection/EbayConnectionResponse"
import EtsyConnectionResponse from "components/Seller/Apis connection/EtsyConnectionResponse"
import SystemHelpPage from "views/Admin/System/SystemHelpPage"
import MarketplaceOrdersPage from "./views/Seller/Orders/MarketplaceOrdersPage"
import NotificationsCenter from "views/App/NotificationsCenter"
import MessagesCenterPage from "views/Seller/MessagesCenter/MessagesCenterPage"
import FacebookConnectionResponse from "./components/Seller/Apis connection/FacebookConnectionResponse"


const dashboard = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: ViewDashboardOutlineIcon,
        component: DashboardPage,
        layout: "/seller",
        menu: true,
    },
    {
        path: "/help",
        name: "System help",
        icon: InformationCircleOutlineIcon,
        component: SystemHelpPage,
        layout: "/seller",
        menu: true,
    },
]

const dashboardRoutes = [
    {
        path: "/product",
        name: "Products",
        icon: ShoppingOutlineIcon,
        component: ProductPage,
        layout: "/seller",
        menu: true,
    },
    {
        path: "/orders",
        name: "Orders",
        icon: TruckDeliveryOutlineIcon,
        component: MarketplaceOrdersPage,
        layout: "/seller",
        menu: true,
    },
    {
        path: "/category",
        name: "Categories",
        icon: CategoryOutlineIcon,
        component: CategoryPage,
        layout: "/seller",
        menu: true,
    },
    {
        path: "/category/form",
        name: "Categories",
        component: CategoryForm,
        layout: "/seller",
        menu: false,
    },
    {
        path: "/connection",
        name: "Connections",
        icon: ShopOutlineIcon,
        component: MarketplacePage,
        layout: "/seller",
        menu: true,
    },
    {
        path: "/connection/form",
        name: "Connections",
        component: MarketplaceForm,
        layout: "/seller",
        menu: false,
    },
    // {
    //     path: "/notification",
    //     name: "Notifications",
    //     component: NotificationPage,
    //     layout: "/seller",
    //     menu: false,
    // },
    {
        path: "/profile/password-change",
        name: "User",
        component: PasswordChange,
        layout: "/seller",
        menu: false,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        layout: "/seller",
        menu: false,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        layout: "none",
        menu: false,
    },
    {
        path: "/country",
        name: "Countries",
        icon: FlagOutlineIcon,
        component: CountryPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/country/form",
        name: "Countries",
        component: CountryForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/admin-marketplace",
        name: "System platforms",
        icon: ShopIcon,
        component: AdminMarketplacePage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/admin-marketplace/form",
        name: "Admin marketplaces",
        component: AdminMarketplaceForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/platform-service",
        name: "Platform services",
        icon: ShopIcon,
        component: PlatformServicePage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/platform-service/form",
        name: "Platform services",
        icon: ShopIcon,
        component: PlatformServiceForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/condition",
        name: "Conditions",
        icon: StateMachineIcon,
        component: ConditionPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/condition/form",
        name: "Conditions",
        component: ConditionForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/material",
        name: "Materials",
        icon: RibbonIcon,
        component: MaterialPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/material/form",
        name: "Materials",
        component: MaterialForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/color",
        name: "Colors",
        icon: ColorizeIcon,
        component: ColorPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/color/form",
        name: "Colors",
        component: ColorForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/id-type",
        name: "Types of id",
        icon: BarcodeIcon,
        component: ProductIdTypePage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/id-type/form",
        name: "Types of id",
        component: ProductIdTypeForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/variation-field",
        name: "Variation fields",
        icon: TuneVerticalIcon,
        component: VariationFieldPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/variation-field/form",
        name: "Types of product id",
        component: VariationFieldForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/weight-unit",
        name: "Weight Units",
        icon: WeightKgIcon,
        component: WeightUnitPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/weight-unit/form",
        name: "Weight Units",
        component: WeightUnitForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/dimension-unit",
        name: "Dimension Units",
        icon: SphereDiameterOutlineIcon,
        component: DimensionUnitPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/dimension-unit/form",
        name: "Dimension Units",
        component: DimensionUnitForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/admin-user",
        name: "Users",
        icon: UserCardDetailsIcon,
        component: UserPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/admin-user/form",
        name: "Users",
        component: UserForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/permissions-group",
        name: "Permissions groups",
        icon: UserBadgeIcon,
        component: PermissionsGroupPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/permissions-group/form",
        name: "Permissions groups",
        component: PermissionsGroupForm,
        layout: "/admin",
        menu: false,
    },
    {
        path: "/configuration",
        name: "Configurations",
        icon: SettingsIcon,
        component: ConfigurationPage,
        layout: "/admin",
        menu: true,
    },
    {
        path: "/ebay-connection",
        name: "Ebay connection",
        component: EbayConnectionResponse,
        layout: "/seller",
        menu: false,
    },
    {
        path: "/etsy-connection",
        name: "Etsy connection",
        component: EtsyConnectionResponse,
        layout: "/seller",
        menu: false,
    },
    {
        path: "/facebook-connection",
        name: "Facebook connection",
        component: FacebookConnectionResponse,
        layout: "/seller",
        menu: false,
    },
    {
        path: "/notification-center",
        name: "Notification center",
        component: NotificationsCenter,
        layout: "/seller",
        menu: false,
    },
    {
        path: "/message-center",
        name: "Message center",
        component: MessagesCenterPage,
        layout: "/seller",
        menu: true,
    },
]

const orderedMenu = dashboardRoutes.sort((a, b) => compare(a, b))

function compare(a, b) {
    if (a.name < b.name) {
        return -1
    } else if (a.name === b.name) {
        return 0
    } else {
        return 1
    }
}

export default dashboard.concat(orderedMenu)
