import gql from "graphql-tag"

//to know if task is running
export const AMAZON_ORDERS_WATCHER_SCHEDULER_IS_RUNNING = gql`
    query schedulerIsRunningAmazonOrderWatcher {
        schedulerIsRunningAmazonOrderWatcher {
            isRunning
            lastError
            params
        }
    }
`

//start schedule task
export const AMAZON_ORDERS_WATCHER_SCHEDULER_START = gql`
    mutation schedulerStartAmazonOrderWatcher($timeInterval: Int) {
        schedulerStartAmazonOrderWatcher(timeInterval: $timeInterval) {
            success
        }
    }
`

//stop schedule task
export const AMAZON_ORDERS_WATCHER_SCHEDULER_STOP = gql`
    mutation schedulerStopAmazonOrderWatcher {
        schedulerStopAmazonOrderWatcher {
            success
        }
    }
`
