import gql from "graphql-tag"

//create seller facebook
export const CREATE_SELLER_FACEBOOK = gql`
    query createSellerFacebook(
        $serviceIds: [ID]
        $alias: String!
        $accessToken: String!
        $dataAccessExpirationTime: String!
        $expiresIn: String!
        $longLivedToken: String!
        $state: String!
    ) {
        createSellerFacebook(
            input: {
                serviceIds: $serviceIds
                alias: $alias
                accessToken: $accessToken
                dataAccessExpirationTime: $dataAccessExpirationTime
                expiresIn: $expiresIn
                longLivedToken: $longLivedToken
                state: $state
            }
        ) {
            success
            reason
        }
    }
`
