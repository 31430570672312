import React from "react";
import {
    Grid,
    TextField,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button
} from "@material-ui/core";

//styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/productFiltersStyle";

const useStyles = makeStyles(styles);

export default function UsersFilter(props) {
    const classes = useStyles()

    const {
        usernameFilter,
        firstNameFilter,
        lastNameFilter,
        emailFilter,
        setUsernameFilter,
        setFirstNameFilter,
        setLastNameFilter,
        setEmailFilter,
        resetPagination,
        toggleShowFilters
    } = props

    //search fields
    const [username, setUsername] = React.useState(usernameFilter)
    const [firstName, setFirstName] = React.useState(firstNameFilter)
    const [lastName, setLastName] = React.useState(lastNameFilter)
    const [email, setEmail] = React.useState(emailFilter)

    const handleFieldsValueChange = (e) => {
        switch (e.target.name) {
            case "username":
                return setUsername(e.target.value)
            case "firstName":
                return setFirstName(e.target.value)
            case "lastName":
                return setLastName(e.target.value)
            case "email":
                return setEmail(e.target.value)
            default: return false
        }
    }

    const applyFilters = () => {
        resetPagination()

        setUsernameFilter(username)
        setFirstNameFilter(firstName)
        setLastNameFilter(lastName)
        setEmailFilter(email)

        toggleShowFilters()
    }

    const cancelFilters = () => {
        resetPagination()

        setUsername("")
        setFirstName("")
        setLastName("")
        setEmail("")

        setUsernameFilter("")
        setFirstNameFilter("")
        setLastNameFilter("")
        setEmailFilter("")

        toggleShowFilters()
    }

    return (
        <Card className={classes.container} variant={"outlined"}>
            <CardHeader
                subheader={"Filter system users"}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="username"
                            label={"Username"}
                            size={"small"}
                            fullWidth
                            variant={"outlined"}
                            value={username}
                            onChange={handleFieldsValueChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="firstName"
                            label={"FirstName"}
                            size={"small"}
                            fullWidth
                            variant={"outlined"}
                            value={firstName}
                            onChange={handleFieldsValueChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="lastName"
                            label={"LastName"}
                            size={"small"}
                            fullWidth
                            variant={"outlined"}
                            value={lastName}
                            onChange={handleFieldsValueChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="email"
                            label={"Email"}
                            size={"small"}
                            fullWidth
                            variant={"outlined"}
                            value={email}
                            onChange={handleFieldsValueChange}
                        />
                    </Grid>
                </Grid>
            </CardContent>

            <CardActions className={classes.footer}>
                <Button
                    size={"small"}
                    variant={"text"}
                    color={"secondary"}
                    onClick={cancelFilters}
                >
                    Cancel filters
                </Button>

                <Button
                    size={"small"}
                    variant={"text"}
                    color={"primary"}
                    onClick={applyFilters}
                >
                    Apply filters
                </Button>
            </CardActions>
        </Card>
    )
}
