import { gql } from "@apollo/client"

//marketplace listing using pagination and sort
export const LOAD_MARKETPLACES = gql`
    query allMarketplacesPaginated(
        $orderBy: [String]!
        $nameFilter: String
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allMarketplaces(
            orderBy: $orderBy
            name_Icontains: $nameFilter
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    name
                    fullName
                    region
                    description
                    image
                    isEnabled
                    marketplaceMs {
                        edges {
                            node {
                                id
                                service {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

//marketplace details
export const LOAD_MARKETPLACE_SERVICES = gql`
    query marketplaceDetails($id: ID!) {
        marketplace(id: $id) {
            id
            name
            fullName
            region
            description
            image
            isEnabled
            marketplaceMs {
                edges {
                    node {
                        id
                        service {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

//all regions
export const LOAD_ALL_REGIONS = gql`
    query allRegions {
        allRegions
    }
`

//add new marketplace
export const CREATE_MARKETPLACE = gql`
    mutation createMarketplace(
        $name: String!
        $region: String
        $description: String
        $file: Upload
        $isEnabled: Boolean!
    ) {
        createMarketplace(
            input: { name: $name, region: $region, description: $description, fileIn: $file, isEnabled: $isEnabled }
        ) {
            marketplace {
                id
                name
            }
        }
    }
`

//update marketplace
export const UPDATE_MARKETPLACE = gql`
    mutation updateMarketplace(
        $id: ID!
        $deleteActualImagen: Boolean
        $name: String!
        $region: String
        $description: String
        $file: Upload
        $isEnabled: Boolean!
    ) {
        updateMarketplace(
            id: $id
            deleteActualImagen: $deleteActualImagen
            input: { name: $name, region: $region, description: $description, fileIn: $file, isEnabled: $isEnabled }
        ) {
            marketplace {
                id
                name
            }
        }
    }
`

//update marketplace
export const PATCH_MARKETPLACE = gql`
    mutation patchMarketplace($id: ID!, $isEnabled: Boolean) {
        patchMarketplace(id: $id, input: { isEnabled: $isEnabled }) {
            marketplace {
                id
                name
                region
                description
                image
                isEnabled
            }
        }
    }
`

//delete marketplace
export const DELETE_MARKETPLACE = gql`
    mutation deleteMarketplace($id: ID!, $option: Int) {
        deleteMarketplace(id: $id, option: $option) {
            found
            deletedId
            apisResponse {
                marketplace
                type
                messages
            }
        }
    }
`

//add new marketplace service
export const CREATE_MARKETPLACE_SERVICE = gql`
    mutation createMarketplaceService($marketplace: ID!, $service: ID!) {
        createMarketplaceService(input: { marketplace: $marketplace, service: $service }) {
            marketplaceService {
                id
            }
        }
    }
`

//add new marketplace service
export const DELETE_MARKETPLACE_SERVICE = gql`
    mutation deleteMarketplaceService($id: ID!) {
        deleteMarketplaceService(id: $id) {
            found
            deletedId
        }
    }
`
