import { whiteColor, primaryColor, grayColor } from "assets/jss/material-dashboard-react.js"
import tooltipStyle from "assets/jss/material-dashboard-react/tooltipStyle"

const productCardStyle = (theme) => ({
    actionArea: {
        transition: "0.2s",
        "&:hover": {
            transform: "scale(1.008)",
        },
    },
    card: {
        borderRadius: 0,
        maxWidth: 300,
        width: 270,
        padding: 5,
        position: "relative",
        backgroundColor: primaryColor[2],
        boxShadow: "none",
        "&:hover": {
            boxShadow: `0 4px 6px 0 rgba(0,0,0,0.5)`,
        },
    },
    imgContainer: {
        width: "100%",
        maxHeight: "190px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    img: {
        width: "135px",
        maxWidth: "135px",
        height: "115px",
        maxHeight: "115px",
    },
    noImage: {
        width: "120px",
        maxWidth: "120px",
        height: "100px",
        maxHeight: "100px",
        opacity: 0.6,
    },
    imageFooter: {
        height: "20px",
        color: whiteColor,
    },
    cardContent: {
        display: "flex",
        textAlign: "center",
        padding: "0 10px",
        height: "66px",
        cursor: "pointer",
    },
    alertIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "18px",
        padding: "0",
    },
    alertText: {
        fontSize: "14px",
    },
    cardActions: {
        display: "flex",
        justifyContent: "space-between",
    },
    avatar: {
        backgroundColor: primaryColor[1],
    },
    speedDialBtn: {
        position: "absolute",
        "& .MuiFab-root": {
            height: "40px",
            width: "40px",
            backgroundColor: "rgba(0,180,216,0.7)",
            color: whiteColor,
        },
        "&.MuiSpeedDial-directionDown": {
            top: theme.spacing(1),
            right: theme.spacing(0),
        },
    },

    //marketplaces field inserted
    insertedMarketplaceAvatar: {
        width: 25,
        height: 25,
        "& .MuiAvatar-img": {
            width: "auto",
            height: "85%",
        },
    },

    marketplaceAvatar: {
        width: 25,
        height: 25,
        border: `1px solid ${primaryColor[1]}`,
        "& .MuiAvatar-img": {
            width: "auto",
            height: "90%",
        },
    },

    //info tooltip
    infoTooltip: {
        ...tooltipStyle.tooltip,
    },

    //no data info
    noDataSection: {
        width: "100%",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#a9afbb",
    },

    //skeleton loading
    actionAreaSkeleton: {
        borderRadius: 0,
    },
    cardSkeleton: {
        maxWidth: 300,
        width: 270,
        position: "relative",
        backgroundImage: `linear-gradient(to top right, ${grayColor[6]}, ${primaryColor[4]}, ${primaryColor[1]})`,
        borderRadius: 0,
        boxShadow: `0 4px 8px 0 rgba(0,0,0,0.5)`,
    },
    avatarSkeleton: {
        backgroundColor: grayColor[6],
    },
    imgSkeleton: {
        width: "auto",
        maxHeight: "130px",
        opacity: 0.4,
    },
})

export default productCardStyle
