import React from "react";
import {Route, Switch} from "react-router-dom";

//layout
import TopLinks from "components/LandingPage/TopLinks";
import Footer from "components/LandingPage/Footer";

//assets
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/layouts/publicLayoutStyle";

//views
import Login from "views/App/Login";
import NewAccount from "views/App/NewAccount";
import VerifyAccount from "views/App/VerifyAccount";
import AutoVerifyAccount from "views/App/AutoVerifyAccount";
import RecoverPassword from "views/App/RecoverPassword";
import ResetPassword from "views/App/ResetPassword";
import PrivacyPolicy from "views/App/PrivacyPolicy";
import ServiceTerms from "views/App/ServiceTerms";
import HowItWorks from "views/App/HowItWorks";
import MarketplacesInfo from "views/App/MarketplacesInfo";
import FAQ from "views/App/FAQ";
import FeatureImportProduct from "views/App/FeatureImportProduct";
import FeatureProductAdds from "views/App/FeatureProductAdds";
import LandingPage from "views/App/LandingPage";
import AmazonConnectionResponse from "components/Seller/Apis connection/AmazonConnectionResponse";
import PublicProductFacebookDetails from "../views/PublicProductDetails/PublicProductFacebookDetails"

const useStyles = makeStyles(styles);

export default function PublicLayout() {
    const classes = useStyles();

    const PublicLayout = (props) => {
        return (
            <>
                <TopLinks/>
                <div className={classes.container}>
                    {props.children}
                </div>
                <Footer/>
            </>
        )
    }

    const LandingPageLayout = () => {
        return (
            <>
                <TopLinks/>
                <LandingPage/>
                <Footer/>
            </>
        )
    }

    return (
        <Switch>
            {/*Public routes*/}
            <Route exact path="/login" children={<PublicLayout><Login/></PublicLayout>}/>
            <Route exact path="/account/create" children={<PublicLayout><NewAccount/></PublicLayout>}/>
            <Route exact path="/account/verify" children={<PublicLayout><VerifyAccount/></PublicLayout>}/>
            <Route exact path="/account/verify/:token" render={(props) => <PublicLayout><AutoVerifyAccount {...props}/></PublicLayout>}/>
            <Route exact path="/account/recover-password" children={<PublicLayout><RecoverPassword/></PublicLayout>}/>
            <Route exact path="/account/reset-password/:token" render={(props) => <PublicLayout><ResetPassword {...props}/></PublicLayout>}/>
            <Route exact path="/privacy-policy" children={<PublicLayout><PrivacyPolicy/></PublicLayout>}/>
            <Route exact path="/service-terms" children={<PublicLayout><ServiceTerms/></PublicLayout>}/>
            <Route exact path="/how-works" children={<PublicLayout><HowItWorks/></PublicLayout>}/>
            <Route exact path="/marketplaces" children={<PublicLayout><MarketplacesInfo/></PublicLayout>}/>
            <Route exact path="/help" children={<PublicLayout><FAQ/></PublicLayout>}/>
            <Route exact path="/import-product" children={<PublicLayout><FeatureImportProduct/></PublicLayout>}/>
            <Route exact path="/product-ads" children={<PublicLayout><FeatureProductAdds/></PublicLayout>}/>
            <Route exact path="/activation_amazon" children={<PublicLayout><AmazonConnectionResponse/></PublicLayout>}/>

            <Route exact path="/product/:productId" render={(props) => <PublicLayout><PublicProductFacebookDetails {...props}/></PublicLayout>}/>

            {/*Landing page layout.*/}
            <Route exact path="/" component={LandingPageLayout}/>

            {/*ToDo: when route does not not math and not in seller layout, redirect to landing page*/}
        </Switch>
    )
};
