import React from "react"
import { useHistory } from "react-router-dom"

import { TextField, Button, InputAdornment, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { EditOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons"

import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"

import { Formik } from "formik"
import * as Yup from "yup"

//apollo
import { Mutation } from "@apollo/client/react/components"
import { PASSWORD_CHANGE } from "querys/auth/loginQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//assets
import styles from "assets/jss/material-dashboard-react/views/cardFormStyle"
import CardFooter from "../../../components/Card/CardFooter"

const useStyles = makeStyles(styles)

export default function PasswordChange(props) {
    const classes = useStyles()

    const history = useHistory()

    const [showOldPassword, setShowOldPassword] = React.useState(false)
    const [showNewPassword1, setShowNewPassword1] = React.useState(false)
    const [showNewPassword2, setShowNewPassword2] = React.useState(false)
    const toggleVisibilityOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    }
    const toggleVisibilityNewPassword1 = () => {
        setShowNewPassword1(!showNewPassword1)
    }
    const toggleVisibilityNewPassword2 = () => {
        setShowNewPassword2(!showNewPassword2)
    }

    const notifyError = (message) => ErrorNotification("Change password", message)
    const notifySuccess = () => SuccessNotification("Change password", "Password change succeed")

    const cancelAction = () => {
        history.push("/seller/profile")
    }

    const successForm = () => {
        notifySuccess()
        history.push("/seller/profile")
    }

    const validationSchema = Yup.object({
        oldPassword: Yup.string().required("field required"),
        newPassword1: Yup.string().required("field required").min(8, "The password most contain 8 characters at least"),
        newPassword2: Yup.string()
            .required("field required")
            .when("newPassword1", {
                is: (val) => !!(val && val.length > 0),
                then: Yup.string().oneOf([Yup.ref("newPassword1")], "Passwords does not match"),
            }),
    })

    function getErrors(errors) {
        let errorsObject = {}
        Object.entries(errors).forEach(([field, fieldErrors]) => {
            if (field === "nonFieldErrors") {
                fieldErrors.forEach((error) => notifyError(error.message))
            } else {
                let errorMessages = []
                fieldErrors.forEach((error) => errorMessages.push(error.message))
                errorsObject[field] = errorMessages
            }
        })
        return errorsObject
    }

    const Form = (props) => {
        const formik = props.formik

        return (
            <GridContainer item xs={12} sm={8} md={7} lg={4} xl={3}>
                <GridItem xs={12}>
                    <TextField
                        type={showOldPassword ? "text" : "password"}
                        name="oldPassword"
                        label="Old Password*"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        className={classes.formFields}
                        {...formik.getFieldProps("oldPassword")}
                        error={formik.errors.oldPassword && formik.touched.oldPassword}
                        helperText={
                            formik.errors.oldPassword && formik.touched.oldPassword && formik.errors.oldPassword
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={toggleVisibilityOldPassword}>
                                    {showOldPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <TextField
                        type={showNewPassword1 ? "text" : "password"}
                        name="newPassword1"
                        label="New Password*"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        className={classes.formFields}
                        {...formik.getFieldProps("newPassword1")}
                        error={formik.errors.newPassword1 && formik.touched.newPassword1}
                        helperText={
                            formik.errors.newPassword1 && formik.touched.newPassword1 && formik.errors.newPassword1
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={toggleVisibilityNewPassword1}>
                                    {showNewPassword1 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <TextField
                        type={showNewPassword2 ? "text" : "password"}
                        name="newPassword2"
                        label="Confirm Password*"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        className={classes.formFields}
                        {...formik.getFieldProps("newPassword2")}
                        error={formik.errors.newPassword2 && formik.touched.newPassword2}
                        helperText={
                            formik.errors.newPassword2 && formik.touched.newPassword2 && formik.errors.newPassword2
                        }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" onClick={toggleVisibilityNewPassword2}>
                                    {showNewPassword2 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                </InputAdornment>
                            ),
                        }}
                    />
                </GridItem>
            </GridContainer>
        )
    }

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="danger" className={classes.cardHeaderIcon}>
                    <EditOutlined />
                </CardIcon>
                <h4 className={classes.cardTitle}>Change password</h4>
            </CardHeader>
            <Mutation mutation={PASSWORD_CHANGE}>
                {(register, { loading }) => (
                    <Formik
                        initialValues={{
                            oldPassword: "",
                            newPassword1: "",
                            newPassword2: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setErrors }) => {
                            register({
                                variables: {
                                    oldPassword: data.oldPassword,
                                    newPassword1: data.newPassword1,
                                    newPassword2: data.newPassword2,
                                },
                            }).then(
                                (response) => {
                                    let dataResponse = response.data.passwordChange

                                    const errors = dataResponse.errors
                                    if (errors) {
                                        return setErrors(getErrors(errors))
                                    }

                                    if (dataResponse.success) {
                                        return successForm()
                                    }
                                },
                                () => {
                                    notifyError("Update error, try again")
                                }
                            )
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <CardBody>
                                    <GridContainer justifyContent={"center"}>
                                        <Form formik={formik} />
                                    </GridContainer>
                                </CardBody>

                                <CardFooter chart>
                                    <Button
                                        size={"small"}
                                        variant={"text"}
                                        color={"secondary"}
                                        onClick={cancelAction}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        size={"small"}
                                        variant={"text"}
                                        color={"primary"}
                                        type="submit"
                                        disabled={!formik.isValid || loading}
                                    >
                                        {loading ? <CircularProgress size={24} /> : "Save"}
                                    </Button>
                                </CardFooter>
                            </form>
                        )}
                    </Formik>
                )}
            </Mutation>
        </Card>
    )
}
