import React from "react"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/productCardStyle"

//material ui
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

import img from "assets/img/035-online shopping.svg"

const useStyles = makeStyles(styles)

export const RowSkeleton = () => {
    const classes = useStyles()

    const Row = () => (
        <Grid item container spacing={2}>
            <Grid item xs={"auto"}>
                <img src={img} alt="Product" className={classes.imgSkeleton} />
            </Grid>
            <Grid item xs={true} style={{ display: "flex" }}>
                <Grid container alignItems={"center"} style={{ alignSelf: "center" }}>
                    <Grid item xs={12}>
                        <Skeleton variant={"text"} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton variant={"text"} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton variant={"text"} style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12}>
                        <Skeleton variant={"text"} style={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={"auto"} style={{ display: "flex" }}>
                <Grid container spacing={1} alignItems={"center"} style={{ alignSelf: "center" }}>
                    <Grid container item justifyContent={"center"}>
                        <Skeleton variant={"circle"} style={{ width: "20px", height: "20px" }} />
                    </Grid>
                    <Grid container item justifyContent={"center"}>
                        <Skeleton variant={"circle"} style={{ width: "20px", height: "20px" }} />
                    </Grid>
                    <Grid container item justifyContent={"center"}>
                        <Skeleton variant={"circle"} style={{ width: "20px", height: "20px" }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    return (
        <Grid container item spacing={2} style={{ padding: "10px" }}>
            <Row />
            <Row />
            <Row />
        </Grid>
    )
}

export const TableNoData = () => {
    const classes = useStyles()

    return <div className={classes.noDataSection}>No data to show</div>
}

export const tagsHints = ["Hardware", "Shoes", "Clothe", "Computer"]
