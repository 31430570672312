import React from "react"

import {
    Button,
    Chip,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { SecurityOutlined } from "@material-ui/icons"

//apollo
import { useLazyQuery } from "@apollo/client"
import { CREATE_SELLER_ETSY } from "querys/seller/Etsy/etsyConnectQueries"
import { LOAD_MARKETPLACE_SERVICES } from "querys/seller/Marketplace/marketplaceQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//assets
import styles from "assets/jss/material-dashboard-react/views/newAccountStyle"

//router
import { useHistory, useLocation } from "react-router-dom"

//components
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import CardBody from "components/Card/CardBody"
import Card from "components/Card/Card"
import CardFooter from "components/Card/CardFooter"

//errors
import { useFormik } from "formik"
import * as Yup from "yup"
import { getFormErrors } from "utils/errorsUtils"

const useStyles = makeStyles(styles)

export default function EbayConnectionResponse(props) {
    const classes = useStyles()

    const history = useHistory()

    function useLocationFunction() {
        return new URLSearchParams(useLocation().search)
    }

    let askParams = useLocationFunction()

    const codeParam = askParams.get("code")
    const stateParam = askParams.get("state")
    const errorParam = askParams.get("error")
    const errorDescriptionParam = askParams.get("error_description")

    const stateParts = stateParam ? stateParam.split("_") : []

    const notifyError = (message) => ErrorNotification("Etsy connection", message)
    const notifySuccess = (message) => SuccessNotification("Etsy connection", message)

    const [serviceOptions, setServiceOptions] = React.useState([])
    const [loadMarketplaceServices] = useLazyQuery(LOAD_MARKETPLACE_SERVICES, {
        onCompleted: (data) => {
            if (data?.marketplace?.id) {
                const services = data.marketplace.marketplaceMs.edges.map((item) => item.node.service)
                setServiceOptions(services)
            }
        },
    })

    const [isNewAccount] = React.useState(!stateParam?.includes("reconnect"))

    //load services when is a new account
    React.useEffect(() => {
        if (isNewAccount && stateParts.length > 0) {
            const marketplaceId = stateParts[0]
            loadMarketplaceServices({ variables: { id: marketplaceId } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isNewAccount])

    const [createdSellerSuccessfully, setCreatedSellerSuccessfully] = React.useState(null)
    const [createSeller, { loading: loadingSellerEtsy }] = useLazyQuery(CREATE_SELLER_ETSY, {
        onCompleted: (data) => {
            if (data.createSellerEtsy && data.createSellerEtsy.success) {
                notifySuccess("Connection done")
                setCreatedSellerSuccessfully(true)
            } else {
                notifyError(data.createSellerEtsy.reason)
                setCreatedSellerSuccessfully(false)
            }
        },
        onError: (response) => {
            if (response?.graphQLErrors?.length) {
                getFormErrors(response.graphQLErrors, notifyError, formik.setErrors)
            } else {
                notifyError("Connection failed, try again")
                setCreatedSellerSuccessfully(false)
            }
        },
    })

    const initialValues = {
        auxValue: true,
        accountName: "",
        accountServices: [],
    }

    const validationSchema = Yup.object({
        accountName: Yup.string().when("auxValue", {
            is: () => isNewAccount,
            then: Yup.string().required("Type a name for the account"),
        }),
        accountServices: Yup.array().when("auxValue", {
            is: () => isNewAccount,
            then: Yup.array().required("Select the services you pretend to use with current account"),
        }),
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let alias = values.accountName

            let newStateParam = stateParam

            if (!isNewAccount && stateParam.length > 1) {
                let parts = [...stateParts]
                parts.shift()
                newStateParam = parts.join("_")

                alias = stateParts[1]
            }

            await createSeller({
                variables: {
                    alias: alias,
                    serviceIds: values.accountServices.map((item) => item.id),
                    code: codeParam,
                    status: newStateParam,
                    error: errorParam,
                    errorDescription: errorDescriptionParam,
                },
            })
        },
    })

    //close popup ebay authentication window or connection view
    const closeView = () => {
        let parentWindow = window.opener
        if (parentWindow) {
            if (createdSellerSuccessfully) {
                parentWindow.location.href = `/seller/connection`
            } else {
                parentWindow.location.href = `/seller/connection/form`
            }
            window.close()
        } else {
            history.replace("/seller/connection")
        }
    }

    const ITEM_HEIGHT = 48
    const ITEM_PADDING_TOP = 8
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    }

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="success" className={classes.cardHeaderIcon}>
                    <SecurityOutlined />
                </CardIcon>
                <h4 className={classes.cardTitle}>Etsy connection</h4>
            </CardHeader>
            <CardBody>
                <Grid container item xs={12} sm={8} md={5} lg={4} xl={2} spacing={3}>
                    {isNewAccount ? (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    name="name"
                                    label="Account name"
                                    variant={"filled"}
                                    size={"small"}
                                    fullWidth
                                    {...formik.getFieldProps("accountName")}
                                    error={!!formik.errors.accountName && !!formik.touched.accountName}
                                    helperText={
                                        formik.errors.accountName &&
                                        formik.touched.accountName &&
                                        formik.errors.accountName
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Select
                                        name={"services"}
                                        id={"services"}
                                        multiple
                                        variant={"filled"}
                                        {...formik.getFieldProps("accountServices")}
                                        error={!!formik.errors.accountServices && !!formik.touched.accountServices}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) => (
                                            <div className={classes.chips}>
                                                {selected.map((value) => (
                                                    <Chip
                                                        variant="outlined"
                                                        key={value.id}
                                                        label={value.name}
                                                        size={"small"}
                                                        className={classes.chip}
                                                    />
                                                ))}
                                            </div>
                                        )}
                                    >
                                        {serviceOptions.map((option) => (
                                            <MenuItem key={option.id} value={option}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <InputLabel id="services">Account services</InputLabel>
                                </FormControl>
                                <FormHelperText style={{ color: "rgb(244, 67, 57)", paddingLeft: "10px" }}>
                                    {formik.errors.accountServices}
                                </FormHelperText>
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12} style={{ padding: "15px" }}>
                            <Typography variant={"subtitle1"}>Reconnecting account {stateParam}</Typography>
                        </Grid>
                    )}
                </Grid>

                <Grid container justifyContent={"center"}>
                    {loadingSellerEtsy && <CircularProgress size={34} />}
                </Grid>
            </CardBody>
            <CardFooter chart>
                {createdSellerSuccessfully === null ? (
                    <Button
                        size={"small"}
                        variant={"text"}
                        color={"primary"}
                        disabled={!formik.isValid}
                        onClick={formik.submitForm}
                    >
                        {isNewAccount ? "Create account" : "Reconnect"}
                    </Button>
                ) : (
                    <Button
                        size={"small"}
                        variant={"text"}
                        color={"primary"}
                        onClick={closeView}
                        disabled={loadingSellerEtsy}
                    >
                        {createdSellerSuccessfully ? "Finish" : "Try Again"}
                    </Button>
                )}
            </CardFooter>
        </Card>
    )
}
