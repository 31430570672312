import React from "react"

//material ui
import { Avatar, Box, Grid, Typography } from "@material-ui/core"

//icons
import ViewListOutlineIcon from "mdi-react/ViewListOutlineIcon"
import HandPointingRightIcon from "mdi-react/HandPointingRightIcon"
import ShoppingOutlineIcon from "mdi-react/ShoppingOutlineIcon"
import ShopOutlineIcon from "mdi-react/ShopOutlineIcon"
import CategoryOutlineIcon from "mdi-react/CategoryOutlineIcon"
import ImportIcon from "mdi-react/ImportIcon"
import TuneVerticalIcon from "mdi-react/TuneVerticalIcon"
import ColorizeIcon from "mdi-react/ColorizeIcon"
import StateMachineIcon from "mdi-react/StateMachineIcon"
import SettingsIcon from "mdi-react/SettingsIcon"
import FlagOutlineIcon from "mdi-react/FlagOutlineIcon"
import SphereDiameterOutlineIcon from "mdi-react/SphereDiameterOutlineIcon"
import RibbonIcon from "mdi-react/RibbonIcon"
import UserBadgeIcon from "mdi-react/UserBadgeIcon"
import BarcodeIcon from "mdi-react/BarcodeIcon"
import UserCardDetailsIcon from "mdi-react/UserCardDetailsIcon"
import WeightKgIcon from "mdi-react/WeightKgIcon"
import FaceProfileIcon from "mdi-react/FaceProfileIcon"
import AmazonIcon from "mdi-react/AmazonIcon"
import FacebookIcon from "mdi-react/FacebookIcon"
import MessageOutlineIcon from "mdi-react/MessageOutlineIcon"
import PostOutlineIcon from "mdi-react/PostOutlineIcon"
import { DescriptionOutlined, NotificationsActiveOutlined, StoreOutlined } from "@material-ui/icons"

//marketplace images
import EtsyBlack_icon from "assets/img/marketplaces/EtsyBlack_icon.svg"
import EtsyWhite_icon from "assets/img/marketplaces/EtsyEmpty_icon.svg"
import EbayBlack_icon from "assets/img/marketplaces/EbayBlack_icon.svg"
import EbayWhite_icon from "assets/img/marketplaces/EbayEmpty_icon.svg"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/views/configurationStyle"

//router
import { Link } from "react-router-dom"

//btn display info text
import { Btn } from "utils/GeneralUsageUtils"
import BookOutlineIcon from "mdi-react/BookOutlineIcon"

const useStyles = makeStyles(styles)

const DescriptionBox = (props) => (
    <Box display={"flex"} width={"100%"}>
        <Box display={"flex"} flexGrow={2} alignItems={"center"} p={1}>
            {props.icon}
        </Box>
        <Box width={"100%"} alignItems={"center"} p={1}>
            {props.children}
        </Box>
    </Box>
)

const EmphasisText = ({ text }) => <em style={{ fontWeight: 800 }}>{text}</em>

//seller pages description*************************************

export const ProfileView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <FaceProfileIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/profile"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Profile{" "}
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Here users can check all personal information provided.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                <EmphasisText text={"User"} /> and <EmphasisText text={"Seller"} /> info are presented. Modify them at
                any time.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"edit"} /> buttons to update the information.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Also available <Btn name={"Chance password"} /> option when editing user information.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const MarketplaceView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <ShopOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/marketplace"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Marketplace
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Here, user can see a list of <EmphasisText text={"integrated marketplaces"} /> in Sync2markets.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                <EmphasisText text={"Integrated marketplaces"} /> are those in witch by your name Sync2Markets can do
                some actions. You can integrate any marketplace from those who are available in our system.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Add marketplace"} /> button to integrate a new marketplace, select one from
                        available then <Btn name={"Add"} /> button. Fallow and complete authentication/authorization
                        process prompted there. To accomplish it user must have an account at selected marketplace.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete marketplace"} /> button to delete an already integrated marketplace.
                        After this action, if user want to integrate that marketplace again has to accomplish
                        integration process again.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const CategoryView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <CategoryOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/category"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Category
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Here, user can see a list of <EmphasisText text={"categories."} />
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                Categories are used for to organize your products. These can be created as a tree, meaning that a
                category can be a child of another existing category or a root category if desire.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        User can use categories with a structure of department > subDepartment > subSubDepartment... and
                        so on. Use this structure to easy locate your products.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Category isn't a mandatory field for product. So, you can have your product without any category
                        at all.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const ImportView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <ImportIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Import
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is intended to <EmphasisText text={"import products"} /> to Sync2Markets from available
                marketplaces.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                Import option target is to help users in the creation of products, product' ad and product variations
                with minimum effort. With this option, an user can import its product' s stock at different marketplaces
                and standardize it at all marketplaces.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        When user has some product at sell in some available marketplace can use the option{" "}
                        <Btn name={"Listings from ..."} /> in the button with the marketplace icon in question (lets
                        refer it as target marketplace).
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        After that previous action, metadata about all user' s products available in target marketplace
                        will be presented waiting for user to choose witch ones would like to import to Syc2Markets.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Press <Btn name={"Import selected"} /> button to import a selection of products. When a product
                        is successfully imported, some actions happens in background.
                    </li>
                    <ol>
                        <li>A new product is created at Syc2Markets for every selection from step 2.</li>
                        <li>
                            When a product from selected ones has variations those variations are created at
                            Sync2Markets.
                        </li>
                        <li>
                            For every new product is created its <em>product' ad</em> for corresponding marketplace.
                        </li>
                        <li>
                            All those <em>marketplace fields</em> and its corresponding product will be automatically
                            linked with its origin at target marketplace.
                        </li>
                        <li>Now on you can control your product's status at target marketplace using Sync2Markets.</li>
                    </ol>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Another import option, available only for Amazon, is to import a product (or a list of products)
                        using its ASIN value. For those products, user needs to insert some missing information in{" "}
                        <em>product' ad</em> and then upload product to Amazon.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const ProductView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <ShoppingOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Product
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>Here, user can manage its products.</DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to be the central entity of Sync2Markets. From that point all actions available in
                Sync2Markets could be done. For our system, products are the essence, so we show them in a different
                way.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Add product"} /> to create products. When user select this option needs to
                        provide some information about the product. Minimum required information is requested.
                    </li>
                    <ol>
                        <li>
                            Although typing information in digital forms es tedious, user most provide all possible
                            fields. All marketplaces has different information needs, so you must ensure to provide
                            these information.
                        </li>
                        <li>
                            Some information fields of product form are for a specific marketplace. All marketplaces has
                            different information needs, so you must ensure to provide these information.
                        </li>
                    </ol>
                </Grid>

                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"See variations"} /> to manage <EmphasisText text={"product's variations"} />.
                    </li>
                </Grid>

                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"See details"} /> to see all product' details and related information.
                    </li>
                </Grid>

                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit product"} /> to edit product's details.
                    </li>
                </Grid>

                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete product"} /> to delete product and related information if desire.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const VariationsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <TuneVerticalIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Product' variations
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Here, user can manage all <EmphasisText text={"product' variation"} /> for a selected product.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                Users can see products variations as another product that variates in some feature around a main
                product. Those variable features are known as <EmphasisText text={"variation field"} /> and could be
                material and/or color at Sync2Markets.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Variations can only be created, modified or deleted as a unique item composed by a group of
                        variations.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        User can use <Btn name={"Modify variations"} /> to modify a specific value from an already
                        create variation but using the same variation fields and values selected when variations were
                        created.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete variations"} /> button to delete all group of variations.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        If your intention is to change the variation fields or values selected from an already created
                        variations, the only way is <Btn name={"Delete variations"} /> and then create again the
                        variations as desired.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Linked images"} /> button to see the associated images.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const VariationsInsertView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <TuneVerticalIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Create Product's variations
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Here, user can manage create/update <EmphasisText text={"product's variation"} /> for a selected
                product.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                Users can see products variations as another product that variates in some feature around a main
                product. Those variable features are known as <EmphasisText text={"variation field"} /> and could be
                material and/or color at Sync2Markets.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        First user needs to select one or both variation field depending on which one your product
                        variates.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        After that, select (or type new ones) the values for the variation fields and press{" "}
                        <Btn name={"Select"} /> button. All possible combinations for selected values will be used to
                        create the variations.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>Next, provide the requested information in corresponding fields.</li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Some marketplaces like Amazon or Ebay require its own ID to identify the product. So, user need
                        to select the ID Type and value to use as identifier. Multiple ID Types must be used because
                        different marketplaces uses different ID Types to recognize its products.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        When a product is uploaded to some marketplace, its used ID Type and value must not be modified
                        or deleted.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>All variations must use same ID Type. All used ID Types must also be used in main product.</li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        <Btn name={"Link images"} /> button is designed to link zero, one or more images to every value
                        selected for each variation field. Available images are those who where inserted in current
                        product.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        <Btn name={"Create"} /> button to create the variations.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const SellView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <StoreOutlined style={{ paddingRight: 10, fontSize: 30 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Product sell
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Sync2Markets needs some product's data (<em>marketplace fields</em>) for every marketplace you decide to
                sell in.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is intended to control your product status for available marketplaces and help user to sell
                product with minimum effort.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Information icon over a marketplace's name present the status of communication to reach and
                        request information from marketplace.
                    </li>
                    <ol>
                        <li>
                            When communication to marketplace is fully established (blue information icon), user can
                            execute actions for the marketplace.
                        </li>
                        <li>
                            A communication error (red information icon) prevents the user to realize actions for the
                            marketplace. This error usually occurs by a problem with user connection to reach
                            marketplace or user credentials provided to Sync2Markets and used to request information in
                            your name.
                        </li>
                    </ol>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert marketplace fields"} /> button to insert{" "}
                        <EmphasisText text={"marketplace fields"} />.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Search in Amazon's catalog"} /> button to create{" "}
                        <EmphasisText text={"marketplace fields"} /> from an existing product. This option is only
                        available for Amazon.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Once your product data for desire marketplace is inserted upload product to marketplace. Use{" "}
                        <Btn name={"Upload"} /> button.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        If marketplace permit it you can use <Btn name={"Start/Pause selling"} /> button to accomplish
                        those actions.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        <Btn name={"Remove product"} /> button to delete ad from marketplace.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        <Btn name={"Delete product fields"} /> button to delete information at Sync2Markets. Also
                        corresponding ad at marketplace if desired.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const AmazonFieldsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <AmazonIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Amazon fields
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is about that information required just for Amazon.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is intended to recover information about your product for Amazon in order to sell at this
                marketplace.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        First you need to select a category where your product fits in and fill some properties that
                        depends on the selected category. Also, other information fields will be requested too.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Once all information has been provided use <Btn name={"Create"} /> button to create Amazon
                        information fields at Sync2Markets.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Then you can proceed to <Btn name={"Upload"} /> your product to Amazon.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const EtsyFieldsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography
                    variant={"body1"}
                    component={"div"}
                    style={{ display: "inline-flex", alignItems: "center" }}
                >
                    <Avatar
                        src={darkStyle ? EtsyWhite_icon : EtsyBlack_icon}
                        variant={"rounded"}
                        className={classes.marketplaceAvatar}
                        style={{ paddingRight: 5 }}
                    />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Etsy fields
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is about that information required just for Etsy.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is intended to recover information about your product for Etsy in order to sell at this
                marketplace.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        First you need to select a category where your product fits in and fill some properties that
                        depends on the selected category. Also, other information fields will be requested too.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Once all information has been provided use <Btn name={"Create"} /> button to create Etsy
                        information fields at Sync2Markets.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Then you can proceed to <Btn name={"Upload"} /> your product to Etsy.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const EbayFieldsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography
                    variant={"body1"}
                    component={"div"}
                    style={{ display: "inline-flex", alignItems: "center" }}
                >
                    <Avatar
                        src={darkStyle ? EbayWhite_icon : EbayBlack_icon}
                        variant={"rounded"}
                        className={classes.marketplaceAvatar}
                        style={{ paddingRight: 5 }}
                    />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Ebay fields
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is about that information required just for Ebay.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is intended to recover information about your product for Ebay in order to sell at this
                marketplace.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        First you need to select a category where your product fits in and fill some properties that
                        depends on the selected category. Also, other information fields will be requested too.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Once all information has been provided use <Btn name={"Create"} /> button to create Ebay
                        information fields at Sync2Markets.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Then you can proceed to <Btn name={"Upload"} /> your product to Ebay.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const FacebookFieldsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography
                    variant={"body1"}
                    component={"div"}
                    style={{ display: "inline-flex", alignItems: "center" }}
                >
                    <FacebookIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Facebook fields
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is about that information required just for Facebook.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is intended to recover information about your product for Facebook in order to sell at this
                marketplace.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        First you need to select a category where your product fits in and fill some properties that
                        depends on the selected category. Also, other information fields will be requested too.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Once all information has been provided use <Btn name={"Create"} /> button to create Facebook
                        information fields at Sync2Markets.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Then you can proceed to <Btn name={"Upload"} /> your product to Facebook.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const MercariFieldsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography
                    variant={"body1"}
                    component={"div"}
                    style={{ display: "inline-flex", alignItems: "center" }}
                >
                    <FacebookIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Mercari fields
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is about that information required just for Mercari.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is intended to recover information about your product for Facebook in order to sell at this
                marketplace.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        First you need to select a category where your product fits in and fill some properties that
                        depends on the selected category. Also, other information fields will be requested too.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const SearchInCatalogView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <AmazonIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Search in Amazon catalog
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is intended to search an equivalent product/variation in Amazon catalog.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                If your product/variations has an equivalent in Amazon catalog, then you can search it an create your{" "}
                <EmphasisText text={"Amazon' ad"} /> and relate you product and variations with those in catalog. If
                your product/variations exist in Amazon then you can sell that product too.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        First add some keywords and then use <Btn name={"Search"} /> button to find matching products at
                        Amazon.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        If you like to reduce your search results add some refinements and use <Btn name={"Search"} />{" "}
                        button again.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Select product"} /> button for a convenient product.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const CreateFromCatalogView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <AmazonIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/product"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Create from Amazon catalog
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is intended to relate your product/variations with those existing in Amazon catalog.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                Depending on your product/variations in Sync2Markets and product/variations from selected one in
                catalog, user can relate them.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Depending on your product/variations in Sync2Markets and product/variations from selected one in
                        catalog user will be asked to establish relations between them.
                    </li>
                    <ul>
                        <li>
                            When selected product from catalog has not variations, user can relate his product, but not
                            variations if they exist. To proceed click <Btn name={"CREATE"} /> button.
                        </li>
                        <li>
                            System product has more variations than selected product from catalog. Here user can relate
                            his product but not variations due to insufficient number of variations in catalog. User can
                            use any variation from catalog as related product. Copy an ASIN value from catalog
                            variations to prompted field. Once that field has value click <Btn name={"CREATE"} />{" "}
                            button."
                        </li>
                        <li>
                            When system product has no variations, user can relate his product using any variation from
                            catalog as related product. Copy an ASIN value from catalog variations to prompted field.
                            Once that field has value click <Btn name={"CREATE"} /> button.
                        </li>
                        <li>
                            When product from catalog has more variations than product from Sync2Markets, user will be
                            asked to decide if would like to relate all its product' variations with existing in
                            selected product from catalog. Depending of its response, actions most be taken.
                        </li>
                        <ul>
                            <li>
                                When answer to previous question is "yes", user needs to relate his system product'
                                variations with existing in catalog (copy and paste a different ASIN value for every
                                system variation from catalog variations in fields inside table with variations in
                                System product information below). Once all system variations has ASIN click{" "}
                                <Btn name={"CREATE"} /> button.
                            </li>
                            <li>
                                If answer is "no", user still can relate his product with any variation from catalog
                                (copy the ASIN value from selected variation and paste value in prompted field). Once
                                that field has value click <Btn name={"CREATE"} /> button.
                            </li>
                        </ul>
                    </ul>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        When all requested data has been provided proceed to <Btn name={"Upload"} /> your product to
                        Amazon.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const MarketplaceOrdersView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <BookOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/orders"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Platforms orders
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is intended to check information about generated orders.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                User request orders from all its connected platforms.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>Orders provided by all connected platforms is presented.</li>
                </Grid>
                <Grid item xs={12}>
                    <li>Information about Sync2Markets products implied in those orders are also presented.</li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const MessageCenterView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <MessageOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/message-center"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Message center
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is intended to see and respond messages originated in user's connected marketplaces.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                User request messages from one of its connected marketplace available at Sync2Markets. Options
                available: Ebay.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>Select one marketplace to request and present messages from it.</li>
                </Grid>
                <Grid item xs={12}>
                    <li>Status column keeps track of message status.</li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Send a response to sender using <Btn name={"Respond"} /> button.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>Use filter and sort options to find an specific order or information.</li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const EbayMessageCenterView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <MessageOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/message-center"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Ebay Message center
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is intended to see and respond messages originated in Ebay marketplaces.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                User request messages from Ebay and respond them.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>Status column keeps track of message status.</li>
                </Grid>
                <Grid item xs={12}>
                    <li>Select a conversation from an user to see details.</li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Respond"} /> button to create or modify a response.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>Use filter and sort options to find an specific order or information.</li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const ListingsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <ViewListOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/listing"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Product listings
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view is designed as a resume for your products and corresponding status in all marketplaces.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                Principal actions over all your products can be execute from here.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert <EmphasisText text={"marketplace fields"} />.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Upload"} /> button to upload product.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Pause/Start"} /> button to accomplish those actions for your product's selling
                        status at some marketplace.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Renew"} /> button to renew your listing at Etsy marketplace.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Remove"} /> button to remove your ad from a marketplace.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

//admin pages description*************************************

export const AdminMarketplaceView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <ShopOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/admin-marketplace"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Admin marketplaces
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                This view contains all marketplace that Sync2Markets permit to integrate.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                From this view, allowed marketplaces are created.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert marketplace.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted marketplace.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted marketplace.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const ColorsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <ColorizeIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/color"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Colors
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Colors view contains all predefined colors that Sync2Markets support.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to manage predefined colors.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert color.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted color.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted color.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const ConditionsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <StateMachineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/condition"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Conditions
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Conditions view contains all conditions that Sync2Markets support.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to manage predefined conditions.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert condition.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted condition.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted condition.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const PlatformServiceView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <PostOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/condition"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Platform service
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Platform services view contains all services that Sync2Markets support.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to manage predefined services.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert service.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted service.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted service.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const ConfigurationView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <SettingsIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/configuration"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Configurations
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Configurations view contains administrative options for Sync2Markets system.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to manage internal configurations.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        <EmphasisText text={"Reset values"} /> section let to initialize to default values for
                        nomenclators tables.
                    </li>
                    <ul>
                        <li>
                            Select <Btn name={"Preserve other inserted values"} /> option to preserve inserted values
                            that doesn't exist in predefined list of values.
                        </li>
                        <li>
                            Select <Btn name={"Preserve modified values"} /> option to preserve modified values from
                            predefined list of values.
                        </li>
                        <li>
                            Use <Btn name={"Reset"} /> button to reset values to default.
                        </li>
                    </ul>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        <EmphasisText text={"Schedule tasks"} /> section let to manage tasks for a specific marketplace.
                    </li>
                    <ul>
                        <li>
                            Use <Btn name={"Start"} /> button to start a task with default{" "}
                            <EmphasisText text={"Time interval"} /> if none is specified.
                        </li>
                        <li>
                            Use <Btn name={"Stop"} /> button to cancel a running task.
                        </li>
                        <li>
                            Use <Btn name={"Manually download"} /> button to start manually download of changes for{" "}
                            <EmphasisText text={"Category Tree"} />
                        </li>
                    </ul>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const CountriesView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <FlagOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/country"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Countries
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Countries view contains all countries that Sync2Markets support.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>This view is designed to manage countries.</DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert country.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted country.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted country.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const DimensionUnitsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <SphereDiameterOutlineIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/dimension-unit"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Dimension units
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Dimension units view contains all the units for measure dimension that Sync2Markets support.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to manage dimension units.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert unit.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted unit.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted unit.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const MaterialsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <RibbonIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/material"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Material
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Materials view contains all predefined material that Sync2Markets support.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to manage predefined materials.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert material.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted material.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted material.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const NotificationCenter = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <NotificationsActiveOutlined style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/notification-center"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Notification Center
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Notification Center contains all notifications generated at Sync2Markets or an external API that our
                system uses.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to see notifications and react over there.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Mark as viewed"} /> button to change status to already read notifications.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use reference column data to locate in our system the product/variation associated to
                        notification.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const PermissionsGroupView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <UserBadgeIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/permissions-group"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Permissions group
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Permissions group view contains groups of permissions for Sync2Markets.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to manage groups of permissions (roles).
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert group.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted group.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted group.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        <EmphasisText>Admin</EmphasisText> and <EmphasisText>Seller</EmphasisText> groups must always
                        exist in list. Appropriate permissions must be assigned.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const IdTypesView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <BarcodeIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/id-type"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Types of Id
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Types of Id view contains all kind of Id supported by Sync2Markets.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to manage types of Id. Those Id are used to identify the products.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert type.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted type.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted type.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const UsersView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <UserCardDetailsIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/admin-user"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Users
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Users view contains the users of Sync2Markets.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to execute some actions over the users.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Verify user account"} /> button to manually verify an account.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit user groups"} /> button to establish the roles for the user.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Activate user"} /> or <Btn name={"Deactivate user"} /> button to change status
                        of user.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete user"} /> button to delete an user.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const VariationFieldsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <TuneVerticalIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/variation-field"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Variation fields
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Variation fields view must contain predefined material and color variation fields.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                Just material an color variation fields must exist. None of them must be missing, any new field will be
                ignored. Changes in this values must affect system functionalities.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert variation field.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted variation field.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted variation field.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}

export const WeightUnitsView = ({ darkStyle }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography variant={"body1"} style={{ display: "inline-flex", alignItems: "center" }}>
                    <WeightKgIcon size={30} style={{ paddingRight: 10 }} />
                    <Link
                        to={"/seller/weight-unit"}
                        className={classes.sectionLink}
                        style={{ color: darkStyle ? "white" : "blue" }}
                    >
                        Weight units
                    </Link>{" "}
                    section.
                </Typography>
            </Grid>

            <DescriptionBox icon={<HandPointingRightIcon />}>
                Weight units view contains all the units for measure weight that Sync2Markets support.
            </DescriptionBox>

            <DescriptionBox icon={<DescriptionOutlined />}>
                This view is designed to manage weight units.
            </DescriptionBox>

            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Insert"} /> button to insert unit.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Edit"} /> button to modify an inserted unit.
                    </li>
                </Grid>
                <Grid item xs={12}>
                    <li>
                        Use <Btn name={"Delete"} /> button to remove an inserted unit.
                    </li>
                </Grid>
            </Grid>
        </Grid>
    )
}
