export default function forceOptionsReducer(state = false, action) {
    switch (action.type) {
        case 'SHOW_FORCE_OPTIONS':
            state = true
            return state
        case 'CLOSE_FORCE_OPTIONS':
            state = false
            return state
        default:
            return state
    }
}
