import gql from "graphql-tag";

//condition listing using pagination and sort
export const LOAD_CONDITIONS = gql`
query allConditionsPaginated(
  $orderBy: [String]!, 
  $nameFilter: String, 
  $firstPageItems: Int, 
  $lastPageItems: Int, 
  $beforeCursor: String,
  $afterCursor: String
) {  
  allConditions(
    orderBy: $orderBy, 
    name_Icontains: $nameFilter,
    first: $firstPageItems,
    last: $lastPageItems,
    before: $beforeCursor,
    after: $afterCursor  
  ) {
  pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      node {
        id
        name
      }
    }
  }
}`

//all conditions listing, used for selecting nomenclator
export const LOAD_ALL_CONDITIONS = gql`
query allConditions {  
  allConditions {
    edges {
      node {
        id
        name
      }
    }
  }
}`

//add new condition
export const CREATE_CONDITION = gql`
mutation createCondition($name: String!){
  createCondition(input: {name: $name}) {
    condition{
      id 
      name
    }
  }
}`

//update condition
export const UPDATE_CONDITION = gql`
mutation updateCondition($id: ID!, $name: String!){
  updateCondition(id: $id, input: {name: $name}) {
    condition{
      id 
      name
    }
  }
}`

//delete condition
export const DELETE_CONDITION = gql`
mutation deleteCondition($id: ID!){
  deleteCondition(id: $id) {
    found
    deletedId
  }
}`
