import React from "react"
import {
    Paper,
    Grid,
    Toolbar,
    Typography,
    CircularProgress,
    Tooltip,
    IconButton,
    TablePagination,
    Divider,
    Dialog,
    Button,
    Fade,
    FormControlLabel,
    Checkbox,
    Modal,
    Backdrop,
} from "@material-ui/core"
import SearchAddIcon from "mdi-react/SearchAddIcon"
import SearchIcon from "mdi-react/SearchIcon"
import AddIcon from "mdi-react/AddIcon"
import ImportIcon from "mdi-react/ImportIcon"
import ReloadIcon from "mdi-react/ReloadIcon"

import GridItem from "components/Grid/GridItem"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/views/productsStyle"
import stylesModal from "assets/jss/material-dashboard-react/components/productDetailsStyle"

//apollo
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { Mutation } from "@apollo/client/react/components"
import { DELETE_PRODUCT, LOAD_PRODUCTS_IN_MARKETPLACES } from "querys/seller/Product/productQueries"

//redux
import { useDispatch, useSelector } from "react-redux"
import { closeApiResponse, showApiResponse } from "actions/globalComponents/apiResponseActions"
import { closeForceOptions } from "actions/globalComponents/forceOptionsActions"

//product components
import MainProductRow from "components/Seller/Product/MainProductRow"
import ProductsFilter from "components/Seller/Product/ProductsFilter"

//loading skeleton
import { TableNoData, RowSkeleton } from "utils/productUtils"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//confirm dialog
import { ConfirmTransition } from "utils/tableUtils"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"

import { notifyMarketplaceConnectionError } from "../../../utils/GeneralUsageUtils"
import { useLocation } from "react-router-dom"
import { FACEBOOK_NOTIFICATIONS_ACTIVATION } from "../../../querys/seller/Facebook/productFacebookFieldsQueries"
import { RELOAD_PRODUCT_IN_MARKETPLACES } from "querys/seller/Product/productQueries"

const useStyles = makeStyles(styles)
const useModalStyles = makeStyles(stylesModal)

const notifySuccess = (message) => SuccessNotification("Products", message)
const notifyError = (message) => ErrorNotification("Products", message)

export default function ProductPage() {
    const classes = useStyles()
    const classesModal = useModalStyles()
    const dispatch = useDispatch()

    const CURRENT_USER = useSelector((state) => state.loginReducer)

    function useLocationFunction() {
        return new URLSearchParams(useLocation().search)
    }

    let searchParams = useLocationFunction()
    const productIdFilter = searchParams.get("productId")
    const openVariationsFilter = searchParams.get("variations")

    //check if exist connection issues with ebay, etsy, amazon, facebook to display message. not individual error message is displayed on every request to them
    const marketplaceConnectionStatus = useSelector((state) => state.marketplaceConnectionReducer)
    React.useEffect(() => {
        if (marketplaceConnectionStatus.connectionIssuesQuantity) {
            //find if connection issues are on amazon, etsy or ebay, facebook
            notifyMarketplaceConnectionError(marketplaceConnectionStatus, ["Ebay", "Etsy", "Amazon", "Facebook"])
        }
    }, [marketplaceConnectionStatus])

    //table variables
    const [order, setOrder] = React.useState("asc")
    const [orderBy, setOrderBy] = React.useState("id")
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(8)

    const handleRequestSort = (property) => {
        resetPagination()

        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const handleChangeRowsPerPage = (event) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const resetPagination = () => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)
    }

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState("")
    const [endCursorPagination, setEndCursorPagination] = React.useState("")

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allProductsStatusInAllSellerMarketplaces.totalCount),
        setStartCursorPagination(data.allProductsStatusInAllSellerMarketplaces.pageInfo.startCursor),
        setEndCursorPagination(data.allProductsStatusInAllSellerMarketplaces.pageInfo.endCursor),
    ]

    //bind query variables to this variables
    const [beforeCursorVariable, setBeforeCursorVariable] = React.useState("")
    const [afterCursorVariable, setAfterCursorVariable] = React.useState("")
    const [itemsVariable, setItemsVariable] = React.useState("firstPageItems")

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setAfterCursorVariable(endCursorPagination)
            setBeforeCursorVariable("")

            setItemsVariable("firstPageItems")
        } else {
            setBeforeCursorVariable(startCursorPagination)
            setAfterCursorVariable("")

            setItemsVariable("lastPageItems")
        }
        setPage(newPage)
    }

    //show filters card
    const [showFilters, setShowFilters] = React.useState(false)
    const toggleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    //filter fields
    const [id, setId] = React.useState(productIdFilter ?? "")
    const [sku, setSKU] = React.useState("")
    const [title, setTitle] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [brand, setBrand] = React.useState("")
    const [manufacturer, setManufacturer] = React.useState("")
    const [category, setCategory] = React.useState("")
    const [condition, setCondition] = React.useState("")

    function queryVariables() {
        return {
            sellerId: CURRENT_USER.seller.id,
            idFilter: id,
            skuFilter: sku,
            titleFilter: title,
            descriptionFilter: description,
            brandFilter: brand,
            manufacturerFilter: manufacturer,
            categoryFilter: category,
            conditionFilter: condition,
            orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
            [itemsVariable]: rowsPerPage,
            beforeCursor: beforeCursorVariable,
            afterCursor: afterCursorVariable,
        }
    }

    const { data: rows, loading, refetch, networkStatus } = useQuery(LOAD_PRODUCTS_IN_MARKETPLACES, {
        variables: queryVariables(),
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data) {
                setPaginationResponse(data)
            }
        },
        onError: () => {
            notifyError("Load data failed")
        },
    })

    const [reloadProduct, { loading: loadingReloadProduct }] = useLazyQuery(RELOAD_PRODUCT_IN_MARKETPLACES)

    const [toggleActiveFacebookNotification, { loading: loadingToggleActiveFacebookNotifications }] = useMutation(
        FACEBOOK_NOTIFICATIONS_ACTIVATION,
        {
            onCompleted: () => refetch(),
        }
    )

    const EnhancedTableToolbar = (props) => {
        const classes = useStyles()

        const { loading, handleInsertProduct, handleImportProduct, refetch } = props

        function appliedFilters() {
            return id || sku || title || description || brand || manufacturer || category || condition
        }

        const toggleSearchInput = () => {
            toggleShowFilters()
        }

        const openForm = () => {
            handleInsertProduct()
        }

        const openImportProducts = () => {
            handleImportProduct()
        }

        const refetchProducts = () => {
            refetch()
        }

        return (
            <Toolbar className={classes.header}>
                <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                    List of products
                </Typography>

                <div>
                    {loading || networkStatus === 4 ? (
                        <CircularProgress size={24} />
                    ) : (
                        <>
                            <Tooltip title="Filter data" classes={{ tooltip: classes.infoTooltip }}>
                                <IconButton
                                    aria-label="filter data"
                                    color={appliedFilters() ? "secondary" : "inherit"}
                                    onClick={toggleSearchInput}
                                >
                                    {appliedFilters() ? <SearchAddIcon /> : <SearchIcon />}
                                </IconButton>
                            </Tooltip>

                            <Tooltip
                                title="Use this option to sync products if you don't see changes reflected in
                            product' information. Example: when you integrate a new marketplace to our system or delete an
                            existing one"
                                classes={{ tooltip: classes.infoTooltip }}
                            >
                                <IconButton aria-label="refetch products" color={"inherit"} onClick={refetchProducts}>
                                    <ReloadIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Import products" classes={{ tooltip: classes.infoTooltip }}>
                                <IconButton aria-label="import products" color={"inherit"} onClick={openImportProducts}>
                                    <ImportIcon />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Add product" classes={{ tooltip: classes.infoTooltip }}>
                                <IconButton aria-label="add product" color={"inherit"} onClick={openForm}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </Toolbar>
        )
    }

    //confirm dialog
    const [openDialog, setOpenDialog] = React.useState(false)
    const [deleteItemIndex, setDeleteItemIndex] = React.useState(null)

    function ConfirmDialog(props) {
        const { register, refetch, loading } = props

        const handleClose = () => {
            setOpenDialog(false)
        }

        //true for option = 2
        const [optionDeleteValue, setOptionDeleteValue] = React.useState(false)
        const handleSelectCheck = (e) => {
            setOptionDeleteValue(e.target.checked)
        }

        const deleteItem = () =>
            register({
                variables: {
                    id: deleteItemIndex,
                    option: optionDeleteValue ? 2 : 1,
                },
            }).then(
                (response) => {
                    if (response.data.deleteProduct.found) {
                        let apiResponse = response.data.deleteProduct.apisResponse
                        let forceOptions = response.data.deleteProduct.forceOptions
                        if (apiResponse.length) {
                            dispatch(
                                showApiResponse({
                                    open: true,
                                    message: apiResponse,
                                    forceOptionsBtn: !!forceOptions.length,
                                })
                            )
                        }
                        if (forceOptions.length) {
                            setForceOptionsValues(forceOptions)
                            notifyError("Delete product failed, see options")
                        } else {
                            notifySuccess("Product deleted")
                            refetch()
                        }
                        handleClose()
                    }
                },
                () => {
                    notifyError("Delete product failed, try again")
                    handleClose()
                }
            )

        return (
            <div>
                <Dialog
                    open={openDialog}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle disableTypography>
                        <Typography variant="h6">Delete product</Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        <Typography gutterBottom>Do you confirm to delete the product?</Typography>
                        <GridItem xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={"optionValue"}
                                        color="primary"
                                        checked={optionDeleteValue}
                                        onChange={handleSelectCheck}
                                    />
                                }
                                label="Also, delete related product's listings from marketplace(s)"
                            />
                        </GridItem>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Button onClick={deleteItem} color="primary">
                                Confirm
                            </Button>
                        )}
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }

    //force options dialog
    const showOptions = useSelector((state) => state.forceOptionsReducer)
    const [forceOptionsValues, setForceOptionsValues] = React.useState([])

    function ForceOptionsDialog(props) {
        const { register, refetch, loading } = props

        const handleClose = () => {
            dispatch(closeForceOptions())
        }

        //true for option = 2
        const [optionValue, setOptionValue] = React.useState(false)
        const handleSelectCheck = (e) => {
            setOptionValue(e.target.checked)
        }

        const forceUpdate = () => {
            register({
                variables: {
                    id: deleteItemIndex,
                    option: optionValue ? 2 : 1,
                    forceValue: forceOptionsValues[0].value,
                },
            }).then(
                (response) => {
                    if (response.data.deleteProduct.found) {
                        let apiResponse = response.data.deleteProduct.apisResponse
                        if (apiResponse.length) {
                            dispatch(
                                showApiResponse({
                                    open: true,
                                    message: apiResponse,
                                    forceOptionsBtn: false,
                                })
                            )
                        }
                        notifySuccess("Product deleted")
                        refetch()
                        dispatch(
                            closeApiResponse({
                                open: false,
                                message: "",
                                forceOptionsBtn: false,
                            })
                        )
                        handleClose()
                    }
                },
                () => {
                    notifyError("Delete product failed, try again")
                    handleClose()
                }
            )
            handleClose()
        }

        return (
            <div>
                <Dialog
                    open={showOptions && !!forceOptionsValues.length}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle disableTypography>
                        <Typography variant="h6">Delete product</Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        <GridItem xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={"optionValue"}
                                        color="primary"
                                        checked={optionValue}
                                        onChange={handleSelectCheck}
                                    />
                                }
                                label="Also, delete related product's listings from marketplace(s)"
                            />
                        </GridItem>
                        {forceOptionsValues.map((item) => (
                            <Typography gutterBottom key={item.value}>
                                {item.description}
                            </Typography>
                        ))}
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Button onClick={forceUpdate} color="primary">
                                Confirm
                            </Button>
                        )}
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }

    const ProductForm = React.lazy(() => import("views/Seller/Product/ProductForm"))
    const ProductVariationPage = React.lazy(() => import("views/Seller/Product/ProductVariationPage"))
    const ProductImportPage = React.lazy(() => import("views/Seller/Product/ProductImportPage"))

    //modal container (ProductForm, ProductImport)
    const [openContainerModal, setOpenContainerModal] = React.useState(false)
    const [containerModalComponent, setContainerModalComponent] = React.useState(null)
    function handleCloseModal() {
        setOpenContainerModal(false)
    }

    const ProductDialogContainer = ({ openDialog, handleClose }) => {
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classesModal.modal}
                open={openDialog}
                onClose={handleClose}
                closeAfterTransition
                disableScrollLock
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openDialog}>
                    <div className={classesModal.paper}>
                        <React.Suspense fallback={null}>{containerModalComponent}</React.Suspense>
                    </div>
                </Fade>
            </Modal>
        )
    }

    function handleInsertProduct() {
        setContainerModalComponent(<ProductForm handleClose={handleCloseModal} refetch={refetch} />)
        setOpenContainerModal(true)
    }

    function handleUpdateProduct(updateProductId) {
        setContainerModalComponent(
            <ProductForm
                updateItemId={updateProductId}
                handleClose={handleCloseModal}
                refetch={() => reloadProduct({ variables: { productId: updateProductId } })}
            />
        )
        setOpenContainerModal(true)
    }

    function handleOpenVariations(product, isUploadedToAmazon) {
        setContainerModalComponent(
            <ProductVariationPage
                product={product}
                handleCloseParentModal={handleCloseModal}
                isUploadedToAmazon={isUploadedToAmazon}
                reloadProducts={() => reloadProduct({ variables: { productId: product.productId } })}
            />
        )
        setOpenContainerModal(true)
    }

    function handleImportProduct() {
        setContainerModalComponent(<ProductImportPage handleCloseModal={handleCloseModal} refetch={refetch} />)
        setOpenContainerModal(true)
    }

    return (
        <>
            <React.Suspense fallback={null}>
                <ProductDialogContainer openDialog={openContainerModal} handleClose={handleCloseModal} />
            </React.Suspense>
            <Mutation mutation={DELETE_PRODUCT}>
                {(deleteProduct, { loading: loadingDelete }) => (
                    <div className={classes.root}>
                        <ConfirmDialog register={deleteProduct} refetch={refetch} loading={loadingDelete} />
                        <ForceOptionsDialog register={deleteProduct} refetch={refetch} loading={loadingDelete} />
                        <Paper className={classes.paper}>
                            <Grid container item>
                                <EnhancedTableToolbar
                                    loading={
                                        loading ||
                                        loadingDelete ||
                                        loadingToggleActiveFacebookNotifications ||
                                        loadingReloadProduct
                                    }
                                    handleInsertProduct={handleInsertProduct}
                                    handleImportProduct={handleImportProduct}
                                    refetch={refetch}
                                />
                            </Grid>

                            {showFilters && (
                                <Fade in={showFilters}>
                                    <ProductsFilter
                                        setIdFilter={setId}
                                        idFilter={id}
                                        setSKUFilter={setSKU}
                                        skuFilter={sku}
                                        setTitleFilter={setTitle}
                                        titleFilter={title}
                                        setDescriptionFilter={setDescription}
                                        descriptionFilter={description}
                                        setBrandFilter={setBrand}
                                        brandFilter={brand}
                                        setManufacturerFilter={setManufacturer}
                                        manufacturerFilter={manufacturer}
                                        setCategoryFilter={setCategory}
                                        categoryFilter={category}
                                        setConditionFilter={setCondition}
                                        conditionFilter={condition}
                                        resetPagination={resetPagination}
                                        handleRequestSort={handleRequestSort}
                                        order={order}
                                        orderBy={orderBy}
                                        toggleShowFilters={toggleShowFilters}
                                    />
                                </Fade>
                            )}

                            <Grid container spacing={2}>
                                {loading ? (
                                    <RowSkeleton />
                                ) : !rows?.allProductsStatusInAllSellerMarketplaces?.edges?.length ? (
                                    <TableNoData />
                                ) : (
                                    <Grid container item spacing={2}>
                                        {rows.allProductsStatusInAllSellerMarketplaces.edges.map((product) => (
                                            <Grid container item key={product.node.id}>
                                                <MainProductRow
                                                    product={product}
                                                    autoOpenVariations={
                                                        openVariationsFilter === "true" &&
                                                        productIdFilter === id &&
                                                        productIdFilter === product.node.productId
                                                    }
                                                    refetch={() =>
                                                        reloadProduct({
                                                            variables: { productId: product.node.productId },
                                                        })
                                                    }
                                                    marketplacesStatus={marketplaceConnectionStatus.marketplaceStatus}
                                                    setOpenDialog={setOpenDialog}
                                                    setDeleteItemIndex={setDeleteItemIndex}
                                                    handleUpdateProduct={handleUpdateProduct}
                                                    handleOpenVariations={handleOpenVariations}
                                                    handleToggleFacebookNotifications={toggleActiveFacebookNotification}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                )}
                            </Grid>

                            <Divider flexItem className={classes.footerDivider} />

                            <Grid item xs={12}>
                                <TablePagination
                                    rowsPerPageOptions={[8, 12, 16]}
                                    component="div"
                                    count={totalItemsPagination}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Grid>
                        </Paper>
                    </div>
                )}
            </Mutation>
        </>
    )
}
