import gql from "graphql-tag";

//color listing using pagination and sort
export const LOAD_COLORS = gql`
query allColorsPaginated(
  $orderBy: [String]!, 
  $nameFilter: String, 
  $firstPageItems: Int, 
  $lastPageItems: Int, 
  $beforeCursor: String,
  $afterCursor: String
) {  
  allColors(
    orderBy: $orderBy, 
    name_Icontains: $nameFilter,
    first: $firstPageItems,
    last: $lastPageItems,
    before: $beforeCursor,
    after: $afterCursor  
  ) {
  pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      node {
        id
        name
        code
      }
    }
  }
}`

//all colors listing, used for selecting nomenclator
export const LOAD_ALL_COLORS = gql`
query allColors {  
  allColors {
    edges {
      node {
        id
        name
        code
      }
    }
  }
}`

//add new color
export const CREATE_COLOR = gql`
mutation createColor($name: String!, $code: String!){
  createColor(input: {name: $name, code: $code}) {
    color{
      id 
      name
      code
    }
  }
}`

//update color
export const UPDATE_COLOR = gql`
mutation updateColor($id: ID!, $name: String!, $code: String!){
  updateColor(id: $id, input: {name: $name, code: $code}) {
    color{
      id 
      name
      code
    }
  }
}`

//delete color
export const DELETE_COLOR = gql`
mutation deleteColor($id: ID!){
  deleteColor(id: $id) {
    found
    deletedId
  }
}`
