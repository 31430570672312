import React from "react"
import {
    Avatar,
    Checkbox,
    Collapse,
    Fade,
    Grid,
    IconButton,
    InputAdornment,
    ListItem,
    TextField,
    Tooltip,
} from "@material-ui/core"
import { format } from "date-fns"
import ExternalLinkIcon from "mdi-react/ExternalLinkIcon"
import { EditOutlined, ExpandLessOutlined, ExpandMoreOutlined, SendOutlined } from "@material-ui/icons"
import CalendarIcon from "mdi-react/CalendarIcon"
import CancelCircleOutlineIcon from "mdi-react/CancelCircleOutlineIcon"
import { Formik } from "formik"
import * as Yup from "yup"
import EbayImg from "../../../assets/img/marketplaces/Ebay_icon.svg"
import { showApiResponse } from "../../../actions/globalComponents/apiResponseActions"
import { REPLAY_EBAY_MESSAGE } from "../../../querys/MessageCenter/messageCenterQueries"
import { useMutation } from "@apollo/client"
import { useDispatch } from "react-redux"

export default function EbayMessageRow(props) {
    const { row, rowIndex, classes, notifyError } = props
    const dispatch = useDispatch()

    //expand data
    const [expandIndex, setExpandIndex] = React.useState(false)

    function handleExpand() {
        setExpandIndex(!expandIndex)
    }

    //message response form
    const [messageResponseForm, setMessageResponseForm] = React.useState(false)

    function handleOpenResponseForm(e, formik) {
        setExpandIndex(true)
        setMessageResponseForm(true)

        if (row.response) {
            formik.setFieldValue("messageResponse", row.response)
        }

        e.stopPropagation()
    }

    function handleCloseResponseForm(formik) {
        setMessageResponseForm(false)
        formik.resetForm()
    }

    const RowIcon = (props) => (
        <Grid item xs={"auto"} style={{ display: "flex", alignItems: "center" }}>
            <Grid container justifyContent={"center"} alignItems={"center"}>
                {props.children}
            </Grid>
        </Grid>
    )
    const RowCell = ({ header, value }) => (
        <Grid item xs={true}>
            <Grid container spacing={1}>
                {header && (
                    <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
                        <span
                            style={{
                                backgroundColor: "lightgray",
                                padding: "0 3px",
                                fontSize: "0.8REM",
                                borderRadius: "2px",
                                fontWeight: 400,
                            }}
                        >
                            {header}:
                        </span>
                    </Grid>
                )}

                <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
                    {value}
                </Grid>
            </Grid>
        </Grid>
    )

    //replay message
    const [replayMessage] = useMutation(REPLAY_EBAY_MESSAGE, {
        onCompleted: (data) => {
            const apiResponse = data?.ebayReplyMessage?.apisResponseItem
            if (apiResponse) {
                dispatch(showApiResponse({ open: true, message: apiResponse, forceOptionsBtn: false }))
            }
        },
        onError: () => {
            notifyError("Replay Ebay message failed, try again")
        },
    })

    const initialValues = {
        messageResponse: "",
        displayToPublic: true,
    }

    const validationSchema = Yup.object({
        messageResponse: Yup.string().required("field required"),
        displayToPublic: Yup.bool(),
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
                await replayMessage({
                    variables: {
                        body: values.messageResponse,
                        displayToPublic: values.displayToPublic,
                        ebayMessageId: row.ebayMessageId,
                    },
                })
                setMessageResponseForm(false)
                resetForm()
            }}
        >
            {(formik) => (
                <>
                    <ListItem button onClick={handleExpand}>
                        <Grid container space={1}>
                            <RowIcon>
                                <Avatar src={EbayImg} variant={"circular"} className={classes.marketplaceAvatar} />
                            </RowIcon>

                            <RowCell header={"Status"} value={row.messageStatus ?? "-"} />

                            <RowCell header={"Receipt"} value={row.senderId ?? "-"} />

                            <RowCell header={"Receipt"} value={row.subject ?? "-"} />

                            <RowCell
                                header={"Message"}
                                value={
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={true}
                                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                        >
                                            {row.body
                                                ? row.body.length > 70
                                                    ? row.body.substring(0, 70) + "..."
                                                    : row.body
                                                : "-"}
                                        </Grid>
                                        <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip
                                                title={
                                                    row.response
                                                        ? "Edit message response"
                                                        : "Send a response for this message"
                                                }
                                                classes={{ tooltip: classes.infoTooltip }}
                                            >
                                                <IconButton
                                                    size={"small"}
                                                    color={row.response ? "default" : "primary"}
                                                    onClick={(e) => handleOpenResponseForm(e, formik)}
                                                >
                                                    {row.response ? (
                                                        <EditOutlined fontSize={"small"} />
                                                    ) : (
                                                        <SendOutlined fontSize={"small"} />
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                }
                            />

                            <RowCell
                                header={"Updated at"}
                                value={
                                    <>
                                        {row.creationDate ? (
                                            <Tooltip
                                                title={`Creation date. Last modification in ${format(
                                                    new Date(row.lastModifiedDate),
                                                    "yyyy-MM-dd HH:mm:ss"
                                                )}`}
                                                classes={{ tooltip: classes.infoTooltip }}
                                            >
                                                <Grid container alignItems={"center"} justifyContent={"center"}>
                                                    <CalendarIcon size={16} style={{ marginRight: "3px" }} />
                                                    {format(new Date(row.creationDate), "yyyy-MM-dd HH:mm:ss")}
                                                </Grid>
                                            </Tooltip>
                                        ) : (
                                            "-"
                                        )}
                                    </>
                                }
                            />

                            <RowCell
                                value={
                                    <>
                                        <Tooltip title={"See ad at Ebay"}>
                                            <IconButton
                                                size={"small"}
                                                href={`https://www.ebay.com/itm/${row.messageId}`}
                                                target={"_blank"}
                                                rel="noopener noreferrer"
                                                style={{ marginLeft: "5px" }}
                                            >
                                                <ExternalLinkIcon size={18} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title={"Details"}>
                                            <IconButton size={"small"} onClick={() => handleExpand(rowIndex)}>
                                                {expandIndex === rowIndex ? (
                                                    <ExpandLessOutlined />
                                                ) : (
                                                    <ExpandMoreOutlined />
                                                )}
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                }
                            />
                        </Grid>
                    </ListItem>

                    <div style={{ width: "100%" }}>
                        <Collapse in={expandIndex} timeout={"auto"} unmountOnExit>
                            <Grid container spacing={2} style={{ padding: "5px 8px" }}>
                                {messageResponseForm && (
                                    <Fade in={true}>
                                        <Grid container item>
                                            <TextField
                                                name={"messageResponse"}
                                                placeholder={"Response text here..."}
                                                size={"small"}
                                                fullWidth
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <Tooltip title={"Send message response"}>
                                                                <span>
                                                                    <IconButton
                                                                        color={"primary"}
                                                                        size={"small"}
                                                                        disabled={!formik.isValid}
                                                                        onClick={formik.handleSubmit}
                                                                    >
                                                                        <SendOutlined fontSize={"small"} />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>

                                                            <Tooltip title={"Display to public response"}>
                                                                <Checkbox
                                                                    color={"default"}
                                                                    size={"small"}
                                                                    checked={formik.values.displayToPublic}
                                                                    {...formik.getFieldProps("displayToPublic")}
                                                                    style={{ marginRight: "5px" }}
                                                                />
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <Tooltip title={"Cancel message response"}>
                                                                <span>
                                                                    <IconButton
                                                                        color={"secondary"}
                                                                        size={"small"}
                                                                        onClick={() => handleCloseResponseForm(formik)}
                                                                    >
                                                                        <CancelCircleOutlineIcon />
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...formik.getFieldProps("messageResponse")}
                                                error={formik.errors.messageResponse && formik.touched.messageResponse}
                                                helperText={
                                                    formik.errors.messageResponse &&
                                                    formik.touched.messageResponse &&
                                                    formik.errors.messageResponse
                                                }
                                            />
                                        </Grid>
                                    </Fade>
                                )}

                                <Grid container item spacing={1} xs={12}>
                                    <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                        Message Id:
                                    </Grid>
                                    <Grid item xs={true}>
                                        {row.messageId ?? "-"}
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={1} xs={12}>
                                    <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                        Message status:
                                    </Grid>
                                    <Grid item xs={true}>
                                        {row.messageStatus ?? "-"}
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={1} xs={12}>
                                    <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                        Sender:
                                    </Grid>
                                    <Grid item xs={true}>
                                        {row.senderId ? `${row.senderId} (${row.senderEmail ?? "-"})` : "-"}
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={1} xs={12}>
                                    <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                        Message:
                                    </Grid>
                                    <Grid item xs={true}>
                                        {row.body ?? "-"}
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={1} xs={12}>
                                    <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                        Response:
                                    </Grid>
                                    <Grid item xs={true}>
                                        {row.response ?? "-"}
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={1} xs={12}>
                                    <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                        Creation date:
                                    </Grid>
                                    <Grid item xs={true}>
                                        {row.creationDate ? (
                                            <Grid container alignItems={"center"}>
                                                <CalendarIcon size={16} style={{ marginRight: "3px" }} />
                                                {`${format(
                                                    new Date(row.creationDate),
                                                    "yyyy-MM-dd HH:mm:ss"
                                                )}, modified at ${
                                                    row.lastModifiedDate
                                                        ? format(new Date(row.lastModifiedDate), "yyyy-MM-dd HH:mm:ss")
                                                        : "-"
                                                }`}
                                            </Grid>
                                        ) : (
                                            "-"
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Collapse>
                    </div>
                </>
            )}
        </Formik>
    )
}
