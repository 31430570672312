export default function marketplaceConnectionReducer(
    state = { connectionIssuesQuantity: 0, marketplaceStatus: {} },
    action
) {
    switch (action.type) {
        case "ADD_MARKETPLACE":
            state.marketplaceStatus[action.payload.sellerMarketplaceId] = action.payload.sellerMarketplaceData
            return state

        case "UPDATE_MARKETPLACE":
            state.marketplaceStatus[action.payload.sellerMarketplaceId] = action.payload.sellerMarketplaceData
            return state

        case "DELETE_MARKETPLACE":
            delete state.marketplaceStatus[action.payload.sellerMarketplaceId]
            return state

        case "UPDATE_CONNECTION_ISSUES_QUANTITY":
            state.connectionIssuesQuantity = action.payload.connectionIssuesQuantity
            return state

        default:
            return state
    }
}
