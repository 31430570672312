import gql from "graphql-tag"

//material listing using pagination and sort
export const LOAD_MATERIALS = gql`
    query allMaterialsPaginated(
        $orderBy: [String]!
        $nameFilter: String
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allMaterials(
            orderBy: $orderBy
            name_Icontains: $nameFilter
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//all materials listing, used for selecting nomenclator
export const LOAD_ALL_MATERIALS = gql`
    query allMaterials {
        allMaterials {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//add new material
export const CREATE_MATERIAL = gql`
    mutation createMaterial($name: String!) {
        createMaterial(input: { name: $name }) {
            material {
                id
                name
            }
        }
    }
`

//update material
export const UPDATE_MATERIAL = gql`
    mutation patchMaterial($id: ID!, $name: String!) {
        patchMaterial(id: $id, input: { name: $name }) {
            material {
                id
                name
            }
        }
    }
`

//delete material
export const DELETE_MATERIAL = gql`
    mutation deleteMaterial($id: ID!) {
        deleteMaterial(id: $id) {
            found
            deletedId
        }
    }
`
