import gql from "graphql-tag"

//download ebay messages
export const DOWNLOAD_EBAY_MESSAGES = gql`
    query ebayDownloadMessages($marketplaceId: ID!) {
        ebayDownloadMessages(sellerMarketplaceId: $marketplaceId) {
            apiResponseItem {
                marketplace
                type
                messages
            }
        }
    }
`

//all marketplace orders
export const LOAD_ALL_MARKETPLACE_MESSAGES = gql`
    query allMyMarketplaceMessages($page: Int!, $perPage: Int!) {
        allMyMarketplaceMessages(page: $page, perPage: $perPage) {
            totalCount
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
            messages {
                lastUpdateDate
                ebayInfo {
                    id
                    messageId
                    senderId
                    senderEmail
                    subject
                    body
                    response
                    messageStatus
                    creationDate
                    lastModifiedDate
                    itemId
                }
            }
        }
    }
`

//download ebay messages
export const REPLAY_EBAY_MESSAGE = gql`
    mutation ebayReplyMessage($body: String!, $displayToPublic: Boolean!, $ebayMessageId: String!) {
        ebayReplyMessage(body: $body, displayToPublic: $displayToPublic, ebayMessageId: $ebayMessageId) {
            apisResponseItem {
                marketplace
                type
                messages
            }
        }
    }
`
