import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { EditOutlined } from "@material-ui/icons"
import { TextField, Button, CircularProgress, MenuItem, Grid } from "@material-ui/core"

import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardFooter from "components/Card/CardFooter"
import GridItem from "components/Grid/GridItem"

import { Formik } from "formik"
import * as Yup from "yup"

import styles from "assets/jss/material-dashboard-react/views/cardFormStyle"

//apollo
import { useQuery } from "@apollo/client"
import { Mutation } from "@apollo/client/react/components"
import { LOAD_ALL_COUNTRIES } from "querys/admin/countryQueries"
import { CREATE_SELLER } from "querys/seller/Auth user/sellerQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//redux
import { useSelector, useDispatch } from "react-redux"
import { updateSeller as updateSellerAction } from "actions/auth/loginActions"

//errors function
import { getFormErrors } from "utils/errorsUtils"

const useStyles = makeStyles(styles)

export default function SellerForm(props) {
    const classes = useStyles()

    const dispatch = useDispatch()

    const CURRENT_USER = useSelector((state) => state.loginReducer)

    const notifySuccess = (message) => SuccessNotification("Seller", message)
    const notifyError = (message) => ErrorNotification("Seller", message)

    const successForm = () => {
        notifySuccess("Seller information added. Now, user can sell with Sync2Markets")
        props.refetch()
    }

    const [countries, serCountries] = React.useState([])
    useQuery(LOAD_ALL_COUNTRIES, {
        onCompleted: (data) => {
            if (data?.allCountries?.edges?.length) {
                serCountries(data.allCountries.edges)
            }
        },
        onError: () => {
            notifyError("Unable to load existing countries, try again")
        },
    })

    const Form = (props) => {
        const { formik, countries } = props

        return (
            <>
                <GridItem xs={12}>
                    <TextField
                        name="firstName"
                        label="First name*"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        className={classes.formFields}
                        {...formik.getFieldProps("firstName")}
                        error={formik.errors.firstName && formik.touched.firstName}
                        helperText={formik.errors.firstName && formik.touched.firstName && formik.errors.firstName}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <TextField
                        name="lastName"
                        label="Last name*"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        className={classes.formFields}
                        {...formik.getFieldProps("lastName")}
                        error={formik.errors.lastName && formik.touched.lastName}
                        helperText={formik.errors.lastName && formik.touched.lastName && formik.errors.lastName}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <TextField
                        name="country"
                        label="Country*"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        select
                        className={classes.formFields}
                        {...formik.getFieldProps("country")}
                        error={formik.errors.country && formik.touched.country}
                        helperText={formik.errors.country && formik.touched.country && formik.errors.country}
                    >
                        {countries.map((option) => (
                            <MenuItem key={option.node.id} value={option.node.id}>
                                {option.node.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </GridItem>
                <GridItem xs={12}>
                    <TextField
                        name="sku"
                        label="SKU*"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        className={classes.formFields}
                        {...formik.getFieldProps("sku")}
                        error={formik.errors.sku && formik.touched.sku}
                        helperText={formik.errors.sku && formik.touched.sku && formik.errors.sku}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <TextField
                        name="phone"
                        label="Phone*"
                        variant={"filled"}
                        size={"small"}
                        fullWidth
                        className={classes.formFields}
                        {...formik.getFieldProps("phone")}
                        error={formik.errors.phone && formik.touched.phone}
                        helperText={formik.errors.phone && formik.touched.phone && formik.errors.phone}
                    />
                </GridItem>
            </>
        )
    }

    const initialValues = {
        firstName: "",
        lastName: "",
        country: "",
        sku: "",
        phone: "",
    }

    const validationSchema = Yup.object({
        firstName: Yup.string().required("field required"),
        lastName: Yup.string().required("field required"),
        phone: Yup.string().required("field required"),
        sku: Yup.string().required("field required"),
        country: Yup.string().required("field required"),
    })

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="success" className={classes.cardHeaderIcon}>
                    <EditOutlined />
                </CardIcon>
                <h4 className={classes.cardTitle}>Complete all information we need from you to use our services</h4>
            </CardHeader>
            <Mutation mutation={CREATE_SELLER}>
                {(createSeller, { loading: loadingCreate }) => (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(data, { setErrors }) => {
                            createSeller({
                                variables: {
                                    country: data.country,
                                    sku: data.sku,
                                    phone: data.phone,
                                    user: CURRENT_USER.id,
                                    firstName: data.firstName,
                                    lastName: data.lastName,
                                },
                            }).then(
                                (createResponse) => {
                                    let createDataResponse = createResponse.data.createSeller

                                    if (createDataResponse.seller) {
                                        dispatch(updateSellerAction(createDataResponse.seller.user))
                                        return successForm()
                                    }
                                },
                                (response) => {
                                    if (response.graphQLErrors.length) {
                                        getFormErrors(response.graphQLErrors, notifyError, setErrors)
                                    } else {
                                        notifyError("Seller information created failed, try again")
                                    }
                                }
                            )
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <CardBody>
                                    <GridContainer>
                                        <Form formik={formik} countries={countries} />
                                    </GridContainer>
                                </CardBody>
                                <CardFooter chart>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <small>(*) means that fields are required</small>
                                        </Grid>

                                        <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"primary"}
                                                type="submit"
                                                disabled={!formik.isValid || loadingCreate}
                                            >
                                                {loadingCreate ? <CircularProgress size={24} /> : "Create"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardFooter>
                            </form>
                        )}
                    </Formik>
                )}
            </Mutation>
        </Card>
    )
}
