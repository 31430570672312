import gql from "graphql-tag";

//to know if task is running
export const SCHEDULE_TASK_IS_RUNNING = gql`
query schedulerIsRunningCategoryTreesChangesDownload {
  schedulerIsRunningCategoryTreesChangesDownload {
    isRunning
    lastError
    params
  }
}`

//start schedule task
export const SCHEDULE_TASK_START = gql`
mutation schedulerStartCategoryTreesChangesDownload($time: Time!) {
  schedulerStartCategoryTreesChangesDownload(time: $time) {
    success
  }
}`

//stop schedule task
export const SCHEDULE_TASK_STOP = gql`
mutation schedulerStopCategoryTreesChangesDownload {
  schedulerStopCategoryTreesChangesDownload {
    success
  }
}`

//manually download task
export const SCHEDULE_TASK_MANUALLY_DOWNLOAD = gql`
mutation schedulerManualCategoryTreesChangesDownload {
  schedulerManualCategoryTreesChangesDownload {
    results
  }
}`
