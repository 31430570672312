import {
    defaultFont,
    container,
    grayColor,
    whiteColor, backGroundBlueFooterColor
} from "assets/jss/material-dashboard-react.js"

const footerStyle = {
    footer: {
        display: "flex",
        bottom: "0",
        borderTop: "1px solid " + grayColor[11],
        height: "60px",
        alignItems: "center",
        justifyContent: "space-between",
        color: whiteColor,
        backgroundColor: backGroundBlueFooterColor,
        ...defaultFont,
        ...container
    },
    brandText: {
        display: "flex",
        alignItems: "center"
    },
    logoImg: {
        height: "35px",
        width: "35px"
    },
};
export default footerStyle;
