export const singIn = (payload) => {
    return {
        type: "SING_IN",
        payload,
    }
}

export const singOut = () => {
    return {
        type: "SING_OUT",
    }
}

export const updateSeller = (payload) => {
    return {
        type: "UPDATE_USER",
        payload,
    }
}
