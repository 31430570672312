import React from "react"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import { IconButton, Popover, useMediaQuery, ListItem, ListItemText } from "@material-ui/core"

//assets
import { ShoppingCartOutlined } from "@material-ui/icons"
import PaperOutlineIcon from "mdi-react/PaperOutlineIcon"
import styles from "assets/jss/material-dashboard-react/views/LandingPageStyle"

//router
import { NavLink, useHistory } from "react-router-dom"

const useStyles = makeStyles(styles)

export default function Footer() {
    const classes = useStyles()
    const history = useHistory()

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"))

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "simple-popover" : undefined

    return (
        <div className={classes.footer}>
            {mobileScreen ? (
                <div className={classes.iconsAuthor}>Sync2Markets &copy; {1900 + new Date().getYear()}</div>
            ) : (
                <div className={classes.brandText}>
                    <div className={classes.brandText}>
                        <div>
                            <ShoppingCartOutlined />
                        </div>
                        <div>Sell made easy</div>
                    </div>
                    <span style={{ padding: "0 3px" }}>|</span>
                    Sync2Markets &copy; {1900 + new Date().getYear()}
                </div>
            )}
            <div>
                {mobileScreen ? (
                    <>
                        <IconButton aria-label="system-documents" color={"inherit"} onClick={handleClick}>
                            <PaperOutlineIcon />
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <ListItem
                                key={"service-terms"}
                                role="listitem"
                                button
                                onClick={() => history.push("/service-terms")}
                            >
                                <ListItemText primary={"Service terms"} />
                            </ListItem>
                            <ListItem
                                key={"privacy-policy"}
                                role="listitem"
                                button
                                onClick={() => history.push("/privacy-policy")}
                            >
                                <ListItemText primary={"Privacy policy"} />
                            </ListItem>
                        </Popover>
                    </>
                ) : (
                    <>
                        <NavLink
                            to={"/service-terms"}
                            key={"/service-terms"}
                            style={{ marginRight: "5px", color: "white", fontWeight: "600" }}
                        >
                            Service terms
                        </NavLink>
                        |
                        <NavLink
                            to={"/privacy-policy"}
                            key={"/privacy-policy"}
                            style={{ marginLeft: "5px", color: "white", fontWeight: "600" }}
                        >
                            Privacy policy
                        </NavLink>
                    </>
                )}
            </div>

            <div className={classes.iconsAuthor}>
                Icons designed by{" "}
                <a
                    href="https://www.flaticon.com/authors/freepik"
                    title="Freepik"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    style={{ color: "white", fontWeight: "600" }}
                >
                    Freepik
                </a>{" "}
                <br />
                from
                <a
                    href="https://www.flaticon.com/"
                    title="Flaticon"
                    target={"_blank"}
                    rel="noopener noreferrer"
                    style={{ color: "white", fontWeight: "600" }}
                >
                    {" "}
                    Flaticon
                </a>
            </div>
        </div>
    )
}
