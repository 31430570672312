import gql from "graphql-tag";

//reset colors
export const RESET_COLORS_VALUES = gql`
mutation resetDefaultColorList($keepModified: Boolean, $keepOthers: Boolean){
  resetDefaultColorList(keepModified: $keepModified, keepOthers: $keepOthers) {
     success
     modifiedRestoredQuantity
     othersRemovedQuantity
  }
}`

//reset countries
export const RESET_COUNTRIES_VALUES = gql`
mutation resetDefaultCountryList($keepOthers: Boolean){
  resetDefaultCountryList(keepOthers: $keepOthers) {
     success
     othersRemovedQuantity
  }
}`

//reset materials
export const RESET_MATERIALS_VALUES = gql`
mutation resetDefaultMaterialList($keepOthers: Boolean){
  resetDefaultMaterialList(keepOthers: $keepOthers) {
     success
     othersRemovedQuantity
  }
}`

//reset product idType
export const RESET_PRODUCT_ID_TYPE_VALUES = gql`
mutation resetDefaultProductIdTypeList($keepOthers: Boolean){
  resetDefaultProductIdTypeList(keepOthers: $keepOthers) {
     success
     othersRemovedQuantity
  }
}`

//reset weight unit
export const RESET_WEIGHT_UNIT_VALUES = gql`
mutation resetDefaultWeightUnitList($keepOthers: Boolean){
  resetDefaultWeightUnitList(keepOthers: $keepOthers) {
     success
     othersRemovedQuantity
  }
}`

//reset dimension unit
export const RESET_DIMENSION_UNIT_VALUES = gql`
mutation resetDefaultDimensionUnitList($keepOthers: Boolean){
  resetDefaultDimensionUnitList(keepOthers: $keepOthers) {
     success
     othersRemovedQuantity
  }
}`

//reset dimension unit
export const RESET_CONDITION_VALUES = gql`
mutation resetDefaultConditionList($keepOthers: Boolean){
  resetDefaultConditionList(keepOthers: $keepOthers) {
     success
     othersRemovedQuantity
  }
}`
