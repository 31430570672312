import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import { Button, CircularProgress, Grid, TextField } from "@material-ui/core"

//icons
import { EditOutlined } from "@material-ui/icons"

import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import GridItem from "components/Grid/GridItem"

import { Formik } from "formik"
import * as Yup from "yup"

import styles from "assets/jss/material-dashboard-react/views/cardFormStyle"

//apollo
import { Mutation } from "@apollo/client/react/components"
import { useQuery } from "@apollo/client"
import { CREATE_GROUP, UPDATE_GROUP } from "querys/admin/authGroupQueries"
import { LOAD_ALL_PERMISSIONS } from "querys/admin/authPermissionQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//router
import { useHistory } from "react-router-dom"

//errors
import { getFormErrors } from "utils/errorsUtils"

//list
import PermissionsList from "components/Admin/PermissionsList"

const useStyles = makeStyles(styles)

export default function PermissionsGroupForm(props) {
    const classes = useStyles()

    const updateItem = props.location.state?.updateItem.node ?? null

    const history = useHistory()

    const notifySuccess = (message) => SuccessNotification("Group", message)
    const notifyError = (message) => ErrorNotification("Group", message)

    const successForm = () => {
        updateItem ? notifySuccess("Group updated") : notifySuccess("New group added")
        history.push("/seller/permissions-group")
    }

    const cancelAction = () => {
        history.push("/seller/permissions-group")
    }

    function getPermissionsId(permissions) {
        let permissionsId = []
        permissions.forEach((item) => {
            permissionsId.push(item.node.id)
        })
        return permissionsId
    }

    const [rows, setRows] = React.useState([])

    //list columns
    const [left, setLeft] = React.useState([])
    const [right, setRight] = React.useState([])

    //load all permissions
    useQuery(LOAD_ALL_PERMISSIONS, {
        onCompleted: (data) => {
            if (data.allPermissions) {
                setRows(data.allPermissions.edges)
            }
        },
        onError: () => {
            notifyError("Unable to load permissions, try again")
        },
    })

    //populate left when rows are loaded
    React.useEffect(() => {
        if (rows.length) {
            setLeft(rows)
        }
    }, [rows])

    //load values of selected permissions for update
    React.useEffect(() => {
        if (updateItem && updateItem.permissions && rows.length > 0) {
            let selected = updateItem.permissions.edges
            setRight(selected)
            let rowsFiltered = rows
            selected.forEach((itemSelected) => {
                rowsFiltered = rowsFiltered.filter((item) => item.node.id !== itemSelected.node.id)
            })
            setLeft(rowsFiltered)
        }
    }, [updateItem, rows])

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <Grid container spacing={1}>
                    <Grid item xs={"auto"} className={classes.cardHeaderIcon}>
                        <EditOutlined />
                    </Grid>
                    <Grid item xs={true}>
                        <h4 className={classes.cardTitle}>{updateItem ? "Update group" : "Create group"}</h4>
                    </Grid>
                </Grid>
            </CardHeader>
            <Mutation mutation={updateItem ? UPDATE_GROUP : CREATE_GROUP}>
                {(register, { loading, error, data }) => (
                    <Formik
                        initialValues={{
                            name: updateItem?.name ?? "",
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string().required("field required"),
                        })}
                        onSubmit={(data, { setErrors }) => {
                            register({
                                variables: {
                                    id: updateItem?.id ?? null,
                                    name: data.name,
                                    permissions: getPermissionsId(right),
                                },
                            }).then(
                                (response) => {
                                    let dataResponse
                                    if (updateItem) {
                                        dataResponse = response.data.updateGroup
                                    } else {
                                        dataResponse = response.data.createGroup
                                    }

                                    if (dataResponse.group) {
                                        return successForm()
                                    }
                                },
                                (response) => {
                                    if (response.graphQLErrors.length) {
                                        getFormErrors(response.graphQLErrors, notifyError, setErrors)
                                    } else {
                                        notifyError("Create group failed, try again")
                                    }
                                }
                            )
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="name"
                                                label="Name*"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                className={classes.formFields}
                                                {...formik.getFieldProps("name")}
                                                error={formik.errors.name && formik.touched.name}
                                                helperText={
                                                    formik.errors.name && formik.touched.name && formik.errors.name
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <PermissionsList
                                                left={left}
                                                setLeft={setLeft}
                                                right={right}
                                                setRight={setRight}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter chart>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <small>(*) means that fields are required</small>
                                        </Grid>

                                        <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"secondary"}
                                                onClick={cancelAction}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"primary"}
                                                type="submit"
                                                disabled={!formik.isValid || loading}
                                            >
                                                {loading ? (
                                                    <CircularProgress size={24} />
                                                ) : updateItem ? (
                                                    "Update"
                                                ) : (
                                                    "Create"
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardFooter>
                            </form>
                        )}
                    </Formik>
                )}
            </Mutation>
        </Card>
    )
}
