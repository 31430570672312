import React from "react"

//material ui
import { makeStyles } from "@material-ui/core/styles"
import { PersonOutline, EditOutlined } from "@material-ui/icons"
import { Divider, Hidden, IconButton, Tooltip, Avatar, Grid, CircularProgress } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

//core components
import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardAvatar from "components/Card/CardAvatar"

import styles from "assets/jss/material-dashboard-react/views/cardInfoStyle"

const useStyles = makeStyles(styles)

export default function AuthUser(props) {
    const classes = useStyles()

    const { loading, detailsItem } = props

    const openUserEditForm = () => {
        props.toggleVisible()
    }

    const composeUrl = (user) => {
        return user.image
            ? `${process.env.REACT_APP_API_URL}file/${user.id}`
            : ""
    }

    const Header = () => {
        return (
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <PersonOutline />
                </CardIcon>
                <h4 className={classes.cardTitle}>User details</h4>
                <div className={classes.cardHeaderActions}>
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <>
                            <Tooltip title="Edit" arrow>
                                <IconButton aria-label="edit" color={"inherit"} onClick={openUserEditForm}>
                                    <EditOutlined />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </CardHeader>
        )
    }

    const LoadingSkeleton = () => {
        return (
            <Grid container className={classes.skeletonContainer}>
                <Grid item xs={4} className={classes.skeletonAvatarContainer}>
                    <Skeleton variant={"circle"} width={100} height={100} />
                </Grid>
                <GridItem item xs={8}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </GridItem>
            </Grid>
        )
    }

    return (
        <Card>
            <Header />
            {loading ? (
                <LoadingSkeleton />
            ) : (
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} md={6}>
                            <CardAvatar className={classes.cardAvatarContainer}>
                                <Avatar alt="Dan" src={composeUrl(detailsItem)} className={classes.cardAvatarIcon} />
                            </CardAvatar>
                        </GridItem>
                        <GridItem xs={12} md={6} container>
                            <Hidden smDown>
                                <GridItem xs={1}>
                                    <Divider
                                        orientation="vertical"
                                        flexItem
                                        className={classes.sectionsDividerVertical}
                                    />
                                </GridItem>
                            </Hidden>
                            <Hidden smUp>
                                <GridItem xs={12}>
                                    <Divider flexItem className={classes.sectionsDividerHorizontal} />
                                </GridItem>
                            </Hidden>
                            <GridItem xs={12} md={11} container>
                                <div className={classes.cardInfoSection}>
                                    <GridItem container>
                                        <GridItem xs={12} container>
                                            <GridItem xs={6}>
                                                <div className={classes.leftFlex}>Username:</div>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <div className={classes.rightFlex}>
                                                    {detailsItem.username ? detailsItem.username : "-"}
                                                </div>
                                            </GridItem>
                                        </GridItem>
                                        <GridItem xs={12} container>
                                            <GridItem xs={6}>
                                                <div className={classes.leftFlex}>First Name:</div>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <div className={classes.rightFlex}>
                                                    {detailsItem.firstName ? detailsItem.firstName : "-"}
                                                </div>
                                            </GridItem>
                                        </GridItem>
                                        <GridItem xs={12} container>
                                            <GridItem xs={6}>
                                                <div className={classes.leftFlex}>Last Name:</div>
                                            </GridItem>
                                            <GridItem xs={6}>
                                                <div className={classes.rightFlex}>
                                                    {detailsItem.lastName ? detailsItem.lastName : "-"}
                                                </div>
                                            </GridItem>
                                        </GridItem>
                                    </GridItem>
                                </div>
                            </GridItem>
                        </GridItem>
                    </GridContainer>

                    <GridContainer>
                        <GridItem xs={12}>
                            <GridItem xs={12}>
                                <Divider flexItem className={classes.sectionsDividerHorizontal} />
                            </GridItem>
                        </GridItem>
                        <GridItem xs={12}>
                            <div className={classes.cardInfoSection}>
                                <GridItem container>
                                    <GridItem xs={12} container>
                                        <GridItem xs={6}>
                                            <div className={classes.leftFlex}>Email:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <div className={classes.rightFlex}>
                                                {detailsItem.email ? detailsItem.email : "-"}
                                            </div>
                                        </GridItem>
                                    </GridItem>
                                    <GridItem xs={12} container>
                                        <GridItem xs={6}>
                                            <div className={classes.leftFlex}>Date joined:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <div className={classes.rightFlex}>
                                                {detailsItem.dateJoined ? detailsItem.dateJoined.slice(0, 10) : "-"}
                                            </div>
                                        </GridItem>
                                    </GridItem>
                                    <GridItem xs={12} container>
                                        <GridItem xs={6}>
                                            <div className={classes.leftFlex}>Last login:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <div className={classes.rightFlex}>
                                                {detailsItem.lastLogin ? detailsItem.lastLogin.slice(0, 10) : "-"}
                                            </div>
                                        </GridItem>
                                    </GridItem>
                                </GridItem>
                            </div>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            )}
        </Card>
    )
}
