import React from "react"

// core components

//icons
import UserCardDetailsIcon from "mdi-react/UserCardDetailsIcon"
import ChevronDownIcon from "mdi-react/ChevronDownIcon"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"

//styles
import styles from "assets/jss/material-dashboard-react/components/dashboardStatsStyle"
import { makeStyles } from "@material-ui/core/styles"

//link
import { NavLink } from "react-router-dom"
import { Avatar, Card, CardContent, Fade, Grid } from "@material-ui/core"


const useStyles = makeStyles(styles)

export default function SystemUsersStats(props) {
    const classes = useStyles()

    const { adminStats } = props

    const [displayUserDetails, setDisplayUserDetails] = React.useState(false)

    const UserBlock = () => (
        <Grid container item xs={12}>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={11} justifyContent={"center"}>
                    <h3 className={classes.cardTitle}>
                        <NavLink to={"/seller/admin-user"} style={{ textDecoration: "solid underline black 1px" }}>
                            {adminStats?.totalUsers ?? "-"} <small>users</small>
                        </NavLink>
                    </h3>
                </Grid>
                <Grid container item xs={1} alignItems={"center"}>
                    {displayUserDetails ? (
                        <ChevronDownIcon onClick={() => setDisplayUserDetails(!displayUserDetails)} />
                    ) : (
                        <ChevronRightIcon onClick={() => setDisplayUserDetails(!displayUserDetails)} />
                    )}
                </Grid>
            </Grid>

            {displayUserDetails && (
                <Fade in={displayUserDetails}>
                    <Grid container item xs={12} justifyContent={"flex-end"}>
                        <h4 className={classes.cardSubTitle}>
                            {adminStats?.totalUsersInactive ?? "-"} <small>inactive</small> |{" "}
                            {adminStats?.totalUsersUnverified ?? "-"} <small>not verified</small>
                        </h4>
                    </Grid>
                </Fade>
            )}
        </Grid>
    )

    return (
        <Card variant={"outlined"}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid container item justifyContent={"flex-end"} className={classes.cardCategory}>
                        Users
                    </Grid>
                    <Grid container item xs={2} alignItems={"center"}>
                        <Avatar variant={"rounded"} className={classes.cardAvatarAdmin}>
                            <UserCardDetailsIcon size={"55"} />
                        </Avatar>
                    </Grid>
                    <Grid container item xs={10} alignItems={"center"} spacing={1}>
                        <UserBlock />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}