import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { EditOutlined } from "@material-ui/icons"
import { TextField, Button, CircularProgress, MenuItem, Grid } from "@material-ui/core"

import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardFooter from "components/Card/CardFooter"
import GridItem from "components/Grid/GridItem"

import { Formik } from "formik"
import * as Yup from "yup"

import styles from "assets/jss/material-dashboard-react/views/cardFormStyle"

//apollo
import { Mutation } from "@apollo/client/react/components"
import { useQuery } from "@apollo/client"
import { UPDATE_SELLER } from "querys/seller/Auth user/sellerQueries"
import { LOAD_ALL_COUNTRIES } from "querys/admin/countryQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"
//redux
import { useSelector } from "react-redux"

//errors
import { getFormErrors } from "utils/errorsUtils"

const useStyles = makeStyles(styles)

export default function SellerForm(props) {
    const classes = useStyles()

    const { updateItem } = props

    const CURRENT_USER = useSelector((state) => state.loginReducer)

    const notifyError = (message) => ErrorNotification("Seller", message)
    const notifySuccess = () => SuccessNotification("Seller", "Update succeed")

    const [country, setCountry] = React.useState("")
    const [countries, setCountries] = React.useState([])

    const { error: errorCountries } = useQuery(LOAD_ALL_COUNTRIES, {
        onCompleted: (data) => {
            if (data && data.allCountries) {
                setCountries(data.allCountries.edges)
            }
        },
    })

    if (errorCountries) {
        notifyError("Unable to load existing countries, try again")
    }

    React.useEffect(() => {
        if (updateItem && updateItem.country && updateItem.country.name !== "None" && countries.length) {
            setCountry(updateItem.country.id)
        }
    }, [updateItem, countries])

    const cancelForm = () => {
        props.toggleVisible()
    }

    const successForm = () => {
        notifySuccess()
        props.refetch()
        props.toggleVisible()
    }

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="warning" className={classes.cardHeaderIcon}>
                    <EditOutlined />
                </CardIcon>
                <h4 className={classes.cardTitle}>Edit seller</h4>
            </CardHeader>
            <Mutation mutation={UPDATE_SELLER}>
                {(register, { loading, error, data }) => (
                    <Formik
                        initialValues={{
                            country: country,
                            sku: updateItem?.sku ?? "",
                            phone: updateItem?.phone ?? "",
                        }}
                        enableReinitialize
                        validationSchema={Yup.object({
                            country: Yup.string().required("field required"),
                            sku: Yup.string().required("field required"),
                            phone: Yup.string().required("field required"),
                        })}
                        onSubmit={(data, { setErrors }) => {
                            register({
                                variables: {
                                    id: updateItem.id,
                                    country: data.country,
                                    sku: data.sku,
                                    phone: data.phone,
                                    user: CURRENT_USER.id,
                                },
                            }).then(
                                (response) => {
                                    let dataResponse = response.data.updateSeller

                                    if (dataResponse.seller) {
                                        return successForm()
                                    }
                                },
                                (response) => {
                                    if (response.graphQLErrors.length) {
                                        getFormErrors(response.graphQLErrors, notifyError, setErrors)
                                    } else {
                                        notifyError("Update seller failed, try again")
                                    }
                                }
                            )
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="country"
                                                label="Country*"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                select
                                                className={classes.formFields}
                                                {...formik.getFieldProps("country")}
                                                error={formik.errors.country && formik.touched.country}
                                                helperText={
                                                    formik.errors.country &&
                                                    formik.touched.country &&
                                                    formik.errors.country
                                                }
                                            >
                                                {countries.map((option) => (
                                                    <MenuItem key={option.node.id} value={option.node.id}>
                                                        {option.node.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="sku"
                                                label="SKU*"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                className={classes.formFields}
                                                {...formik.getFieldProps("sku")}
                                                error={formik.errors.sku && formik.touched.sku}
                                                helperText={
                                                    formik.errors.sku && formik.touched.sku && formik.errors.sku
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="phone"
                                                label="Phone*"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                className={classes.formFields}
                                                {...formik.getFieldProps("phone")}
                                                error={formik.errors.phone && formik.touched.phone}
                                                helperText={
                                                    formik.errors.phone && formik.touched.phone && formik.errors.phone
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>

                                <CardFooter chart>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <small>(*) means that fields are required</small>
                                        </Grid>

                                        <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"secondary"}
                                                onClick={cancelForm}
                                                disabled={loading}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"primary"}
                                                type="submit"
                                                disabled={!formik.isValid || loading}
                                            >
                                                {loading ? <CircularProgress size={24} /> : "Update"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardFooter>
                            </form>
                        )}
                    </Formik>
                )}
            </Mutation>
        </Card>
    )
}
