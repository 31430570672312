import gql from "graphql-tag"

//create seller etsy
export const CREATE_SELLER_ETSY = gql`
    query createSellerEtsy(
        $alias: String!
        $serviceIds: [ID]
        $code: String
        $status: String!
        $error: String
        $errorDescription: String
    ) {
        createSellerEtsy(
            input: {
                serviceIds: $serviceIds
                alias: $alias
                code: $code
                status: $status
                error: $error
                errorDescription: $errorDescription
            }
        ) {
            success
            reason
        }
    }
`
