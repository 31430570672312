/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import {Router, Route} from "react-router-dom";

//apollo
import {client} from "config/apollo.conf";
import { ApolloProvider } from "@apollo/client";

//layout
import PublicLayout from "layouts/PublicLayout";
import SellerLayout from "layouts/SellerLayout";

//back to top
import BackToTop from "components/App/backToTopButton";
import ScrollToTop from "components/App/ScrollToTop";

//redux
import {Provider, useSelector} from "react-redux"
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor} from "config/redux.conf"

import "assets/css/material-dashboard-react.css?v=1.9.0";

//react-toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//browser cookies manage
import * as Cookies from "js-cookie";

//cookies agree
import CookiesAgreement from "views/App/CookiesAgreement";

const hist = createBrowserHistory();

const PrivateRoutes = () => {
    const token = !!Cookies.get("JWT")
    const authUser = useSelector(state => state.loginReducer)
    if (token && authUser) {
        return (<Route path="/seller" component={SellerLayout}/>)
    }
    return null
};



ReactDOM.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <BackToTop/>
            <ToastContainer hideProgressBar newestOnTop closeOnClick={false} autoClose={7000}/>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={hist}>
                    <ScrollToTop>
                        {/*Layout of the logged in user*/}
                        <PrivateRoutes/>

                        {/*Public routes*/}
                        <PublicLayout/>
                    </ScrollToTop>
                    <CookiesAgreement/>
                </Router>
            </PersistGate>
        </Provider>
    </ApolloProvider>,
    document.getElementById("root")
);
