import React from "react";
import {Avatar, Button, IconButton, ListItem, ListItemText, Popover, useMediaQuery} from "@material-ui/core";

import {Link, useHistory} from "react-router-dom"

import {makeStyles, useTheme} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/LandingPageStyle";
import logo from "assets/img/logo/logo.png";

import MenuIcon from "mdi-react/MenuIcon";


const useStyles = makeStyles(styles);

export default function TopLinks() {
    const classes = useStyles();

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const history = useHistory();

    const helpRoutes = [
        {route: "/how-works", label: "How it works"},
        {route: "/help", label: "Help"},
        {route: "/marketplaces", label: "Marketplaces"},
    ]

    const featuresRoutes = [
        {route: "/import-product", label: "Import products"},
        {route: "/product-ads", label: "Product ads"}
    ]

    const policyRoutes = [
        {route: "/service-terms", label: "Service terms"},
        {route: "/privacy-policy", label: "Privacy policy"},
    ]

    const loginRoutes = [
        {route: "/login", label: "Login"}
    ]

    //mobile menu
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    //features menu
    const [anchorElFeatures, setAnchorElFeatures] = React.useState(null);

    const handleClickFeatures = (event) => {
        setAnchorElFeatures(event.currentTarget);
    }

    const handleCloseFeatures = () => {
        setAnchorElFeatures(null);
    }

    const openFeatures = Boolean(anchorElFeatures)
    const idFeatures = openFeatures ? 'features-popover' : undefined

    //policy menu
    const [anchorElPolicy, setAnchorElPolicy] = React.useState(null);

    const handleClickPolicy = (event) => {
        setAnchorElPolicy(event.currentTarget);
    }

    const handleClosePolicy = () => {
        setAnchorElPolicy(null);
    }

    const openPolicy = Boolean(anchorElPolicy)
    const idPolicy = openPolicy ? 'policy-popover' : undefined

    const openUrl = (url) => {
        history.push(url);
        handleClose()
        handleClosePolicy()
        handleCloseFeatures()
    };

    const FeaturesMenu = () => (
        <Popover
            id={idFeatures}
            open={openFeatures}
            anchorEl={anchorElFeatures}
            onClose={handleCloseFeatures}
            transitionDuration={500}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            {
                featuresRoutes.map(item => (
                    <ListItem
                        key={item.route}
                        role="listitem"
                        button
                        onClick={() => openUrl(item.route)}
                    >
                        <ListItemText primary={item.label}/>
                    </ListItem>
                ))
            }
        </Popover>
    )

    const PolicyMenu = () => (
        <Popover
            id={idPolicy}
            open={openPolicy}
            anchorEl={anchorElPolicy}
            onClose={handleClosePolicy}
            transitionDuration={500}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            {
                policyRoutes.map(item => (
                    <ListItem
                        key={item.route}
                        role="listitem"
                        button
                        onClick={() => openUrl(item.route)}
                    >
                        <ListItemText primary={item.label}/>
                    </ListItem>
                ))
            }
        </Popover>
    )

    const MobileMenu = () => (
        <Popover
            id={id}
            open={open}
            anchorReference={"anchorEl"}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transitionDuration={500}
            onClose={handleClose}

        >
            {
                [...helpRoutes, ...featuresRoutes, ...policyRoutes, ...loginRoutes].map(item => (
                    <ListItem
                        key={item.route}
                        role="listitem"
                        button
                        onClick={() => openUrl(item.route)}
                    >
                        <ListItemText primary={item.label}/>
                    </ListItem>
                ))
            }
        </Popover>
    )


    return (
        <div className={classes.topLinks}>
            <MobileMenu/>
            <FeaturesMenu/>
            <PolicyMenu/>

            <Link to={"/"}>
                <Avatar
                    alt={"sync2markets"}
                    src={logo}
                    className={classes.logo}
                />
            </Link>

            {
                smallScreen
                    ? <IconButton
                        aria-label="options"
                        color={"inherit"}
                        onClick={handleClick}
                        style={{marginRight: "10px"}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    : <div>
                        {
                            helpRoutes.map(item => (
                                <Button
                                    key={item.route}
                                    variant="text"
                                    color={"inherit"}
                                    className={classes.headerButtons}
                                    onClick={() => openUrl(item.route)}
                                >
                                    {item.label}
                                </Button>
                            ))
                        }
                        <Button
                            variant="text"
                            color={"inherit"}
                            className={classes.headerButtons}
                            onClick={handleClickFeatures}
                        >
                            Features
                        </Button>
                        <Button
                            variant="text"
                            color={"inherit"}
                            className={classes.headerButtons}
                            onClick={handleClickPolicy}
                        >
                            Policy
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{color: "white", borderColor: "white"}}
                            onClick={() => openUrl("/login")}
                        >
                            Login
                        </Button>
                    </div>
            }
        </div>
    )
}
