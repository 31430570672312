import React from "react"

// icons
import { Badge, Grid, IconButton, Popover, Typography } from "@material-ui/core"

//redux
import { useSelector } from "react-redux"

//apollo
import { useQuery, useMutation } from "@apollo/client"

import NotificationsIcon from "mdi-react/NotificationsIcon"
import NotificationsNoneIcon from "mdi-react/NotificationsNoneIcon"
import { LOAD_SELLER_NOTIFICATIONS, READ_SELLER_NOTIFICATION } from "querys/seller/Notification/notificationQueries"

//notifications
import NotificationsMenu from "../App/Notifications"
import ConnectionIcon from "mdi-react/ConnectionIcon"
import MarketplacesConnectionStatus from "../Seller/MarketplacesConnectionStatus"

export default function SellerNavbarLinks() {
    const CURRENT_USER = useSelector((state) => state.loginReducer)
    const marketplaceConnectionStatus = useSelector((state) => state.marketplaceConnectionReducer)

    const [unReadNotificationsQuantity, setUnReadNotificationsQuantity] = React.useState(0)
    const { data } = useQuery(LOAD_SELLER_NOTIFICATIONS, {
        variables: {
            sellerId: CURRENT_USER?.seller?.id,
        },
        onCompleted: (data1) => {
            if (data1?.allSellerNotification?.edges) {
                let unreadQuantity = 0
                data1.allSellerNotification.edges.forEach((item) => {
                    if (!item.node.wasSeen) {
                        unreadQuantity++
                    }
                })
                setUnReadNotificationsQuantity(unreadQuantity)
            }
        },
    })

    const [readNotification, { loading: loadingReadNotification }] = useMutation(READ_SELLER_NOTIFICATION, {
        refetchQueries: [{ query: LOAD_SELLER_NOTIFICATIONS, variables: { sellerId: CURRENT_USER?.seller?.id } }],
    })

    //notifications menu
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    async function handleReadNotification(item) {
        await readNotification({
            variables: {
                id: item.id,
                seller: item.seller.id,
                type: item.type,
                value: item.value,
                reference: item.reference,
                creationDate: item.creationDate,
            },
        })
    }

    //marketplaces menu
    const [anchorElMarketplaces, setAnchorElMarketplaces] = React.useState(null)
    const handleCloseMenuMarketplaces = () => {
        setAnchorElMarketplaces(null)
    }

    const openMenuMarketplaces = Boolean(anchorElMarketplaces)
    const idMenuMarketplaces = openMenuMarketplaces ? "marketplaces-popover" : undefined

    const handleClickMenuMarketplaces = (event) => {
        setAnchorElMarketplaces(event.currentTarget)
    }

    const MarketplaceConnectionMenu = () => (
        <Popover
            id={idMenuMarketplaces}
            open={openMenuMarketplaces}
            anchorEl={anchorElMarketplaces}
            onClose={handleCloseMenuMarketplaces}
            transitionDuration={500}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <Grid container style={{ padding: "5px 0", maxWidth: "500px" }}>
                <Grid container item justifyContent={"space-between"} style={{ padding: "8px" }}>
                    <Typography variant={"subtitle2"}>Connections</Typography>
                </Grid>
                <Grid item xs={12}>
                    <MarketplacesConnectionStatus />
                </Grid>
            </Grid>
        </Popover>
    )

    return (
        <div>
            <NotificationsMenu
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                data={data}
                loadingReadNotification={loadingReadNotification}
                handleReadNotification={handleReadNotification}
            />

            <MarketplaceConnectionMenu />

            <IconButton color={"inherit"} onClick={handleClickMenuMarketplaces}>
                <Badge
                    color={"error"}
                    badgeContent={marketplaceConnectionStatus.connectionIssuesQuantity}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    <ConnectionIcon />
                </Badge>
            </IconButton>

            <IconButton aria-label="notifications" color={"inherit"} onClick={handleClickMenu}>
                <Badge
                    color={"primary"}
                    badgeContent={unReadNotificationsQuantity}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                >
                    {unReadNotificationsQuantity > 0 ? (
                        <NotificationsIcon size={"1.5rem"} />
                    ) : (
                        <NotificationsNoneIcon size={"1.5rem"} />
                    )}
                </Badge>
            </IconButton>
        </div>
    )
}
