import { gql } from "@apollo/client"

//variationField listing using pagination and sort
export const LOAD_VARIATION_FIELDS = gql`
    query allVariationFieldsPaginated(
        $orderBy: [String]!
        $nameFilter: String
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allVariationFields(
            orderBy: $orderBy
            name_Icontains: $nameFilter
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//all variationFields listing, used for selecting nomenclator
export const LOAD_ALL_VARIATION_FIELDS = gql`
    query allVariationFields {
        allVariationFields {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//add new variationField
export const CREATE_VARIATION_FIELD = gql`
    mutation createVariationField($name: String!) {
        createVariationField(input: { name: $name }) {
            variationField {
                id
                name
            }
        }
    }
`

//update variationField
export const UPDATE_VARIATION_FIELD = gql`
    mutation updateVariationField($id: ID!, $name: String!) {
        updateVariationField(id: $id, input: { name: $name }) {
            variationField {
                id
                name
            }
        }
    }
`

//delete variationField
export const DELETE_VARIATION_FIELD = gql`
    mutation deleteVariationField($id: ID!) {
        deleteVariationField(id: $id) {
            found
            deletedId
        }
    }
`
