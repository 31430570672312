import { whiteColor, grayColor, primaryColor } from "assets/jss/material-dashboard-react.js"
import tooltipStyle from "assets/jss/material-dashboard-react/tooltipStyle"

const cardInfoStyle = {
    cardHeader: {
        backgroundColor: primaryColor[1],
        margin: "-20px 15px 0",
    },
    cardHeaderIcon: {
        color: whiteColor,
        display: "flex",
        alignItems: "center",
    },
    cardTitle: {
        color: whiteColor,
        marginTop: "3px",
        minHeight: "auto",
        fontWeight: "400",
        fontSize: "16px",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        "& small": {
            color: grayColor[1],
            fontWeight: "100",
            lineHeight: "1",
        },
    },
    cardHeaderActions: {
        float: "right",
        color: whiteColor,
        margin: "-20px 15px 0",
    },
    cardAvatarContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    cardAvatarIcon: {
        height: "120px",
        width: "120px",
        border: "1px solid " + primaryColor[2],
    },
    sectionsDividerVertical: {
        width: "1px",
        backgroundColor: grayColor[4],
        height: "100%",
    },
    sectionsDividerHorizontal: {
        height: "1px",
        backgroundColor: grayColor[4],
        width: "100%",
        margin: "10px 0",
    },
    cardInfoSection: {
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        "& div": {
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
        },
    },
    leftFlex: {
        width: "90%",
        display: "flex",
        justifyContent: "flex-end",
        padding: "5px 0",
    },
    rightFlex: {
        padding: "5px 0",
    },

    //skeleton loader
    skeletonContainer: {
        padding: "10px",
    },
    skeletonAvatarContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    skeletonTextContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    //info tooltip
    infoTooltip: {
        ...tooltipStyle.tooltip,
    },
}

export default cardInfoStyle
