import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/views/LandingPageStyle"

//components
import Slide1 from "components/LandingPage/Slide1"
import Slide2 from "components/LandingPage/Slide2"
import Slide3 from "components/LandingPage/Slide3"
import Slide4 from "components/LandingPage/Slide4"
import Slide5 from "components/LandingPage/Slide5"
import MarketplacesAbout from "components/LandingPage/MarketplacesAbout"

const useStyles = makeStyles(styles)

export default function LandingPage() {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            {/*slide 2*/}
            <Slide1 />

            {/*slide 2*/}
            <Slide2 />

            {/*slide 3*/}
            <Slide3 />

            {/*slide 4*/}
            <Slide4 />

            {/*slide 4*/}
            <Slide5 />

            {/*about marketplaces*/}
            <MarketplacesAbout />
        </div>
    )
}
