import gql from "graphql-tag"

//create seller amazon
export const CREATE_SELLER_AMAZON = gql`
    query createSellerAmazon($serviceIds: [ID], $alias: String!, $parameters: String!) {
        createSellerAmazon(input: { serviceIds: $serviceIds, alias: $alias, parameters: $parameters }) {
            success
            reason
        }
    }
`
