import cardInfoStyle from "assets/jss/material-dashboard-react/views/cardInfoStyle"
import { primaryColor } from "assets/jss/material-dashboard-react"
import tooltipStyle from "assets/jss/material-dashboard-react/tooltipStyle"

const cardFormStyle = (theme) => ({
    ...cardInfoStyle,
    formFields: {
        margin: "6px 0",
    },

    //description tooltip
    descriptionTooltip: {
        ...tooltipStyle.descriptionTooltip,
    },

    //roles assignment section
    paper: {
        padding: "10px 0 10px 10px",
    },

    title: {
        marginBottom: "6px",
    },

    list: {
        overflow: "auto",
        maxHeight: 400,
        "& .Mui-selected": {
            backgroundColor: primaryColor[3],
        },
    },

    avatar: {
        width: 70,
        height: 70,
        margin: "0 3px",
        border: `2px solid ${primaryColor[2]}`,
    },

    marketplaceThumb: {
        width: 200,
        height: 200,
        border: `1px solid ${primaryColor[2]}`,
        cursor: "pointer",
        "& .MuiAvatar-img": {
            width: "auto",
            height: "90%",
        },
    },
    selectedMarketplaceThumb: {
        width: 205,
        height: 205,
        border: `3px solid ${primaryColor[1]}`,
    },
})

export default cardFormStyle
