//apollo
import { ApolloClient, InMemoryCache, from } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import { createUploadLink } from "apollo-upload-client"

//manage browser cookies
import * as Cookies from "js-cookie"

//notifications
import { ErrorNotification } from "components/Notifications/Notifications"
const notifyError = (message) => ErrorNotification("Authentication", message)

//ToDo: implement refresh token functionality at this level with a link (any working solution found)

// const getNewToken = async () => {
//     const storedRefreshToken = localStorage.getItem("refreshToken")
//
//     let token
//     let refreshToken
//
//     await client
//         .mutate({
//             mutation: REFRESH_TOKEN,
//             variables: {
//                 refreshToken: storedRefreshToken,
//             },
//         })
//         .then((response) => {
//             token = response.data.refreshToken.token
//             refreshToken = response.data.refreshToken.refreshToken
//             Cookies.set("JWT", token, { secure: true, sameSite: "Strict" })
//             localStorage.setItem("refreshToken", refreshToken)
//             console.log(token)
//             console.log(refreshToken)
//         })
//         .catch((error) => {
//             //make log out when error ocurred
//             console.log("refresh token failed", error)
//         })
//
//     return token
// }


const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        for (const { message } of graphQLErrors) {
            if (message === "You do not have permission to perform this action") {
                notifyError("Credentials validation error. Re authentication needed")
                window.open("/", "_self")
                Cookies.remove("JWT")
                client.clearStore()
            }
        }
    }
})

const authLink = setContext((_, { headers }) => {
    const token = Cookies.get("JWT")

    return {
        headers: {
            ...headers,
            authorization: token ? `JWT ${token}` : "",
        },
    }
})

const httpLink = createUploadLink({
    uri: `${process.env.REACT_APP_API_URL}graphql/`,
})

export const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
})
