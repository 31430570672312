import React from "react"
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    CircularProgress,
    Tooltip,
    IconButton,
    Popover,
    Grid,
    Typography,
} from "@material-ui/core"
import DoneIcon from "mdi-react/DoneIcon"
import { MoreOutlined } from "@material-ui/icons"
import styles from "assets/jss/material-dashboard-react/views/productsStyle"
import { makeStyles } from "@material-ui/core/styles"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(styles)

export default function NotificationsMenu(props) {
    const classes = useStyles()
    const history = useHistory()

    const { anchorEl, setAnchorEl, data, loadingReadNotification, handleReadNotification } = props

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const openMenu = Boolean(anchorEl)
    const idMenu = openMenu ? "notifications-popover" : undefined

    function handleClickNotification(notificationItem) {
        const param = notificationItem.node.reference
        switch (notificationItem.node.type) {
            case 1:
                //product
                return history.push(`/seller/product?productId=${param}`)

            case 2:
                //variation
                return history.push(`/seller/product?variationId=${param}`)

            case 3:
                //amazon order
                return history.push(`/seller/orders?amazonOrderId=${param}`)

            case 4:
                //etsy order
                return history.push(`/seller/orders?etsyOrderId=${param}`)

            default:
                break
        }
    }

    return (
        <Popover
            id={idMenu}
            open={openMenu}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            transitionDuration={500}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
        >
            <Grid container style={{ marginBottom: "5px", padding: "5px", width: "400px", maxWidth: "450px" }}>
                <Grid container item justifyContent={"space-between"} style={{ padding: "8px" }}>
                    <Typography variant={"subtitle2"}>Notifications</Typography>
                    <Tooltip title={"Notification center"} classes={{ tooltip: classes.infoTooltip }}>
                        <MoreOutlined
                            style={{ fontSize: 16, cursor: "pointer" }}
                            onClick={() => history.push("/seller/notification-center")}
                        />
                    </Tooltip>
                </Grid>

                <Grid item xs={12}>
                    {data?.allSellerNotification?.edges?.length > 0 ? (
                        <List dense style={{ width: "100%" }}>
                            {data.allSellerNotification.edges.map((item) => (
                                <ListItem
                                    role="listitem"
                                    key={item.node.id}
                                    button
                                    dense
                                    divider
                                    onClick={() => handleClickNotification(item)}
                                >
                                    <ListItemText
                                        primary={!item.node.wasSeen ? <b>{item.node.value}</b> : item.node.value}
                                    />
                                    {!item.node.wasSeen && (
                                        <ListItemSecondaryAction>
                                            {loadingReadNotification ? (
                                                <CircularProgress size={18} />
                                            ) : (
                                                <Tooltip
                                                    title={"Mark as viewed"}
                                                    classes={{ tooltip: classes.infoTooltip }}
                                                >
                                                    <IconButton
                                                        edge={"end"}
                                                        size={"small"}
                                                        onClick={() => handleReadNotification(item.node)}
                                                    >
                                                        <DoneIcon size={18} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </ListItemSecondaryAction>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                    ) : (
                        <Grid style={{ padding: "8px", color: "#a9afbb" }}>No notifications to show</Grid>
                    )}
                </Grid>
            </Grid>
        </Popover>
    )
}
