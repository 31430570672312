import React from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Tooltip,
    CircularProgress,
    Dialog,
    Button,
    useTheme,
    useMediaQuery,
    Checkbox
} from '@material-ui/core';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
    EditOutlined,
    DeleteOutlined,
    ViewListOutlined,
    InfoOutlined
} from '@material-ui/icons';
import AddIcon from "mdi-react/AddIcon";

//styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/tableStyle";

import {useHistory} from "react-router-dom";

//apollo
import {Query, Mutation} from "@apollo/client/react/components"
import {LOAD_GROUPS, DELETE_GROUP} from "querys/admin/authGroupQueries"

//notifications
import {ErrorNotification, SuccessNotification} from "components/Notifications/Notifications";

//table utils
import {getComparator, stableSort, LoadingSkeleton, TableNoData, ConfirmTransition} from "utils/tableUtils";

//permissions dialog
import PermissionsDialog from "components/Admin/PermissionsDialog";

//page description
import {PermissionsGroupView} from "utils/viewsDescriptionUtils";

const useStyles = makeStyles(styles);

const notifyError = message => ErrorNotification("Group", message)
const notifySuccess = message => SuccessNotification("Group", message)

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

    //not to permit sort by columns of equal values. server sorting does not work properly
    const cells = [
        { id: 'name', label: 'Name', align: "left", sort: true },
        { id: 'permissions', label: 'Permissions', align: "left", sort: false }
    ];

    const headCells = () => {
        if (mobileScreen) {
            cells.unshift({ id: 'select', label: '', align: "left", sort: false })
        }
        return cells
    }

    return (
        <TableHead>
            <TableRow>
                {headCells().map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.head}
                        align={headCell.align ? headCell.align : "center"}
                    >
                        {
                            headCell.sort
                            ? <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                hideSortIcon={!headCell.sort}
                            >
                                {headCell.label}
                                {
                                    orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>)
                                        : null
                                }
                            </TableSortLabel>
                                : <span>{headCell.label}</span>
                        }

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = (props) => {
    const classes = useStyles();

    const history = useHistory();

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const { loading, selected, updateItem, deleteItem } = props

    const openForm = () => {
        history.push("/seller/permissions-group/form")
    }

    return (
        <Toolbar className={classes.header}>
            <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                List of groups
                <Tooltip
                    title={<PermissionsGroupView darkStyle/>}
                    arrow
                    interactive
                    classes={{tooltip: classes.descriptionTooltip}}
                >
                    <InfoOutlined style={{fontSize: 16}}/>
                </Tooltip>
            </Typography>

            <div>
                {
                    loading
                        ? <CircularProgress size={24}/>
                        : <>
                            <Tooltip title="Add group">
                                <IconButton aria-label="add group" color={"inherit"} onClick={openForm}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>

                            {
                                mobileScreen && selected
                                && <>
                                    <br/>
                                    <Tooltip title="Edit marketplace">
                                        <IconButton
                                            aria-label="edit marketplace"
                                            color={"inherit"}
                                            onClick={() => updateItem(selected)}
                                        >
                                            <EditOutlined fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete marketplace">
                                        <IconButton
                                            aria-label="delete marketplace"
                                            color={"inherit"}
                                            onClick={() => deleteItem(selected)}
                                        >
                                            <DeleteOutlined fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                        </>
                }
            </div>
        </Toolbar>
    );
};

export default function EnhancedTable() {
    const classes = useStyles();

    const history = useHistory();

    //table variables
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('name')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    const handleRequestSort = (event, property) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeRowsPerPage = (event) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState("")
    const [endCursorPagination, setEndCursorPagination] = React.useState("")

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allGroups.totalCount),
        setStartCursorPagination(data.allGroups.pageInfo.startCursor),
        setEndCursorPagination(data.allGroups.pageInfo.endCursor)
    ]

    //bind query variables to this variables
    const [beforeCursorVariable, setBeforeCursorVariable] = React.useState("")
    const [afterCursorVariable, setAfterCursorVariable] = React.useState("")
    const [itemsVariable, setItemsVariable] = React.useState("firstPageItems")

    let queryVariables = {
        orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
        [itemsVariable]: rowsPerPage,
        beforeCursor: beforeCursorVariable,
        afterCursor: afterCursorVariable
    }

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setAfterCursorVariable(endCursorPagination)
            setBeforeCursorVariable("")

            setItemsVariable("firstPageItems")
        } else {
            setBeforeCursorVariable(startCursorPagination)
            setAfterCursorVariable("")

            setItemsVariable("lastPageItems")
        }
        setPage(newPage);
    };

    const updateItem = (item) => {
        history.push("/seller/permissions-group/form", {updateItem: item})
        setSelected(null)
    }

    const deleteItem = (item) => {
        setDeleteItemIndex(item.node.id)
        setOpenDialog(true)
        setSelected(null)
    }

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'))

    //table selected item (for small screens)
    const [selected, setSelected] = React.useState(null)
    const handleSelectItem = (item) => {
        if (selected && (selected.node.id === item.node.id)) {
            setSelected(null)
        } else {
            setSelected(item)
        }
    }

    //permissions dialog
    const [openPermissionsDialog, setOpenPermissionsDialog] = React.useState(false)
    const [permissionsItem, setPermissionsItem] = React.useState(null)

    const TableRows = (props) => {
        const {row, index} = props

        const viewPermissions = (item) => {
            setPermissionsItem(item.node.permissions.edges)
            setOpenPermissionsDialog(true)
        }

        return (
            <TableRow
                hover
                tabIndex={-1}
                key={`row-${index}`}
                className={classes.row}
            >
                {
                    mobileScreen
                    && <TableCell align="left">
                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            checked={selected && (row.node.id === selected.node.id)}
                            onClick={() => handleSelectItem(row)}
                        />
                    </TableCell>
                }
                <TableCell align="left">
                    { row.node.name }
                </TableCell>
                <TableCell align="left">
                    <IconButton
                        aria-label="view permissions"
                        size={"small"}
                        color={"primary"}
                        onClick={() => viewPermissions(row)}
                    >
                        <ViewListOutlined fontSize={"small"}/>
                    </IconButton>
                    <div className={classes.actionColumn}>
                        <Tooltip title="Edit group">
                            <IconButton
                                aria-label="edit group"
                                className={classes.editBtn}
                                onClick={() => updateItem(row)}
                            >
                                <EditOutlined fontSize={"small"}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete group">
                            <IconButton
                                aria-label="delete group"
                                className={classes.deleteBtn}
                                onClick={() => deleteItem(row)}
                            >
                                <DeleteOutlined fontSize={"small"}/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    const [openDialog, setOpenDialog] = React.useState(false)
    const [deleteItemIndex, setDeleteItemIndex] = React.useState(null)

    function ConfirmDialog(props) {
        const {register, refetch, loading} = props

        const handleClose = () => {
            setOpenDialog(false)
        }

        const deleteItem = () => (
            register({
                variables: {
                    id: deleteItemIndex
                }
            }).then(
                (response) => {
                    if (response.data.deleteGroup.found) {
                        notifySuccess("Group deleted")
                        refetch()
                    }
                    if (!response.data.deleteGroup.found) {
                        notifyError("Delete group failed, try again")
                    }
                    handleClose()
                },
                () => {
                    notifyError("Delete group failed, try again")
                    handleClose()
                }
            )
        )


        return (
            <div>
                <Dialog
                    open={openDialog}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle disableTypography>
                        <Typography variant="h6">Delete group</Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        <Typography gutterBottom>
                            Do you confirm to delete the group?
                        </Typography>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        {
                            loading
                                ? <CircularProgress size={24}/>
                                : <Button onClick={deleteItem} color="primary">
                                    Confirm
                                </Button>
                        }
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }

    return (
        <Query
            query={LOAD_GROUPS}
            fetchPolicy={"network-only"}
            variables={queryVariables}
            onCompleted={data => {
                setPaginationResponse(data)
            }}
        >
            {({ loading, error, data, refetch }) => {
                if (error) {
                    notifyError("Load data failed")
                }

                let rows = data?.allGroups.edges ?? []
                return (
                    <Mutation mutation={DELETE_GROUP}>
                        {
                            (register, {loading: loadingDelete}) => {
                                return (
                                    <div className={classes.root}>
                                        <ConfirmDialog
                                            register={register}
                                            refetch={refetch}
                                            loading={loadingDelete}
                                        />
                                        <PermissionsDialog
                                            open={openPermissionsDialog}
                                            setOpenDialog={setOpenPermissionsDialog}
                                            permissionsItem={permissionsItem}
                                        />
                                        <Paper className={classes.paper}>
                                            <EnhancedTableToolbar
                                                loading={loading || loadingDelete}
                                                selected={selected}
                                                updateItem={updateItem}
                                                deleteItem={deleteItem}
                                            />
                                            {
                                                loading
                                                    ? <LoadingSkeleton/>
                                                    : !rows.length ? <TableNoData/>
                                                    : <TableContainer>
                                                        <Table
                                                            className={classes.table}
                                                            aria-labelledby="tableTitle"
                                                            aria-label="enhanced table"
                                                        >
                                                            <EnhancedTableHead
                                                                classes={classes}
                                                                order={order}
                                                                orderBy={orderBy}
                                                                onRequestSort={handleRequestSort}
                                                                rowCount={rows.length}
                                                            />
                                                            <TableBody>
                                                                {
                                                                    stableSort(rows, getComparator(order, orderBy))
                                                                        .map((row, index) => {
                                                                            return <TableRows
                                                                                row={row}
                                                                                index={index}
                                                                                key={index}
                                                                            />
                                                                        })
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                            }
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={totalItemsPagination}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    </div>
                                )
                            }
                        }
                    </Mutation>
                )
            }}
        </Query>
    )
}
