import React, { useMemo } from "react"

//material components
import {
    Card,
    Avatar,
    Typography,
    Grid,
    ImageListItem,
    ImageListItemBar,
    IconButton,
    Tooltip,
    Fade,
} from "@material-ui/core"

//icons
import { RotateRight, DateRangeOutlined } from "@material-ui/icons"
import CancelIcon from "mdi-react/CancelIcon"
import EditOffOutlineIcon from "mdi-react/EditOffOutlineIcon"
import EditOutlineIcon from "mdi-react/EditOutlineIcon"
import MoreCircleOutlineIcon from "mdi-react/MoreCircleOutlineIcon"
import DeleteOffOutlineIcon from "mdi-react/DeleteOffOutlineIcon"
import DeleteOutlineIcon from "mdi-react/DeleteOutlineIcon"
import ChevronUpIcon from "mdi-react/ChevronUpIcon"
import ChevronDownIcon from "mdi-react/ChevronDownIcon"
import SourceBranchIcon from "mdi-react/SourceBranchIcon"
import LoadingIcon from "mdi-react/LoadingIcon"
import AsteriskIcon from "mdi-react/AsteriskIcon"

//material lab components
import { Alert } from "@material-ui/lab"

//lazy load components
import { LazyLoadComponent } from "react-lazy-load-image-component"

//assets
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/productCardStyle"
import img from "assets/img/035-online shopping.svg"
import { dangerColor, grayColor, whiteColor } from "assets/jss/material-dashboard-react"

//marketplaces icon
import AmazonImg from "assets/img/marketplaces/Amazon-icon.svg"
import EtsyImg from "assets/img/marketplaces/Etsy_icon.svg"
import EbayImg from "assets/img/marketplaces/Ebay_icon.svg"
import FacebookImg from "assets/img/marketplaces/Facebook_icon.svg"
import AmazonEmptyImg from "assets/img/marketplaces/AmazonEmpty-icon.svg"
import EtsyEmptyImg from "assets/img/marketplaces/EtsyEmpty_icon.svg"
import EbayEmptyImg from "assets/img/marketplaces/EbayEmpty_icon.svg"
import FacebookEmptyImg from "assets/img/marketplaces/FacebookEmpty_icon.svg"

const useStyles = makeStyles(styles)

export default function MainProductRow(props) {
    const classes = useStyles()

    const {
        autoOpenVariations,
        setDeleteItemIndex,
        setOpenDialog,
        marketplacesStatus,
        refetch,
        handleUpdateProduct,
        handleOpenVariations,
        handleToggleFacebookNotifications,
    } = props

    const product = props.product.node

    const blockedMessage = (action) => `This product is blocked to perform the '${action}' action`

    //components for lazy load
    const ProductDetailsModal = React.lazy(() => import("components/Seller/Product/ProductDetailsModal"))
    const ProductCardMarketplaces = React.lazy(() => import("components/Seller/Product/ProductCardMarketplaces"))

    //details modal
    const [openDetailsModal, setOpenDetailsModal] = React.useState(false)

    const handleOpenModal = () => {
        setOpenDetailsModal(true)
    }

    const [displayMarketplacesActions, setDisplayMarketplacesActions] = React.useState(false)

    const uploadedToAmazon = useMemo(() => {
        let isUploadedToAmazon = false
        product.statusInSellerMarketplaces.forEach((marketplaceItem) => {
            if (
                marketplaceItem.sellerMarketplace.marketplace.name.toUpperCase() === "AMAZON" &&
                marketplaceItem.isUploaded
            ) {
                isUploadedToAmazon = true
            }
        })

        return isUploadedToAmazon
    }, [product.statusInSellerMarketplaces])

    function handleVariationsOption() {
        handleOpenVariations(product, uploadedToAmazon)
    }

    //auto open variations form when prop autoOpenVariations === true
    React.useEffect(() => {
        if (autoOpenVariations) {
            handleVariationsOption()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //primary actions
    const primaryActions = [
        {
            icon: <MoreCircleOutlineIcon size={18} />,
            name: "See product details",
            action: function () {
                return handleOpenModal()
            },
        },
        {
            icon: <SourceBranchIcon size={18} />,
            name: "Manage variations",
            action: function () {
                return handleVariationsOption()
            },
        },
        {
            icon: displayMarketplacesActions ? <ChevronUpIcon /> : <ChevronDownIcon />,
            name: "See marketplaces detail",
            action: function () {
                return setDisplayMarketplacesActions(!displayMarketplacesActions)
            },
        },
    ]

    //secondary actions
    const secondaryActions = [
        {
            icon: product.isBlocked ? <EditOffOutlineIcon size={18} /> : <EditOutlineIcon size={18} />,
            name: product.isBlocked ? blockedMessage("edit") : "Edit product",
            action: function (product) {
                handleUpdateProduct(product.productId)
            },
        },
        {
            icon: product.isBlocked ? <DeleteOffOutlineIcon size={18} /> : <DeleteOutlineIcon size={18} />,
            name: product.isBlocked ? blockedMessage("delete") : "Delete product",
            action: function (product) {
                if (product.isBlocked) {
                    return null
                } else {
                    setDeleteItemIndex(product.id)
                    setOpenDialog(true)
                }
            },
        },
    ]

    function getAlertType(itemsRemaining) {
        if (itemsRemaining !== null) {
            if (itemsRemaining >= 20) {
                return "info"
            } else if (itemsRemaining >= 10) {
                return "warning"
            } else return "error"
        } else return "info"
    }

    const composeUrl = (file) => {
        return file?.node?.image ? `${process.env.REACT_APP_API_URL}file/${file.node.id}` : ""
    }

    const [imageIndex, setImageIndex] = React.useState(0)

    function rotateImageIndex() {
        let imagesQuantity = product.productImage.edges.length
        if (imagesQuantity > 1) {
            setImageIndex((imageIndex + 1) % imagesQuantity)
        }
    }

    const ProductBlockedIcon = () => (
        <Grid container item>
            <Tooltip title="This product is blocked to perform some actions" classes={{ tooltip: classes.infoTooltip }}>
                <div>
                    <CancelIcon color={dangerColor[0]} />
                </div>
            </Tooltip>
        </Grid>
    )

    const DateInfo = () => (
        <Tooltip title={`Modification date: ${product.lastModified}`} classes={{ tooltip: classes.infoTooltip }}>
            <div
                style={{
                    display: "inline-flex",
                    backgroundColor: "rgba(170, 170, 170, 0.8)",
                    padding: "2px 3px 1px 0",
                    borderRadius: "0 3px 0 0",
                }}
            >
                <Typography
                    variant="body2"
                    color="textSecondary"
                    component="div"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        color: whiteColor,
                    }}
                >
                    <DateRangeOutlined
                        style={{
                            fontSize: "14px",
                            margin: "3px",
                            paddingBottom: "3px",
                        }}
                    />
                    {product.lastModified}
                </Typography>
            </div>
        </Tooltip>
    )

    const [linkedMarketplacesInfo, setLinkedMarketplacesInfo] = React.useState([])
    React.useEffect(() => {
        let marketplaces = []
        product.statusInSellerMarketplaces.forEach((marketplaceItem) => {
            switch (marketplaceItem.sellerMarketplace.marketplace.name.toUpperCase()) {
                case "AMAZON":
                    if (marketplaceItem.isLinked) {
                        marketplaces.push({
                            marketplace: "Amazon",
                            region: marketplaceItem.sellerMarketplace.marketplace.fullName,
                            isUploaded: marketplaceItem.isUploaded,
                            image: marketplaceItem.isUploaded ? AmazonImg : AmazonEmptyImg,
                        })
                    }
                    break

                case "ETSY":
                    if (marketplaceItem.isLinked) {
                        marketplaces.push({
                            marketplace: "Etsy",
                            region: marketplaceItem.sellerMarketplace.marketplace.fullName,
                            isUploaded: marketplaceItem.isUploaded,
                            image: marketplaceItem.isUploaded ? EtsyImg : EtsyEmptyImg,
                        })
                    }
                    break

                case "EBAY":
                    if (marketplaceItem.isLinked) {
                        marketplaces.push({
                            marketplace: "Ebay",
                            region: marketplaceItem.sellerMarketplace.marketplace.fullName,
                            isUploaded: marketplaceItem.isUploaded,
                            image: marketplaceItem.isUploaded ? EbayImg : EbayEmptyImg,
                        })
                    }
                    break

                case "FACEBOOK":
                    if (marketplaceItem.isLinked) {
                        marketplaces.push({
                            marketplace: "Facebook",
                            region: marketplaceItem.sellerMarketplace.marketplace.fullName,
                            isUploaded: marketplaceItem.isUploaded,
                            image: marketplaceItem.isUploaded ? FacebookImg : FacebookEmptyImg,
                        })
                    }
                    break

                default:
                    break
            }
        })

        setLinkedMarketplacesInfo([...marketplaces])
    }, [product])

    const SeparatorIcon = () => <AsteriskIcon size={10} style={{ color: grayColor[1] }} />

    return (
        <>
            <React.Suspense fallback={null}>
                <ProductDetailsModal open={openDetailsModal} setOpen={setOpenDetailsModal} product={product} />
            </React.Suspense>

            <Grid container>
                <Card elevation={3} style={{ width: "100%", padding: "5px" }}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item xs={"auto"}>
                            <ImageListItem component={"div"}>
                                <LazyLoadComponent>
                                    <Avatar
                                        src={composeUrl(product.productImage.edges[imageIndex])}
                                        variant={"rounded"}
                                        className={classes.img}
                                    >
                                        <img src={img} alt="Product" className={classes.noImage} />
                                    </Avatar>
                                </LazyLoadComponent>

                                {product.productImage.edges.length > 0 && (
                                    <ImageListItemBar
                                        subtitle={`${imageIndex + 1}/${product.productImage.edges.length}`}
                                        className={classes.imageFooter}
                                        actionIcon={
                                            product.productImage.edges.length > 1 ? (
                                                <IconButton
                                                    size={"small"}
                                                    aria-label="rotate"
                                                    color={"inherit"}
                                                    onClick={rotateImageIndex}
                                                >
                                                    <RotateRight fontSize={"small"} />
                                                </IconButton>
                                            ) : null
                                        }
                                    />
                                )}
                            </ImageListItem>
                        </Grid>

                        <Grid container item spacing={1} xs={true}>
                            {product.isBlocked && <ProductBlockedIcon />}

                            <Grid item xs={12}>
                                {product.category && (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            color="textSecondary"
                                            component={"span"}
                                            style={{ paddingRight: "5px" }}
                                        >
                                            {`in ${product.category.name.toUpperCase()}`}
                                        </Typography>
                                        <AsteriskIcon
                                            size={10}
                                            style={{ margin: "0 5px 0 3px", color: grayColor[1] }}
                                        />
                                    </>
                                )}
                                <Typography variant="subtitle2" color="textPrimary" component={"span"}>
                                    {product.title}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                    style={{ textAlign: "justify" }}
                                >
                                    {product.description ? product.description : "No description"}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Alert
                                    severity={getAlertType(product.quantity)}
                                    style={{ padding: "0 5px" }}
                                    classes={{ icon: classes.alertIcon, message: classes.alertText }}
                                >
                                    {`${product.quantity} item(s) available`}
                                </Alert>
                            </Grid>

                            <Grid container item spacing={1} alignItems={"center"}>
                                <Grid item>
                                    <DateInfo />
                                </Grid>

                                <Grid item>
                                    <SeparatorIcon />
                                </Grid>

                                <Grid item>
                                    <Tooltip
                                        title={`${product.productProductVariation?.totalCount} variations`}
                                        classes={{ tooltip: classes.infoTooltip }}
                                    >
                                        <Grid
                                            item
                                            style={{
                                                display: "inline-flex",
                                                borderRadius: "0 3px 0 0",
                                                backgroundColor: "rgba(170, 170, 170, 0.8)",
                                                padding: "4px 5px",
                                                color: "white",
                                                cursor: "pointer",
                                            }}
                                            onClick={handleVariationsOption}
                                        >
                                            <Grid item xs={"auto"}>
                                                {product.productProductVariation?.totalCount}
                                            </Grid>
                                            <Grid item xs={"auto"} style={{ marginLeft: "3px" }}>
                                                <SourceBranchIcon size={"14px"} />
                                            </Grid>
                                        </Grid>
                                    </Tooltip>
                                </Grid>

                                {linkedMarketplacesInfo.length > 0 && (
                                    <>
                                        <Grid item>
                                            <SeparatorIcon />
                                        </Grid>

                                        <Grid
                                            item
                                            style={{
                                                display: "inline-flex",
                                            }}
                                        >
                                            {linkedMarketplacesInfo.map((item) => (
                                                <Grid
                                                    item
                                                    xs={"auto"}
                                                    key={item.region}
                                                    style={{
                                                        marginRight: "15px",
                                                        borderRadius: "0 3px 0 0",
                                                        backgroundColor: "rgba(170, 170, 170, 0.8)",
                                                        padding: "3px",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Tooltip
                                                        title={
                                                            item.isUploaded
                                                                ? `${item.region} marketplace' ad inserted and uploaded.`
                                                                : `${item.region} marketplace' ad inserted. Not uploaded yet.`
                                                        }
                                                        classes={{ tooltip: classes.infoTooltip }}
                                                    >
                                                        <Avatar
                                                            variant={"circular"}
                                                            alt={item.region}
                                                            src={item.image}
                                                            className={classes.insertedMarketplaceAvatar}
                                                            onClick={() => setDisplayMarketplacesActions(true)}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            {displayMarketplacesActions && (
                                <Fade in={displayMarketplacesActions}>
                                    <Grid container item justifyContent={"center"}>
                                        <Card variant={"outlined"} style={{ width: "500px", padding: "10px" }}>
                                            <React.Suspense fallback={<LoadingIcon size={24} />}>
                                                <ProductCardMarketplaces
                                                    product={product}
                                                    marketplacesStatus={marketplacesStatus}
                                                    refetch={refetch}
                                                    setDisplayMarketplacesActions={setDisplayMarketplacesActions}
                                                    handleToggleFacebookNotifications={
                                                        handleToggleFacebookNotifications
                                                    }
                                                />
                                            </React.Suspense>
                                        </Card>
                                    </Grid>
                                </Fade>
                            )}
                        </Grid>

                        <Grid item xs={"auto"}>
                            <Grid container style={{ display: "flex", flexDirection: "column" }}>
                                {primaryActions.map((action) => (
                                    <Grid item key={action.name}>
                                        <Tooltip title={action.name} classes={{ tooltip: classes.infoTooltip }}>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => action.action(product)}
                                                style={{ margin: "4px 0" }}
                                            >
                                                {action.icon}
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Grid item xs={"auto"}>
                            <Grid container style={{ display: "flex", flexDirection: "column" }}>
                                {secondaryActions.map((action) => (
                                    <Grid item key={action.name}>
                                        <Tooltip title={action.name} classes={{ tooltip: classes.infoTooltip }}>
                                            <IconButton
                                                size={"small"}
                                                onClick={() => action.action(product)}
                                                style={{ margin: "4px 0" }}
                                            >
                                                {action.icon}
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </>
    )
}
