import { gql } from "@apollo/client"

//seller details
export const LOAD_SELLER_DETAILS = gql`
    query allSellers($userId: ID!) {
        allSellers(userId: $userId) {
            edges {
                node {
                    id
                    phone
                    sku
                    country {
                        id
                        name
                    }
                }
            }
        }
    }
`

//add new seller
export const CREATE_SELLER = gql`
    mutation createSeller(
        $phone: String!
        $sku: String!
        $user: ID!
        $country: ID!
        $firstName: String
        $lastName: String
    ) {
        createSeller(
            input: {
                phone: $phone
                sku: $sku
                user: $user
                country: $country
                firstName: $firstName
                lastName: $lastName
            }
        ) {
            seller {
                user {
                    id
                    username
                    firstName
                    lastName
                    seller {
                        id
                        country {
                            id
                            name
                        }
                        sku
                        phone
                    }
                    groups {
                        id
                        name
                    }
                }
            }
        }
    }
`

//update seller
export const UPDATE_SELLER = gql`
    mutation updateSeller($id: ID!, $phone: String!, $sku: String!, $country: ID!, $user: ID!) {
        updateSeller(id: $id, input: { phone: $phone, sku: $sku, country: $country, user: $user }) {
            seller {
                id
                phone
                sku
                country {
                    id
                    name
                }
            }
        }
    }
`
