import React from "react";
import {useHistory} from "react-router-dom";

import {CircularProgress, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CheckBoxOutlined} from "@material-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";

//apollo
import {useMutation} from "@apollo/client"
import { VERIFY_ACCOUNT } from "querys/auth/loginQueries"

//notifications
import {ErrorNotification, SuccessNotification} from "components/Notifications/Notifications"

//assets
import styles from "assets/jss/material-dashboard-react/views/verifyAccountStyle";

const useStyles = makeStyles(styles);

export default function AutoVerifyAccount(props) {
    const classes = useStyles();

    const history = useHistory();

    const notifyError = message => ErrorNotification("Account", message)
    const notifySuccess = () => SuccessNotification("Account", "Account successfully verified. Now you can log in")

    const cancelAction = () => {
        notifyError("Verification failed, try again")
        history.push("/login")
    }

    const successForm = () => {
        notifySuccess()
        history.push('/login')
    }

    const [verifyAccount, {loading}] = useMutation(VERIFY_ACCOUNT, {
        variables: {
            token: props.match?.params?.token ?? ""
        },
        onCompleted: response => {
            let dataResponse = response.data.verifyAccount

            if (dataResponse.errors) {
                return cancelAction()
            }

            if (dataResponse.success) {
                return successForm()
            }
        },
        onError: () => {
            return cancelAction()
        }
    })

    const executeVerifyAccount = React.useCallback(() => {
        verifyAccount()
    }, [verifyAccount])

    React.useEffect(() => {
       executeVerifyAccount()
    }, [props, executeVerifyAccount])

    return (
        <Card className={classes.formContainer}>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="success" className={classes.cardHeaderIcon}>
                    <CheckBoxOutlined/>
                </CardIcon>
                <h4 className={classes.cardTitle}>Verify account</h4>
            </CardHeader>
            <CardBody>
                <Grid container justifyContent={"center"}>
                    {
                        loading && <CircularProgress size={34} />
                    }
                </Grid>
            </CardBody>
        </Card>
    )
};
