import React from "react"
import {
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Tooltip,
    CircularProgress,
    TextField,
    InputAdornment,
    Fade,
    useTheme,
    useMediaQuery,
    Checkbox,
} from "@material-ui/core"

//icons
import { InfoOutlined } from "@material-ui/icons"
//import SearchAddIcon from "mdi-react/SearchAddIcon"
import SearchIcon from "mdi-react/SearchIcon"
import CancelIcon from "mdi-react/CancelIcon"
import DoneIcon from "mdi-react/DoneIcon"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/tableStyle"

//apollo
import { Query } from "@apollo/client/react/components"
import { useMutation } from "@apollo/client"
import {
    LOAD_SELLER_NOTIFICATIONS,
    READ_SELLER_NOTIFICATION,
} from "../../querys/seller/Notification/notificationQueries"

//notifications
import { ErrorNotification } from "components/Notifications/Notifications"

//table utils
import { getComparator, stableSort, LoadingSkeleton, TableNoData } from "utils/tableUtils"

//page description
import { NotificationCenter } from "utils/viewsDescriptionUtils"

//redux
import { useSelector } from "react-redux"

//history
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(styles)

const notifyError = (message) => ErrorNotification("Material", message)

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

    //not to permit sort by columns of equal values. server sorting does not work properly
    const cells = [
        { id: "value", label: "Notification", align: "left", sort: true },
        { id: "type", label: "Reference", align: "left", sort: true },
        { id: "creation_date", label: "Creation date", align: "left", sort: true },
    ]

    const headCells = () => {
        if (mobileScreen) {
            cells.unshift({ id: "select", label: "", align: "left", sort: false })
        }
        return cells
    }

    return (
        <TableHead>
            <TableRow>
                {headCells().map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.head}
                        align={headCell.align ? headCell.align : "center"}
                    >
                        {headCell.sort ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                hideSortIcon={!headCell.sort}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <span>{headCell.label}</span>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const EnhancedTableToolbar = (props) => {
    const classes = useStyles()

    const { loading, handleSearchInputChange } = props

    const [showSearchInput, setShowSearchInput] = React.useState(false)

    const toggleSearchInput = () => {
        setShowSearchInput(!showSearchInput)
    }

    const [searchValue, setSearchValue] = React.useState("")

    const handleSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const handlePressEnter = (e) => {
        if (e.keyCode === 13) {
            applySearch()
        }
    }

    const applySearch = () => {
        handleSearchInputChange(searchValue)
        toggleSearchInput()
    }

    const cancelFilters = () => {
        setSearchValue("")
        handleSearchInputChange("")
        toggleSearchInput()
    }

    return (
        <Toolbar className={classes.header}>
            {!showSearchInput ? (
                <>
                    <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                        Notification center
                        <Tooltip
                            title={<NotificationCenter darkStyle />}
                            arrow
                            interactive
                            classes={{ tooltip: classes.descriptionTooltip }}
                        >
                            <InfoOutlined style={{ fontSize: 16 }} />
                        </Tooltip>
                    </Typography>

                    <div>
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <>
                                {/*<Tooltip title="Filter data">*/}
                                {/*    <IconButton*/}
                                {/*        aria-label="filter data"*/}
                                {/*        color={searchValue ? "secondary" : "inherit"}*/}
                                {/*        onClick={toggleSearchInput}*/}
                                {/*    >*/}
                                {/*        {searchValue ? <SearchAddIcon /> : <SearchIcon />}*/}
                                {/*    </IconButton>*/}
                                {/*</Tooltip>*/}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <Fade in={showSearchInput}>
                    <TextField
                        placeholder="Search..."
                        autoFocus
                        size={"small"}
                        className={classes.searchInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    onClick={cancelFilters}
                                    className={classes.adornmentSearch}
                                >
                                    <CancelIcon />
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    onClick={applySearch}
                                    className={classes.adornmentSearch}
                                >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        onKeyDown={handlePressEnter}
                    />
                </Fade>
            )}
        </Toolbar>
    )
}

export default function EnhancedTable() {
    const classes = useStyles()

    const history = useHistory()

    const CURRENT_USER = useSelector((state) => state.loginReducer)

    //table variables
    const [order, setOrder] = React.useState("asc")
    const [orderBy, setOrderBy] = React.useState("creationDate")
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    const handleRequestSort = (event, property) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const handleChangeRowsPerPage = (event) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    //search input field
    const [searchInput, setSearchInput] = React.useState("")

    const handleSearchInputChange = (value) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        setSearchInput(value)
    }

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState("")
    const [endCursorPagination, setEndCursorPagination] = React.useState("")

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allSellerNotification.totalCount),
        setStartCursorPagination(data.allSellerNotification.pageInfo.startCursor),
        setEndCursorPagination(data.allSellerNotification.pageInfo.endCursor),
    ]

    //bind query variables to this variables
    const [beforeCursorVariable, setBeforeCursorVariable] = React.useState("")
    const [afterCursorVariable, setAfterCursorVariable] = React.useState("")
    const [itemsVariable, setItemsVariable] = React.useState("firstPageItems")

    let queryVariables = {
        sellerId: CURRENT_USER?.seller?.id,
        orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
        nameFilter: searchInput,
        [itemsVariable]: rowsPerPage,
        beforeCursor: beforeCursorVariable,
        afterCursor: afterCursorVariable,
    }

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setAfterCursorVariable(endCursorPagination)
            setBeforeCursorVariable("")

            setItemsVariable("firstPageItems")
        } else {
            setBeforeCursorVariable(startCursorPagination)
            setAfterCursorVariable("")

            setItemsVariable("lastPageItems")
        }
        setPage(newPage)
    }

    const [readNotification, { loading: loadingReadNotification }] = useMutation(READ_SELLER_NOTIFICATION, {
        refetchQueries: [{ query: LOAD_SELLER_NOTIFICATIONS, variables: { sellerId: CURRENT_USER?.seller?.id } }],
    })

    const handleReadNotification = async (item) => {
        await readNotification({
            variables: {
                id: item.id,
                seller: item.seller.id,
                type: item.type,
                value: item.value,
                reference: item.reference,
                creationDate: item.creationDate,
            },
        })
    }

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

    //table selected item (for small screens)
    const [selected, setSelected] = React.useState(null)
    const handleSelectItem = (item) => {
        if (selected && selected.node.id === item.node.id) {
            setSelected(null)
        } else {
            setSelected(item)
        }
    }

    function goToReference(row) {
        const param = row.node.reference
        switch (row.node.type) {
            case 1:
                //product
                return history.push(`/seller/product?productId=${param}`)

            case 2:
                //variation
                return history.push(`/seller/product?productId=${param}&variations=true`)

            case 3:
                //amazon order
                return history.push(`/seller/orders?amazonOrderId=${param}`)

            case 4:
                //etsy order
                return history.push(`/seller/orders?etsyOrderId=${param}`)

            default:
                break
        }
    }

    function getReferenceData(type) {
        switch (type) {
            case 1:
                return "System Product"

            case 2:
                return "System Variation"

            case 3:
                return "Amazon Order"

            case 4:
                return "Etsy Order"

            default:
                return "Unknown"
        }
    }

    const TableRows = (props) => {
        const { row, index } = props

        return (
            <TableRow hover tabIndex={-1} key={`row-${index}`} className={classes.row}>
                {mobileScreen && (
                    <TableCell align="left">
                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            checked={selected && row.node.id === selected.node.id}
                            onClick={() => handleSelectItem(row)}
                        />
                    </TableCell>
                )}
                <TableCell align="left">{row.node.wasSeen ? row.node.value : <b>{row.node.value}</b>}</TableCell>
                <TableCell align="left">
                    <span onClick={() => goToReference(row)} style={{ color: "blue", cursor: "pointer" }}>
                        {getReferenceData(row.node.type)}
                    </span>
                </TableCell>
                <TableCell align="left">
                    {row.node.creationDate}
                    <div className={classes.actionColumn}>
                        {!row.node.wasSeen && (
                            <Tooltip title={"Mark as viewed"} classes={{ tooltip: classes.infoTooltip }}>
                                <IconButton
                                    edge={"end"}
                                    size={"small"}
                                    onClick={() => handleReadNotification(row.node)}
                                >
                                    <DoneIcon size={18} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    return (
        <Query
            query={LOAD_SELLER_NOTIFICATIONS}
            fetchPolicy={"network-only"}
            variables={queryVariables}
            onCompleted={(data) => {
                setPaginationResponse(data)
            }}
        >
            {({ loading, error, data }) => {
                if (error) {
                    notifyError("Load data failed")
                }

                let rows = data?.allSellerNotification.edges ?? []
                return (
                    <div className={classes.root}>
                        <Paper className={classes.paper}>
                            <EnhancedTableToolbar
                                loading={loading || loadingReadNotification}
                                handleSearchInputChange={handleSearchInputChange}
                            />
                            {loading ? (
                                <LoadingSkeleton />
                            ) : !rows.length ? (
                                <TableNoData />
                            ) : (
                                <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        aria-label="enhanced table"
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                            rowCount={rows.length}
                                        />
                                        <TableBody>
                                            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                                                return <TableRows row={row} index={index} key={index} />
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={totalItemsPagination}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                )
            }}
        </Query>
    )
}
