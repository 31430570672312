import React, {useEffect, useState} from "react";
import {Fab, Zoom} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/backToTopButtonStyle";

const useStyles = makeStyles(styles);

const BackToTop = () => {
    const classes = useStyles()

    const [isVisible, setIsVisible] = useState(false)

    const toggleVisibility = () => {
        if (window.pageYOffset > 250) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(() => {
        document.addEventListener("scroll", toggleVisibility)
        return () => {
            document.removeEventListener("scroll", toggleVisibility)
        }
    }, [])

    if (!isVisible) return null

    return (
        <div className={classes.container} style={{display: isVisible ? "inherit" : "none"}}>
            <Zoom in={isVisible}>
                <Fab
                    color="primary"
                    size={"small"}
                    aria-label="backToTop"
                    className={classes.fabButton}
                    onClick={scrollToTop}
                >
                    &#8593;
                </Fab>
            </Zoom>
        </div>
    )
}

export default BackToTop
