import gql from "graphql-tag"

//all marketplace listing, used for selecting nomenclator
export const LOAD_ALL_UNLINKED_MARKETPLACES = gql`
    query allMarketplacesLoginUrls {
        allMarketplacesLoginUrls {
            marketplace {
                id
                name
                region
                description
                fullName
                image
                marketplaceMs {
                    edges {
                        node {
                            id
                            service {
                                id
                                name
                            }
                        }
                    }
                }
            }
            url
        }
    }
`
//All marketplaces with services
export const LOAD_ALL_MARKETPLACES = gql`
    query allMarketplaces {
        allMarketplaces {
            edges {
                node {
                    id
                    name
                    fullName
                    region
                    description
                    image
                    isEnabled
                    marketplaceMs {
                        edges {
                            node {
                                id
                                service {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

//Marketplace with services
export const LOAD_MARKETPLACE_SERVICES = gql`
    query marketplaceWithServices($id: ID!) {
        marketplace(id: $id) {
            id
            name
            fullName
            region
            description
            image
            isEnabled
            marketplaceMs {
                edges {
                    node {
                        id
                        service {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`
