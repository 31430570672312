import gql from "graphql-tag"

//category listing of current seller, using pagination and sort
export const LOAD_CATEGORIES = gql`
    query allCategoriesPaginated(
        $sellerId: ID!
        $orderBy: [String]!
        $nameFilter: String
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allCategories(
            sellerId: $sellerId
            orderBy: $orderBy
            name_Icontains: $nameFilter
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    name
                    parent {
                        id
                        name
                    }
                    fullPath
                }
            }
        }
    }
`

//all categories listing, used for selecting nomenclator
export const LOAD_ALL_CATEGORIES = gql`
    query allCategories($sellerId: ID!) {
        allCategories(sellerId: $sellerId) {
            edges {
                node {
                    id
                    name
                    parent {
                        id
                    }
                }
            }
        }
    }
`

//all categories available, used for updating selecting nomenclator. not to permit cycles
export const LOAD_ALL_UPDATE_CATEGORIES = gql`
    query allCategoriesForUpdate($sellerId: ID!, $updatedCategoryId: ID) {
        allCategories(sellerId: $sellerId, updatedCategoryId: $updatedCategoryId) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//add new category
export const CREATE_CATEGORY = gql`
    mutation createCategory($name: String!, $parent: ID, $seller: ID!) {
        createCategory(input: { name: $name, parent: $parent, seller: $seller }) {
            category {
                id
                name
                parent {
                    id
                    name
                }
            }
        }
    }
`

//update category
export const UPDATE_CATEGORY = gql`
    mutation updateCategory($id: ID!, $name: String!, $parent: ID, $seller: ID!) {
        updateCategory(id: $id, input: { name: $name, parent: $parent, seller: $seller }) {
            category {
                id
                name
                parent {
                    id
                    name
                }
            }
        }
    }
`

//delete category
export const DELETE_CATEGORY = gql`
    mutation deleteCategory($id: ID!) {
        deleteCategory(id: $id) {
            found
            deletedId
        }
    }
`
