import {
    primaryColor,
    whiteColor,
    dangerColor,
    successColor,
    grayColor,
    warningColor
} from "assets/jss/material-dashboard-react.js";
import tooltipStyle from "../tooltipStyle";

const configurationStyle = theme => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: primaryColor[1],
        color: whiteColor,
        fontSize: "16px",
        position: "relative"
    },
    headerTitle: {
        fontSize: "16px"
    },
    headerActions: {
        float: "right",
        color: whiteColor,
        margin: "-20px 15px 0"
    },
    root: {
        width: '100%',
    },
    head: {
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.7)"
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    avatar: {
        width: 20,
        height: 20,
        "& .MuiAvatar-img" : {
            width: "auto",
            height: "90%",
        }
    },

    //info tooltip
    infoTooltip: {
        ...tooltipStyle.tooltip
    },

    //description tooltip
    descriptionTooltip: {
        ...tooltipStyle.descriptionTooltip
    },

    //schedule tasks
    stoppedTask: {
        margin: "0 5px",
        padding: "3px",
        backgroundColor: dangerColor[1],
        fontSize: "14px"
    },
    runningTask: {
        margin: "0 5px",
        padding: "3px",
        backgroundColor: successColor[1],
        fontSize: "14px"
    },
    timeInterval: {
        margin: "0 5px",
        padding: "3px",
        backgroundColor: grayColor[0],
        fontSize: "14px"
    },
    errorOccurred: {
        display: "inline-flex",
        alignItems: "center",
        margin: "0 5px",
        padding: "3px",
        backgroundColor: warningColor[3],
        fontSize: "12px",
        color: whiteColor
    },

    marketplaceAvatar: {
        width: 25,
        height: 25
    },

    sectionLink: {
        fontWeight: 400,
        margin: "0 5px"
    },
});

export default configurationStyle;
