import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { EditOutlined } from "@material-ui/icons"
import { TextField, Button, CircularProgress, MenuItem, Grid } from "@material-ui/core"

import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import GridItem from "components/Grid/GridItem"

import { Formik } from "formik"
import * as Yup from "yup"

import styles from "assets/jss/material-dashboard-react/views/cardFormStyle"

//apollo
import { useQuery } from "@apollo/client"
import { Mutation } from "@apollo/client/react/components"
import {
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    LOAD_ALL_UPDATE_CATEGORIES,
    LOAD_ALL_CATEGORIES,
} from "querys/seller/Category/categoryQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//router
import { useHistory } from "react-router-dom"

//redux
import { useSelector } from "react-redux"

//errors
import { getFormErrors } from "utils/errorsUtils"

const useStyles = makeStyles(styles)

export default function CategoryForm(props) {
    const classes = useStyles()

    const updateItem = props.location.state?.updateItem.node ?? null

    const CURRENT_USER = useSelector((state) => state.loginReducer)

    const history = useHistory()

    const notifySuccess = (message) => SuccessNotification("Category", message)
    const notifyError = (message) => ErrorNotification("Category", message)

    const successForm = () => {
        updateItem ? notifySuccess("Category updated") : notifySuccess("New category added")
        history.push("/seller/category")
    }

    const cancelAction = () => {
        history.push("/seller/category")
    }

    const [category, setCategory] = React.useState("")
    const [categories, setCategories] = React.useState([])

    const { error: errorCategories } = useQuery(LOAD_ALL_UPDATE_CATEGORIES, {
        variables: {
            sellerId: CURRENT_USER.seller.id,
            updatedCategoryId: updateItem?.id ?? null,
        },
        onCompleted: (data) => {
            if (data && data.allCategories) {
                setCategories(data.allCategories.edges)
            }
        },
        fetchPolicy: "network-only",
    })

    if (errorCategories) {
        notifyError("Unable to load existing categories, try again")
    }

    React.useEffect(() => {
        if (updateItem && updateItem.parent && categories.length) {
            setCategory(updateItem.parent.id)
        }
    }, [updateItem, categories])

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <Grid container spacing={1}>
                    <Grid item xs={"auto"} className={classes.cardHeaderIcon}>
                        <EditOutlined />
                    </Grid>
                    <Grid item xs={true}>
                        <h4 className={classes.cardTitle}>{updateItem ? "Update category" : "Create category"}</h4>
                    </Grid>
                </Grid>
            </CardHeader>
            <Mutation
                mutation={updateItem ? UPDATE_CATEGORY : CREATE_CATEGORY}
                refetchQueries={[
                    {
                        query: LOAD_ALL_CATEGORIES,
                        variables: {
                            sellerId: CURRENT_USER.seller.id,
                        },
                    },
                ]}
            >
                {(register, { loading }) => (
                    <Formik
                        initialValues={{
                            name: updateItem?.name ?? "",
                            parent: category,
                        }}
                        enableReinitialize
                        validationSchema={Yup.object({
                            name: Yup.string().required("field required"),
                            parent: Yup.string().nullable(),
                        })}
                        onSubmit={(data, { setErrors }) => {
                            register({
                                variables: {
                                    id: updateItem?.id ?? null,
                                    name: data.name,
                                    parent: data.parent ? data.parent : null,
                                    seller: CURRENT_USER.seller.id,
                                },
                            }).then(
                                (response) => {
                                    let dataResponse = []
                                    if (updateItem) {
                                        dataResponse = response.data.updateCategory
                                    } else {
                                        dataResponse = response.data.createCategory
                                    }

                                    if (dataResponse.category) {
                                        return successForm()
                                    }
                                },
                                (response) => {
                                    if (response.graphQLErrors.length) {
                                        getFormErrors(response.graphQLErrors, notifyError, setErrors)
                                    } else {
                                        notifyError("Create category failed, try again")
                                    }
                                }
                            )
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="name"
                                                label="Name*"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                className={classes.formFields}
                                                {...formik.getFieldProps("name")}
                                                error={formik.errors.name && formik.touched.name}
                                                helperText={
                                                    formik.errors.name && formik.touched.name && formik.errors.name
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="parent"
                                                label="Parent category"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                select
                                                className={classes.formFields}
                                                {...formik.getFieldProps("parent")}
                                                error={formik.errors.parent && formik.touched.parent}
                                                helperText={
                                                    formik.errors.parent &&
                                                    formik.touched.parent &&
                                                    formik.errors.parent
                                                }
                                            >
                                                <MenuItem value={""}>
                                                    <b>select none</b>
                                                </MenuItem>
                                                {categories.map((option) => (
                                                    <MenuItem key={option.node.id} value={option.node.id}>
                                                        {option.node.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter chart>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <small>(*) means that fields are required</small>
                                        </Grid>

                                        <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"secondary"}
                                                onClick={cancelAction}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"primary"}
                                                type="submit"
                                                disabled={!formik.isValid || loading}
                                            >
                                                {loading ? (
                                                    <CircularProgress size={24} />
                                                ) : updateItem ? (
                                                    "Update"
                                                ) : (
                                                    "Create"
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardFooter>
                            </form>
                        )}
                    </Formik>
                )}
            </Mutation>
        </Card>
    )
}
