import { whiteColor, grayColor } from "assets/jss/material-dashboard-react.js"
import cardInfoStyle from "assets/jss/material-dashboard-react/views/cardInfoStyle"

const infoPageStyle = (theme) => ({
    cardContainer: {
        backgroundColor: whiteColor,
        height: "auto",
        [theme.breakpoints.up("md")]: {
            width: "75%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "95%",
        },
    },

    //marketplace avatar
    avatar: {
        width: 60,
        height: 60,
        "& .MuiAvatar-img": {
            width: "auto",
            height: "90%",
        },
    },
    ...cardInfoStyle,

    //how ir works page
    colContainerSlide: {
        minHeight: "calc(100vh - 70px)",
        margin: "0 0 10px 0",
    },
    leftSide: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        height: "100%",
        backgroundColor: grayColor[11],
        padding: "10px",
        [theme.breakpoints.down("sm")]: {
            height: "120px",
        },
    },
    imgContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            order: 1,
            height: "calc(100vh / 2)",
        },
    },
    img: {
        width: "90%",
        maxWidth: "700px",
        height: "auto",
    },

    //marketplace link
    linkContainer: {
        "&:hover >svg": {
            display: "inline",
        },
    },
    linkIcon: {
        display: "none",
        margin: "0 3px",
    },
})

export default infoPageStyle
