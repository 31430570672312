import React from "react"
import { Avatar, Card, Chip, Grid, IconButton, TableCell, TableRow, Tooltip } from "@material-ui/core"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/tableStyle"

//redux
import { useSelector } from "react-redux"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import AmazonImg from "../../assets/img/marketplaces/Amazon-icon.svg"
import EtsyImg from "../../assets/img/marketplaces/Etsy_icon.svg"
import EbayImg from "../../assets/img/marketplaces/Ebay_icon.svg"
import FacebookImg from "../../assets/img/marketplaces/Facebook_icon.svg"
import { dangerColor, grayColor, successColor } from "../../assets/jss/material-dashboard-react"
import LoginIcon from "mdi-react/LoginIcon"
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(styles)

export default function MarketplacesConnectionStatus(props) {
    const classes = useStyles()

    const history = useHistory()

    const marketplaceConnectionStatus = useSelector((state) => state.marketplaceConnectionReducer)

    function getMarketplaceDefaultImage(marketplace) {
        switch (marketplace.toUpperCase()) {
            case "AMAZON":
                return AmazonImg
            case "ETSY":
                return EtsyImg
            case "EBAY":
                return EbayImg
            case "FACEBOOK":
                return FacebookImg
            default:
                return ""
        }
    }

    const composeUrl = (row) => {
        return row.sellerMarketplace.marketplace.image
            ? `${process.env.REACT_APP_API_URL}file/${row.sellerMarketplace.marketplace.id}`
            : getMarketplaceDefaultImage(row.sellerMarketplace.marketplace.name)
    }

    function handleMarketplaceClick() {
        history.push("/seller/connection")
    }

    return (
        <Card variant={"outlined"}>
            <Grid container spacing={1}>
                <Table size="small">
                    <TableBody>
                        {Object.keys(marketplaceConnectionStatus.marketplaceStatus).map((marketplaceSellerId) => {
                            const row = marketplaceConnectionStatus.marketplaceStatus[marketplaceSellerId]

                            return (
                                <TableRow key={marketplaceSellerId} hover onClick={handleMarketplaceClick}>
                                    <TableCell>
                                        <Avatar
                                            variant={"rounded"}
                                            alt={row.sellerMarketplace.marketplace.name}
                                            src={composeUrl(row)}
                                            className={classes.smallAvatar}
                                        />
                                    </TableCell>

                                    <TableCell>{row.sellerMarketplace.alias}</TableCell>
                                    <TableCell>{row.sellerMarketplace.marketplace.fullName}</TableCell>
                                    <TableCell>
                                        {row.sellerMarketplace.marketplace.isEnabled ? (
                                            <>
                                                {row.apiWorking.code === "200" ? (
                                                    <Chip
                                                        variant="outlined"
                                                        size={"small"}
                                                        style={{
                                                            color: successColor[0],
                                                            borderColor: successColor[0],
                                                        }}
                                                        label={"Connected"}
                                                    />
                                                ) : (
                                                    <>
                                                        {row.apiWorking.code === "401" ||
                                                        (row.apiWorking.code === "400" &&
                                                            row.sellerMarketplace.marketplace.name.toUpperCase() ===
                                                                "ETSY") ? (
                                                            <Tooltip
                                                                title={`Error returned by platform '${row.apiWorking.message}'`}
                                                                classes={{ tooltip: classes.infoTooltip }}
                                                            >
                                                                <Chip
                                                                    variant="outlined"
                                                                    size={"small"}
                                                                    style={{
                                                                        color: dangerColor[0],
                                                                        borderColor: dangerColor[0],
                                                                    }}
                                                                    label={
                                                                        <>
                                                                            Reconnect needed
                                                                            <IconButton
                                                                                color={"primary"}
                                                                                size={"small"}
                                                                                style={{ marginLeft: "5px" }}
                                                                            >
                                                                                <LoginIcon size={18} />
                                                                            </IconButton>
                                                                        </>
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip
                                                                title={`Connection error '${row.apiWorking.message}'`}
                                                                classes={{ tooltip: classes.infoTooltip }}
                                                            >
                                                                <Chip
                                                                    variant="outlined"
                                                                    size={"small"}
                                                                    style={{
                                                                        color: dangerColor[0],
                                                                        borderColor: dangerColor[0],
                                                                    }}
                                                                    label={"Connection error"}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <Chip
                                                variant="outlined"
                                                size={"small"}
                                                style={{ color: grayColor[0], borderColor: grayColor[0] }}
                                                label={"Disabled temporally"}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Grid>
        </Card>
    )
}
