import gql from "graphql-tag";

//all permissions listing, used for selecting nomenclator
export const LOAD_ALL_PERMISSIONS = gql`
query allPermissions {  
  allPermissions {
    edges {
      node {
        id
        name
        codename
      }
    }
  }
}`
