import { primaryColor, whiteColor, grayColor } from "assets/jss/material-dashboard-react"
import tooltipStyle from "../tooltipStyle"

const productsStyle = (theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: primaryColor[1],
        color: whiteColor,
        fontSize: "16px",
        position: "relative",
        width: "100%",
        marginBottom: "10px",
    },
    headerTitle: {
        fontSize: "16px",
    },
    searchInput: {
        position: "absolute",
        left: "2%",
        bottom: "10px",
        width: "96%",
        "& .MuiInputBase-root": {
            color: whiteColor,
        },
    },
    footerDivider: {
        height: "1px",
        backgroundColor: grayColor[4],
        width: "100%",
        margin: "15px 0 10px 0",
    },
    //info tooltip
    infoTooltip: {
        ...tooltipStyle.tooltip,
    },
})

export default productsStyle
