import { whiteColor, primaryColor, grayColor } from "assets/jss/material-dashboard-react.js"

const productDetailsStyle = (theme) => ({
    //modal
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: "100vw",
        minHeight: "95vh",
        maxHeight: "95vh",
        overflowY: "auto",
        padding: 25,
    },

    //details component
    cardHeader: {
        backgroundColor: primaryColor[1],
        margin: "-20px 15px 0",
    },
    cardHeaderIcon: {
        color: whiteColor,
    },
    cardTitle: {
        color: whiteColor,
        marginTop: "3px",
        minHeight: "auto",
        fontWeight: "100",
        fontSize: "14px",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        "& small": {
            color: grayColor[1],
            fontWeight: "100",
            lineHeight: "1",
        },
    },
    cardHeaderActions: {
        float: "right",
        color: whiteColor,
        margin: "-20px 15px 0",
    },
    cardAvatarContainer: {
        width: "100%",
        maxHeight: "185px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    cardAvatarIcon: {
        width: "230px",
        maxWidth: "230px",
        height: "180px",
        maxHeight: "187px",
        border: "1px solid " + primaryColor[2],
    },
    imageFooter: {
        height: "30px",
        color: whiteColor,
    },
    noImage: {
        width: "auto",
        maxWidth: "230px",
        height: "180px",
        maxHeight: "180px",
        opacity: 0.6,
    },
    marketplaceAvatar: {
        width: 35,
        height: 35,
        "& .MuiAvatar-img": {
            width: "auto",
            height: "90%",
        },
    },
    sectionsDividerVertical: {
        width: "1px",
        backgroundColor: grayColor[4],
        height: "100%",
        margin: "0 10px",
    },
    sectionsDividerHorizontal: {
        height: "1px",
        backgroundColor: grayColor[4],
        width: "100%",
        margin: "10px 0",
    },
    leftFlex: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 5px 0 0",
    },
    rightFlex: {
        padding: "0 0 0 5px",
    },
})

export default productDetailsStyle
