import React from 'react';
import {
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Tooltip,
    CircularProgress,
    Chip,
    Badge,
    Dialog,
    Button,
    Fade,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import {
    EditOutlined,
    DeleteOutlined,
    PersonOutlined,
    CheckBoxOutlined,
    PauseOutlined,
    PlayCircleOutline, InfoOutlined
} from '@material-ui/icons';
import SearchIcon from "mdi-react/SearchIcon";
import SearchAddIcon from "mdi-react/SearchAddIcon";

//styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/tableStyle";

//router
import {useHistory} from "react-router-dom";

//apollo
import {useMutation} from "@apollo/client"
import {Query, Mutation} from "@apollo/client/react/components"
import {LOAD_USERS, DELETE_USER, CHANGE_STATUS_USER, VERIFY_ACCOUNT} from "querys/admin/userQueries"

//notifications
import {ErrorNotification, SuccessNotification} from "components/Notifications/Notifications";

//table utils
import {getComparator, stableSort, LoadingSkeleton, TableNoData, ConfirmTransition} from "utils/tableUtils";

//permissions dialog
import PermissionsDialog from "components/Admin/PermissionsDialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import GridItem from "components/Grid/GridItem";

//redux
import {useDispatch, useSelector} from "react-redux";
import {showApiResponse} from "actions/globalComponents/apiResponseActions";

//filters
import UsersFilter from "components/Admin/UsersFilter";

//page description
import {UsersView} from "utils/viewsDescriptionUtils";

const useStyles = makeStyles(styles);

const notifyError = message => ErrorNotification("User", message)
const notifySuccess = message => SuccessNotification("User", message)

//not to permit sort by columns of equal values. server sorting does not work properly
const headCells = [
    { id: 'username', label: 'Username', align: "left", sort: true, search: true },
    { id: 'group', label: 'Group', align: "left", sort: false },
    { id: 'first_name', label: 'FirstName', align: "left", sort: true, search: true },
    { id: 'last_name', label: 'LastName', align: "left", sort: true, search: true },
    { id: 'email', label: 'Email', align: "left", sort: true, search: true },
    { id: 'verified', label: 'Verified', align: "left", sort: false }
];

function EnhancedTableHead(props) {
    const {
        classes,
        order,
        orderBy,
        onRequestSort
    } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    }

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.head}
                        align={headCell.align ? headCell.align : "center"}
                    >
                        {
                            headCell.sort
                            ? <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                hideSortIcon={!headCell.sort}
                            >
                                {headCell.label}
                                {
                                    orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>)
                                        : null
                                }
                            </TableSortLabel>
                                : <span>{headCell.label}</span>
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function EnhancedTable() {
    const classes = useStyles()

    const history = useHistory()
    const dispatch = useDispatch()

    const authUser = useSelector(state => state.loginReducer)

    const EnhancedTableToolbar = (props) => {
        const classes = useStyles();

        const { loading } = props

        function appliedFilters() {
            return (usernameSearch || firstNameSearch || lastNameSearch || emailSearch)
        }

        const toggleSearchInput = () => {
            toggleShowFilters()
        }

        return (
            <Toolbar className={classes.header}>
                <>
                    <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                        List of users
                        <Tooltip
                            title={<UsersView darkStyle/>}
                            arrow
                            interactive
                            classes={{tooltip: classes.descriptionTooltip}}
                        >
                            <InfoOutlined style={{fontSize: 16}}/>
                        </Tooltip>
                    </Typography>

                    <div>
                        {
                            loading
                                ? <CircularProgress size={24}/>
                                : <>
                                    <Tooltip title="Filter data">
                                        <IconButton
                                            aria-label="filter data"
                                            color={appliedFilters() ? "secondary" : "inherit"}
                                            onClick={toggleSearchInput}
                                        >
                                            {
                                                appliedFilters() ? <SearchAddIcon /> : <SearchIcon />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                </>
                        }
                    </div>
                </>
            </Toolbar>
        );
    };

    //table variables
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('username')
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    const handleRequestSort = (event, property) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeRowsPerPage = (event) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //show filters card
    const [showFilters, setShowFilters] = React.useState(false)
    const toggleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    //search input values
    const [usernameSearch, setUsernameSearch] = React.useState("")
    const [firstNameSearch, setFirstNameSearch] = React.useState("")
    const [lastNameSearch, setLastNameSearch] = React.useState("")
    const [emailSearch, setEmailSearch] = React.useState("")

    const resetPagination = () => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)
    }

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState("")
    const [endCursorPagination, setEndCursorPagination] = React.useState("")

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allUsers.totalCount),
        setStartCursorPagination(data.allUsers.pageInfo.startCursor),
        setEndCursorPagination(data.allUsers.pageInfo.endCursor)
    ]

    //bind query variables to this variables
    const [beforeCursorVariable, setBeforeCursorVariable] = React.useState("")
    const [afterCursorVariable, setAfterCursorVariable] = React.useState("")
    const [itemsVariable, setItemsVariable] = React.useState("firstPageItems")

    let queryVariables = {
        orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
        usernameFilter: usernameSearch,
        firsNameFilter: firstNameSearch,
        lastNameFilter: lastNameSearch,
        emailFilter: emailSearch,
        [itemsVariable]: rowsPerPage,
        beforeCursor: beforeCursorVariable,
        afterCursor: afterCursorVariable
    }

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setAfterCursorVariable(endCursorPagination)
            setBeforeCursorVariable("")

            setItemsVariable("firstPageItems")
        } else {
            setBeforeCursorVariable(startCursorPagination)
            setAfterCursorVariable("")

            setItemsVariable("lastPageItems")
        }
        setPage(newPage);
    };

    const TableRows = (props) => {
        const {row, index, verify, changeStatus, refetch} = props

        const verifyAccount = (item) => {
            verify({
                variables: {
                    userId: item.node.id
                }
            }).then(
                (response) => {
                    if (response.data.verifyAccountByAdmin?.user) {
                        notifySuccess("User verified")
                        refetch()
                    }
                    if (!response.data.verifyAccountByAdmin) {
                        notifyError("Verify user failed, try again")
                    }
                },
                () => {
                    notifyError("Verify user failed, try again")
                }
            )
        }

        const changeAccountStatus = (item) => {
            changeStatus({
                variables: {
                    userId: item.node.id
                }
            }).then(
                (response) => {
                    if (response.data.inactiveOrActiveAccountByAdmin?.user) {
                        notifySuccess("User status changed")
                        refetch()
                    }
                    if (!response.data.inactiveOrActiveAccountByAdmin) {
                        notifyError("Change user status failed, try again")
                    }
                },
                () => {
                    notifyError("Change user status failed, try again")
                }
            )
        }

        const editUserRoles = (row) => {
            history.push("/seller/admin-user/form", {updateItem: row})
        }

        const deleteItem = (item) => {
            setDeleteItemIndex(item.node.id)
            setOpenDialog(true)
        }

        const ActionColumn = (props) => {
            const { user } = props

            return (
                <div className={classes.actionColumn}>
                    {
                        !user.isVerified
                        && <Tooltip title="Verify user account">
                            <IconButton
                                aria-label="verify user"
                                className={classes.checkBtn}
                                onClick={() => verifyAccount(row)}
                            >
                                <CheckBoxOutlined fontSize={"small"}/>
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title="Edit user groups">
                        <IconButton
                            aria-label="edit user"
                            className={classes.editBtn}
                            onClick={() => editUserRoles(row)}
                        >
                            <EditOutlined fontSize={"small"}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={user.isActive === true ? "Deactivate user" : "Activate user"}>
                        <IconButton
                            aria-label="activate user"
                            className={user.isActive === true ? classes.deleteBtn : classes.checkBtn}
                            onClick={() => changeAccountStatus(row)}
                        >
                            {
                                user.isActive === true
                                    ? <PauseOutlined fontSize={"small"}/>
                                    : <PlayCircleOutline fontSize={"small"}/>
                            }
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete user">
                        <IconButton
                            aria-label="delete user"
                            className={classes.deleteBtn}
                            onClick={() => deleteItem(row)}
                        >
                            <DeleteOutlined fontSize={"small"}/>
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }

        const viewPermissions = (item) => {
            setPermissionsItem(item.node.permissions.edges)
            setOpenPermissionsDialog(true)
        }

        function getGroups(groups) {
            return groups.map(group =>
                <li key={group.id}>
                    <span
                        onClick={() => viewPermissions(group)}
                        style={{color: "blue", cursor: "pointer"}}
                    >
                        {group.name}
                    </span>
                </li>
            )
        }

        const iconGroupColor = {
            color: row.node.isStaff ? "#43a047" : row.node.isSuperuser ? "#00acc1" : "#000000"
        }

        const activeLabel = row.node.isActive ? "active" : "inactive"
        const tooltipLabel = row.node.isStaff
            ? `staff (${activeLabel})`
            : row.node.isSuperuser
                ? `admin (${activeLabel})`
                : `${activeLabel}`

        return (
            <TableRow
                hover
                tabIndex={-1}
                key={`row-${index}`}
                className={classes.row}
            >
                <TableCell align="left">
                    <Tooltip title={tooltipLabel}>
                        <Badge
                            color={row.node.isActive === true ? "primary" : "secondary"}
                            variant={"dot"}
                            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                        >
                            <PersonOutlined fontSize={"small"} style={iconGroupColor}/>
                        </Badge>
                    </Tooltip>
                    { " " + row.node.username }
                </TableCell>
                <TableCell align="left">{ getGroups(row.node.groups) }</TableCell>
                <TableCell align="left">{ row.node.firstName }</TableCell>
                <TableCell align="left">{ row.node.lastName }</TableCell>
                <TableCell align="left">{ row.node.email }</TableCell>
                <TableCell align="left">
                    <Chip
                        variant="outlined"
                        size="small"
                        color={row.node.isVerified === true ? "primary" : "secondary"}
                        label={row.node.isVerified === true ? "true" : "false"}
                    />
                    {
                        authUser.username !== row.node.username && <ActionColumn user={row.node}/>
                    }
                </TableCell>
            </TableRow>
        )
    }

    //permissions dialog
    const [openPermissionsDialog, setOpenPermissionsDialog] = React.useState(false)
    const [permissionsItem, setPermissionsItem] = React.useState(null)

    //delete confirm dialog
    const [openDialog, setOpenDialog] = React.useState(false)
    const [deleteItemIndex, setDeleteItemIndex] = React.useState(null)

    function ConfirmDialog(props) {
        const {deleteAccount, refetch, loading} = props

        //true for option = 2
        const [optionValue, setOptionValue] = React.useState(false)
        const handleSelectCheck = (e) => {
            setOptionValue(e.target.checked)
        }

        const handleClose = () => {
            setOpenDialog(false)
        }

        const deleteItem = () => {
            deleteAccount({
                variables: {
                    userId: deleteItemIndex,
                    option: optionValue ? 2 : 1
                }
            }).then(
                (response) => {
                    if (response.data.deleteAccountByAdmin.found) {
                        notifySuccess("User deleted")
                        let apiResponse = response.data.deleteAccountByAdmin.apisResponse
                        if (apiResponse.length) {
                            dispatch(showApiResponse({open: true, message: apiResponse, forceOptionsBtn: false}))
                        }
                        refetch()
                    }
                    handleClose()
                },
                () => {
                    notifyError("Delete user failed, try again")
                    handleClose()
                }
            )
        }

        return (
            <div>
                <Dialog
                    open={openDialog}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle disableTypography>
                        <Typography variant="h6">Delete user</Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        <Typography gutterBottom>
                            Do you confirm to delete the user?
                        </Typography>
                        <GridItem xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={"optionValue"}
                                        color="primary"
                                        checked={optionValue}
                                        onChange={handleSelectCheck}
                                    />
                                }
                                label="Also, delete related product's listings in available marketplaces"
                            />
                        </GridItem>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        {
                            loading
                                ? <CircularProgress size={24}/>
                                : <Button onClick={deleteItem} color="primary">
                                    Confirm
                                </Button>
                        }
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }

    //verify account by admin
    const [verify, {loading: loadingVerify}] = useMutation(VERIFY_ACCOUNT)

    //toggle active status
    const [changeStatus, {loading: loadingChangeStatus}] = useMutation(CHANGE_STATUS_USER)

    return (
        <Query
            query={LOAD_USERS}
            fetchPolicy={"network-only"}
            variables={queryVariables}
            onCompleted={data => {
                setPaginationResponse(data)
            }}
        >
            {({ loading, error, data, refetch }) => {
                if (error) {
                    notifyError("Load data failed")
                }

                let rows = data?.allUsers.edges ?? []

                return (
                    <Mutation mutation={DELETE_USER}>
                        {
                            (deleteAccount, {loading: loadingDelete}) => {
                                return (
                                    <div className={classes.root}>
                                        <PermissionsDialog
                                            open={openPermissionsDialog}
                                            setOpenDialog={setOpenPermissionsDialog}
                                            permissionsItem={permissionsItem}
                                        />
                                        <ConfirmDialog
                                            deleteAccount={deleteAccount}
                                            refetch={refetch}
                                            loading={loadingDelete}
                                        />
                                        <Paper className={classes.paper}>
                                            <EnhancedTableToolbar
                                                loading={loading || loadingDelete || loadingVerify || loadingChangeStatus}
                                            />
                                            {
                                                showFilters
                                                && <Fade in={showFilters}>
                                                    <UsersFilter
                                                        setUsernameFilter={setUsernameSearch}
                                                        setFirstNameFilter={setFirstNameSearch}
                                                        setLastNameFilter={setLastNameSearch}
                                                        setEmailFilter={setEmailSearch}
                                                        usernameFilter={usernameSearch}
                                                        firstNameFilter={firstNameSearch}
                                                        lastNameFilter={lastNameSearch}
                                                        emailFilter={emailSearch}
                                                        resetPagination={resetPagination}
                                                        handleRequestSort={handleRequestSort}
                                                        toggleShowFilters={toggleShowFilters}
                                                    />
                                                </Fade>
                                            }
                                            {
                                                loading
                                                    ? <LoadingSkeleton/>
                                                    : !rows.length ? <TableNoData/>
                                                    : <TableContainer>
                                                        <Table
                                                            className={classes.table}
                                                            aria-labelledby="tableTitle"
                                                            aria-label="enhanced table"
                                                        >
                                                            <EnhancedTableHead
                                                                classes={classes}
                                                                order={order}
                                                                orderBy={orderBy}
                                                                onRequestSort={handleRequestSort}
                                                                rowCount={rows.length}
                                                            />
                                                            {
                                                                !rows.length
                                                                    ? <TableNoData/>
                                                                    : <TableBody>
                                                                        {
                                                                            stableSort(rows, getComparator(order, orderBy))
                                                                                .map((row, index) => {
                                                                                    return <TableRows
                                                                                        row={row}
                                                                                        index={index}
                                                                                        key={index}
                                                                                        verify={verify}
                                                                                        changeStatus={changeStatus}
                                                                                        refetch={refetch}
                                                                                    />
                                                                                })
                                                                        }
                                                                    </TableBody>
                                                            }
                                                        </Table>
                                                    </TableContainer>
                                            }
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={totalItemsPagination}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </Paper>
                                    </div>
                                )
                            }
                        }
                    </Mutation>
                )
            }}
        </Query>
    );
}
