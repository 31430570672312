import React from "react"

//icons
import ChevronDownIcon from "mdi-react/ChevronDownIcon"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"
import MessageOutlineIcon from "mdi-react/MessageOutlineIcon"

//styles
import styles from "assets/jss/material-dashboard-react/components/dashboardStatsStyle"
import { makeStyles } from "@material-ui/core/styles"

//link
import { NavLink } from "react-router-dom"

//material
import { Avatar, Card, CardContent, Fade, Grid } from "@material-ui/core"
import { DragHandle } from "@material-ui/icons"

const useStyles = makeStyles(styles)

export default function MessageCenterStats(props) {
    const classes = useStyles()

    const [displayDetails, setDisplayDetails] = React.useState(false)

    const EbayMessagesBlock = () => (
        <Grid container item xs={12}>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={11} justifyContent={"center"}>
                    <h3 className={classes.cardTitle}>
                        <NavLink to={"/seller/message-center"} style={{ textDecoration: "solid underline black 1px" }}>
                            - <small>Ebay messages</small>
                        </NavLink>
                    </h3>
                </Grid>
                <Grid container item xs={1} alignItems={"center"}>
                    {displayDetails ? (
                        <ChevronDownIcon onClick={() => setDisplayDetails(!displayDetails)} />
                    ) : (
                        <ChevronRightIcon onClick={() => setDisplayDetails(!displayDetails)} />
                    )}
                </Grid>
            </Grid>

            {displayDetails && (
                <Fade in={displayDetails}>
                    <Grid container item xs={12} justifyContent={"flex-end"}>
                        <h4 className={classes.cardSubTitle}>
                            - <small>response pending</small>
                        </h4>
                    </Grid>
                </Fade>
            )}
        </Grid>
    )

    return (
        <Card variant={"outlined"}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid container item>
                        <Grid item xs={6}>
                            <DragHandle fontSize={"small"} />
                        </Grid>
                        <Grid container item xs={6} justifyContent={"flex-end"} className={classes.cardCategory}>
                            Messages center
                        </Grid>
                    </Grid>
                    <Grid container item xs={2} alignItems={"center"}>
                        <Avatar variant={"rounded"} className={classes.cardAvatar}>
                            <MessageOutlineIcon size={"55"} />
                        </Avatar>
                    </Grid>
                    <Grid container item xs={10} alignItems={"center"} spacing={1}>
                        <EbayMessagesBlock />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
