import {useEffect} from 'react';
import {useLocation, withRouter} from 'react-router-dom';

const AutoScrollToTop = (props) => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "auto"
        })
    }, [pathname])

    return props.children
}

const ScrollToTop = withRouter(AutoScrollToTop)

export default ScrollToTop;