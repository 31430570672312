export const showApiResponse = (payload) => {
    return {
        type: "SHOW_API_RESPONSE",
        payload
    }
}

export const closeApiResponse = (payload) => {
    return {
        type: "CLOSE_API_RESPONSE",
        payload
    }
}
