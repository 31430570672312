//redux
import { createStore, applyMiddleware, compose } from "redux";
import allReducers from "reducers";
import thunk from "redux-thunk";

//redux persist
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['loginReducer'],
    stateReconciler: autoMergeLevel2
};

const pReducer = persistReducer(persistConfig, allReducers);

let enhancers = [ applyMiddleware(thunk) ]

//to run this project en development mode you must install REDUX_DEVTOOLS_EXTENSION in your browser
if ( process.env.NODE_ENV === 'development') {
    //enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
}

export const store = createStore(
    pReducer,
    compose(...enhancers)
)
export const persistor = persistStore(store)

