import React from "react";
import {useHistory} from "react-router-dom";

import {TextField, Button, InputAdornment, Divider, Hidden, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {
    PersonAddOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined
} from "@material-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";

import { Formik } from "formik";
import * as Yup from "yup";

//apollo
import { Mutation } from "@apollo/client/react/components"
import { USER_REGISTER } from "querys/auth/loginQueries"

//notifications
import {ErrorNotification, SuccessNotification} from "components/Notifications/Notifications"

//assets
import styles from "assets/jss/material-dashboard-react/views/newAccountStyle";


const useStyles = makeStyles(styles);

export default function NewAccount(props) {
    const classes = useStyles();

    const history = useHistory();

    const [showPassword1, setShowPassword1] = React.useState(false);
    const [showPassword2, setShowPassword2] = React.useState(false);
    const toggleVisibilityPassword1 = () => {
        setShowPassword1(!showPassword1)
    }
    const toggleVisibilityPassword2 = () => {
        setShowPassword2(!showPassword2)
    }

    const notifyError = message => ErrorNotification("Register", message)
    const notifySuccess = () => SuccessNotification("Register", "Registration succeeded. Check your mail to verify your account")

    const cancelAction = () => {
        history.push("/login")
    }

    const successForm = () => {
        notifySuccess()
        history.push('/account/verify')
    }

    const validationSchema = Yup.object({
        username: Yup.string()
            .required('field required')
            .min(6, 'username most contain 6 characters at least'),
        email: Yup.string()
            .required('field required')
            .email('email address is not valid'),
        password1: Yup.string()
            .required('field required')
            .min(8, 'most contain 8 characters at least'),
        password2: Yup.string()
            .required('field required')
            .when("password1", {
                is: val => (!!(val && val.length > 0)),
                then: Yup.string().oneOf(
                    [Yup.ref("password1")],
                    "passwords does not match"
                )
            })
    })

    function getErrors(errors) {
        let errorsObject = {}
        Object.entries(errors).forEach(([field, fieldErrors]) => {
            if (field === "nonFieldErrors") {
                fieldErrors.forEach(error => notifyError(error.message))
            } else {
                let errorMessages = []
                fieldErrors.forEach(error => errorMessages.push(error.message))
                errorsObject[field] = errorMessages
            }
        })
        return errorsObject
    }

    const Form = (props) => {
        const formik = props.formik

        return (
            <>
                <GridItem xs={12}>
                    <TextField
                        name="username"
                        label="Username*"
                        size={"small"}
                        fullWidth
                        variant={"filled"}
                        className={classes.inputFields}
                        {...formik.getFieldProps("username")}
                        error={formik.errors.username && formik.touched.username}
                        helperText={(formik.errors.username && formik.touched.username) && formik.errors.username}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <TextField
                        name="email"
                        label="Email*"
                        size={"small"}
                        fullWidth
                        variant={"filled"}
                        className={classes.inputFields}
                        {...formik.getFieldProps("email")}
                        error={formik.errors.email && formik.touched.email}
                        helperText={(formik.errors.email && formik.touched.email) && formik.errors.email}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <TextField
                        name="password1"
                        label="Password*"
                        size={"small"}
                        type={showPassword1 ? "text" : "password"}
                        fullWidth
                        variant={"filled"}
                        className={classes.inputFields}
                        {...formik.getFieldProps("password1")}
                        error={formik.errors.password1 && formik.touched.password1}
                        helperText={(formik.errors.password1 && formik.touched.password1) && formik.errors.password1}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position="end"
                                    onClick={toggleVisibilityPassword1}
                                >
                                    {showPassword1 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                </InputAdornment>,
                        }}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <TextField
                        name="password2"
                        label="Confirm Password*"
                        size={"small"}
                        type={showPassword2 ? "text" : "password"}
                        fullWidth
                        variant={"filled"}
                        className={classes.inputFields}
                        {...formik.getFieldProps("password2")}
                        error={formik.errors.password2 && formik.touched.password2}
                        helperText={(formik.errors.password2 && formik.touched.password2) && formik.errors.password2}
                        InputProps={{
                            endAdornment:
                                <InputAdornment
                                    position="end"
                                    onClick={toggleVisibilityPassword2}
                                >
                                    {showPassword2 ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                                </InputAdornment>,
                        }}
                    />
                </GridItem>
            </>
        )
    }

    const FooterButtons = (props) => {
        const formik = props.formik
        const loading = props.loading

        return (
            <>
                <Button
                    color={"secondary"}
                    size={"small"}
                    variant={"text"}
                    className={classes.actionButton}
                    onClick={cancelAction}
                >
                    Cancel
                </Button>

                <Button
                    color={"primary"}
                    type="submit"
                    size={"small"}
                    variant={"text"}
                    disabled={!formik.isValid || loading}
                    className={classes.actionButton}
                >
                    {loading ? <CircularProgress size={24}/> : "Create"}
                </Button>
            </>
        )
    }

    return (
        <Card className={classes.formContainer}>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="success" className={classes.cardHeaderIcon}>
                    <PersonAddOutlined/>
                </CardIcon>
                <h4 className={classes.cardTitle}>Create new account</h4>
            </CardHeader>
            <CardBody>
                <Mutation mutation={USER_REGISTER}>
                    {
                        (register, { loading, error, data}) => (
                            <Formik
                                initialValues={
                                    {
                                        username: '',
                                        email: '',
                                        password1: '',
                                        password2: ''
                                    }
                                }
                                validationSchema={validationSchema}
                                onSubmit={(data, { setErrors }) => {
                                    register({
                                        variables: {
                                            username: data.username,
                                            email: data.email,
                                            password1: data.password1,
                                            password2: data.password2
                                        }
                                    }).then(
                                        (response) => {
                                            let dataResponse = response.data.register

                                            const errors = dataResponse.errors
                                            if (errors) {
                                                return setErrors(getErrors(errors))
                                            }

                                            if (dataResponse.success) {
                                                return successForm()
                                            }
                                        },
                                        () => {
                                            notifyError("Registration failed, try again")
                                        }
                                    )
                                }}
                            >
                                {
                                    formik => (
                                        <form onSubmit={formik.handleSubmit}>
                                            <GridContainer>
                                                <Form formik={formik}/>
                                            </GridContainer>

                                            <Divider flexItem className={classes.sectionsDividerHorizontal}/>

                                            <Hidden xsDown>
                                                <GridContainer>
                                                    <div className={classes.actionButtonsContainer}>
                                                        <small>(*) means that fields are required</small>
                                                        <div>
                                                            <FooterButtons formik={formik} loading={loading}/>
                                                        </div>
                                                    </div>
                                                </GridContainer>
                                            </Hidden>
                                            <Hidden only={["sm", "md", "lg", "xl"]}>
                                                <GridContainer>
                                                    <div className={classes.actionButtonsContainer}>
                                                        <small>(*) means that fields are required</small>
                                                    </div>
                                                    <div className={classes.xmActions}>
                                                        <FooterButtons formik={formik} loading={loading}/>
                                                    </div>
                                                </GridContainer>
                                            </Hidden>
                                        </form>
                                    )
                                }
                            </Formik>
                        )
                    }
                </Mutation>
            </CardBody>
        </Card>
    )
};
