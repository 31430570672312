import React, { useRef } from "react"
import { NavLink, useHistory } from "react-router-dom"

import { TextField, Button, InputAdornment, CircularProgress, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { VisibilityOutlined, VisibilityOffOutlined, PersonOutlined } from "@material-ui/icons"

import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"

import { Formik } from "formik"
import * as Yup from "yup"

//redux
import { useDispatch, useSelector } from "react-redux"
import { singIn } from "actions/auth/loginActions"

//assets
import styles from "assets/jss/material-dashboard-react/views/loginStyle"
import logo from "assets/img/035-online shopping.svg"
import googleLogo from "assets/img/login/google.svg"
import facebookLogo from "assets/img/login/facebook.svg"
import twitterLogo from "assets/img/login/twitter.svg"

//notifications
import { SuccessNotification, ErrorNotification } from "components/Notifications/Notifications"

//social login
import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import app_id from "config/app-id.json"

//apollo
import { useLazyQuery, useMutation } from "@apollo/client"
import { Mutation } from "@apollo/client/react/components"
import { LOGIN, LOGIN_SOCIAL } from "querys/auth/loginQueries"

//browser cookies
import * as Cookies from "js-cookie"

const useStyles = makeStyles(styles)

const notifySuccess = () => SuccessNotification("Authentication", "Login succeed")
const notifyError = (message) => ErrorNotification("Authentication", message)

export default function Login(props) {
    const classes = useStyles()

    const history = useHistory()

    const token = !!Cookies.get("JWT")
    const authUser = useSelector((state) => state.loginReducer)

    React.useEffect(() => {
        if (token && authUser) {
            history.replace("/seller/dashboard")
        }
    })

    const [showPassword, setShowPassword] = React.useState(false)
    const toggleVisibility = () => {
        setShowPassword(!showPassword)
    }

    const dispatch = useDispatch()

    const successForm = (response) => {
        dispatch(
            singIn({
                token: response.token,
                refreshToken: response.refreshToken,
                user: response.user,
            })
        )
        history.push("/seller/dashboard")
        notifySuccess()
    }

    //google login component
    const SocialLoginGoogle = () => {
        const [googleLogin, { loading }] = useMutation(LOGIN_SOCIAL)

        const googleResponse = (response) => {
            const token = response.access_token

            if (token) {
                googleLogin({
                    variables: {
                        accessToken: token,
                        provider: "google",
                    },
                }).then(
                    (response) => {
                        if (response.data.social) {
                            successForm(response.data.social.social)
                        }
                        if (!response.data.social) {
                            notifyError("Google login failed, try again")
                        }
                    },
                    () => {
                        notifyError("Google login failed, try again")
                    }
                )
            } else {
                notifyError("Google login failed, try again")
            }
        }

        let tokenClient = useRef()

        /* global google */
        React.useEffect(() => {
            if ("google" in window) {
                tokenClient.current = google.accounts.oauth2.initTokenClient({
                    client_id: app_id.GOOGLE_CLIENT_ID,
                    prompt: "consent",
                    scope: "https://www.googleapis.com/auth/userinfo.email",
                    callback: googleResponse,
                })
            }
        })

        return loading ? (
            <CircularProgress size={30} />
        ) : (
            <img
                src={googleLogo}
                alt="logoGoogle"
                className={classes.socialAuthLogo}
                onClick={() => tokenClient.current.requestAccessToken()}
            />
        )
    }

    //facebook login component
    const SocialLoginFacebook = () => {
        const [facebookLogin, { loading }] = useMutation(LOGIN_SOCIAL)

        const facebookResponse = (response) => {
            const token = response.accessToken

            if (token) {
                facebookLogin({
                    variables: {
                        accessToken: token,
                        provider: "facebook",
                    },
                }).then(
                    (response) => {
                        if (response.data.social) {
                            successForm(response.data.social.social)
                        }
                        if (!response.data.social) {
                            notifyError("Facebook login failed, try again")
                        }
                    },
                    () => {
                        notifyError("Facebook login failed, try again")
                    }
                )
            } else {
                notifyError("Facebook login failed, try again")
            }
        }

        const failureResponse = () => notifyError("Facebook login failed, try again")

        return loading ? (
            <CircularProgress size={30} />
        ) : (
            <FacebookLogin
                appId={app_id.FACEBOOK_APP_ID}
                callback={facebookResponse}
                onFailure={failureResponse}
                scope="public_profile,pages_show_list"
                render={(renderProps) =>
                    renderProps.isProcessing ? (
                        <CircularProgress size={30} />
                    ) : (
                        <img
                            src={facebookLogo}
                            alt="logoGoogle"
                            className={classes.socialAuthLogo}
                            onClick={renderProps.onClick}
                        />
                    )
                }
            />
        )
    }

    //twitter login component
    // eslint-disable-next-line
    const SocialLoginTwitter = () => {
        const [twitterLogin, { loading }] = useLazyQuery(LOGIN_SOCIAL, {
            onCompleted: (data) => {
                if (data && data.social) {
                    successForm(data.social)
                }
            },
            onError: () => {
                notifyError("Twitter login failed, try again")
            },
        })

        const twitterResponse = (response) => {
            const token = response.headers?.get("x-auth-token")

            if (token) {
                twitterLogin({
                    variables: {
                        accessToken: token,
                        provider: "twitter",
                    },
                }).then(
                    (response) => {
                        if (response.data.social) {
                            successForm(response.data.social.social)
                        }
                        if (!response.data.social) {
                            notifyError("Twitter login failed, try again")
                        }
                    },
                    () => {
                        notifyError("Twitter login failed, try again")
                    }
                )
            } else {
                notifyError("Twitter login failed, try again")
            }
        }

        const failureResponse = () => notifyError("Twitter login failed, try again")

        return loading ? (
            <CircularProgress size={30} />
        ) : (
            <TwitterLogin
                loginUrl="http://localhost:4000/api/v1/auth/twitter"
                onFailure={failureResponse}
                onSuccess={twitterResponse}
                requestTokenUrl="http://localhost:4000/api/v1/auth/twitter/reverse"
                tag={"div"}
            >
                <img src={twitterLogo} alt="logoTwitter" className={classes.socialAuthLogoTwitter} />
            </TwitterLogin>
        )
    }

    return (
        <Card className={classes.loginFormContainer}>
            <CardBody>
                <div className={classes.logo}>
                    <img src={logo} alt="logoLogin" className={classes.img} />
                </div>

                <Mutation mutation={LOGIN}>
                    {(register, { loading, error, data }) => (
                        <Formik
                            initialValues={{
                                username: "",
                                password: "",
                            }}
                            validationSchema={Yup.object({
                                username: Yup.string().required("field required"),
                                password: Yup.string().required("field required"),
                            })}
                            onSubmit={(data, { setFieldError }) => {
                                register({
                                    variables: {
                                        username: data.username,
                                        password: data.password,
                                    },
                                }).then(
                                    (response) => {
                                        let loginResponse = response.data.tokenAuth
                                        if (!loginResponse.success && loginResponse.errors) {
                                            setFieldError("password", loginResponse.errors.nonFieldErrors[0].message)
                                        }

                                        if (loginResponse.success) {
                                            successForm(loginResponse)
                                        }
                                    },
                                    () => {
                                        notifyError("Login error, try again")
                                    }
                                )
                            }}
                        >
                            {(formik) => (
                                <form onSubmit={formik.handleSubmit}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="username"
                                                label="Username"
                                                size={"small"}
                                                fullWidth
                                                variant={"outlined"}
                                                className={classes.inputFields}
                                                {...formik.getFieldProps("username")}
                                                error={formik.errors.username && formik.touched.username}
                                                helperText={
                                                    formik.errors.username &&
                                                    formik.touched.username &&
                                                    formik.errors.username
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" style={{ color: "#757575" }}>
                                                            <PersonOutlined />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="password"
                                                label="Password"
                                                size={"small"}
                                                type={showPassword ? "text" : "password"}
                                                fullWidth
                                                variant={"outlined"}
                                                className={classes.inputFields}
                                                {...formik.getFieldProps("password")}
                                                error={formik.errors.password && formik.touched.password}
                                                helperText={
                                                    formik.errors.password &&
                                                    formik.touched.password &&
                                                    formik.errors.password
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" onClick={toggleVisibility}>
                                                            <IconButton size={"small"}>
                                                                {showPassword ? (
                                                                    <VisibilityOffOutlined />
                                                                ) : (
                                                                    <VisibilityOutlined />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>

                                    <Button
                                        color={"primary"}
                                        type="submit"
                                        variant={"contained"}
                                        className={classes.submitBtn}
                                        disabled={!formik.isValid || loading}
                                    >
                                        {loading ? <CircularProgress size={24} /> : "Login"}
                                    </Button>

                                    <div className={classes.socialAuthContainer}>
                                        <SocialLoginGoogle />

                                        <SocialLoginFacebook />

                                        {/*<SocialLoginTwitter/>*/}
                                    </div>

                                    <div className={classes.textLink}>
                                        Don't you have account yet?
                                        <NavLink to="/account/create"> Register here</NavLink>
                                    </div>

                                    <div className={classes.textLink}>
                                        Did you forget your password?
                                        <NavLink to="/account/recover-password"> Recover it here</NavLink>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    )}
                </Mutation>
            </CardBody>
        </Card>
    )
}
