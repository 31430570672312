import React from "react"

import { Button, CircularProgress, IconButton, Toolbar, Tooltip, Typography } from "@material-ui/core"

//icons
import { ClearOutlined, CloseOutlined, InfoOutlined } from "@material-ui/icons"

//assets
import { MarketplaceOrdersView } from "utils/viewsDescriptionUtils"

//redux
import { useSelector } from "react-redux"

export default function MarketplaceOrdersTableHeader(props) {
    const {
        classes,
        loading,
        handleCloseModal,
        displayAllAmazonOrders,
        setDisplayAllAmazonOrders,
        displayAllEtsyOrders,
        setDisplayAllEtsyOrders,
        displayAllFacebookOrders,
        setDisplayAllFacebookOrders,
        displayOneAmazonOrder,
        setDisplayOneAmazonOrder,
        displayOneEtsyOrder,
        setDisplayOneEtsyOrder,
        displayOneFacebookOrder,
        setDisplayOneFacebookOrder,
    } = props

    const [userHasAmazonMarketplace, setUserHasAmazonMarketplace] = React.useState(false)
    const [userHasEtsyMarketplace, setUserHasEtsyMarketplace] = React.useState(false)
    const [userHasFacebookMarketplace, setUserHasFacebookMarketplace] = React.useState(false)

    const marketplaceConnectionStatus = useSelector((state) => state.marketplaceConnectionReducer)
    React.useEffect(() => {
        Object.keys(marketplaceConnectionStatus.marketplaceStatus).forEach((marketplaceSellerId) => {
            const row = marketplaceConnectionStatus.marketplaceStatus[marketplaceSellerId]

            if (row.sellerMarketplace.marketplace.name.toUpperCase() === "AMAZON") setUserHasAmazonMarketplace(true)

            if (row.sellerMarketplace.marketplace.name.toUpperCase() === "ETSY") setUserHasEtsyMarketplace(true)

            if (row.sellerMarketplace.marketplace.name.toUpperCase() === "FACEBOOK") setUserHasFacebookMarketplace(true)
        })
    }, [marketplaceConnectionStatus.marketplaceStatus])

    return (
        <Toolbar className={classes.header}>
            <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                Marketplaces orders
                <Tooltip
                    title={<MarketplaceOrdersView darkStyle />}
                    arrow
                    interactive
                    classes={{ tooltip: classes.descriptionTooltip }}
                >
                    <InfoOutlined style={{ fontSize: 16 }} />
                </Tooltip>
            </Typography>

            <div style={{ display: "flex", alignItems: "center" }}>
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        {displayOneAmazonOrder && (
                            <Tooltip title={"Displaying referenced Amazon order"}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => setDisplayOneAmazonOrder(false)}
                                    size={"small"}
                                    startIcon={<ClearOutlined fontSize={"small"} />}
                                >
                                    Amazon
                                </Button>
                            </Tooltip>
                        )}

                        {displayOneEtsyOrder && (
                            <Tooltip title={"Displaying referenced Etsy order"}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => setDisplayOneEtsyOrder(false)}
                                    size={"small"}
                                    startIcon={<ClearOutlined fontSize={"small"} />}
                                >
                                    Etsy
                                </Button>
                            </Tooltip>
                        )}

                        {displayOneFacebookOrder && (
                            <Tooltip title={"Displaying referenced Facebook order"}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => setDisplayOneFacebookOrder(false)}
                                    size={"small"}
                                    startIcon={<ClearOutlined fontSize={"small"} />}
                                >
                                    Facebook
                                </Button>
                            </Tooltip>
                        )}

                        <>
                            {!displayOneAmazonOrder && !displayOneEtsyOrder && !displayOneFacebookOrder && (
                                <>
                                    {userHasAmazonMarketplace && (
                                        <Tooltip
                                            title={
                                                displayAllAmazonOrders
                                                    ? "Displaying orders from Amazon"
                                                    : "Orders from Amazon not displayed"
                                            }
                                        >
                                            <Button
                                                variant={displayAllAmazonOrders ? "contained" : "outlined"}
                                                color={displayAllAmazonOrders ? "primary" : "inherit"}
                                                onClick={() => setDisplayAllAmazonOrders(!displayAllAmazonOrders)}
                                                size={"small"}
                                            >
                                                Amazon
                                            </Button>
                                        </Tooltip>
                                    )}

                                    {userHasEtsyMarketplace && (
                                        <Tooltip
                                            title={
                                                displayAllEtsyOrders
                                                    ? "Displaying orders from Etsy"
                                                    : "Orders from Etsy not displayed"
                                            }
                                        >
                                            <Button
                                                variant={displayAllEtsyOrders ? "contained" : "outlined"}
                                                color={displayAllEtsyOrders ? "primary" : "inherit"}
                                                onClick={() => setDisplayAllEtsyOrders(!displayAllEtsyOrders)}
                                                size={"small"}
                                                style={{ marginLeft: "7px" }}
                                            >
                                                Etsy
                                            </Button>
                                        </Tooltip>
                                    )}

                                    {userHasFacebookMarketplace && (
                                        <Tooltip
                                            title={
                                                displayAllFacebookOrders
                                                    ? "Displaying orders from Facebook"
                                                    : "Orders from Facebook not displayed"
                                            }
                                        >
                                            <Button
                                                variant={displayAllFacebookOrders ? "contained" : "outlined"}
                                                color={displayAllFacebookOrders ? "primary" : "inherit"}
                                                onClick={() => setDisplayAllFacebookOrders(!displayAllFacebookOrders)}
                                                size={"small"}
                                                style={{ marginLeft: "7px" }}
                                            >
                                                Facebook
                                            </Button>
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </>

                        {handleCloseModal && (
                            <Tooltip title="Close" arrow>
                                <IconButton aria-label="close" color={"inherit"} onClick={handleCloseModal}>
                                    <CloseOutlined color={"secondary"} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </>
                )}
            </div>
        </Toolbar>
    )
}
