import React, { useState } from "react"

//components
import AuthUser from "components/Seller/Auth user/AuthUser"
import AuthUserForm from "components/Seller/Auth user/AuthUserForm"
import SellerDetails from "components/Seller/Auth user/SellerDetails"
import SellerForm from "components/Seller/Auth user/SellerForm"

//apollo
import { AUTH_USER_VALID_ID } from "querys/auth/loginQueries"
import { Query } from "@apollo/client/react/components"

//notifications
import { ErrorNotification } from "components/Notifications/Notifications"

const notifyError = (title, message) => ErrorNotification(title, message)

export default function Profile(props) {
    const [showSellerForm, setShowSellerForm] = useState(false)
    const toggleSellerFormVisible = () => {
        setShowSellerForm(!showSellerForm)
    }

    const [showUserForm, setShowUserForm] = useState(false)
    const toggleUserFormVisible = () => {
        setShowUserForm(!showUserForm)
    }

    return (
        <Query query={AUTH_USER_VALID_ID}>
            {({ loading, error, data, refetch }) => {
                if (error) {
                    notifyError("User", "Load data failed")
                }

                let response = data?.me2 ?? {}
                return (
                    <>
                        <div>
                            {showUserForm ? (
                                <AuthUserForm
                                    toggleVisible={() => toggleUserFormVisible()}
                                    updateItem={response}
                                    refetch={refetch}
                                />
                            ) : (
                                <AuthUser
                                    toggleVisible={() => toggleUserFormVisible()}
                                    detailsItem={response}
                                    loading={loading}
                                />
                            )}
                        </div>

                        <br />

                        <div>
                            {showSellerForm ? (
                                <SellerForm
                                    toggleVisible={() => toggleSellerFormVisible()}
                                    updateItem={response.seller}
                                    refetch={refetch}
                                />
                            ) : (
                                <SellerDetails
                                    toggleVisible={() => toggleSellerFormVisible()}
                                    detailsItem={response.seller}
                                    loading={loading}
                                />
                            )}
                        </div>
                    </>
                )
            }}
        </Query>
    )
}
