import React from "react";
import {
    Button,
    Dialog,
    Grid,
    Typography,
    useTheme,
    useMediaQuery,
    Avatar
} from "@material-ui/core";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import {ConfirmTransition} from "utils/tableUtils";


export default function ImageZoom(props) {
    const {
        open,
        setOpen,
        image,
        imageName
    } = props

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={ConfirmTransition}
            keepMounted
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Image "{imageName}"</Typography>
            </MuiDialogTitle>
            <MuiDialogContent dividers>
                <Grid container justifyContent={"center"} alignItems={"center"} style={{height: "100%"}}>
                    <Avatar
                        src={image}
                        variant={"rounded"}
                        style={{width: "100%", height: "auto"}}
                    />
                </Grid>
            </MuiDialogContent>
            <MuiDialogActions>
                <Button onClick={handleClose} color="primary">
                    Ok
                </Button>
            </MuiDialogActions>
        </Dialog>
    )
}
