import React from "react"

//icons
import StoreIcon from "mdi-react/StoreIcon"
import ChevronDownIcon from "mdi-react/ChevronDownIcon"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"

//styles
import styles from "assets/jss/material-dashboard-react/components/dashboardStatsStyle"
import { makeStyles } from "@material-ui/core/styles"

//link
import { NavLink } from "react-router-dom"

//material
import { Avatar, Card, CardContent, Fade, Grid } from "@material-ui/core"
import { DragHandle } from "@material-ui/icons"

const useStyles = makeStyles(styles)

export default function UserMarketplacesStats(props) {
    const classes = useStyles()

    const { sellerStats } = props

    function getAvailableMarketplaces(stats) {
        if (stats) {
            return 1 + stats.amazonInformation.length + stats.ebayInformation.length
        }
        return 0
    }

    const [displayMarketplaceDetails, setDisplayMarketplaceDetails] = React.useState(false)

    const MarketplaceBlock = () => (
        <Grid container item xs={12}>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={11} justifyContent={"center"}>
                    <h3 className={classes.cardTitle}>
                        <NavLink to={"/seller/connection"} style={{ textDecoration: "solid underline black 1px" }}>
                            {sellerStats?.totalMarketplacesReady ?? "-"} <small>marketplaces</small>
                        </NavLink>
                    </h3>
                </Grid>
                <Grid container item xs={1} alignItems={"center"}>
                    {displayMarketplaceDetails ? (
                        <ChevronDownIcon onClick={() => setDisplayMarketplaceDetails(!displayMarketplaceDetails)} />
                    ) : (
                        <ChevronRightIcon onClick={() => setDisplayMarketplaceDetails(!displayMarketplaceDetails)} />
                    )}
                </Grid>
            </Grid>

            {displayMarketplaceDetails && (
                <Fade in={displayMarketplaceDetails}>
                    <Grid container item xs={12} justifyContent={"flex-end"}>
                        <h4 className={classes.cardSubTitle}>
                            {getAvailableMarketplaces(sellerStats)} <small>available marketplaces</small>
                        </h4>
                    </Grid>
                </Fade>
            )}
        </Grid>
    )

    return (
        <Card variant={"outlined"}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid container item>
                        <Grid item xs={6}>
                            <DragHandle fontSize={"small"} />
                        </Grid>
                        <Grid container item xs={6} justifyContent={"flex-end"} className={classes.cardCategory}>
                            Connections
                        </Grid>
                    </Grid>
                    <Grid container item xs={2} alignItems={"center"}>
                        <Avatar variant={"rounded"} className={classes.cardAvatar}>
                            <StoreIcon size={"55"} />
                        </Avatar>
                    </Grid>
                    <Grid container item xs={10} alignItems={"center"} spacing={1}>
                        <MarketplaceBlock />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
