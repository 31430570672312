import gql from "graphql-tag"

//create seller ebay
export const CREATE_SELLER_EBAY = gql`
    query createSellerEbay($serviceIds: [ID], $alias: String!, $code: String!, $status: String!) {
        createSellerEbay(input: { serviceIds: $serviceIds, alias: $alias, code: $code, status: $status }) {
            success
            reason
        }
    }
`
