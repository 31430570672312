import React from "react"

import { TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { QuestionAnswerOutlined } from "@material-ui/icons"

import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"

//assets
import styles from "assets/jss/material-dashboard-react/views/infoPageStyle"
import Grid from "@material-ui/core/Grid"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"

const useStyles = makeStyles(styles)

export default function FAQ(props) {
    const classes = useStyles()

    const productActionsData = [
        {
            action: "Create a product",
            details: "Create a new product in our system",
        },
        {
            action: "Create variations for product",
            details: "Create variations for main product",
        },
        {
            action: "Insert product' ad",
            details: "insert specific information for a product in a given marketplace",
        },
        {
            action: "Upload ad to marketplace",
            details: "Upload product ad to a given marketplace",
        },
        {
            action: "Start selling product in marketplace",
            details: "Make visible you product ad in some marketplace",
        },
        {
            action: "Edit ad information for a marketplace",
            details: "Edit an specific product ad",
        },
        {
            action: "Pause product' ad in a marketplace",
            details: "Make not visible you product ad in some marketplace",
        },
        {
            action: "Edit product information",
            description:
                "These changes will be applied automatically in all marketplaces where related product' ad is uploaded",
            details: "Edit main product information",
        },
        {
            action: "Edit variations of a product",
            description:
                "These changes will be applied automatically in all marketplaces where related product' ad is uploaded",
            details: "Edit variations for a given product",
        },
        {
            action: "Remove product' ad from the marketplace",
            details: "Remove product ad from a marketplace",
        },
        {
            action: "Remove product' ad from our system",
            details: "Remove an ad from our system",
        },
        {
            action: "Delete product",
            details: "Delete a product from our system",
        },
    ]

    return (
        <Card className={classes.cardContainer}>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <QuestionAnswerOutlined />
                </CardIcon>
                <h4 className={classes.cardTitle}>Frequent questions</h4>
            </CardHeader>
            <CardBody>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"body1"}>
                            These are questions that have been frequently asked by our users. If you don't see the
                            answer to your question here, feel free to send us a message using our support email. We
                            will reply to you as soon as possible.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>How do I use the software?</Typography>
                        <Typography variant={"body2"}>
                            In the page HOW IT WORKS you can see general workflow at Sync2markets. The next step would
                            be to create your own products and advertise them, you can see this in the featureList
                            products. Additionally, you can import the products you already have in other marketplaces
                            by using the function Import products.
                        </Typography>
                    </Grid>

                    <Grid item container xs={12} style={{ textAlign: "justify" }}>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle2"}>What actions can I do on my products?</Typography>
                        </Grid>
                        <Grid item xs={12} md={8} lg={6}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Action</TableCell>
                                        <TableCell>Description</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {productActionsData.map((item) => (
                                        <TableRow key={item.action}>
                                            <TableCell>
                                                {item.action}
                                                {item.description && (
                                                    <>
                                                        <br />
                                                        <small>{item.description}</small>
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {item.details}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>Can I use Sync2markets on my mobile device?</Typography>
                        <Typography variant={"body2"}>
                            Sync2markets is designed to run on any web browser, be it on a computer or mobile device.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>
                            What happens to my publications at the end of the cycle invoiced in the marketplace?
                        </Typography>
                        <Typography variant={"body2"}>
                            Sync2markets offers you the possibility to auto renew your ad automatically. Otherwise, your
                            ad will go to the state decided by the marketplace and here it will be shown as unpublished.
                            If you decide to republish it in each marketplace, the fees corresponding to the renewal of
                            the article will be applied.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>
                            Will Sync2makets automatically delete my items when they are sold?
                        </Typography>
                        <Typography variant={"body2"}>
                            The answer is no. As long as it exists in inventory, only the inventory will be updated in
                            all the linked marketplaces. When the quantity in inventory reaches zero the item will go to
                            the corresponding status in each marketplaces and Sync2markets will show it not published.
                            To renew the publication you must specify an inventory quantity greater than zero and in
                            each marketplace the corresponding rates will be applied to the renewal of the article.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>What markets does Sync2markets support?</Typography>
                        <Typography variant={"body2"}>
                            Sync2markets currently allows you to connect to eBay, Etsy, and Amazon. We will add more
                            markets soon.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>
                            Can I use Sync2markets outside of the United States?
                        </Typography>
                        <Typography variant={"body2"}>
                            At the moment, Sync2markets is designed for use in the United States. We hope to add other
                            locations soon.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>Can I import items that I already have for sale?</Typography>
                        <Typography variant={"body2"}>
                            Yes, we have an import function that allows you to bring your existing items to Sync2markets
                            so that you can manage them from here and publish them on other marketplaces.
                        </Typography>
                        <Typography variant={"body2"}>
                            You can access it from:
                            <li>Main menu</li>
                            <li>Products main page</li>
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>Why do the products need SKUs?</Typography>
                        <Typography variant={"body2"}>
                            SKUs are required for all products on Sync2markets. The SKU value is required for each
                            product created or imported in Sync2markets. SKUs must be matched implemented and matched
                            from channel to channel.
                        </Typography>
                        <Typography variant={"body2"}>
                            Sync2markets uses SKUs to link a product with its respective advertisement on the
                            marketplace. You can have ads on several Marketplaces (such as eBay, Etsy or Amazon.) For
                            the same product, but they must all have the same SKU otherwise Sync2markets cannot link the
                            ad with the product and therefore cannot keep the data synchronized.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>Is the Sync2markets website secure?</Typography>
                        <Typography variant={"body2"}>
                            For us, security comes first and we have taken several measures to ensure that information
                            is protected. Sync2markets runs on a secure Amazon cloud platform called Amazon Lightsail.
                            This gives us Amazon-level protection against hackers or anyone looking to access our data.
                        </Typography>
                        <Typography variant={"body2"}>
                            The Sync2markets website uses an SSL certificate, ensuring that the information is encrypted
                            in all parts of the client-server communication. It is also a way to indicate to your
                            clients and potential clients that your website is authentic, real and trustworthy. We also
                            do not store login information for any of your marketplaces (eBay, Etsy, or Amazon). In all
                            cases, we store an access token (given to us when you allow Sync2markets to publish on your
                            behalf), but you can revoke this access token at any time. The token only grants us access
                            to manage your ads and nothing else.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"subtitle2"}>How much does Sync2markets cost?</Typography>
                        <Typography variant={"body2"}>
                            At the moment Sync2markets is free. We use advertising to be able to raise funds to pay the
                            costs of the service.
                        </Typography>
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
    )
}
