import gql from "graphql-tag"

//system sellers
export const SELLER_STATS = gql`
    query dashboardSellerInfoType {
        dashboardSellerInfoType {
            totalProducts
            totalProductsUploaded
            totalProductsPublished
            totalProductsExpired
            totalMarketplacesReady
            totalProductsQuantityLess10
            totalProductsQuantityBetween10And19
            totalProductsEtsy
            totalProductsUploadedEtsy
            totalProductsPublishedEtsy
            totalProductsExpiredEtsy
            amazonInformation {
                region
                totalProductsAmazon
                totalProductsPublishedAmazon
                totalProductsUploadedAmazon
            }
            ebayInformation {
                region
                totalProductsEbay
                totalProductsPublishedEbay
                totalProductsUploadedEbay
            }
        }
    }
`

//system sellers
export const ADMIN_STATS = gql`
    query dashboardAdminInfoType {
        dashboardAdminInfoType {
            totalUsers
            totalUsersInactive
            totalUsersUnverified
            totalUsersStaff
            totalUsersSuperuser
            totalUsersAdmin
            totalUsersSeller
            totalProductsEtsy
            totalProductsUploadedEtsy
            totalProductsPublishedEtsy
            totalProductsExpiredEtsy
            amazonInformation {
                region
                totalProductsAmazon
                totalProductsPublishedAmazon
                totalProductsUploadedAmazon
            }
            ebayInformation {
                region
                totalProductsEbay
                totalProductsPublishedEbay
                totalProductsUploadedEbay
            }
            topCountries {
                countryName
                sellersCount
            }
        }
    }
`
