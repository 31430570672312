import React from "react";
import {useHistory} from "react-router-dom";

import {TextField, Button, Divider, CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {CheckBoxOutlined} from "@material-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";

import { Formik } from "formik";
import * as Yup from "yup";

//apollo
import { Mutation } from "@apollo/client/react/components"
import { VERIFY_ACCOUNT } from "querys/auth/loginQueries"

//notifications
import {ErrorNotification, SuccessNotification} from "components/Notifications/Notifications"

//assets
import styles from "assets/jss/material-dashboard-react/views/verifyAccountStyle";

const useStyles = makeStyles(styles);

export default function VerifyAccount(props) {
    const classes = useStyles();

    const history = useHistory();

    const notifyError = message => ErrorNotification("Account", message)
    const notifySuccess = () => SuccessNotification("Account", "Account successfully verified. Now you can log in")

    const cancelAction = () => {
        history.push("/login")
    }

    const successForm = () => {
        notifySuccess()
        history.push('/login')
    }

    const validationSchema = Yup.object({
        token: Yup.string()
            .required('field required')
    })

    function getErrors(errors) {
        let errorsObject = {}
        Object.entries(errors).forEach(([field, fieldErrors]) => {
            if (field === "nonFieldErrors") {
                fieldErrors.forEach(error => notifyError(error.message))
            } else {
                let errorMessages = []
                fieldErrors.forEach(error => errorMessages.push(error.message))
                errorsObject[field] = errorMessages
            }
        })
        return errorsObject
    }

    const Form = (props) => {
        const formik = props.formik

        return (
            <>
                <GridItem xs={12}>
                    <TextField
                        name="token"
                        label="Activation code"
                        size={"small"}
                        fullWidth
                        multiline
                        rows={3}
                        variant={"filled"}
                        className={classes.inputFields}
                        {...formik.getFieldProps("token")}
                        error={formik.errors.token && formik.touched.token}
                        helperText={(formik.errors.token && formik.touched.token) && formik.errors.token}
                    />
                </GridItem>
            </>
        )
    }

    const FooterButtons = (props) => {
        const formik = props.formik
        const loading = props.loading

        return (
            <>
                <Button
                    color={"secondary"}
                    size={"small"}
                    variant={"text"}
                    className={classes.actionButton}
                    onClick={cancelAction}
                >
                    Cancel
                </Button>

                <Button
                    color={"primary"}
                    type="submit"
                    size={"small"}
                    variant={"text"}
                    disabled={!formik.isValid || loading}
                    className={classes.actionButton}
                >
                    {loading ? <CircularProgress size={24}/> : "Verify"}
                </Button>
            </>
        )
    }

    return (
        <Card className={classes.formContainer}>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="success" className={classes.cardHeaderIcon}>
                    <CheckBoxOutlined/>
                </CardIcon>
                <h4 className={classes.cardTitle}>Verify account</h4>
            </CardHeader>
            <CardBody>
                <Mutation mutation={VERIFY_ACCOUNT}>
                    {
                        (register, { loading, error, data}) => (
                            <Formik
                                initialValues={
                                    {
                                        token: ''
                                    }
                                }
                                validationSchema={validationSchema}
                                onSubmit={(data, { setErrors }) => {
                                    register({
                                        variables: {
                                            token: data.token
                                        }
                                    }).then(
                                        (response) => {
                                            let dataResponse = response.data.verifyAccount

                                            const errors = dataResponse.errors
                                            if (errors) {
                                                return setErrors(getErrors(errors))
                                            }

                                            if (dataResponse.success) {
                                                return successForm()
                                            }
                                        },
                                        () => {
                                            notifyError("Verification failed, try again")
                                        }
                                    )
                                }}
                            >
                                {
                                    formik => (
                                        <form onSubmit={formik.handleSubmit}>
                                            <GridContainer>
                                                <Form formik={formik}/>
                                            </GridContainer>

                                            <Divider flexItem className={classes.sectionsDividerHorizontal}/>

                                            <GridContainer>
                                                <div className={classes.actionButtonsContainer}>
                                                    <div>
                                                        <FooterButtons formik={formik} loading={loading}/>
                                                    </div>
                                                </div>
                                            </GridContainer>
                                        </form>
                                    )
                                }
                            </Formik>
                        )
                    }
                </Mutation>
            </CardBody>
        </Card>
    )
};
