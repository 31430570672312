import React from "react";
import {
    Grid,
    Button,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText
} from "@material-ui/core";

//styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/cardFormStyle";

//icons
import ChevronDoubleRightIcon from "mdi-react/ChevronDoubleRightIcon";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import ChevronDoubleLeftIcon from "mdi-react/ChevronDoubleLeftIcon";

const useStyles = makeStyles(styles);

export default function PermissionsList(props) {
    const classes = useStyles()

    const {left, setLeft, right, setRight} = props

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1)
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1)
    }

    const [selected, setSelected] = React.useState([])

    const leftSelected = intersection(selected, left)
    const rightSelected = intersection(selected, right)

    const handleToggle = (value) => () => {
        const currentIndex = selected.indexOf(value)
        const newSelected = [...selected]

        if (currentIndex === -1) {
            newSelected.push(value)
        } else {
            newSelected.splice(currentIndex, 1)
        }

        setSelected(newSelected)
    }

    const handleAllRight = () => {
        setRight(right.concat(left))
        setLeft([])
    }

    const handleAllLeft = () => {
        setLeft(left.concat(right))
        setRight([])
    }

    const handleSelectedRight = () => {
        setRight(right.concat(leftSelected))
        setLeft(not(left, leftSelected))
        setSelected(not(selected, leftSelected))
    }

    const handleSelectedLeft = () => {
        setLeft(left.concat(rightSelected))
        setRight(not(right, rightSelected))
        setSelected(not(selected, rightSelected))
    }

    const customList = (items, title) => (
        <Paper className={classes.paper}>
            <Typography variant={"subtitle2"}>{title}</Typography>
            <List
                dense
                component="div"
                role="list"
                className={classes.list}
            >
                {items.map((item) => {
                    const labelId = `item-${item.node.id}-label`;

                    return (
                        <ListItem
                            key={item.node.id}
                            role="listitem"
                            button
                            selected={selected.indexOf(item) !== -1}
                            onClick={handleToggle(item)}
                        >
                            <ListItemText
                                id={labelId}
                                primary={item.node.name}
                                secondary={item.node.codename}
                            />
                        </ListItem>
                    )
                })}
                <ListItem />
            </List>
        </Paper>
    )

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.formFields}>
            <Grid item xs={12} md={5}>{customList(left, "Available permissions")}</Grid>
            <Grid item xs={12} md={2}>
                <Grid container direction="column" alignItems="center">
                    <Button
                        variant="outlined"
                        color={"primary"}
                        size="small"
                        className={classes.formFields}
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        <ChevronDoubleRightIcon/>
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className={classes.formFields}
                        onClick={handleSelectedRight}
                        disabled={leftSelected.length === 0}
                        aria-label="move selected right"
                    >
                        <ChevronRight/>
                    </Button>
                    <Button
                        variant="contained"
                        size="small"
                        className={classes.formFields}
                        onClick={handleSelectedLeft}
                        disabled={rightSelected.length === 0}
                        aria-label="move selected left"
                    >
                        <ChevronLeft/>
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.formFields}
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                        color={"primary"}
                    >
                        <ChevronDoubleLeftIcon/>
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5}>{customList(right, "Group permissions")}</Grid>
        </Grid>
    )
}
