import React from "react"

//styles
import { makeStyles } from "@material-ui/core/styles"
import generalUsageUtilsStyle from "assets/jss/material-dashboard-react/components/GeneralUsageUtilsStyle"
import { ErrorNotification } from "../components/Notifications/Notifications"

const useStyles = makeStyles(generalUsageUtilsStyle)

export const Btn = ({ name }) => {
    const classes = useStyles()

    return <code className={classes.informationBtn}>{name}</code>
}

const notifyErrorConnection = (message) => ErrorNotification("Connection", message)
export function notifyMarketplaceConnectionError(marketplaceConnectionStatusSelector, marketplacesList) {
    Object.keys(marketplaceConnectionStatusSelector.marketplaceStatus).map((marketplaceSellerId) => {
        const row = marketplaceConnectionStatusSelector.marketplaceStatus[marketplaceSellerId]
        if (
            marketplacesList.find((item) => item.toUpperCase() === row.sellerMarketplace.marketplace.name.toUpperCase())
        )
            notifyErrorConnection(
                `${row.sellerMarketplace.alias} connection has troubles and need your attention. Information here may be incomplete`
            )

        return null
    })
}
