import { gql } from "@apollo/client"

//user login with username and password
export const LOGIN = gql`
    mutation tokenAuth($username: String!, $password: String!) {
        tokenAuth(input: { username: $username, password: $password }) {
            success
            errors
            token
            refreshToken
            user {
                id
                seller {
                    id
                }
                username
                isSuperuser
                isStaff
                groups {
                    id
                    name
                }
            }
        }
    }
`

//update user account (without user id, user app mutation)
export const UPDATE_USER_ACCOUNT = gql`
    mutation updateAccount($firstName: String!, $lastName: String!) {
        updateAccount(input: { firstName: $firstName, lastName: $lastName }) {
            success
            errors
        }
    }
`

//update user account (made by floder)
export const UPDATE_ACCOUNT = gql`
    mutation updateProfile($id: ID!, $fileIn: Upload, $username: String!, $firstName: String!, $lastName: String!) {
        updateProfile(userId: $id, username: $username, firstName: $firstName, lastName: $lastName, fileIn: $fileIn) {
            profile {
                token
                user {
                    id
                    username
                }
            }
        }
    }
`

//authenticated user data (from user app)
export const AUTH_USER = gql`
    query me {
        me {
            id
            username
            firstName
            lastName
            email
            image
            dateJoined
            lastLogin
            verified
            isSuperuser
            isStaff
            seller {
                id
                phone
                sku
                country {
                    id
                    name
                }
            }
        }
    }
`

//authenticated user data (from floder sprinker app)
export const AUTH_USER_VALID_ID = gql`
    query me2 {
        me2 {
            id
            username
            firstName
            lastName
            email
            image
            dateJoined
            lastLogin
            isSuperuser
            isStaff
            groups {
                id
                name
            }
            seller {
                id
                phone
                sku
                country {
                    id
                    name
                }
            }
        }
    }
`

//new user registration
export const USER_REGISTER = gql`
    mutation register($email: String!, $username: String!, $password1: String!, $password2: String!) {
        register(input: { email: $email, username: $username, password1: $password1, password2: $password2 }) {
            success
            errors
        }
    }
`

//new user account verification
export const VERIFY_ACCOUNT = gql`
    mutation verifyAccount($token: String!) {
        verifyToken(input: { token: $token }) {
            success
            errors
        }
    }
`

//authenticated user password change
export const PASSWORD_CHANGE = gql`
    mutation passwordChange($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
        passwordChange(input: { oldPassword: $oldPassword, newPassword1: $newPassword1, newPassword2: $newPassword2 }) {
            success
            errors
            token
        }
    }
`

//authenticate user with social auth
export const LOGIN_SOCIAL = gql`
    mutation social($accessToken: String!, $provider: String!) {
        social(accessToken: $accessToken, provider: $provider) {
            social {
                token
                refreshToken
                user {
                    id
                    seller {
                        id
                    }
                    username
                    isSuperuser
                    isStaff
                    groups {
                        id
                        name
                    }
                }
            }
        }
    }
`

//send password reset email
export const SEND_PASSWORD_RESET_EMAIL = gql`
    mutation sendPasswordResetEmail($email: String!) {
        sendPasswordResetEmail(input: { email: $email }) {
            success
            errors
        }
    }
`

//reset password
export const PASSWORD_RESET = gql`
    mutation passwordReset($token: String!, $password1: String!, $password2: String!) {
        passwordReset(input: { token: $token, newPassword1: $password1, newPassword2: $password2 }) {
            success
            errors
        }
    }
`

//reset password
export const REFRESH_TOKEN = gql`
    mutation refreshToken($refreshToken: String!) {
        refreshToken(input: { refreshToken: $refreshToken }) {
            refreshToken
            errors
            payload 
            success
            token
        }
    }
`
