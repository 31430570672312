/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";

// core components
import styles from "assets/jss/material-dashboard-react/components/footerStyle.js";
import logo from "assets/img/logo/logo.png";
import {Avatar} from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Footer(props) {
    const classes = useStyles();
    return (
        <div className={classes.footer}>
            <div className={classes.brandText}>
                <div>
                    <Avatar
                        alt={"logo"}
                        src={logo}
                        className={classes.logoImg}
                    />
                </div>
                <div>Sell made easy </div>
            </div>
            <div>
                Sync2Markets &copy; {1900 + new Date().getYear()}
            </div>
        </div>
    );
}
