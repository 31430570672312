import { gql } from "@apollo/client"

//product listing of current seller, using pagination and sort. include product marketplace information
export const LOAD_PRODUCTS_IN_MARKETPLACES = gql`
    query allProductsInMarketplaces(
        $sellerId: ID!
        $orderBy: [String]!
        $idFilter: ID
        $skuFilter: String
        $titleFilter: String
        $descriptionFilter: String
        $brandFilter: String
        $manufacturerFilter: String
        $categoryFilter: ID
        $conditionFilter: ID
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allProductsStatusInAllSellerMarketplaces(
            sellerId: $sellerId
            orderBy: $orderBy
            id: $idFilter
            sku: $skuFilter
            title_Icontains: $titleFilter
            description_Icontains: $descriptionFilter
            brand_Icontains: $brandFilter
            manufacturer_Icontains: $manufacturerFilter
            categoryId: $categoryFilter
            conditionId: $conditionFilter
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    productId
                    id
                    title
                    description
                    quantity
                    sku
                    category {
                        id
                        name
                    }
                    lastModified
                    isBlocked
                    productImage {
                        edges {
                            node {
                                id
                                image
                            }
                        }
                    }
                    productProductVariation {
                        totalCount
                    }
                    statusInSellerMarketplaces {
                        sellerMarketplace {
                            id
                            alias
                            marketplace {
                                id
                                name
                                fullName
                                description
                                image
                                isEnabled
                            }
                        }
                        isLinked
                        isUploaded
                        isPublished
                        isExpired
                        isUnfilled
                        webhookEnabled
                        url
                        productSellerMarketplaceFieldsId
                        productSellerMarketplaceId
                    }
                }
            }
        }
    }
`

//product pagination reload
export const RELOAD_PRODUCT_IN_MARKETPLACES = gql`
    query realodProductInMarketplaces($productId: String!) {
        allProductsStatusInAllSellerMarketplaces(productId: $productId) {
            edges {
                node {
                    productId
                    id
                    title
                    description
                    quantity
                    sku
                    category {
                        id
                        name
                    }
                    lastModified
                    isBlocked
                    productImage {
                        edges {
                            node {
                                id
                                image
                            }
                        }
                    }
                    productProductVariation {
                        totalCount
                    }
                    statusInSellerMarketplaces {
                        sellerMarketplace {
                            id
                            alias
                            marketplace {
                                id
                                name
                                fullName
                                description
                                image
                                isEnabled
                            }
                        }
                        isLinked
                        isUploaded
                        isPublished
                        isExpired
                        isUnfilled
                        webhookEnabled
                        url
                        productSellerMarketplaceFieldsId
                        productSellerMarketplaceId
                    }
                }
            }
        }
    }
`

//product details
export const PRODUCT_DETAILS = gql`
    query productDetails($id: ID!) {
        product(id: $id) {
            id
            title
            description
            quantity
            buyPrice
            sellPrice
            weight
            weightUnit {
                id
                name
            }
            dimensionL
            dimensionW
            dimensionH
            dimensionUnit {
                id
                name
            }
            sku
            category {
                id
                name
            }
            lastModified
            condition {
                id
                name
            }
            color
            brand
            manufacturer
            isPhysical
            productMaterials {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            productProductIdTypeProduct {
                edges {
                    node {
                        id
                        productIdType {
                            id
                            name
                        }
                        value
                    }
                }
            }
            productFeature {
                edges {
                    node {
                        id
                        name
                        value
                    }
                }
            }
            productImage {
                edges {
                    node {
                        id
                        image
                    }
                }
            }
            productFile {
                edges {
                    node {
                        id
                        file
                    }
                }
            }
            productTag {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            productProductVariation {
                edges {
                    node {
                        id
                        name
                        sku
                        quantity
                        price
                        productVariationProductIdTypeProductVariation {
                            edges {
                                node {
                                    id
                                    productIdType {
                                        id
                                        name
                                    }
                                    value
                                }
                            }
                        }
                        visible
                        productVariationProductVariationVariationField {
                            edges {
                                node {
                                    id
                                    variationField {
                                        id
                                        name
                                    }
                                    value
                                    productVariationVariationFieldImage {
                                        totalCount
                                        edges {
                                            node {
                                                id
                                                image
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            productProductSellerMarketplace {
                edges {
                    node {
                        productEbayFieldProductSellerMarketplace {
                            edges {
                                node {
                                    id
                                    isUploaded
                                    isVariationsUploaded
                                    isPublished
                                    categoryName
                                    productIdType {
                                        id
                                        name
                                    }
                                    fixedSellPrice
                                    condition
                                    fulfillmentPolicyId
                                    currency
                                    merchantLocationKey
                                    packageType
                                    paymentPolicyId
                                    returnPolicyId
                                    aspectsImageVariesBy
                                    productEbayFieldProductEbayCategoryField {
                                        edges {
                                            node {
                                                name
                                                values
                                            }
                                        }
                                    }
                                    url
                                    productSellerMarketplace {
                                        sellerMarketplace {
                                            marketplace {
                                                id
                                                name
                                                fullName
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        productEtsyFieldProductSellerMarketplace {
                            edges {
                                node {
                                    id
                                    categoryName
                                    title
                                    taxonomyId
                                    isUploaded
                                    isPublished
                                    isExpired
                                    aspectsImageVariesBy
                                    shouldAutoRenew
                                    fixedSellPrice
                                    whenMade
                                    whoMade
                                    shopId
                                    shopSectionId
                                    productionPartnerIds
                                    shippingProfileId
                                    isSupply
                                    isCustomizable
                                    isTaxable
                                    processingMin
                                    processingMax
                                    isPersonalizable
                                    personalizationIsRequired
                                    personalizationInstructions
                                    personalizationCharCountMax
                                    url
                                    productEtsyFieldProductEtsyCategoryField {
                                        edges {
                                            node {
                                                id
                                                name
                                                values
                                            }
                                        }
                                    }
                                    productSellerMarketplace {
                                        sellerMarketplace {
                                            marketplace {
                                                id
                                                name
                                                fullName
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        productAmazonFieldProductSellerMarketplace {
                            edges {
                                node {
                                    id
                                    isUploaded
                                    wasImported
                                    isUnfilled
                                    isVariationsUploaded
                                    productIdType {
                                        id
                                        name
                                    }
                                    productType
                                    taxCode
                                    url
                                    fixedSellMapPrice
                                    fixedSellOurPrice
                                    productAmazonFieldProductAmazonBulletPoint {
                                        edges {
                                            node {
                                                value
                                            }
                                        }
                                    }
                                    productAmazonFieldProductAmazonCategoryField {
                                        edges {
                                            node {
                                                isComplex
                                                name
                                                values
                                                complexParentProductAmazonCategoryField {
                                                    edges {
                                                        node {
                                                            isComplex
                                                            name
                                                            values
                                                            complexParentProductAmazonCategoryField {
                                                                edges {
                                                                    node {
                                                                        isComplex
                                                                        name
                                                                        values
                                                                        complexParentProductAmazonCategoryField {
                                                                            edges {
                                                                                node {
                                                                                    isComplex
                                                                                    name
                                                                                    values
                                                                                    complexParentProductAmazonCategoryField {
                                                                                        edges {
                                                                                            node {
                                                                                                isComplex
                                                                                                name
                                                                                                values
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    productSellerMarketplace {
                                        sellerMarketplace {
                                            marketplace {
                                                id
                                                name
                                                fullName
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        productFacebookFieldProductSellerMarketplace {
                            edges {
                                node {
                                    id
                                    title
                                    availability
                                    condition
                                    fbProductCategory
                                    categoryName
                                    size
                                    salePrice
                                    salePriceEffectiveDate
                                    status
                                    image {
                                        id
                                        image
                                    }
                                    additionalImageLink
                                    gender
                                    ageGroup
                                    pattern
                                    shipping
                                    shippingWeight
                                    url
                                    isUploaded
                                    isVariationsUploaded
                                    isPublished
                                    productSellerMarketplace {
                                        sellerMarketplace {
                                            marketplace {
                                                id
                                                name
                                                fullName
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

//all materials, types of id, features, images and tags for a specific product
export const PRODUCT_UPDATE_DETAILS = gql`
    query productDetailsForUpdate($id: ID!) {
        product(id: $id) {
            brand
            buyPrice
            category {
                id
                name
            }
            color
            condition {
                id
                name
            }
            description
            dimensionH
            dimensionL
            dimensionW
            dimensionUnit {
                id
                name
            }
            id
            isPhysical
            manufacturer
            quantity
            sellPrice
            sku
            title
            weight
            weightUnit {
                id
                name
            }
            productMaterials {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            productProductIdTypeProduct {
                edges {
                    node {
                        id
                        productIdType {
                            id
                            name
                        }
                        value
                    }
                }
            }
            productFeature {
                edges {
                    node {
                        id
                        name
                        value
                    }
                }
            }
            productImage {
                edges {
                    node {
                        id
                        image
                        productVariationVariationField {
                            edges {
                                node {
                                    id
                                    value
                                }
                            }
                        }
                    }
                }
            }
            productFile {
                edges {
                    node {
                        id
                        file
                    }
                }
            }
            productTag {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`

//all variations for a specific product (information for updating product variations)
export const PRODUCT_VARIATIONS_DETAILS = gql`
    query productVariationsDetails($id: ID!) {
        product(id: $id) {
            id
            title
            sellPrice
            sku
            productProductVariation {
                edges {
                    node {
                        id
                        name
                        sku
                        quantity
                        price
                        productVariationProductIdTypeProductVariation {
                            edges {
                                node {
                                    id
                                    productIdType {
                                        id
                                        name
                                    }
                                    value
                                }
                            }
                        }
                        visible
                        productVariationProductVariationVariationField {
                            edges {
                                node {
                                    id
                                    value
                                    variationField {
                                        id
                                        name
                                    }
                                    productVariationVariationFieldImage {
                                        edges {
                                            node {
                                                id
                                                image
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            productImage {
                edges {
                    node {
                        id
                        image
                    }
                }
            }
        }
    }
`

//all variations for a specific product
export const PRODUCT_VARIATIONS = gql`
    query productVariations($id: ID!) {
        product(id: $id) {
            id
            title
            sellPrice
            sku
            productProductVariation {
                edges {
                    node {
                        id
                        name
                        sku
                        quantity
                        price
                        productVariationProductIdTypeProductVariation {
                            edges {
                                node {
                                    id
                                    productIdType {
                                        id
                                        name
                                    }
                                    value
                                }
                            }
                        }
                        visible
                        productVariationProductVariationVariationField {
                            edges {
                                node {
                                    id
                                    value
                                    variationField {
                                        id
                                        name
                                    }
                                    productVariationVariationFieldImage {
                                        edges {
                                            node {
                                                id
                                                image
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

//all images for a specific product
export const PRODUCT_IMAGES = gql`
    query productImages($id: ID!) {
        product(id: $id) {
            id
            productImage {
                edges {
                    node {
                        id
                        image
                    }
                }
            }
        }
    }
`

//add new product
export const CREATE_PRODUCT = gql`
    mutation createProduct(
        $seller: ID!
        $title: String!
        $description: String!
        $quantity: Int!
        $buyPrice: Decimal
        $sellPrice: Decimal!
        $weight: Decimal
        $weightUnit: ID
        $dimensionL: Decimal
        $dimensionW: Decimal
        $dimensionH: Decimal
        $dimensionUnit: ID
        $sku: String
        $category: ID
        $condition: ID
        $color: String
        $brand: String
        $manufacturer: String
        $isPhysical: Boolean!
        $productMaterialsAdd: [CreateProductInputAddProductProductmaterials]
        $productProductIdTypeProductAdd: [CreateProductInputAddProductProductproductidtypeproduct]
        $productFeatureAdd: [CreateProductInputAddProductProductfeature]
        $productImageAdd: [CreateProductInputAddProductProductimage]
        $productFileAdd: [CreateProductInputAddProductProductfile]
        $productTagAdd: [CreateProductInputAddProductProducttag]
    ) {
        createProduct(
            input: {
                seller: $seller
                title: $title
                description: $description
                quantity: $quantity
                buyPrice: $buyPrice
                sellPrice: $sellPrice
                weight: $weight
                weightUnit: $weightUnit
                dimensionL: $dimensionL
                dimensionW: $dimensionW
                dimensionH: $dimensionH
                dimensionUnit: $dimensionUnit
                sku: $sku
                category: $category
                condition: $condition
                color: $color
                brand: $brand
                manufacturer: $manufacturer
                isPhysical: $isPhysical
                productMaterialsAdd: $productMaterialsAdd
                productProductIdTypeProductAdd: $productProductIdTypeProductAdd
                productFeatureAdd: $productFeatureAdd
                productImageAdd: $productImageAdd
                productFileAdd: $productFileAdd
                productTagAdd: $productTagAdd
            }
        ) {
            product {
                id
                title
            }
        }
    }
`

//update product
export const UPDATE_PRODUCT = gql`
    mutation updateProduct(
        $id: ID!
        $forceValue: Int
        $seller: ID!
        $title: String!
        $description: String!
        $quantity: Int!
        $buyPrice: Decimal
        $sellPrice: Decimal!
        $weight: Decimal
        $weightUnit: ID
        $dimensionL: Decimal
        $dimensionW: Decimal
        $dimensionH: Decimal
        $dimensionUnit: ID
        $sku: String!
        $category: ID
        $condition: ID
        $color: String
        $brand: String
        $manufacturer: String
        $isPhysical: Boolean!
        $productMaterialsAdd: [UpdateProductInputAddProductProductmaterials]
        $productProductIdTypeProductAdd: [UpdateProductInputExactProductProductproductidtypeproduct]
        $productFeatureAdd: [UpdateProductInputExactProductProductfeature]
        $productImageAdd: [UpdateProductInputAddProductProductimage]
        $productImageRemove: [ID]
        $productFileAdd: [UpdateProductInputAddProductProductfile]
        $productFileRemove: [ID]
        $productTagAdd: [UpdateProductInputExactProductProducttag]
    ) {
        updateProduct(
            id: $id
            input: {
                forceValue: $forceValue
                seller: $seller
                title: $title
                description: $description
                quantity: $quantity
                buyPrice: $buyPrice
                sellPrice: $sellPrice
                weight: $weight
                weightUnit: $weightUnit
                dimensionL: $dimensionL
                dimensionW: $dimensionW
                dimensionH: $dimensionH
                dimensionUnit: $dimensionUnit
                sku: $sku
                category: $category
                condition: $condition
                color: $color
                brand: $brand
                manufacturer: $manufacturer
                isPhysical: $isPhysical
                productMaterialsAdd: $productMaterialsAdd
                productProductIdTypeProduct: $productProductIdTypeProductAdd
                productFeature: $productFeatureAdd
                productImageAdd: $productImageAdd
                productImageRemove: $productImageRemove
                productFileAdd: $productFileAdd
                productFileRemove: $productFileRemove
                productTag: $productTagAdd
            }
        ) {
            product {
                id
                title
            }
            apisResponse {
                marketplace
                type
                messages
            }
            forceOptions {
                value
                description
            }
        }
    }
`

//delete product
export const DELETE_PRODUCT = gql`
    mutation deleteProduct($id: ID!, $forceValue: Int, $option: Int) {
        deleteProduct(id: $id, forceValue: $forceValue, option: $option) {
            found
            deletedId
            apisResponse {
                marketplace
                type
                messages
            }
            forceOptions {
                value
                description
            }
        }
    }
`

//id types list from product and variations
export const PRODUCT_USABLE_IDS = gql`
    query productUsableProductId($productId: ID!) {
        productUsableProductId(productId: $productId) {
            source
            usableProductIdItems {
                id
                name
                value
            }
        }
    }
`

//product/variations id types
export const PRODUCT_ID_TYPE_VALUES = gql`
    query product($id: ID!) {
        product(id: $id) {
            id
            productProductIdTypeProduct {
                edges {
                    node {
                        id
                        productIdType {
                            id
                            name
                        }
                        value
                    }
                }
            }
            productProductVariation {
                edges {
                    node {
                        id
                        name
                        sku
                        quantity
                        price
                        productVariationProductIdTypeProductVariation {
                            edges {
                                node {
                                    id
                                    productIdType {
                                        id
                                        name
                                    }
                                    value
                                }
                            }
                        }
                        visible
                        productVariationProductVariationVariationField {
                            edges {
                                node {
                                    id
                                    value
                                    variationField {
                                        id
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

//create ProductIdTypeProduct (batch)
export const CREATE_PRODUCT_ID_TYPE_FOR_PRODUCT = gql`
    mutation createProductIdTypeProductBatch($productIdType: ID!, $product: ID!, $value: String!) {
        createProductIdTypeProduct(input: { productIdType: $productIdType, product: $product, value: $value }) {
            productIdTypeProduct {
                id
            }
        }
    }
`

//delete ProductIdTypeProduct (batch)
export const DELETE_PRODUCT_ID_TYPE_FOR_PRODUCT = gql`
    mutation deleteProductIdTypeProductBatch($id: ID!) {
        deleteProductIdTypeProduct(id: $id) {
            found
            deletedId
        }
    }
`
