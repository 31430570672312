export const showForceOptions = () => {
    return {
        type: "SHOW_FORCE_OPTIONS"
    }
}

export const closeForceOptions = () => {
    return {
        type: "CLOSE_FORCE_OPTIONS"
    }
}
