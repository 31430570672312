const productFiltersStyle = (theme) => ({
    container: {
        margin: "10px",
        marginBottom: "15px",
    },
    firstFieldContainer: {
        margin: "0 0 7 0",
        padding: "0 15px"
    },
    fieldContainer: {
        margin: "7px 0",
        padding: "0 15px"
    },
    fieldLabel: {
        margin: "7px"
    },
    footer: {
        padding: "10px",
        justifyContent: "flex-end",
        alignItems: "center",
        display: "flex"
    },
});

export default productFiltersStyle;
