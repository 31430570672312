import React from "react"
import { Grid } from "@material-ui/core"
import Tipography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/views/LandingPageStyle"

//reveal effects
import { Zoom } from "react-awesome-reveal"

//parallax
import { Background, Parallax } from "react-parallax"

const useStyles = makeStyles(styles)

export default function MarketplacesAbout() {
    const classes = useStyles()

    return (
        <Parallax strength={500}>
            <Background>
                <div className={classes.background5} />
            </Background>
            <Grid container className={classes.colContainerBlocksSlide} alignItems={"center"}>
                <Zoom duration={1500} triggerOnce>
                    <Grid container item justifyContent={"center"} spacing={2}>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Tipography variant="subtitle2" display="inline">
                                Etsy is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
                            </Tipography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Tipography variant="subtitle2" display="inline">
                                eBay is a trademark of eBay, Inc. This application uses the eBay API but is not endorsed or certified by eBay, Inc.
                            </Tipography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Tipography variant="subtitle2" display="inline">
                                Amazon is a trademark of Amazon, Inc. This application uses the Amazon API but is not endorsed or certified by Amazon, Inc.
                            </Tipography>
                        </Grid>
                    </Grid>
                </Zoom>
            </Grid>
        </Parallax>
    )
}
