import React from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { Grid } from "@material-ui/core"
import MainButtonMessages from "./MainButtonMessages"
import MainButtonAdd from "./MainButtonAdd"
import MainButtonInventory from "./MainButtonInventory"
import MainButtonOrders from "./MainButtonOrders"
import MainButtonConnections from "./MainButtonConnections"
import MainButtonNotifications from "./MainButtonNotifications"

export default function MainButtons() {
    const [buttonsColumn1, setButtonsColumn1] = React.useState([
        { component: <MainButtonAdd />, key: "MainButtonAdd" },
        { component: <MainButtonInventory />, key: "MainButtonInventory" },
        { component: <MainButtonOrders />, key: "MainButtonOrders" },
    ])

    const [buttonsColumn2, setButtonsColumn2] = React.useState([
        { component: <MainButtonConnections />, key: "MainButtonConnections" },
        { component: <MainButtonMessages />, key: "MainButtonMessages" },
        { component: <MainButtonNotifications />, key: "MainButtonNotifications" },
    ])

    // Function to update list on drop
    function onDragEnd(result) {
        const { source, destination } = result

        // dropped outside the list
        if (!destination) {
            return
        }

        if (source.droppableId === destination.droppableId) {
            let updatedList = source.droppableId === "droppable1" ? [...buttonsColumn1] : [...buttonsColumn2]

            const [reorderedItem] = updatedList.splice(source.index, 1)

            updatedList.splice(destination.index, 0, reorderedItem)

            source.droppableId === "droppable1" ? setButtonsColumn1(updatedList) : setButtonsColumn2(updatedList)
        } else {
            let newSourceList = source.droppableId === "droppable1" ? [...buttonsColumn1] : [...buttonsColumn2]
            const [reorderedSourceItem] = newSourceList.splice(source.index, 1)


            let newDestinationList =
                destination.droppableId === "droppable1" ? [...buttonsColumn1] : [...buttonsColumn2]
            const [reorderedDestinationItem] = newDestinationList.splice(destination.index, 1)

            newSourceList.splice(destination.index, 0, reorderedDestinationItem)
            newDestinationList.splice(source.index, 0, reorderedSourceItem)

            setButtonsColumn1(newSourceList)
            setButtonsColumn2(newDestinationList)
        }
    }

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightgray" : "",
    })

    const getItemStyle = (isDragging, draggableStyle) => ({
        // change background colour if dragging
        boxShadow: isDragging ? "5px 3px 5px black" : "",

        // styles we need to apply on draggables
        ...draggableStyle,
    })

    return (
        <Grid container spacing={1}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable1" direction={"vertical"}>
                    {(provided, snapshot) => (
                        <Grid
                            item
                            container
                            xs={6}
                            spacing={3}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {buttonsColumn1.map((item, index) => (
                                <Draggable key={item.key} draggableId={item.key} index={index}>
                                    {(provided, snapshot) => (
                                        <Grid item container justifyContent={"center"} key={item.key}>
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                {item.component}
                                            </div>
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>

                <Droppable droppableId="droppable2" direction={"vertical"}>
                    {(provided, snapshot) => (
                        <Grid
                            item
                            container
                            xs={6}
                            spacing={3}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {buttonsColumn2.map((item, index) => (
                                <Draggable key={item.key} draggableId={item.key} index={index}>
                                    {(provided, snapshot) => (
                                        <Grid item container justifyContent={"center"}
                                            key={item.key}
                                        >
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                            >
                                                {item.component}
                                            </div>
                                        </Grid>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        </Grid>
    )
}
