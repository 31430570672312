import { gql } from "@apollo/client"

//add facebook fields
export const CREATE_PRODUCT_FACEBOOK_FIELDS = gql`
    mutation createProductFacebookField(
        $productMarketplace: CreateProductSellerMarketplaceInput!
        $title: String!
        $availability: String!
        $condition: String!
        $fbProductCategory: String
        $categoryName: String!
        $fullPathCategoryIds: String!
        $size: String
        $salePrice: String
        $salePriceEffectiveDate: String
        $status: String
        $image: ID
        $additionalImageLink: String
        $gender: String
        $ageGroup: String
        $pattern: String
        $shipping: String
        $shippingWeight: String
        $businessesId: String
        $catalogId: String
        $productFacebookFieldProductFacebookCategoryField: [CreateProductFacebookFieldInputAddProductFacebookFieldProductfacebookfieldproductfacebookcategoryfield]
    ) {
        createProductFacebookField(
            input: {
                productSellerMarketplace: $productMarketplace
                title: $title
                availability: $availability
                condition: $condition
                fbProductCategory: $fbProductCategory
                categoryName: $categoryName
                fullPathCategoryIds: $fullPathCategoryIds
                size: $size
                salePrice: $salePrice
                salePriceEffectiveDate: $salePriceEffectiveDate
                status: $status
                image: $image
                additionalImageLink: $additionalImageLink
                gender: $gender
                ageGroup: $ageGroup
                pattern: $pattern
                shipping: $shipping
                shippingWeight: $shippingWeight
                businessesId: $businessesId
                catalogId: $catalogId
                productFacebookFieldProductFacebookCategoryFieldAdd: $productFacebookFieldProductFacebookCategoryField
            }
        ) {
            productFacebookField {
                id
            }
        }
    }
`

//update facebook fields
export const UPDATE_PRODUCT_FACEBOOK_FIELDS = gql`
    mutation updateProductFacebookField(
        $id: ID!
        $productMarketplace: ID!
        $title: String!
        $availability: String!
        $condition: String!
        $fbProductCategory: String
        $categoryName: String!
        $fullPathCategoryIds: String!
        $size: String
        $salePrice: String
        $salePriceEffectiveDate: String
        $status: String
        $image: ID
        $additionalImageLink: String
        $gender: String
        $ageGroup: String
        $pattern: String
        $shipping: String
        $shippingWeight: String
        $businessesId: String
        $catalogId: String
        $productFacebookFieldProductFacebookCategoryField: [UpdateProductFacebookFieldInputAddProductFacebookFieldProductfacebookfieldproductfacebookcategoryfield]
    ) {
        updateProductFacebookField(
            id: $id
            input: {
                productSellerMarketplace: $productMarketplace
                title: $title
                availability: $availability
                condition: $condition
                fbProductCategory: $fbProductCategory
                categoryName: $categoryName
                fullPathCategoryIds: $fullPathCategoryIds
                size: $size
                salePrice: $salePrice
                salePriceEffectiveDate: $salePriceEffectiveDate
                status: $status
                image: $image
                additionalImageLink: $additionalImageLink
                gender: $gender
                ageGroup: $ageGroup
                pattern: $pattern
                shipping: $shipping
                shippingWeight: $shippingWeight
                businessesId: $businessesId
                catalogId: $catalogId
                productFacebookFieldProductFacebookCategoryFieldAdd: $productFacebookFieldProductFacebookCategoryField
            }
        ) {
            productFacebookField {
                id
            }
            apisResponse {
                marketplace
                type
                messages
            }
        }
    }
`

//upload/remove facebook fields
export const UPLOAD_FACEBOOK_FIELDS = gql`
    mutation patchProductFacebookFieldUpload($id: ID!, $isUploaded: Boolean) {
        patchProductFacebookField(id: $id, input: { isUploaded: $isUploaded }) {
            productFacebookField {
                id
            }
            apisResponse {
                marketplace
                type
                messages
            }
        }
    }
`

//publish/pause facebook fields
export const PUBLISH_FACEBOOK_FIELDS = gql`
    mutation patchProductFacebookFieldPublish($id: ID!, $isPublished: Boolean) {
        patchProductFacebookField(id: $id, input: { isPublished: $isPublished }) {
            productFacebookField {
                id
            }
            apisResponse {
                marketplace
                type
                messages
            }
        }
    }
`

//taxonomy children
export const LOAD_FACEBOOK_TAXONOMIES_CHILDREN = gql`
    query facebookGetTaxonomyNodeChildrens($taxonomyId: Int) {
        facebookGetTaxonomyNodeChildrens(taxonomyId: $taxonomyId)
    }
`

//facebook availability options
export const LOAD_FACEBOOK_AVAILABILITY = gql`
    query facebookGetEnumAvailability {
        facebookGetEnumAvailability
    }
`

//facebook condition options
export const LOAD_FACEBOOK_CONDITION = gql`
    query facebookGetEnumCondition {
        facebookGetEnumCondition
    }
`

//facebook status options
export const LOAD_FACEBOOK_STATUS = gql`
    query facebookGetEnumStatus {
        facebookGetEnumStatus
    }
`

//facebook gender options
export const LOAD_FACEBOOK_GENDER = gql`
    query facebookGetEnumGender {
        facebookGetEnumGender
    }
`

//facebook gender options
export const LOAD_FACEBOOK_AGE_GROUP = gql`
    query facebookGetEnumAgeGroup {
        facebookGetEnumAgeGroup
    }
`

//facebook shipping country options
export const LOAD_FACEBOOK_SHIPPING_COUNTRY = gql`
    query facebookGetEnumShippingCountry {
        facebookGetEnumShippingCountry
    }
`

//facebook shipping state options
export const LOAD_FACEBOOK_SHIPPING_STATE = gql`
    query facebookGetEnumShippingState {
        facebookGetEnumShippingState
    }
`

//facebook shipping service options
export const LOAD_FACEBOOK_SHIPPING_SERVICE = gql`
    query facebookGetEnumShippingService {
        facebookGetEnumShippingService
    }
`

//facebook shipping weight units
export const LOAD_FACEBOOK_SHIPPING_WEIGHT_UNITS = gql`
    query facebookGetEnumShippingWeightUnits {
        facebookGetEnumShippingWeightUnits
    }
`

//facebook shipping concurrency options
export const LOAD_FACEBOOK_SHIPPING_CONCURRENCY = gql`
    query facebookGetEnumShippingConcurrency {
        facebookGetEnumShippingConcurrency
    }
`

//facebook my businesses
export const LOAD_FACEBOOK_MY_BUSINESSES = gql`
    query facebookGetMyBusinesses {
        facebookGetMyBusinesses {
            url
            statusCode
            reason
            content
        }
    }
`

//facebook my catalogs
export const LOAD_FACEBOOK_MY_CATALOGS = gql`
    query facebookGetMyCatalogs($businessesId: String!) {
        facebookGetMyCatalogs(businessesId: $businessesId) {
            url
            statusCode
            reason
            content
        }
    }
`

//publish/pause facebook fields
export const FACEBOOK_NOTIFICATIONS_ACTIVATION = gql`
    mutation facebookNotificationsEndpointGestion($option: Int!, $productFacebookFieldId: ID!) {
        facebookNotificationsEndpointGestion(option: $option, productFacebookFieldId: $productFacebookFieldId) {
            apisResponseItem {
                marketplace
                type
                messages
            }
        }
    }
`
