import { whiteColor } from "assets/jss/material-dashboard-react.js"
import cardInfoStyle from "assets/jss/material-dashboard-react/views/cardInfoStyle"

const newAccountStyle = (theme) => ({
    ...cardInfoStyle,
    formContainer: {
        backgroundColor: whiteColor,
        height: "auto",
        [theme.breakpoints.up("lg")]: {
            width: "30%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "45%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "75%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "90%",
        },
    },
    logo: {
        width: "100%",
        maxHeight: "190px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    img: {
        width: "auto",
        maxHeight: "190px",
        marginBottom: "10px",
    },
    inputFields: {
        marginBottom: "10px",
        marginTop: "10px",
    },
    actionButtonsContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "10px",
        padding: "0 10px",
    },
    actionButton: {
        marginLeft: "10px",
        minWidth: "87px",
    },
    xmActions: {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end",
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
    },

    chip: {
        margin: 2,
    },
})

export default newAccountStyle
