/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import {NavLink, useHistory} from "react-router-dom";

// @material-ui
import {makeStyles} from "@material-ui/core/styles";
import {
    Drawer,
    Hidden,
    List,
    ListItem,
    ListItemText,
    Icon,
    Grid,
    Avatar,
    Typography
} from "@material-ui/core";

//assets
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";

//redux
import { useDispatch, useSelector } from "react-redux"
import { singOut } from "actions/auth/loginActions"

//apollo
import { AUTH_USER_VALID_ID } from "querys/auth/loginQueries";
import { useApolloClient, useQuery } from "@apollo/client"

//lazy load images
import {LazyLoadComponent} from "react-lazy-load-image-component";

//notifications
import {ErrorNotification} from "components/Notifications/Notifications";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const client = useApolloClient()

    const history = useHistory()

    const {color, routes} = props;

    const authUser = useSelector(state => state.loginReducer)

    const openProfile = () => {
        history.push("/seller/profile")
        props.handleDrawerToggle()
    }

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1;
    }

    //known if authenticated user is admin. a user is admin if isStaff = true or isSuperuser = true or belongs to a group named "admin"
    function isAdminUser(user) {
        if (user.isStaff || user.isSuperuser) {
            return true
        }
        return user.groups.findIndex(group => group.name.toUpperCase() === "ADMIN") > -1
    }

    const handleLogOut = () => {
        window.open("/", "_self")
        dispatch(singOut())
        client.clearStore()
    }

    let links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                if (prop.menu) {
                    //admin users can view all routes. non admin users (seller) can view just seller routes
                    if (prop.layout === "/seller" || (isAdminUser(authUser) && prop.layout === "/admin")) {
                        let listItemClasses;
                        listItemClasses = classNames({
                            [" " + classes[color]]: activeRoute("/seller" + prop.path)
                        });
                        const whiteFontClasses = classNames({
                            [" " + classes.whiteFont]: activeRoute("/seller" + prop.path)
                        });
                        return (
                            <NavLink
                                to={"/seller" + prop.path}
                                className={classes.item}
                                activeClassName="active"
                                key={key}
                            >
                                <ListItem
                                    button
                                    className={classes.itemLink + listItemClasses}
                                    onClick={props.handleDrawerToggle}
                                >
                                    {/*{typeof prop.icon === "string" ? (
                                        <Icon
                                            className={classNames(classes.itemIcon, whiteFontClasses)}
                                        >
                                            {prop.icon}
                                        </Icon>
                                    ) : (
                                        <prop.icon
                                            className={classNames(classes.itemIcon, whiteFontClasses)}
                                        />
                                    )}*/}
                                    <ListItemText
                                        primary={prop.name}
                                        className={classNames(classes.itemText, whiteFontClasses)}
                                        disableTypography={true}
                                    />
                                </ListItem>
                            </NavLink>
                        )
                    }
                }
            })}
            <ListItem
                button
                className={classes.itemLink}
                onClick={handleLogOut}
            >
                <ListItemText
                    primary={"Logout"}
                    className={classNames(classes.itemText)}
                    disableTypography={true}
                />
            </ListItem>
        </List>
    )

    const Profile = () => {
        //authenticated user data
        const {data: authUserData, error: errorAuthUser} = useQuery(AUTH_USER_VALID_ID)

        const notifyError = message => ErrorNotification("User", message)

        if (errorAuthUser) {
            notifyError("Unable to load authenticated user data, try again")
        }

        let user = authUserData?.me2 ?? {}

        const composeUrl = (user) => {
            return user.image
                ? `${process.env.REACT_APP_API_URL}file/${user.id}`
                : ""
        }

        return (
            <Grid container spacing={1} onClick={openProfile} style={{cursor: "pointer"}}>
                <Grid container item justifyContent={"center"}>
                    <LazyLoadComponent>
                        <Avatar alt="image" src={composeUrl(user)} className={classes.profileAvatar} />
                    </LazyLoadComponent>
                </Grid>
                <Grid container item justifyContent={"center"}>
                    <Typography variant={"body1"} className={classes.profileUsername}>
                        {user?.username ?? "-"}
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <div>
            <Drawer
                variant="temporary"
                anchor={"left"}
                open={props.open}
                classes={{
                    paper: classNames(classes.drawerPaper)
                }}
                onClose={props.handleDrawerToggle}
                closeAfterTransition
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
            >
                <div className={classes.profileContainer}>
                    <Profile/>
                </div>
                <div className={classes.sidebarWrapper}>
                    {links}
                </div>
            </Drawer>
        </div>
    );
}

Sidebar.propTypes = {
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool
};
