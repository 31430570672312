import React from "react"
import {
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TableRow,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Tooltip,
    CircularProgress,
    Dialog,
    Button,
    Avatar,
    useTheme,
    useMediaQuery,
    Checkbox,
    Chip,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import { DeleteOutlined } from "@material-ui/icons"
import AddIcon from "mdi-react/AddIcon"
import DeleteOffOutlineIcon from "mdi-react/DeleteOffOutlineIcon"
import LoginIcon from "mdi-react/LoginIcon"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/tableStyle"
import { dangerColor, grayColor, successColor } from "assets/jss/material-dashboard-react"

import { useHistory } from "react-router-dom"

//apollo
import { Query, Mutation } from "@apollo/client/react/components"
import { useQuery } from "@apollo/client"
import { CHECK_API_WORKING } from "querys/seller/Product sell/listingQueries"
import { DELETE_SELLER_MARKETPLACE } from "querys/seller/Marketplace/sellerMarketplaceQueries"
import { LOAD_ALL_UNLINKED_MARKETPLACES } from "querys/seller/Marketplace/marketplaceQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//table utils
import { LoadingSkeleton, TableNoData, ConfirmTransition } from "utils/tableUtils"

//assets
import AmazonImg from "assets/img/marketplaces/Amazon-icon.svg"
import EtsyImg from "assets/img/marketplaces/Etsy_icon.svg"
import EbayImg from "assets/img/marketplaces/Ebay_icon.svg"
import FacebookImg from "assets/img/marketplaces/Facebook_icon.svg"

//connection component
import AmazonConnection from "components/Seller/Apis connection/AmazonConnection"
import EtsyConnection from "components/Seller/Apis connection/EtsyConnection"
import EbayConnection from "components/Seller/Apis connection/EbayConnection"

const useStyles = makeStyles(styles)

const notifyError = (message) => ErrorNotification("Marketplace", message)
const notifySuccess = (message) => SuccessNotification("Marketplace", message)

function EnhancedTableHead(props) {
    const { classes } = props

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

    //not to permit sort by columns of equal values. server sorting does not work properly
    const cells = [
        { id: "image", label: "", align: "left" },
        { id: "alias", label: "Name", align: "left" },
        { id: "fullName", label: "Platform", align: "left" },
        { id: "description", label: "Description" },
        { id: "isEnabled", label: "Status", align: "left" },
    ]

    const headCells = () => {
        if (mobileScreen) {
            cells.unshift({ id: "select", label: "", align: "left", sort: false })
        }
        return cells
    }

    return (
        <TableHead>
            <TableRow>
                {headCells().map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        className={classes.head}
                        align={headCell.align ? headCell.align : "center"}
                    >
                        <span>{headCell.label}</span>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const EnhancedTableToolbar = (props) => {
    const classes = useStyles()

    const history = useHistory()

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const { loading, selected, deleteItem } = props

    const openForm = () => {
        history.push("/seller/connection/form")
    }

    return (
        <Toolbar className={classes.header}>
            <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                Connected Marketplaces
            </Typography>

            <div>
                {loading ? (
                    <CircularProgress size={24} />
                ) : (
                    <>
                        <Tooltip title="Connect marketplace">
                            <IconButton aria-label="add marketplace" color={"inherit"} onClick={openForm}>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>

                        {mobileScreen && selected && (
                            <>
                                {selected.sellerMarketplace.isBlocked ? (
                                    <Tooltip
                                        title="This product is blocked to perform the 'delete' action"
                                        classes={{ tooltip: classes.infoTooltip }}
                                    >
                                        <IconButton aria-label="delete marketplace" color={"inherit"}>
                                            <DeleteOffOutlineIcon size={20} />
                                        </IconButton>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title="Delete marketplace">
                                        <IconButton
                                            aria-label="delete marketplace"
                                            color={"inherit"}
                                            onClick={() => deleteItem(selected)}
                                        >
                                            <DeleteOutlined fontSize={"small"} />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </Toolbar>
    )
}

export default function EnhancedTable() {
    const classes = useStyles()

    const { data: allMarketplacesLoginData } = useQuery(LOAD_ALL_UNLINKED_MARKETPLACES, {
        onError: () => {
            notifyError("Unable to load existing marketplaces login data, try again")
        },
        fetchPolicy: "network-only",
    })

    const deleteItem = (item) => {
        setDeleteItemIndex(item.sellerMarketplace.id)
        setOpenDialog(true)
        setSelected(null)
    }

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

    //table selected item (for small screens)
    const [selected, setSelected] = React.useState(null)
    const handleSelectItem = (item) => {
        if (selected && selected.node.id === item.node.id) {
            setSelected(null)
        } else {
            setSelected(item)
        }
    }

    //allow popUp integration windows dialog
    const [openAllowWindowsDialog, setOpenAllowWindowsDialog] = React.useState(false)

    //open dialog only once
    const [openedAllowWindowsDialog, setOpenedAllowWindowsDialog] = React.useState(false)

    const AllowIntegrationWindows = () => {
        const handleCloseDialog = () => {
            setOpenAllowWindowsDialog(false)
        }

        return (
            <Dialog
                open={openAllowWindowsDialog}
                onClose={handleCloseDialog}
                TransitionComponent={ConfirmTransition}
                aria-labelledby="allow-integration-windows"
                aria-describedby="allow-integration-windows"
            >
                <DialogTitle id="alert-dialog-title">{"Allow windows needed"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Connection window may be blocked by your browser. If this happened you must authorize in your
                        browser to execute it. Authorize to execute "Emergent windows, Emergent items or PopUp windows",
                        depending of your browser and retry this action again. If your browser do not permit to execute
                        the windows, connection process will be not possible.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    //amazon login url
    const [amazonUrlLogin, setAmazonUrlLogin] = React.useState(null)
    function openConnectionToAmazon(url) {
        setAmazonUrlLogin("")
        setAmazonUrlLogin(url)
    }

    //ebay login url
    const [ebayUrlLogin, setEbayUrlLogin] = React.useState(null)
    function openConnectionToEbay(url) {
        setEbayUrlLogin("")
        setEbayUrlLogin(url)
    }

    //etsy login url
    const [etsyUrlLogin, setEtsyUrlLogin] = React.useState(null)
    function openConnectionToEtsy(url) {
        setEtsyUrlLogin("")
        setEtsyUrlLogin(url)
    }

    function handleMarketplaceRelogin(row) {
        if (allMarketplacesLoginData.allMarketplacesLoginUrls.length) {
            const index = allMarketplacesLoginData.allMarketplacesLoginUrls.findIndex(
                (item) => item.marketplace.id === row.sellerMarketplace.marketplace.id
            )

            if (index > -1) {
                const selectedMarketplace = allMarketplacesLoginData.allMarketplacesLoginUrls[index]

                const url = selectedMarketplace.url
                const urlParts = url.split("?")

                const params = new URLSearchParams(urlParts[1])
                const newState = `reconnect_${row.sellerMarketplace.alias}_${params.get("state")}` //new structure here: reconnect_alias_marketpalceId_anythinghere
                params.set("state", newState)

                const newUrl = urlParts[0] + params.toString()

                if (openedAllowWindowsDialog) {
                    if (selectedMarketplace.marketplace.name.toUpperCase() === "ETSY") {
                        openConnectionToEtsy(newUrl)
                    } else if (selectedMarketplace.marketplace.name.toUpperCase() === "EBAY") {
                        openConnectionToEbay(newUrl)
                    } else if (selectedMarketplace.marketplace.name.toUpperCase() === "AMAZON") {
                        openConnectionToAmazon(newUrl)
                    } else if (selectedMarketplace.marketplace.name.toUpperCase() === "FACEBOOK") {
                        console.log("connect facebook marketplace")
                    } else {
                        console.log("this marketplace is not available, yet")
                    }
                } else {
                    setOpenedAllowWindowsDialog(true)
                    setOpenAllowWindowsDialog(true)
                }
            }
        }
    }

    const TableRows = (props) => {
        const { row, index } = props

        function getMarketplaceDefaultImage(marketplace) {
            switch (marketplace.toUpperCase()) {
                case "AMAZON":
                    return AmazonImg
                case "ETSY":
                    return EtsyImg
                case "EBAY":
                    return EbayImg
                case "FACEBOOK":
                    return FacebookImg
                default:
                    return ""
            }
        }

        const composeUrl = (row) => {
            return row.sellerMarketplace.marketplace.image
                ? `${process.env.REACT_APP_API_URL}file/${row.sellerMarketplace.marketplace.id}`
                : getMarketplaceDefaultImage(row.sellerMarketplace.marketplace.name)
        }

        return (
            <TableRow hover tabIndex={-1} key={`row-${index}`} className={classes.row}>
                {mobileScreen && (
                    <TableCell align="left">
                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            checked={selected && row.sellerMarketplace.id === selected.sellerMarketplace.id}
                            onClick={() => handleSelectItem(row)}
                        />
                    </TableCell>
                )}
                <TableCell align="right" style={{ padding: "7px 15px" }}>
                    <Avatar
                        variant={"rounded"}
                        alt={row.sellerMarketplace.marketplace.name}
                        src={composeUrl(row)}
                        className={classes.avatar}
                    />
                </TableCell>
                <TableCell align="left">{row.sellerMarketplace.alias}</TableCell>
                <TableCell align="left">{row.sellerMarketplace.marketplace.fullName}</TableCell>
                <TableCell align="center">{row.sellerMarketplace.marketplace.description}</TableCell>
                <TableCell align="left">
                    {row.sellerMarketplace.marketplace.isEnabled ? (
                        <>
                            {row.apiWorking.code === "200" ? (
                                <Chip
                                    variant="outlined"
                                    size={"small"}
                                    style={{
                                        color: successColor[0],
                                        borderColor: successColor[0],
                                    }}
                                    label={"Connected"}
                                />
                            ) : (
                                <>
                                    {row.apiWorking.code === "401" ||
                                    (row.apiWorking.code === "400" &&
                                        row.sellerMarketplace.marketplace.name.toUpperCase() === "ETSY") ? (
                                        <Tooltip
                                            title={`Error returned by platform '${row.apiWorking.message}'`}
                                            classes={{ tooltip: classes.infoTooltip }}
                                        >
                                            <Chip
                                                variant="outlined"
                                                size={"small"}
                                                style={{
                                                    color: dangerColor[0],
                                                    borderColor: dangerColor[0],
                                                }}
                                                label={
                                                    <>
                                                        Reconnect needed
                                                        <IconButton
                                                            color={"primary"}
                                                            size={"small"}
                                                            style={{ marginLeft: "5px" }}
                                                        >
                                                            <LoginIcon size={18} />
                                                        </IconButton>
                                                    </>
                                                }
                                                onClick={() => handleMarketplaceRelogin(row)}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title={`Connection error '${row.apiWorking.message}'`}
                                            classes={{ tooltip: classes.infoTooltip }}
                                        >
                                            <Chip
                                                variant="outlined"
                                                size={"small"}
                                                style={{
                                                    color: dangerColor[0],
                                                    borderColor: dangerColor[0],
                                                }}
                                                label={"Connection error"}
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <Chip
                            variant="outlined"
                            size={"small"}
                            style={{ color: grayColor[0], borderColor: grayColor[0] }}
                            label={"Disabled temporally"}
                        />
                    )}

                    <div className={classes.actionColumn}>
                        {row.sellerMarketplace.marketplace.isEnabled === false ? (
                            <Tooltip
                                title="This product is disabled to perform the 'delete' action"
                                classes={{ tooltip: classes.infoTooltip }}
                            >
                                <IconButton aria-label="delete marketplace" className={classes.deleteBtn}>
                                    <DeleteOffOutlineIcon size={20} />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Delete marketplace">
                                <IconButton
                                    aria-label="delete marketplace"
                                    className={classes.deleteBtn}
                                    onClick={() => deleteItem(row)}
                                >
                                    <DeleteOutlined fontSize={"small"} />
                                </IconButton>
                            </Tooltip>
                        )}
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    const [openDialog, setOpenDialog] = React.useState(false)
    const [deleteItemIndex, setDeleteItemIndex] = React.useState(null)

    function ConfirmDialog(props) {
        const { register, refetch, loading } = props

        const handleClose = () => {
            setOpenDialog(false)
        }

        const deleteItem = () =>
            register({
                variables: {
                    id: deleteItemIndex,
                },
            }).then(
                (response) => {
                    if (response.data.deleteSellerMarketplace.found) {
                        notifySuccess("Marketplace deleted")
                        refetch()
                    }
                    if (!response.data.deleteSellerMarketplace.found) {
                        notifyError("Delete marketplace failed, try again")
                    }
                    handleClose()
                },
                () => {
                    notifyError("Delete marketplace failed, try again")
                    handleClose()
                }
            )

        return (
            <div>
                <Dialog
                    open={openDialog}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle disableTypography>
                        <Typography variant="h6">Delete marketplace</Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        <Typography gutterBottom>Do you confirm to delete the marketplace?</Typography>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Button onClick={deleteItem} color="primary">
                                Confirm
                            </Button>
                        )}
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }

    return (
        <>
            <AmazonConnection url={amazonUrlLogin} />
            <EtsyConnection url={etsyUrlLogin} />
            <EbayConnection url={ebayUrlLogin} />
            <AllowIntegrationWindows />

            <Query query={CHECK_API_WORKING}>
                {({ loading, error, data, refetch }) => {
                    if (error) {
                        notifyError("Load data failed")
                    }

                    let rows = data?.allSellerMarketplacesApiWorking ?? []
                    return (
                        <Mutation mutation={DELETE_SELLER_MARKETPLACE}>
                            {(register, { loading: loadingDelete }) => {
                                return (
                                    <div className={classes.root}>
                                        <ConfirmDialog register={register} refetch={refetch} loading={loadingDelete} />
                                        <Paper className={classes.paper}>
                                            <EnhancedTableToolbar
                                                loading={loading || loadingDelete}
                                                selected={selected}
                                                deleteItem={deleteItem}
                                            />
                                            {loading ? (
                                                <LoadingSkeleton />
                                            ) : !rows.length ? (
                                                <TableNoData />
                                            ) : (
                                                <TableContainer>
                                                    <Table
                                                        className={classes.table}
                                                        aria-labelledby="tableTitle"
                                                        aria-label="enhanced table"
                                                    >
                                                        <EnhancedTableHead classes={classes} rowCount={rows.length} />
                                                        <TableBody>
                                                            {rows.map((row, index) => {
                                                                return <TableRows row={row} index={index} key={index} />
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </Paper>
                                    </div>
                                )
                            }}
                        </Mutation>
                    )
                }}
            </Query>
        </>
    )
}
