export const addMarketplaceData = (payload) => {
    return {
        type: "ADD_MARKETPLACE",
        payload,
    }
}

export const updateConnectionIssuesQuantity = (payload) => {
    return {
        type: "UPDATE_CONNECTION_ISSUES_QUANTITY",
        payload,
    }
}

export const updateMarketplaceData = (payload) => {
    return {
        type: "UPDATE_MARKETPLACE",
        payload,
    }
}

export const deleteMarketplaceData = (payload) => {
    return {
        type: "DELETE_MARKETPLACE",
        payload,
    }
}
