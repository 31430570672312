import {
    dangerColor,
    warningColor,
    successColor,
    grayColor,
    whiteColor
} from "assets/jss/material-dashboard-react.js";

const apiResponseStyle = theme => ({
    alert: {
        margin: "1px 15px",
        borderRadius: 0
    },
    list: {
        margin: "5px 2px"
    },
    marketplaceLabel: {
        margin: "0 5px",
        padding: "3px",
        backgroundColor: grayColor[2],
        fontSize: "14px",
        color: whiteColor
    },
    errorLabel: {
        margin: "0 5px",
        padding: "3px",
        backgroundColor: dangerColor[0],
        fontSize: "14px",
        color: whiteColor
    },
    warningLabel: {
        margin: "0 5px",
        padding: "3px",
        backgroundColor: warningColor[0],
        fontSize: "14px",
        color: whiteColor
    },
    successLabel: {
        margin: "0 5px",
        padding: "3px",
        backgroundColor: successColor[0],
        fontSize: "14px",
        color: whiteColor
    },
    messageLabel: {
        margin: "0 5px",
        padding: "3px",
        backgroundColor: grayColor[0],
        fontSize: "14px",
        color: whiteColor
    },

    //form validation errors inside an alert
    formErrors: {
        width: "100%"
    }
});

export default apiResponseStyle;
