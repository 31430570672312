import loginReducer from "reducers/auth/loginReducer";
import apiResponseReducer from "reducers/globalComponents/apiResponseReducer";
import forceOptionsReducer from "reducers/globalComponents/forceOptionsReducer";
import marketplaceConnectionReducer from "reducers/globalComponents/marketplaceConnectionReducer";

import { combineReducers } from "redux"

export default combineReducers({
    loginReducer,
    apiResponseReducer,
    forceOptionsReducer,
    marketplaceConnectionReducer,
})
