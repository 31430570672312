import React from "react"
import { ErrorNotification } from "../../Notifications/Notifications"

export default function EbayConnection(props) {
    const { url } = props

    const notifyError = (message) => ErrorNotification("Ebay connection", message)

    React.useEffect(() => {
        const h = 0.75 * window.top.outerHeight
        const w = 0.75 * window.top.outerWidth
        const y = window.top.outerHeight / 2 + window.top.screenY - h / 2
        const x = window.top.outerWidth / 2 + window.top.screenX - w / 2

        const params = `
              scrollbars=yes,
              location=yes,
              width=${w}, 
              height=${h}, 
              top=${y}, 
              left=${x}
            `

        if (url) {
            const popUpWindows = window.open(url, "EbayAuthorization", params)

            if (window.focus && popUpWindows) {
                popUpWindows.focus()
            }

            if (!popUpWindows || popUpWindows.closed || typeof popUpWindows.closed === undefined) {
                notifyError(
                    "Apparently your browser blocked the Ebay connection window. Disable the ads blocker to perform this action."
                )
            }
        }
    }, [url])

    return null
}
