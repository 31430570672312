import { transition, container } from "assets/jss/material-dashboard-react.js"

const appStyle = (theme) => ({
    wrapper: {
        //position: "relative",
        top: "0",
        height: "100vh",
    },
    mainPanel: {
        overflow: "auto",
        //position: "relative",
        //float: "right",
        ...transition,
        maxHeight: "100%",
        width: "100%",
        overflowScrolling: "touch",
    },
    content: {
        marginTop: "70px",
        padding: "30px 15px",
        minHeight: "calc(100vh - 190px)",
    },
    container,
    map: {
        marginTop: "70px",
    },
    //back to top btn
    backToTopContainer: {
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 99999,
    },
    backToTopFab: {
        color: "#fff",
        backgroundColor: "rgba(63,81,181,0.5)",
    },
})

export default appStyle
