import React from "react"

import { Avatar, Typography, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { QuestionAnswerOutlined } from "@material-ui/icons"

import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"

//assets
import styles from "assets/jss/material-dashboard-react/views/infoPageStyle"
import { primaryColor } from "assets/jss/material-dashboard-react"

//lazy load components
import { LazyLoadComponent } from "react-lazy-load-image-component"

//images zoom
import ImageZoom from "components/App/ImageZoom"

const useStyles = makeStyles(styles)

export default function HowItWorks(props) {
    const classes = useStyles()

    const requireImagePath = (imageName) => require(`assets/img/how-works/${imageName}1000.gif`)

    //image zoom dialog
    const [showImageDialog, setShowImageDialog] = React.useState(false)
    const [imageToShow, setImageToShow] = React.useState(null)
    const [imageName, setImageName] = React.useState("")
    function showZoomImage(image) {
        let img = require(`assets/img/how-works/${image.image}1000.gif`)
        setImageToShow(img)
        setImageName(image.imageName)
        setShowImageDialog(true)
    }

    const Feature1 = () => {
        return (
            <Grid container item className={classes.colContainerSlide}>
                <Grid
                    container
                    item
                    alignItems={"center"}
                    justifyContent={"center"}
                    xs={12}
                    md={5}
                    className={classes.leftSide}
                >
                    <div>
                        <Typography variant={"body1"} style={{ color: primaryColor[0] }}>
                            Register and login in the system with an email
                        </Typography>
                        <Typography variant={"body2"}>Also, you can login with a social account</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={7} className={classes.imgContainer}>
                    <LazyLoadComponent>
                        <Avatar
                            variant={"rounded"}
                            src={requireImagePath("login")}
                            alt="login"
                            className={classes.img}
                            onClick={() => showZoomImage({ image: "login", imageName: "Sync2Markets login" })}
                            style={{ cursor: "pointer" }}
                        />
                    </LazyLoadComponent>
                </Grid>
            </Grid>
        )
    }

    const Feature2 = () => {
        return (
            <Grid container item className={classes.colContainerSlide}>
                <Grid item xs={12} md={7} className={classes.imgContainer}>
                    <LazyLoadComponent>
                        <Avatar
                            variant={"rounded"}
                            src={requireImagePath("statistics")}
                            alt="statistics"
                            className={classes.img}
                            onClick={() => showZoomImage({ image: "statistics", imageName: "Sync2Markets statistics" })}
                            style={{ cursor: "pointer" }}
                        />
                    </LazyLoadComponent>
                </Grid>
                <Grid
                    container
                    item
                    alignItems={"center"}
                    justifyContent={"center"}
                    xs={12}
                    md={5}
                    className={classes.leftSide}
                >
                    <div>
                        <Typography variant={"body1"} style={{ color: primaryColor[0] }}>
                            Access your data & statistics
                        </Typography>
                        <Typography variant={"body2"}>Reach all your data in a few clicks</Typography>
                        <Typography variant={"body2"}>Products and seller statistics</Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }

    const Feature3 = () => {
        return (
            <Grid container item className={classes.colContainerSlide}>
                <Grid
                    container
                    item
                    alignItems={"center"}
                    justifyContent={"center"}
                    xs={12}
                    md={5}
                    className={classes.leftSide}
                >
                    <div>
                        <Typography variant={"body1"} style={{ color: primaryColor[0] }}>
                            Add marketplaces
                        </Typography>
                        <Typography variant={"body2"}>Integrate desirable marketplaces from available</Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={7} className={classes.imgContainer}>
                    <LazyLoadComponent>
                        <Avatar
                            variant={"rounded"}
                            src={requireImagePath("marketplaces_select")}
                            alt="marketplaces_select"
                            className={classes.img}
                            onClick={() =>
                                showZoomImage({ image: "marketplaces_select", imageName: "Integrate marketplace" })
                            }
                            style={{ cursor: "pointer" }}
                        />
                    </LazyLoadComponent>
                </Grid>
            </Grid>
        )
    }

    const Feature4 = () => {
        return (
            <Grid container item className={classes.colContainerSlide}>
                <Grid item xs={12} md={7} className={classes.imgContainer}>
                    <LazyLoadComponent>
                        <Avatar
                            variant={"rounded"}
                            src={requireImagePath("create")}
                            alt="import"
                            className={classes.img}
                            onClick={() => showZoomImage({ image: "create", imageName: "Create product" })}
                            style={{ cursor: "pointer" }}
                        />
                    </LazyLoadComponent>
                </Grid>
                <Grid
                    container
                    item
                    alignItems={"center"}
                    justifyContent={"center"}
                    xs={12}
                    md={5}
                    className={classes.leftSide}
                >
                    <div>
                        <Typography variant={"body1"} style={{ color: primaryColor[0] }}>
                            Add products
                        </Typography>
                        <Typography variant={"body2"}>
                            Create new products or import them from integrated marketplaces
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }

    const Feature5 = () => {
        return (
            <Grid container item className={classes.colContainerSlide}>
                <Grid
                    container
                    item
                    alignItems={"center"}
                    justifyContent={"center"}
                    xs={12}
                    md={5}
                    className={classes.leftSide}
                >
                    <div>
                        <Typography variant={"body1"} style={{ color: primaryColor[0] }}>
                            Manage your products & ads
                        </Typography>
                        <Typography variant={"body2"}>
                            Manage your product, ad and status in all marketplaces from a single place
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} md={7} className={classes.imgContainer}>
                    <LazyLoadComponent>
                        <Avatar
                            variant={"rounded"}
                            src={requireImagePath("product_manage")}
                            alt="listings"
                            className={classes.img}
                            onClick={() => showZoomImage({ image: "product_manage", imageName: "Product manage" })}
                            style={{ cursor: "pointer" }}
                        />
                    </LazyLoadComponent>
                </Grid>
            </Grid>
        )
    }

    const Feature6 = () => {
        return (
            <Grid container item className={classes.colContainerSlide}>
                <Grid item xs={12} md={7} className={classes.imgContainer}>
                    <LazyLoadComponent>
                        <Avatar
                            variant={"rounded"}
                            src={requireImagePath("product")}
                            alt="product_sell"
                            className={classes.img}
                            onClick={() => showZoomImage({ image: "product", imageName: "Product and ads" })}
                            style={{ cursor: "pointer" }}
                        />
                    </LazyLoadComponent>
                </Grid>
                <Grid
                    container
                    item
                    alignItems={"center"}
                    justifyContent={"center"}
                    xs={12}
                    md={5}
                    className={classes.leftSide}
                >
                    <div>
                        <Typography variant={"body1"} style={{ color: primaryColor[0] }}>
                            Sell your product
                        </Typography>
                        <Typography variant={"body2"}>Create product ads</Typography>
                        <Typography variant={"body2"}>Manage its status in every marketplace</Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }

    return (
        <Card className={classes.cardContainer}>
            <ImageZoom open={showImageDialog} setOpen={setShowImageDialog} image={imageToShow} imageName={imageName} />
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <QuestionAnswerOutlined />
                </CardIcon>
                <h4 className={classes.cardTitle}>How it works</h4>
            </CardHeader>
            <CardBody>
                <Grid container spacing={2}>
                    <Feature1 />
                    <Feature2 />
                    <Feature3 />
                    <Feature4 />
                    <Feature5 />
                    <Feature6 />
                </Grid>
            </CardBody>
        </Card>
    )
}
