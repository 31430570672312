import gql from "graphql-tag"

//services listing using pagination and sort
export const LOAD_SERVICES = gql`
    query allServicePaginated(
        $orderBy: [String]!
        $nameFilter: String
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allService(
            orderBy: $orderBy
            name_Icontains: $nameFilter
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//all services listing, used for selecting nomenclator
export const LOAD_ALL_SERVICES = gql`
    query allService {
        allService {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//add new service
export const CREATE_SERVICE = gql`
    mutation createService($name: String!) {
        createService(input: { name: $name }) {
            service {
                id
                name
            }
        }
    }
`

//update service
export const UPDATE_SERVICE = gql`
    mutation updateService($id: ID!, $name: String!) {
        updateService(id: $id, input: { name: $name }) {
            service {
                id
                name
            }
        }
    }
`

//delete service
export const DELETE_SERVICE = gql`
    mutation deleteService($id: ID!) {
        deleteService(id: $id) {
            found
            deletedId
        }
    }
`
