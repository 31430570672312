import * as Cookies from "js-cookie"

export default function loginReducer(state = null, action) {
    switch (action.type) {
        case "SING_IN":
            state = Object.assign({}, action.payload.user)
            Cookies.set("JWT", action.payload.token, { secure: true, sameSite: "Strict", expires: 15 }) //FixMe: use httpOnly: true for production or domain option
            localStorage.setItem("refreshToken", action.payload.refreshToken)
            return state
        case "SING_OUT":
            state = null
            Cookies.remove("JWT")
            return state
        case "UPDATE_USER":
            state = Object.assign({}, action.payload)
            return state
        default:
            return state
    }
}
