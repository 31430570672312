import gql from "graphql-tag";

//weight unit listing using pagination and sort
export const LOAD_WEIGHT_UNITS = gql`
query allWeightUnitsPaginated(
  $orderBy: [String]!, 
  $nameFilter: String, 
  $firstPageItems: Int, 
  $lastPageItems: Int, 
  $beforeCursor: String,
  $afterCursor: String
) {  
  allWeightUnits(
    orderBy: $orderBy, 
    name_Icontains: $nameFilter,
    first: $firstPageItems,
    last: $lastPageItems,
    before: $beforeCursor,
    after: $afterCursor  
  ) {
  pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      node {
        id
        name
      }
    }
  }
}`

//all weight units listing, used for selecting nomenclator
export const LOAD_ALL_WEIGHT_UNITS = gql`
query allWeightUnits {  
  allWeightUnits {
    edges {
      node {
        id
        name
      }
    }
  }
}`

//add new weight unit
export const CREATE_WEIGHT_UNIT = gql`
mutation createWeightUnit($name: String!){
  createWeightUnit(input: {name: $name}) {
    weightUnit{
      id 
      name
    }
  }
}`

//update weight unit
export const UPDATE_WEIGHT_UNIT = gql`
mutation updateWeightUnit($id: ID!, $name: String!){
  updateWeightUnit(id: $id, input: {name: $name}) {
    weightUnit{
      id 
      name
    }
  }
}`

//delete weight unit
export const DELETE_WEIGHT_UNIT = gql`
mutation deleteWeightUnit($id: ID!){
  deleteWeightUnit(id: $id) {
    found
    deletedId
  }
}`
