import React from "react"

import { Button, CircularProgress, Toolbar, Tooltip, Typography } from "@material-ui/core"

//icons
import { InfoOutlined } from "@material-ui/icons"

//assets
import { MessageCenterView } from "utils/viewsDescriptionUtils"
import { useSelector } from "react-redux"

export default function MessageCenterTableHeader(props) {
    const { classes, loading, displayAllEbayMessages, setDisplayAllEbayMessages } = props

    const [userHasEbayMarketplace, setUserHasEbayMarketplace] = React.useState(false)

    const marketplaceConnectionStatus = useSelector((state) => state.marketplaceConnectionReducer)
    React.useEffect(() => {
        Object.keys(marketplaceConnectionStatus.marketplaceStatus).forEach((marketplaceSellerId) => {
            const row = marketplaceConnectionStatus.marketplaceStatus[marketplaceSellerId]

            if (row.sellerMarketplace.marketplace.name.toUpperCase() === "EBAY") setUserHasEbayMarketplace(true)
        })
    }, [marketplaceConnectionStatus.marketplaceStatus])

    return (
        <Toolbar className={classes.header}>
            <>
                <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                    Messages
                    <Tooltip
                        title={<MessageCenterView darkStyle />}
                        arrow
                        interactive
                        classes={{ tooltip: classes.descriptionTooltip }}
                    >
                        <InfoOutlined style={{ fontSize: 16 }} />
                    </Tooltip>
                </Typography>

                <div style={{ display: "flex", alignItems: "center" }}>
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <>
                            {userHasEbayMarketplace && (
                                <Tooltip
                                    title={
                                        displayAllEbayMessages
                                            ? "Displaying messages from Ebay"
                                            : "Messages from Ebay not displayed"
                                    }
                                >
                                    <Button
                                        variant={displayAllEbayMessages ? "contained" : "outlined"}
                                        color={displayAllEbayMessages ? "primary" : "inherit"}
                                        onClick={() => setDisplayAllEbayMessages(!displayAllEbayMessages)}
                                        size={"small"}
                                    >
                                        Ebay
                                    </Button>
                                </Tooltip>
                            )}
                        </>
                    )}
                </div>
            </>
        </Toolbar>
    )
}
