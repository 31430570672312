import React from "react"

//icons
import UserBadgeIcon from "mdi-react/UserBadgeIcon"

//styles
import styles from "assets/jss/material-dashboard-react/components/dashboardStatsStyle"
import { makeStyles } from "@material-ui/core/styles"

//link
import { NavLink } from "react-router-dom"
import { Avatar, Card, CardContent, Grid } from "@material-ui/core"

const useStyles = makeStyles(styles)

export default function SellersStats(props) {
    const classes = useStyles()

    const { adminStats } = props

    const [productsTotal, setProductsTotal] = React.useState(0)
    React.useEffect(() => {
        if (adminStats) {
            let total = 0

            if (adminStats?.totalProductsEtsy) {
                total += adminStats.totalProductsEtsy
            }

            if (adminStats.amazonInformation.length > 0) {
                adminStats.amazonInformation.forEach((marketplace) => {
                    total += marketplace.totalProductsAmazon
                })
            }

            if (adminStats?.ebayInformation?.length > 0) {
                adminStats.ebayInformation.forEach((marketplace) => {
                    total += marketplace.totalProductsEbay
                })
            }

            setProductsTotal(total)
        }
    }, [adminStats])

    return (
        <Card variant={"outlined"}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid container item justifyContent={"flex-end"} className={classes.cardCategory}>
                        Sellers
                    </Grid>
                    <Grid container item xs={2} alignItems={"center"}>
                        <Avatar variant={"rounded"} className={classes.cardAvatarAdmin}>
                            <UserBadgeIcon size={"55"} />
                        </Avatar>
                    </Grid>
                    <Grid container item xs={10} alignItems={"center"} spacing={1}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid container item xs={12} justifyContent={"center"}>
                                <h3 className={classes.cardTitle}>
                                    <NavLink
                                        to={"/seller/product"}
                                        style={{ textDecoration: "solid underline black 1px" }}
                                    >
                                        {adminStats?.totalUsersSeller ?? "-"} <small>sellers</small>
                                    </NavLink>
                                </h3>
                            </Grid>
                            <Grid container item xs={12} justifyContent={"center"}>
                                <h3 className={classes.cardTitle}>
                                    <NavLink
                                        to={"/seller/product"}
                                        style={{ textDecoration: "solid underline black 1px" }}
                                    >
                                        {productsTotal} <small>products</small>
                                    </NavLink>
                                </h3>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
