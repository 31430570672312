import {whiteColor} from "assets/jss/material-dashboard-react.js";

const loginStyle = theme => ({
    loginFormContainer: {
        backgroundColor: whiteColor,
        height: "auto",
        [theme.breakpoints.up("lg")]: {
            width: "30%"
        },
        [theme.breakpoints.down("lg")]: {
            width: "40%"
        },
        [theme.breakpoints.down("sm")]: {
            width: "75%"
        },
        [theme.breakpoints.down("xs")]: {
            width: "90%"
        },
    },
    logo: {
        width: "100%",
        maxHeight: "190px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    img: {
        width: "auto",
        maxHeight: "190px",
        marginBottom: "10px"
    },
    inputFields: {
        marginBottom: "10px",
        marginTop: "10px"
    },
    submitBtn: {
        display: "block",
        width: "100%",
        marginTop: "10px"
    },
    textLink: {
        paddingTop: "10px"
    },
    socialAuthContainer: {
        paddingTop: "10px",
        display: "flex",
        justifyContent: "center"
    },
    socialAuthLogo: {
        width: "32px",
        maxHeight: "32px",
        margin: "0 5px",
        cursor: "pointer"
    },
    socialAuthLogoTwitter: {
        width: "32px",
        maxHeight: "32px",
        margin: "0 5px",
        borderRadius: "50%",
        cursor: "pointer"
    }
});

export default loginStyle;
