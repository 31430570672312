import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { EditOutlined } from "@material-ui/icons"
import {
    Button,
    CircularProgress,
    Grid,
    Avatar,
    ImageListItem,
    ImageListItemBar,
    FormHelperText,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Dialog,
} from "@material-ui/core"
import { ConfirmTransition } from "utils/tableUtils"

import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"

import { Formik } from "formik"
import * as Yup from "yup"

import styles from "assets/jss/material-dashboard-react/views/cardFormStyle"

//assets
import AmazonImg from "assets/img/marketplaces/Amazon-icon.svg"
import EtsyImg from "assets/img/marketplaces/Etsy_icon.svg"
import EbayImg from "assets/img/marketplaces/Ebay_icon.svg"
import FacebookImg from "assets/img/marketplaces/Facebook_icon.svg"

//apollo
import { Query } from "@apollo/client/react/components"
import { LOAD_ALL_UNLINKED_MARKETPLACES } from "querys/seller/Marketplace/marketplaceQueries"

//notifications
import { ErrorNotification } from "components/Notifications/Notifications"

//router
import { useHistory } from "react-router-dom"

//marketplaces connection
import EtsyConnection from "components/Seller/Apis connection/EtsyConnection"
import EbayConnection from "components/Seller/Apis connection/EbayConnection"
import AmazonConnection from "components/Seller/Apis connection/AmazonConnection"
import FacebookConnection from "components/Seller/Apis connection/FacebookConnection"

//combining clases
import classNames from "classnames"

const useStyles = makeStyles(styles)

export default function MarketplaceForm(props) {
    const classes = useStyles()

    const history = useHistory()

    const notifyError = (message) => ErrorNotification("Marketplace", message)

    const cancelAction = () => {
        history.push("/seller/connection")
    }

    const getImageSrc = (option) => {
        switch (option.marketplace.name.toUpperCase()) {
            case "ETSY":
                return EtsyImg
            case "EBAY":
                return EbayImg
            case "AMAZON":
                return AmazonImg
            case "FACEBOOK":
                return FacebookImg
            default:
                return ""
        }
    }

    //allow popUp integration windows dialog
    const [openAllowWindowsDialog, setOpenAllowWindowsDialog] = React.useState(false)

    //open dialog only once
    const [openedAllowWindowsDialog, setOpenedAllowWindowsDialog] = React.useState(false)

    const AllowIntegrationWindows = ({ formik }) => {
        const handleCloseDialog = () => {
            setOpenAllowWindowsDialog(false)
            formik.submitForm()
        }

        return (
            <Dialog
                open={openAllowWindowsDialog}
                onClose={handleCloseDialog}
                TransitionComponent={ConfirmTransition}
                aria-labelledby="allow-integration-windows"
                aria-describedby="allow-integration-windows"
            >
                <DialogTitle id="alert-dialog-title">{"Allow windows needed"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Connection window may be blocked by your browser. If this happened you must authorize in your
                        browser to execute it. Authorize to execute "Emergent windows, Emergent items or PopUp windows",
                        depending of your browser and retry this action again. If your browser do not permit to execute
                        the windows, connection process will be not possible.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    //marketplaces options
    const [marketplaces, setMarketplaces] = React.useState([])

    const selectMarketplace = (formik, marketplace) => {
        if (formik.values.marketplace?.marketplace?.id === marketplace.marketplace.id) {
            formik.setFieldValue("marketplace", "")
        } else {
            formik.setFieldValue("marketplace", marketplace)
        }
    }

    //amazon login url
    const [amazonUrlLogin, setAmazonUrlLogin] = React.useState(null)

    function openConnectionToAmazon(data) {
        setAmazonUrlLogin("")
        setAmazonUrlLogin(data.marketplace.url)
    }

    //ebay login url
    const [ebayUrlLogin, setEbayUrlLogin] = React.useState(null)

    function openConnectionToEbay(data) {
        setEbayUrlLogin("")
        setEbayUrlLogin(data.marketplace.url)
    }

    //etsy login url
    const [etsyUrlLogin, setEtsyUrlLogin] = React.useState(null)

    function openConnectionToEtsy(data) {
        setEtsyUrlLogin("")
        setEtsyUrlLogin(data.marketplace.url)
    }

    //facebook login url
    const [facebookUrlLogin, setFacebookUrlLogin] = React.useState(null)

    function openConnectionToFacebook(data) {
        setFacebookUrlLogin("")
        setFacebookUrlLogin(data.marketplace.url)
    }

    //formik
    const initialValues = {
        marketplace: "",
    }

    let validationSchema = Yup.object({
        marketplace: Yup.object().required("You must select a platform"),
    })

    return (
        <Card>
            <AmazonConnection url={amazonUrlLogin} />
            <EtsyConnection url={etsyUrlLogin} />
            <EbayConnection url={ebayUrlLogin} />
            <FacebookConnection url={facebookUrlLogin} />
            <CardHeader className={classes.cardHeader}>
                <Grid container spacing={1}>
                    <Grid item xs={"auto"} className={classes.cardHeaderIcon}>
                        <EditOutlined />
                    </Grid>
                    <Grid item xs={true}>
                        <h4 className={classes.cardTitle}>Available connections</h4>
                    </Grid>
                </Grid>
            </CardHeader>
            <Query
                query={LOAD_ALL_UNLINKED_MARKETPLACES}
                onCompleted={(data) => {
                    if (data) {
                        setMarketplaces(data.allMarketplacesLoginUrls)
                    }
                }}
            >
                {({ loading, error, data }) => {
                    if (error) {
                        notifyError("Unable to load existing marketplaces, try again")
                    }

                    return (
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(data) => {
                                if (openedAllowWindowsDialog) {
                                    if (data.marketplace.marketplace.name.toUpperCase() === "ETSY") {
                                        openConnectionToEtsy(data)
                                    } else if (data.marketplace.marketplace.name.toUpperCase() === "EBAY") {
                                        openConnectionToEbay(data)
                                    } else if (data.marketplace.marketplace.name.toUpperCase() === "AMAZON") {
                                        openConnectionToAmazon(data)
                                    } else if (data.marketplace.marketplace.name.toUpperCase() === "FACEBOOK") {
                                        openConnectionToFacebook(data)
                                    } else {
                                        console.log("this marketplace is not available, yet")
                                    }
                                } else {
                                    setOpenedAllowWindowsDialog(true)
                                    setOpenAllowWindowsDialog(true)
                                }
                            }}
                        >
                            {(formik) => (
                                <form onSubmit={formik.handleSubmit}>
                                    <CardBody>
                                        <AllowIntegrationWindows formik={formik} />
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <h4 style={{ fontSize: "16px" }}>
                                                    Select a platform to connect it to Sync2Markets
                                                </h4>
                                            </Grid>
                                            <Grid
                                                container
                                                item
                                                spacing={2}
                                                justifyContent={"center"}
                                                alignItems={"center"}
                                                style={{ padding: "0 15px" }}
                                            >
                                                {marketplaces.map((option) => (
                                                    <Grid
                                                        key={option.marketplace.id}
                                                        container
                                                        item
                                                        justifyContent={"center"}
                                                        xs={12}
                                                        sm={6}
                                                        md={3}
                                                    >
                                                        <ImageListItem component={"div"}>
                                                            <Avatar
                                                                alt={option.marketplace.name}
                                                                src={getImageSrc(option)}
                                                                variant={"rounded"}
                                                                className={classNames(classes.marketplaceThumb, {
                                                                    [classes.selectedMarketplaceThumb]:
                                                                        formik.values.marketplace?.marketplace
                                                                            ?.fullName === option.marketplace.fullName,
                                                                })}
                                                                onClick={() => selectMarketplace(formik, option)}
                                                            />
                                                            <ImageListItemBar
                                                                title={option.marketplace.fullName}
                                                                subtitle={option.marketplace.marketplaceMs.edges
                                                                    .map((item) => item.node.service.name.toUpperCase())
                                                                    .join(" | ")}
                                                                style={{
                                                                    color: "#fff",
                                                                    height: "40px",
                                                                    paddingTop: "0",
                                                                    paddingBottom: "5px",
                                                                }}
                                                            />
                                                        </ImageListItem>
                                                    </Grid>
                                                ))}
                                            </Grid>

                                            <Grid container item xs={12} justifyContent={"center"}>
                                                <FormHelperText style={{ color: "rgb(244, 67, 57)" }}>
                                                    {formik.errors && formik.errors.marketplace}
                                                </FormHelperText>
                                            </Grid>
                                        </Grid>
                                    </CardBody>
                                    <CardFooter chart>
                                        <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"secondary"}
                                                onClick={cancelAction}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"primary"}
                                                type="submit"
                                                disabled={!formik.isValid || loading}
                                            >
                                                {loading ? <CircularProgress size={24} /> : "Connect"}
                                            </Button>
                                        </Grid>
                                    </CardFooter>
                                </form>
                            )}
                        </Formik>
                    )
                }}
            </Query>
        </Card>
    )
}
