import gql from "graphql-tag"

//dimension unit listing using pagination and sort
export const LOAD_DIMENSION_UNITS = gql`
    query allDimensionUnitsPaginated(
        $orderBy: [String]!
        $nameFilter: String
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allDimensionUnits(
            orderBy: $orderBy
            name_Icontains: $nameFilter
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//all dimension units listing, used for selecting nomenclator
export const LOAD_ALL_DIMENSION_UNITS = gql`
    query allDimensionUnits {
        allDimensionUnits {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//add new dimension unit
export const CREATE_DIMENSION_UNIT = gql`
    mutation createDimensionUnit($name: String!) {
        createDimensionUnit(input: { name: $name }) {
            dimensionUnit {
                id
                name
            }
        }
    }
`

//update dimension unit
export const UPDATE_DIMENSION_UNIT = gql`
    mutation updateDimensionUnit($id: ID!, $name: String!) {
        updateDimensionUnit(id: $id, input: { name: $name }) {
            dimensionUnit {
                id
                name
            }
        }
    }
`

//delete dimension unit
export const DELETE_DIMENSION_UNIT = gql`
    mutation deleteDimensionUnit($id: ID!) {
        deleteDimensionUnit(id: $id) {
            found
            deletedId
        }
    }
`
