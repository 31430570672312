import {
    whiteColor,
    grayColor,
} from "assets/jss/material-dashboard-react.js";

const generalUsageUtilsStyle = () => ({
    informationBtn: {
        color: whiteColor,
        backgroundColor: grayColor[2],
        border: "1px solid #AAAAAA",
        padding: "2px 6px",
        textAlign: "start",
        whiteSpace: "nowrap"
    }
});

export default generalUsageUtilsStyle;
