import React from "react";

import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {PolicyOutlined} from "@material-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";

//assets
import styles from "assets/jss/material-dashboard-react/views/infoPageStyle";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(styles);

export default function PrivacyPolicy(props) {
    const classes = useStyles();

    return (
        <Card className={classes.cardContainer}>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <PolicyOutlined/>
                </CardIcon>
                <h4 className={classes.cardTitle}>Privacy Policy</h4>
            </CardHeader>
            <CardBody>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"body2"}>
                            We at Sync2Markets are committed to protecting your privacy. Our privacy policy governs the
                            collection, storage, and use of the personal information provided by you. By accessing our
                            website or using our platform you agree to the terms of this privacy policy.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"body2"}>
                            If you do not agree with the data practices described in this Privacy Policy, you should not
                            use our services.
                            If you have any questions regarding Sync2Markets Privacy Policy, feel free to contact us through
                            email at ceticsoftdev@gmail.com.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Information we collect from you
                        </Typography>
                        <li><b>Your identity details: </b>first name, last name.</li>
                        <li><b>Contact details: </b>telephone number, email address.</li>
                        <li><b>Profile Data: </b>username and password, account preferences.</li>
                        <li><b>Transaction Data: </b>Includes details about payments to and from you and details
                            about products and services you have purchased from us.
                        </li>
                        <li><b>Technical Data: </b>Internet protocol (IP) address used to connect your computer
                            to the Internet, browser type and version, time zone setting, operating system, and platform.
                        </li>
                        <li><b>Business data: </b>products, inventory, orders, shipment information, and client data
                            (their name, username, address, and contact details).
                        </li>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            How we collect information
                        </Typography>
                        <li>During registration or the purchase of any of our services.</li>
                        <li>During customer surveys, feedback forms.</li>
                        <li>By using cookies.</li>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Cookie policy
                        </Typography>
                        <Typography variant={"body2"}>
                            A cookie is a small piece of text sent to your browser by a website you visit.
                            It helps the site remember information about your visit, which can make it easier to
                            visit the site again and make the site more useful to you. In addition it allows making
                            the ads you see more relevant to you, to count how many visitors we receive to a page
                            and to help you sign up for our services, to protect your data, and to remember your ad settings.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            How de we use collected information
                        </Typography>
                        <li>
                            To establish, maintain, administer and support your account. We never impersonate
                            your account for troubleshooting purposes without your consent. In unique cases, a
                            dedicated Sync2Markets support member could offer the user an option to help solve an
                            issue with their account via direct access to their account. This level of support can
                            only be perform by a dedicated support team and only with specific agreement of the account holder.
                        </li>
                        <li>To communicate with you over a phone, email or live chat.</li>
                        <li>To aggregate statistics about the use of our services.</li>
                        <li>For taxes and legal reasons.</li>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            How long do we store your information
                        </Typography>
                        <Typography variant={"body2"}>
                            Sync2Markets will store your personal and business information for as-long as you are
                            our user, if your account becomes in-active and does not hold a subscription we will
                            automatically delete it after 60 days.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Whom we share your information with
                        </Typography>
                        <Typography variant={"body2"}>
                            Sync2Markets does not share your personal information with anyone ever. If you,
                            the user choose to link any 3rd party software, shipping provider, or marketplace to our
                            platform, you give consent for Sync2Markets to pass your business data from Sync2Markets
                            to the 3rd party integration you choose to connect with.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            How we secure your information
                        </Typography>
                        <Typography variant={"body2"}>
                            We are committed to ensuring that your information is secure. To prevent unauthorised
                            access or disclosure, we have put in place suitable physical, electronic, and managerial
                            procedures to safeguard and secure the information we collect online. Also, we limit
                            access to your personal data to those employees, agents, contractors, and other third
                            parties who have a business need to know. They will only process your personal data on
                            our instructions, and they are subject to a duty of confidentiality.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Edit or change your information
                        </Typography>
                        <Typography variant={"body2"}>
                            You can access all of your information in Sync2Markets, change or edit your information at any time.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Links to other websites
                        </Typography>
                        <Typography variant={"body2"}>
                            Our website may contain links to other websites of interest. However, once you have
                            used these links to leave our site, you should note that we do not have any control over
                            that other website. Therefore, we cannot be responsible for the protection and privacy
                            of any information which you provide whilst visiting such sites and such sites are not
                            governed by this privacy statement. You should exercise caution and look at the privacy
                            statement applicable to the website in question.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Changes to our privacy policy
                        </Typography>
                        <Typography variant={"body2"}>
                            This policy may be updated. Please check back frequently to see any updates or changes
                            to our privacy policy.
                        </Typography>
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
    )
};
