import React from "react"
import { Grid } from "@material-ui/core"
import Tipography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/views/LandingPageStyle"

//reveal effects
import { Slide } from "react-awesome-reveal"

//assets
import SettingsApplicationsIcon from "mdi-react/SettingsApplicationsIcon"
import HandPointingUpIcon from "mdi-react/HandPointingUpIcon"
import QuestionAnswerIcon from "mdi-react/QuestionAnswerIcon"
import MobileDevicesIcon from "mdi-react/MobileDevicesIcon"

//parallax
import { Background, Parallax } from "react-parallax"

const useStyles = makeStyles(styles)

export default function Slide5() {
    const classes = useStyles()

    const Block1 = () => (
        <Slide duration={1500} direction={"top"} triggerOnce>
            <Grid container item spacing={1} direction={"column"}>
                <Grid container item className={classes.blockRow} alignItems={"flex-end"}>
                    <HandPointingUpIcon size={"70%"} />
                </Grid>
                <Grid container item className={classes.blockRow}>
                    <Tipography variant="body2">
                        Intuitive interface
                    </Tipography>
                </Grid>
            </Grid>
        </Slide>
    )

    const Block2 = () => (
        <Slide duration={1800} direction={"top"} triggerOnce>
            <Grid container item spacing={1} direction={"column"}>
                <Grid container item className={classes.blockRow}>
                    <SettingsApplicationsIcon size={"70%"} />
                </Grid>
                <Grid container item className={classes.blockRow}>
                    <Tipography variant="body2">
                        Minimal config needed
                    </Tipography>
                </Grid>
            </Grid>
        </Slide>
    )

    const Block3 = () => (
        <Slide duration={2100} direction={"top"} triggerOnce>
            <Grid container item spacing={1} direction={"column"}>
                <Grid container item className={classes.blockRow}>
                    <QuestionAnswerIcon size={"70%"} />
                </Grid>
                <Grid container item className={classes.blockRow}>
                    <Tipography variant="body2">
                        Explained operations and results
                    </Tipography>
                </Grid>
            </Grid>
        </Slide>
    )

    const Block4 = () => (
        <Slide duration={2400} direction={"top"} triggerOnce>
            <Grid container item spacing={1} direction={"column"}>
                <Grid container item className={classes.blockRow}>
                    <MobileDevicesIcon size={"70%"} />
                </Grid>
                <Grid container item className={classes.blockRow}>
                    <Tipography variant="body2">
                        Optimized for different devices
                    </Tipography>
                </Grid>
            </Grid>
        </Slide>
    )

    return (
        <Parallax strength={500}>
            <Background>
                <div className={classes.background4} />
            </Background>
            <Grid container spacing={1} className={classes.colContainerBlocksSlide}>
                <Grid container item xs={12} sm={6} md={3} alignItems={"center"} justifyContent={"center"}>
                    <Block1 />
                </Grid>

                <Grid container item xs={12} sm={6} md={3} alignItems={"center"} justifyContent={"center"}>
                    <Block2 />
                </Grid>

                <Grid container item xs={12} sm={6} md={3} alignItems={"center"} justifyContent={"center"}>
                    <Block3 />
                </Grid>

                <Grid container item xs={12} sm={6} md={3} alignItems={"center"} justifyContent={"center"}>
                    <Block4 />
                </Grid>
            </Grid>
        </Parallax>
    )
}
