import React from "react";

import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {PolicyOutlined} from "@material-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader";

//assets
import styles from "assets/jss/material-dashboard-react/views/infoPageStyle";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(styles);

export default function ServiceTerms(props) {
    const classes = useStyles();

    return (
        <Card className={classes.cardContainer}>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <PolicyOutlined/>
                </CardIcon>
                <h4 className={classes.cardTitle}>Service Terms</h4>
            </CardHeader>
            <CardBody>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"body2"}>
                            Welcome to Sync2Markets. The Sync2Markets branded and related services are provided by
                            CeticSoft.com, LLC, through the Sync2Markets Website https://www.sync2markets.com.
                            This Terms of Service Agreement (“Agreement”) is entered into by and between CeticSoft
                            and you, and is made effective as of the date of your use of this website or the date
                            of electronic acceptance. The following terms and conditions govern our relationship
                            with our clients and others who interact with our website.
                        </Typography>

                        <Typography variant={"body2"}>
                            The services offered by the software are only accessible on our website at
                            <a href={"www.sync2markets.com"}> www.sync2markets.com</a>
                        </Typography>
                    </Grid>


                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Amendments to Terms of service
                        </Typography>
                        <Typography variant={"body2"}>
                            CeticSoft the right to change or update this Agreement without notice. In the
                            appropriate time, CeticSoft will notify users about the changes that could affect
                            their use of the Service.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Registration and access settings
                        </Typography>
                        <Typography variant={"body2"}>
                            In order to create an account, you hereby affirm that you provide accurate information
                            about your identity, address and the products you sell via the connected platforms and
                            agree to update this information regularly and promptly.
                        </Typography>
                        <li>
                            You ensure that you do not use this platform to sell products that are illegal on
                            destination websites.
                        </li>
                        <li>
                            You cannot create an account, username or password that is the personal information
                            of another person, words that are the trademarks, the property of another party
                            (including ours). Text placed by you or on your behalf for the products offered for
                            sale cannot be obscene, malicious, threatening or in any other way inappropriate.
                        </li>
                        <li>
                            Users need to provide Sync2Markets with all information rightfully requested by it,
                            in order to enable use of the software.
                        </li>
                        <li>
                            Users are responsible for maintaining the security of their account login information
                            (username and password) as Sync2Markets cannot and will not be liable for any loss or
                            damage from failure to comply with this security obligation.
                        </li>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Restrictions
                        </Typography>
                        <Typography variant={"body2"}>
                            When agreeing to these terms, CeticSoft guarantees you a limited, non-transferable,
                            non-exclusive license to use and access the Service as long as you do not perform the
                            following actions:
                        </Typography>
                        <li>
                            Exploit the terms of this licence (for instance to licence, sublicense, sell, resell,
                            rent, lease, transfer, assign, distribute) or make the Service available for access or
                            use by any person(s) other than the Users, save as for is expressly permitted by this
                            Agreement or authorised by CeticSoft.
                        </li>
                        <li>
                            Use the Service to process any data unlawfully or for any third party.
                        </li>
                        <li>
                            Attempt to determine the source code of the Software. For instance, you shall not modify,
                            adapt, decompile, decipher, reverse engineer or otherwise change the source code, which
                            makes up the Service.
                        </li>
                        <li>
                            Harm the software with viruses, malware or trojan horses, by uploading, storing or
                            transmitting any malicious code.
                        </li>
                        <li>
                            Use the Service in a manner that interferes or disrupts with the provision of the
                            Service by Sync2Markets to third parties.
                        </li>
                        <li>
                            Use the Service to display or show any material which is of a defamatory, offensive,
                            abusive or menacing character to any other person.
                        </li>
                        <li>
                            Use the Service for any transmission, display or publication of any material in breach
                            of the Data Protection Act 1998 (or any amending statute) dealing with data protection
                            or similar legislation in any other country of any material which is confidential or
                            is a trade secret.
                        </li>
                        <li>
                            You may not use your account for any illegal or unauthorised purpose. You must not,
                            during your use of this Service, violate any laws in your jurisdiction (including,
                            but not limited to copyright laws).
                        </li>
                        <li>
                            You agree to indemnify CeticSoft against all and any losses, costs and expenses
                            CeticSoft may incur as a result of any breach by you.
                        </li>
                        <li>
                            The above list is not intended to be complete. CeticSoft have the right to remove any
                            content and suspend or terminate the account of any user who in our sole judgment is in
                            breach of the Terms of Service.
                        </li>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Storage and Processing of Customer Data
                        </Typography>
                        <Typography variant={"body2"}>
                            Sync2Markets might retain certain Customer Data to enable various features and
                            functionality of the Service, including the resending of Customer Data to owned
                            products and third parties at Customer’s discretion. Sync2Markets solely holds and
                            forms our Clients client information on the sake of our clients.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Cancellation and termination
                        </Typography>
                        <Typography variant={"body2"}>
                            Sync2Markets have the right to suspend or terminate your account and current or future
                            use of service due to any reason. Termination or suspension will result in deletion of
                            your account.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Right to use the service and maintenance
                        </Typography>
                        <li>
                            Sync2Markets cannot guarantee that the Service will be continuously accessible as
                            the Service may be inaccessible from time to time due to:
                            <ol>
                                <li>Planned downtime for updates and / or maintenance of the Service.</li>
                                <li>Any circumstances that are beyond our control, such as technical failures.</li>
                            </ol>
                        </li>
                        <li>
                            In order to fulfill its commitments in the supervision and review of the Service,
                            Sync2Markets may at any time correct the Service and any documentation related to it for
                            any reason, including, among others: legal, technical or commercial considerations.
                        </li>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Customer Support
                        </Typography>
                        <Typography variant={"body2"}>
                            Customer support is available on our website via email at ceticsoftdev@gmail.com
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Code ownership
                        </Typography>
                        <Typography variant={"body2"}>
                            Unauthorized distribution of the Software without earlier assent is entirely prohibited
                            and includes placing our Software on any physical or virtual servers or mediums without
                            special agreement or a written consent form CeticSoft.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Data Loss and Backups
                        </Typography>
                        <li>
                            CeticSoft will make great effort to guarantee that your information is secure when
                            using Sync2Markets Service.
                        </li>
                        <li>
                            CeticSoft will not accept beneath any circumstances the obligation for any liability
                            for any loss of client information whether that be through general use, hacking or
                            server failure.
                        </li>
                        <li>
                            CeticSoft will operate one daily backup of the subscription account data and the
                            server itself.
                        </li>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Warranty
                        </Typography>
                        <Typography variant={"body2"}>
                            Sync2Markets warrants that:
                        </Typography>
                        <li>
                            It makes great effort to secure the software and checks the Service for the most
                            commonly known infections.
                        </li>
                        <li>
                            The Service will be provided with all due care, aptitude and constancy and by means
                            of appropriately qualified and talented personnel.
                        </li>
                        <li>
                            The Service will work and function as described on the website.
                        </li>
                        <li>
                            Sync2Markets have the right to provide the Service and any accompanying materials as
                            mulled contemplated beneath this Agreement and does not and will not encroach the
                            Intellectual Property Rights or other rights of any third party.
                        </li>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Confidentiality
                        </Typography>
                        <Typography variant={"body2"}>
                            CeticSoft will at all times be committed to guaranteeing the confidentiality of data.
                            Any data submitted by the client will only be used by Sync2Markets in agreement
                            with the instructions of the client or in accordance with the terms of this Agreement.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Liability
                        </Typography>
                        <Typography variant={"body2"}>
                            In no event will we or our directors, employees, or agents be liable to you or any
                            third party for any direct, indirect, consequential, exemplary, incidental, special,
                            or punitive damages, including lost profit, lost revenue, loss of data, or other damages
                            arising from your use of the site, even if we have been advised of the possibility of
                            such damages.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Liability
                        </Typography>
                        <Typography variant={"body2"}>
                            Uploading any restricted content as listed below could lead to immediate account termination.
                        </Typography>
                        <Typography variant={"body2"}>
                            You must not transfer, post, host or transmit the subsequent things to or from the
                            Service together with however not restricted to:
                        </Typography>
                        <li>Uninvited email, SMS’s, or “Spam” messages</li>
                        <li>Worms, viruses or code of a harmful nature</li>
                        <li>Questionable or outlawed material – together with copyrighted material</li>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Indemnity
                        </Typography>
                        <Typography variant={"body2"}>
                            You agree to protect, defend, indemnify and hold harmless CeticSoft and its officers,
                            directors, employees, agents, and third party service providers from and against any
                            and all claims, demands, costs, expenses, losses, liabilities and damages of every kind
                            and nature (including, without limitation, reasonable attorneys’ fees) imposed upon or
                            incurred by CeticSoft directly or indirectly arising from your use of and access to this
                            Site or the Services found at this Site;  your violation of any provision of this
                            Agreement or the policies or agreements which are incorporated herein; and/or your
                            violation of any third-party right, including without limitation any intellectual
                            property or other proprietary right. The indemnification obligations under this section
                            shall survive any termination or expiration of this Agreement or your use of this Site
                            or the Services found at this Site.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Links to third-party websites
                        </Typography>
                        <Typography variant={"body2"}>
                            This Site and the Services found at this Site may contain links to third-party websites
                            that are not owned or controlled by CeticSoft. CeticSoft assumes no responsibility for
                            the content, terms and conditions, privacy policies, or practices of any third-party
                            websites. In addition, CeticSoft does not censor or edit the content of any third-party
                            websites. By using this Site or the Services found at this Site, you expressly release
                            CeticSoft from any and all liability arising from your use of any third-party website.
                            Accordingly, CeticSoft encourages you to be aware when you leave this Site or the Services
                            found at this Site and to review the terms and conditions, privacy policies, and other
                            governing documents of each other website that you may visit.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Compliance with local laws
                        </Typography>
                        <Typography variant={"body2"}>
                            CeticSoft makes no representation or warranty that the content available on this Site
                            or the Services found at this Site are appropriate in every country or jurisdiction,
                            and access to this Site or the Services found at this Site from countries or jurisdictions
                            where its content is illegal is prohibited.  Users who choose to access this Site or
                            the Services found at this Site are responsible for compliance with all local laws,
                            rules and regulations.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            General
                        </Typography>
                        <Typography variant={"body2"}>
                            Nothing in this Agreement is intended to confer on a person any right to enforce any
                            term of this Agreement which that person would not have had but for the Contract
                            (Rights of Third Parties) Act 1999.
                            All notices in reference to this Agreement must be in English, in writing, addressed
                            to the other party and sent to your address embarked on your Company profile or to
                            ceticsoftdev@gmail.com (as applicable) or such different address as either party has
                            notified the opposite in accordance with this clause. All notices shall be deemed to
                            have been given on receipt as verified by written or automated receipt or electronic
                            log (as applicable). All other notices must be in English, in writing, addressed to the
                            other party’s primary contact and sent to their then current postal address or email address.
                            All disputes between the parties arising out of or with reference to this Agreement or
                            the breach, termination or validity thence shall be referred by either party in writing,
                            first to every party’s representative. The representatives shall meet and arrange to
                            resolve the dispute within a period of thirty (30) operating days from the date of referral
                            of the dispute to them.
                            Each party acknowledges that in stepping into this Agreement it’s not relied on, and
                            shall have no right or remedy in respect of, any statement, falsity, misrepresentation,
                            illustration, representation or assurance (whether created negligently or innocently)
                            and whether or not created by either party, orally or in writing, before the execution
                            of this Agreement and not expressly stated during this Agreement or any form.
                            This Agreement means that all terms are agreed between the parties and supersedes and
                            extinguishes all previous agreements, misrepresentations, understandings and arrangements
                            between the parties whether written or oral, relating to its subject matter.
                            You are not entitled to transfer or assign this Agreement without Ceticsoft prior written
                            consent.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            English language controls
                        </Typography>
                        <Typography variant={"body2"}>
                            This Agreement, along with all policies and the applicable product agreements identified
                            above and incorporated herein by reference (collectively, the “Agreement”), is executed
                            in the English language. To the extent any translation is provided to you, it is provided
                            for convenience purposes only, and in the event of any conflict between the English and
                            translated version, where permitted by law, the English version will control and prevail.
                            Where the translated version is required to be provided to you and is to be considered
                            binding by law (i) both language versions shall have equal validity, (ii) each party
                            acknowledges that it has reviewed both language versions and that they are substantially
                            the same in all material respects, and (iii) in the event of any discrepancy between
                            these two versions, the translated version may prevail, provided that the intent of the
                            Parties has been fully taken into consideration.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "justify"}}>
                        <Typography variant={"subtitle2"}>
                            Contact information
                        </Typography>
                        <Typography variant={"body2"}>
                            If you have any questions about this Agreement, please contact us by email or regular
                            mail at the following address: <br/>

                            CeticSoft Legal Department <br/>
                            14455 North Hayden Rd.<br/>
                            Suite 219<br/>
                            Scottsdale, AZ 85260<br/>
                            legal@godaddy.com<br/>
                        </Typography>
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
    )
};
