import React from "react"
import { Collapse } from "@material-ui/core"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/apiResponseStyle"
import MessageAlertIcon from "mdi-react/MessageAlertIcon"

//redux
import { useSelector, useDispatch } from "react-redux"
import { closeApiResponse } from "actions/globalComponents/apiResponseActions"

const useStyles = makeStyles(styles)

export default function ApiResponse(props) {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { open, message, forceOptionsBtn } = useSelector((state) => state.apiResponseReducer)

    return (
        <Snackbar
            open={open}
            onClose={() => {
                dispatch(closeApiResponse({ open: false, message: "", forceOptionsBtn: false }))
            }}
            TransitionComponent={Collapse}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            style={{ width: "80%", maxWidth: "80%" }}
        >
            <Alert
                variant={"filled"}
                color={"info"}
                className={classes.alert}
                onClose={() => {
                    dispatch(closeApiResponse({ open: false, message: "", forceOptionsBtn: false }))
                }}
            >
                {message.length > 0 &&
                    message.map((item, index) => (
                        <li key={`${item.marketplace}-${index}`} className={classes.list}>
                            <code className={classes.marketplaceLabel}>{item.marketplace}</code>
                            {"return"}
                            <code
                                className={
                                    item.type === "Success"
                                        ? classes.successLabel
                                        : item.type === "Warning"
                                        ? classes.warningLabel
                                        : classes.errorLabel
                                }
                            >
                                {item.type}
                            </code>
                            {"with message(s)"} <br />
                            {item.messages.map((message, messageIndex) => (
                                <div key={messageIndex}>
                                    <MessageAlertIcon style={{ fontSize: 12 }} />
                                    <code className={classes.messageLabel}>{message}</code>
                                    <br />
                                </div>
                            ))}
                        </li>
                    ))}
                {forceOptionsBtn && "See new option in form  to force action"}
            </Alert>
        </Snackbar>
    )
}
