import React from "react"
import {toast} from "react-toastify"
import {Divider} from "@material-ui/core"

import {makeStyles} from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/notificationStyle"

const useStyles = makeStyles(styles)

const MsgInfo = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.title}>{props.title}</div>
            <Divider className={classes.dividerInfo}/>
            <div className={classes.message}>{props.message}</div>
        </div>
    )
}

const MsgSuccess = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.title}>{props.title}</div>
            <Divider className={classes.dividerSuccess}/>
            <div className={classes.message}>{props.message}</div>
        </div>
    )
}

const MsgError = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.title}>{props.title}</div>
            <Divider className={classes.dividerError}/>
            <div className={classes.message}>{props.message}</div>
        </div>
    )
}

const MsgWarning = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.title}>{props.title}</div>
            <Divider className={classes.dividerWarning}/>
            <div className={classes.message}>{props.message}</div>
        </div>
    )
}

export const InfoNotification = (title, message) => ( toast.info(<MsgInfo title={title} message={message} />) )

export const SuccessNotification = (title, message) => ( toast.success(<MsgSuccess title={title} message={message} />) )

export const ErrorNotification = (title, message) => ( toast.error(<MsgError title={title} message={message} />) )

export const WarningNotification = (title, message) => ( toast.warning(<MsgWarning title={title} message={message} />) )
