import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { EditOutlined } from "@material-ui/icons"
import { TextField, Button, CircularProgress, Grid } from "@material-ui/core"

import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import GridItem from "components/Grid/GridItem"

import { Formik } from "formik"
import * as Yup from "yup"

import styles from "assets/jss/material-dashboard-react/views/cardFormStyle"

//apollo
import { Mutation } from "@apollo/client/react/components"
import { CREATE_SERVICE, LOAD_ALL_SERVICES, UPDATE_SERVICE } from "querys/admin/platformServicesQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//form errors
import { getFormErrors } from "utils/errorsUtils"

//router
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(styles)

export default function PlatformServiceForm(props) {
    const classes = useStyles()

    const updateItem = props.location.state?.updateItem.node ?? null

    const history = useHistory()

    const notifySuccess = (message) => SuccessNotification("Service", message)
    const notifyError = (message) => ErrorNotification("Service", message)

    const successForm = () => {
        updateItem ? notifySuccess("Service updated") : notifySuccess("New service added")
        history.push("/seller/platform-service")
    }

    const cancelAction = () => {
        history.push("/seller/platform-service")
    }

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <Grid container spacing={1}>
                    <Grid item xs={"auto"} className={classes.cardHeaderIcon}>
                        <EditOutlined />
                    </Grid>
                    <Grid item xs={true}>
                        <h4 className={classes.cardTitle}>{updateItem ? "Update service" : "Create service"}</h4>
                    </Grid>
                </Grid>
            </CardHeader>
            <Mutation
                mutation={updateItem ? UPDATE_SERVICE : CREATE_SERVICE}
                refetchQueries={[
                    {
                        query: LOAD_ALL_SERVICES,
                    },
                ]}
            >
                {(register, { loading }) => (
                    <Formik
                        initialValues={{
                            name: updateItem?.name ?? "",
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string().required("field required"),
                        })}
                        onSubmit={(data, { setErrors }) => {
                            register({
                                variables: {
                                    id: updateItem?.id ?? null,
                                    name: data.name,
                                },
                            }).then(
                                (response) => {
                                    let dataResponse = []
                                    if (updateItem) {
                                        dataResponse = response.data.updateService
                                    } else {
                                        dataResponse = response.data.createService
                                    }

                                    if (dataResponse.service) {
                                        return successForm()
                                    }
                                },
                                (response) => {
                                    if (response.graphQLErrors.length) {
                                        getFormErrors(response.graphQLErrors, notifyError, setErrors)
                                    } else {
                                        notifyError("Create service failed, try again")
                                    }
                                }
                            )
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="name"
                                                label="Name*"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                className={classes.formFields}
                                                {...formik.getFieldProps("name")}
                                                error={formik.errors.name && formik.touched.name}
                                                helperText={
                                                    formik.errors.name && formik.touched.name && formik.errors.name
                                                }
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter chart>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <small>(*) means that fields are required</small>
                                        </Grid>

                                        <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"secondary"}
                                                onClick={cancelAction}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"primary"}
                                                type="submit"
                                                disabled={!formik.isValid || loading}
                                            >
                                                {loading ? (
                                                    <CircularProgress size={24} />
                                                ) : updateItem ? (
                                                    "Update"
                                                ) : (
                                                    "Create"
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardFooter>
                            </form>
                        )}
                    </Formik>
                )}
            </Mutation>
        </Card>
    )
}
