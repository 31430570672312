import {
    backGroundBlueColor,
    backGroundBlueFooterColor,
    backGroundBlueHeaderColor,
    backGroundGrayColor,
    container,
    defaultFont,
    grayColor,
    whiteColor
} from "assets/jss/material-dashboard-react.js"

const landingPageStyle = (theme) => ({
    container: {
        color: whiteColor,
        overflowX: "hidden",
    },

    topLinks: {
        boxSizing: "border-box",
        display: "flex",
        top: "0",
        borderBottom: "1px solid " + grayColor[9],
        height: "60px",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        color: whiteColor,
        backgroundColor: backGroundBlueHeaderColor,
        ...defaultFont,
        ...container,
        zIndex: 200,
    },
    logo: {
        height: "50px",
        width: "50px",
        zIndex: 2,
    },

    headerButtons: {
        backgroundColor: "transparent",
        height: "59px",
        transition: "background-color 500ms ease",
        "&:hover": {
            backgroundColor: "rgba(47, 50, 58, 0.9)",
            height: "59px",
        },
    },

    colContainerSlide: {
        height: "100vh",
        [theme.breakpoints.down("sm")]: {
            height: "calc(100vh / 2)",
        },
    },
    colContainerBlocksSlide: {
        padding: "30px 0",
        minHeight: "100vh",
    },

    //slide1 background
    background1: {
        width: "100vw",
        height: "100vh",
        backgroundImage: `radial-gradient(circle closest-corner at 30% 40%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `radial-gradient(circle closest-corner at 60% 20%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        },
    },

    //slide2 background
    background2: {
        width: "100vw",
        height: "100vh",
        backgroundImage: `radial-gradient(circle closest-corner at 65% 45%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `radial-gradient(circle closest-corner at 60% 20%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        },
    },

    //slide3 background
    background3: {
        width: "100vw",
        height: "100vh",
        backgroundImage: `radial-gradient(circle closest-corner at 30% 40%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `radial-gradient(circle closest-corner at 60% 20%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        },
    },

    //slide4 background
    background4: {
        width: "100vw",
        height: "calc(100vh + 100vh)",
        backgroundImage: `radial-gradient(circle closest-corner at 75% 15%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `radial-gradient(circle closest-corner at 45% 25%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        },
    },

    //slide5 background
    background5: {
        width: "100vw",
        height: "100vh",
        backgroundImage: `radial-gradient(circle closest-corner at 50% 15%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        [theme.breakpoints.down("sm")]: {
            backgroundImage: `radial-gradient(circle closest-corner at 45% 20%, ${backGroundBlueColor}, ${backGroundGrayColor}, ${backGroundGrayColor})`,
        },
    },

    leftSide: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    blocks: {
        textAlign: "center",
        margin: "5px 0",
    },
    button: {
        marginTop: "5px",
    },
    imgContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            order: 1,
            height: "calc(100vh / 2)",
        },
    },
    img: {
        maxHeight: "300px",
        width: "auto",
        opacity: "0.5",
        [theme.breakpoints.down("xs")]: {
            maxHeight: "230px",
        },
    },
    blockRow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    blockImg: {
        fontSize: "50px",
        opacity: "0.5",
    },
    footer: {
        display: "flex",
        bottom: "0",
        borderTop: "1px solid " + grayColor[11],
        height: "60px",
        alignItems: "center",
        justifyContent: "space-between",
        color: whiteColor,
        backgroundColor: backGroundBlueFooterColor,
        ...defaultFont,
        ...container,
        [theme.breakpoints.down("xs")]: {
            height: "80px",
        },
    },
    brandText: {
        display: "flex",
        alignItems: "center",
    },
    iconsAuthor: {
        textAlign: "center",
    },
})

export default landingPageStyle
