import React from "react";
import {
    Grid,
    TextField,
    InputLabel,
    IconButton,
    Tooltip,
    MenuItem,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button
} from "@material-ui/core";
import {
    Sort,
    ArrowUpward,
    ArrowDownward
} from "@material-ui/icons";

//styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/productFiltersStyle";

//apollo
import { useQuery } from "@apollo/client";
import {LOAD_ALL_CATEGORIES} from "querys/seller/Category/categoryQueries";
import {LOAD_ALL_CONDITIONS} from "querys/admin/conditionQueries";

//redux
import {useSelector} from "react-redux";

//notifications
import {ErrorNotification} from "components/Notifications/Notifications";

const useStyles = makeStyles(styles);

export default function ProductsFilter(props) {
    const classes = useStyles()

    const notifyError = (title, message) => ErrorNotification(title, message)

    const CURRENT_USER = useSelector(state => state.loginReducer)

    const {
        setIdFilter,
        idFilter,
        setSKUFilter,
        setTitleFilter,
        setDescriptionFilter,
        setBrandFilter,
        setManufacturerFilter,
        setCategoryFilter,
        setConditionFilter,
        skuFilter,
        titleFilter,
        descriptionFilter,
        brandFilter,
        manufacturerFilter,
        categoryFilter,
        conditionFilter,
        resetPagination,
        handleRequestSort,
        order,
        orderBy,
        toggleShowFilters
    } = props

    const {data: dataCategories, error: errorCategories} = useQuery(LOAD_ALL_CATEGORIES, {
        variables: {
            sellerId: CURRENT_USER.seller.id
        }
    })

    const {data: dataConditions, error: errorConditions} = useQuery(LOAD_ALL_CONDITIONS)

    if (errorCategories) {
        notifyError("Categories", "Unable to load existing categories, try again")
    }

    if (errorConditions) {
        notifyError("Conditions", "Unable to load existing conditions, try again")
    }

    let categories = dataCategories?.allCategories.edges ?? []

    let conditions = dataConditions?.allConditions.edges ?? []

    React.useEffect(() => {
        if (categoryFilter && categories.length) {
            setCategory(categoryFilter)
        }
    }, [categories, categoryFilter])

    React.useEffect(() => {
        if (conditionFilter && conditions.length) {
            setCondition(conditionFilter)
        }
    }, [conditions, conditionFilter])

    //search fields
    const [id, setId] = React.useState(idFilter)
    const [sku, setSKU] = React.useState(skuFilter)
    const [title, setTitle] = React.useState(titleFilter)
    const [description, setDescription] = React.useState(descriptionFilter)
    const [brand, setBrand] = React.useState(brandFilter)
    const [manufacturer, setManufacturer] = React.useState(manufacturerFilter)
    const [category, setCategory] = React.useState("")
    const [condition, setCondition] = React.useState("")

    const handleFieldsValueChange = (e) => {
        switch (e.target.name) {
            case "id":
                return setId(e.target.value)
            case "sku":
                return setSKU(e.target.value)
            case "title":
                return setTitle(e.target.value)
            case "description":
                return setDescription(e.target.value)
            case "brand":
                return setBrand(e.target.value)
            case "manufacturer":
                return setManufacturer(e.target.value)
            case "category":
                return setCategory(e.target.value)
            case "condition":
                return setCondition(e.target.value)
            default: return false
        }
    }

    const getSortIcon = (field) => {
        if (orderBy === field) {
            return order === "asc" ? <ArrowUpward style={{fontSize: "14px"}}/> : <ArrowDownward style={{fontSize: "14px"}}/>
        } else return <Sort style={{fontSize: "14px"}}/>
    }

    const applyFilters = () => {
        resetPagination()

        setIdFilter(id)
        setSKUFilter(sku)
        setTitleFilter(title)
        setDescriptionFilter(description)
        setBrandFilter(brand)
        setManufacturerFilter(manufacturer)
        setCategoryFilter(category)
        setConditionFilter(condition)
        toggleShowFilters()
    }

    const cancelFilters = () => {
        resetPagination()

        setId("")
        setSKU("")
        setTitle("")
        setDescription("")
        setBrand("")
        setManufacturer("")
        setCategory("")
        setCondition("")

        setIdFilter("")
        setSKUFilter("")
        setTitleFilter("")
        setDescriptionFilter("")
        setBrandFilter("")
        setManufacturerFilter("")
        setCategoryFilter("")
        setConditionFilter("")
        toggleShowFilters()
    }

    const applySort = (field) => (handleRequestSort(field))

    return (
        <Card className={classes.container} variant={"outlined"}>
            <CardHeader
                subheader={"Filter or sort your products"}
            />
            <CardContent>
                <Grid container item xs={12}>
                    <Grid container item xs={12}>
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <InputLabel htmlFor="description" className={classes.fieldLabel}>
                                <Tooltip title="Sort data by">
                                    <IconButton
                                        aria-label="sort data"
                                        color={"inherit"}
                                        size={"small"}
                                        onClick={() => applySort("description")}
                                    >
                                        {getSortIcon("description")}
                                    </IconButton>
                                </Tooltip>
                                Description
                            </InputLabel>
                            <TextField
                                name="description"
                                size={"small"}
                                fullWidth
                                variant={"outlined"}
                                value={description}
                                onChange={handleFieldsValueChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} md={4}>
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <InputLabel htmlFor="id" className={classes.fieldLabel}>
                                ID
                            </InputLabel>
                            <TextField
                                name="id"
                                size={"small"}
                                fullWidth
                                variant={"outlined"}
                                value={id}
                                onChange={handleFieldsValueChange}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <InputLabel htmlFor="title" className={classes.fieldLabel}>
                                <Tooltip title="Sort data by">
                                    <IconButton
                                        aria-label="sort data"
                                        color={"inherit"}
                                        size={"small"}
                                        onClick={() => applySort("title")}
                                    >
                                        {getSortIcon("title")}
                                    </IconButton>
                                </Tooltip>
                                Title
                            </InputLabel>
                            <TextField
                                name="title"
                                size={"small"}
                                fullWidth
                                variant={"outlined"}
                                value={title}
                                onChange={handleFieldsValueChange}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.firstFieldContainer}>
                            <InputLabel htmlFor="sku" className={classes.fieldLabel}>
                                <Tooltip title="Sort data by">
                                    <IconButton
                                        aria-label="sort data"
                                        color={"inherit"}
                                        size={"small"}
                                        onClick={() => applySort("sku")}
                                    >
                                        {getSortIcon("sku")}
                                    </IconButton>
                                </Tooltip>
                                SKU
                            </InputLabel>
                            <TextField
                                name="sku"
                                size={"small"}
                                fullWidth
                                variant={"outlined"}
                                value={sku}
                                onChange={handleFieldsValueChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} md={4}>
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <InputLabel htmlFor="brand" className={classes.fieldLabel}>
                                <Tooltip title="Sort data by">
                                    <IconButton
                                        aria-label="sort data"
                                        color={"inherit"}
                                        size={"small"}
                                        onClick={() => applySort("brand")}
                                    >
                                        {getSortIcon("brand")}
                                    </IconButton>
                                </Tooltip>
                                Brand
                            </InputLabel>
                            <TextField
                                name="brand"
                                size={"small"}
                                fullWidth
                                variant={"outlined"}
                                value={brand}
                                onChange={handleFieldsValueChange}
                            />
                        </Grid>

                        <Grid item xs={12} className={classes.fieldContainer}>
                            <InputLabel htmlFor="manufacturer" className={classes.fieldLabel}>
                                <Tooltip title="Sort data by">
                                    <IconButton
                                        aria-label="sort data"
                                        color={"inherit"}
                                        size={"small"}
                                        onClick={() => applySort("manufacturer")}
                                    >
                                        {getSortIcon("manufacturer")}
                                    </IconButton>
                                </Tooltip>
                                Manufacturer
                            </InputLabel>
                            <TextField
                                name="manufacturer"
                                size={"small"}
                                fullWidth
                                variant={"outlined"}
                                value={manufacturer}
                                onChange={handleFieldsValueChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} md={4}>
                        <Grid item xs={12} className={classes.fieldContainer}>
                            <InputLabel htmlFor="category" className={classes.fieldLabel}>
                                Category
                            </InputLabel>
                            <TextField
                                name="category"
                                variant={"outlined"}
                                size={"small"}
                                fullWidth
                                select
                                value={category}
                                onChange={handleFieldsValueChange}
                            >
                                {categories.map((option) => (
                                    <MenuItem key={option.node.id} value={option.node.id}>
                                        {option.node.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} className={classes.fieldContainer}>
                            <InputLabel htmlFor="condition" className={classes.fieldLabel}>
                                Condition
                            </InputLabel>
                            <TextField
                                name="condition"
                                variant={"outlined"}
                                size={"small"}
                                fullWidth
                                select
                                value={condition}
                                onChange={handleFieldsValueChange}
                            >
                                {conditions.map((option) => (
                                    <MenuItem key={option.node.id} value={option.node.id}>
                                        {option.node.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>

            <CardActions className={classes.footer}>
                <Button
                    size={"small"}
                    variant={"text"}
                    color={"secondary"}
                    onClick={cancelFilters}
                >
                    Cancel filters
                </Button>

                <Button
                    size={"small"}
                    variant={"text"}
                    color={"primary"}
                    onClick={applyFilters}
                >
                    Apply filters
                </Button>
            </CardActions>
        </Card>
    )
}
