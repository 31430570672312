import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Cancel, EditOutlined, ImageOutlined } from "@material-ui/icons"
import {
    TextField,
    Button,
    CircularProgress,
    Badge,
    Avatar,
    Grid,
    Typography,
    MenuItem,
    Switch,
    FormControlLabel,
} from "@material-ui/core"

import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import GridItem from "components/Grid/GridItem"

import { Formik } from "formik"
import * as Yup from "yup"

import styles from "assets/jss/material-dashboard-react/views/cardFormStyle"

//apollo
import { Mutation } from "@apollo/client/react/components"
import { useQuery } from "@apollo/client"
import { CREATE_MARKETPLACE, UPDATE_MARKETPLACE, LOAD_ALL_REGIONS } from "querys/admin/marketplaceQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//errors
import { getFormErrors } from "utils/errorsUtils"

//router
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(styles)

export default function MarketplaceForm(props) {
    const classes = useStyles()

    const updateItem = props.location.state?.updateItem.node ?? null

    const history = useHistory()

    const [regionOptions, setRegionOptions] = React.useState([])

    useQuery(LOAD_ALL_REGIONS, {
        onCompleted: (data) => {
            if (data.allRegions && data.allRegions.length) {
                setRegionOptions(data.allRegions)
            }
        },
    })

    const [region, setRegion] = React.useState("")

    React.useEffect(() => {
        if (updateItem && updateItem.region && regionOptions.length) {
            setRegion(updateItem.region)
        }
    }, [updateItem, regionOptions])

    const notifySuccess = (message) => SuccessNotification("Marketplace", message)
    const notifyError = (message) => ErrorNotification("Marketplace", message)

    const successForm = () => {
        updateItem ? notifySuccess("Marketplace updated") : notifySuccess("New marketplace added")
        history.push("/seller/admin-marketplace")
    }

    const cancelAction = () => {
        history.push("/seller/admin-marketplace")
    }

    const composeUrl = (row) => {
        return row.image ? `${process.env.REACT_APP_API_URL}file/${row.id}` : ""
    }

    const [image, setImage] = React.useState(null)
    const [deleteActualImage, setDeleteActualImage] = React.useState(false)

    function onImageChange({
        target: {
            validity,
            files: [file],
        },
    }) {
        if (validity.valid) {
            file.preview = URL.createObjectURL(file)
            setImage(file)
        }
    }

    const deleteUpdateMarketplaceImage = () => {
        setDeleteActualImage(true)
    }

    const deleteMarketplaceImage = () => {
        setImage("")
    }

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <Grid container spacing={1}>
                    <Grid item xs={"auto"} className={classes.cardHeaderIcon}>
                        <EditOutlined />
                    </Grid>
                    <Grid item xs={true}>
                        <h4 className={classes.cardTitle}>
                            {updateItem ? "Update marketplace" : "Create marketplace"}
                        </h4>
                    </Grid>
                </Grid>
            </CardHeader>
            <Mutation mutation={updateItem ? UPDATE_MARKETPLACE : CREATE_MARKETPLACE}>
                {(register, { loading }) => (
                    <Formik
                        initialValues={{
                            name: updateItem?.name ?? "",
                            region: region,
                            description: updateItem?.description ?? "",
                            isEnabled: updateItem?.isEnabled ?? true,
                        }}
                        validationSchema={Yup.object({
                            name: Yup.string().required("field required"),
                            region: Yup.string().when("name", {
                                is: (val) => !!val && (val.toUpperCase() === "AMAZON" || val.toUpperCase() === "EBAY"),
                                then: Yup.string().required("Region requred for marketplace"),
                            }),
                            description: Yup.string().nullable(),
                            isEnabled: Yup.bool(),
                        })}
                        enableReinitialize
                        onSubmit={(data, { setErrors }) => {
                            register({
                                variables: {
                                    id: updateItem?.id ?? null,
                                    name: data.name,
                                    region: data.region,
                                    description: data.description,
                                    isEnabled: data.isEnabled,
                                    deleteActualImagen: deleteActualImage,
                                    file: image,
                                },
                            }).then(
                                (response) => {
                                    let dataResponse = []
                                    if (updateItem) {
                                        dataResponse = response.data.updateMarketplace
                                    } else {
                                        dataResponse = response.data.createMarketplace
                                    }

                                    if (dataResponse.marketplace) {
                                        return successForm()
                                    }
                                },
                                (response) => {
                                    if (response.graphQLErrors.length) {
                                        getFormErrors(response.graphQLErrors, notifyError, setErrors)
                                    } else {
                                        notifyError("Create marketplace failed, try again")
                                    }
                                }
                            )
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="name"
                                                label="Name*"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                className={classes.formFields}
                                                {...formik.getFieldProps("name")}
                                                error={formik.errors.name && formik.touched.name}
                                                helperText={
                                                    formik.errors.name && formik.touched.name && formik.errors.name
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="region"
                                                label="Region"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                select
                                                className={classes.formFields}
                                                {...formik.getFieldProps("region")}
                                                error={formik.errors.region && formik.touched.region}
                                                helperText={
                                                    formik.errors.region &&
                                                    formik.touched.region &&
                                                    formik.errors.region
                                                }
                                            >
                                                <MenuItem value={""}>
                                                    <b>select none</b>
                                                </MenuItem>
                                                {regionOptions.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="description"
                                                label="Description"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                multiline
                                                minRows={3}
                                                className={classes.formFields}
                                                {...formik.getFieldProps("description")}
                                                error={formik.errors.description && formik.touched.description}
                                                helperText={
                                                    formik.errors.description &&
                                                    formik.touched.description &&
                                                    formik.errors.description
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        color={"primary"}
                                                        size={"small"}
                                                        checked={formik.values.isEnabled}
                                                        {...formik.getFieldProps("isEnabled")}
                                                    />
                                                }
                                                label="Enable marketplace?"
                                                labelPlacement={"start"}
                                                className={classes.formFields}
                                            />
                                        </GridItem>
                                        {(image || (updateItem?.image && !deleteActualImage)) && (
                                            <GridItem xs={12}>
                                                <Typography variant={"caption"} style={{ color: "rgb(244, 67, 57)" }}>
                                                    Don't select any image to use default optimized image
                                                </Typography>
                                            </GridItem>
                                        )}
                                        <GridItem xs={12}>
                                            <div className={classes.formFields}>
                                                <input
                                                    name="image"
                                                    accept="image/*"
                                                    id="contained-button-file"
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={onImageChange}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button
                                                        variant="contained"
                                                        component="span"
                                                        startIcon={<ImageOutlined />}
                                                    >
                                                        Image
                                                    </Button>
                                                </label>
                                            </div>
                                            {updateItem && updateItem.image && !image && !deleteActualImage && (
                                                <Badge
                                                    overlap={"circular"}
                                                    badgeContent={
                                                        <Cancel
                                                            fontSize={"small"}
                                                            style={{ color: "rgb(255, 255, 255)", cursor: "pointer" }}
                                                        />
                                                    }
                                                    onClick={() => deleteUpdateMarketplaceImage()}
                                                >
                                                    <Avatar
                                                        src={composeUrl(updateItem)}
                                                        variant={"rounded"}
                                                        className={classes.avatar}
                                                    />
                                                </Badge>
                                            )}
                                            {image && (
                                                <Badge
                                                    overlap={"circular"}
                                                    badgeContent={
                                                        <Cancel
                                                            fontSize={"small"}
                                                            style={{ color: "rgb(255, 255, 255)", cursor: "pointer" }}
                                                        />
                                                    }
                                                    onClick={() => deleteMarketplaceImage()}
                                                >
                                                    <Avatar
                                                        src={image.preview}
                                                        variant={"rounded"}
                                                        className={classes.avatar}
                                                    />
                                                </Badge>
                                            )}
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter chart>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <small>(*) means that fields are required</small>
                                        </Grid>

                                        <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"secondary"}
                                                onClick={cancelAction}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"primary"}
                                                type="submit"
                                                disabled={!formik.isValid || loading}
                                            >
                                                {loading ? (
                                                    <CircularProgress size={24} />
                                                ) : updateItem ? (
                                                    "Update"
                                                ) : (
                                                    "Create"
                                                )}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardFooter>
                            </form>
                        )}
                    </Formik>
                )}
            </Mutation>
        </Card>
    )
}
