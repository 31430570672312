import gql from "graphql-tag"

//product listing, of current seller, for all marketplaces, using pagination and sort
export const LOAD_PRODUCT_LISTINGS = gql`
    query allProductsStatusInAllMarketplaces(
        $sellerId: ID!
        $orderBy: [String]!
        $skuFilter: String
        $titleFilter: String
        $descriptionFilter: String
        $brandFilter: String
        $manufacturerFilter: String
        $categoryFilter: ID
        $conditionFilter: ID
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allProductsStatusInAllSellerMarketplaces(
            sellerId: $sellerId
            orderBy: $orderBy
            sku: $skuFilter
            title_Icontains: $titleFilter
            description_Icontains: $descriptionFilter
            brand_Icontains: $brandFilter
            manufacturer_Icontains: $manufacturerFilter
            categoryId: $categoryFilter
            conditionId: $conditionFilter
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    title
                    description
                    sku
                    productId
                    isBlocked
                    statusInSellerMarketplaces {
                        sellerMarketplace {
                            id
                            marketplace {
                                name
                                fullName
                            }
                        }
                        isLinked
                        isUploaded
                        isExpired
                        url
                        isPublished
                        productSellerMarketplaceFieldsId
                        productSellerMarketplaceId
                    }
                }
            }
        }
    }
`

//check apis access status
export const CHECK_API_WORKING = gql`
    query allMarketplacesApiWorking {
        allSellerMarketplacesApiWorking {
            sellerMarketplace {
                id
                alias
                marketplace {
                    id
                    name
                    fullName
                    description
                    image
                    isEnabled
                }
                isBlocked
            }
            apiWorking {
                code
                message
            }
        }
    }
`
