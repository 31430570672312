import React from "react";

import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import CardIcon from "components/Card/CardIcon";

import DownloadCircleOutlineIcon from "mdi-react/DownloadCircleOutlineIcon";
import TimerOutlineIcon from "mdi-react/TimerOutlineIcon";

import {
    Avatar,
    Button,
    CircularProgress,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    TextField, Tooltip,
    Typography
} from "@material-ui/core";

//apollo
import {useMutation, useQuery} from "@apollo/client";
import {
    SCHEDULE_TASK_IS_RUNNING,
    SCHEDULE_TASK_START,
    SCHEDULE_TASK_STOP,
    SCHEDULE_TASK_MANUALLY_DOWNLOAD
} from "querys/admin/scheduleTasksQueries";

//forms
import * as Yup from "yup";
import {Formik} from "formik";

//styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/configurationStyle";

//notifications
import {ErrorNotification, SuccessNotification} from "components/Notifications/Notifications";
import Alert from "@material-ui/lab/Alert";

//icon
import {Close} from "@material-ui/icons";

//errors
import {getFormErrors} from "utils/errorsUtils";

//images
import EtsyImg from "assets/img/marketplaces/Etsy_icon.svg";
import EbayImg from "assets/img/marketplaces/Ebay_icon.svg";
import WarningOutlineIcon from "mdi-react/WarningOutlineIcon";

const useStyles = makeStyles(styles);

export default function CategoryTreeChanges() {
    const classes = useStyles();

    const notifySuccess = message => SuccessNotification("Schedule task", message)
    const notifyError = message => ErrorNotification("Schedule task", message)

    //to know if schedule task is running
    const {data, error} = useQuery(
        SCHEDULE_TASK_IS_RUNNING,
        {
            fetchPolicy: "network-only"
        }
    )

    if (error) {
        notifyError("Unable to load status of schedule task, try again")
    }

    let task = data?.schedulerIsRunningCategoryTreesChangesDownload ?? {isRunning: false}

    //start task
    const [startTask, {loading: loadingStartTask}] = useMutation(SCHEDULE_TASK_START)
    //stop task
    const [stopTask, {loading: loadingStopTask}] = useMutation(SCHEDULE_TASK_STOP)
    //manually download changes
    const [manuallyDownload, {loading: loadingManuallyDownload}] = useMutation(SCHEDULE_TASK_MANUALLY_DOWNLOAD)

    function handleStopTask() {
        stopTask({
            refetchQueries: [
                {
                    query: SCHEDULE_TASK_IS_RUNNING
                }
            ]
        }).then(
            (response) => {
                let dataResponse = response.data.schedulerStopCategoryTreesChangesDownload

                if (dataResponse.success) {
                    return notifySuccess(`Task has been stopped at specified time`)
                } else {
                    return  notifyError("Schedule task stop failed, try again")
                }
            },
            (response) => {
                if (response.graphQLErrors.length) {
                    getFormErrors(response.graphQLErrors, notifyError, null)
                } else {
                    notifyError("Schedule task stop failed, try again")
                }
            }
        )
    }

    function handleManuallyDownloadTask() {
        manuallyDownload().then(
            (response) => {
                let dataResponse = response.data.schedulerManualCategoryTreesChangesDownload

                if (dataResponse) {
                    if (dataResponse.results.length > 0) {
                        setManuallyDownloadResponse(dataResponse.results)
                        setOpenManuallyDownloadAlert(true)
                    }
                    return notifySuccess(`Manually download changes successfully`)
                }
            },
            (response) => {
                if (response.graphQLErrors.length) {
                    getFormErrors(response.graphQLErrors, notifyError, null)
                } else {
                    notifyError("Manually download changes failed, try again")
                }
            }
        )
    }

    //manually download response
    const [manuallyDownloadResponse, setManuallyDownloadResponse] = React.useState([])
    const [openManuallyDownloadAlert, setOpenManuallyDownloadAlert] = React.useState(false)

    const ResponseAlert = () => (
        <Collapse in={openManuallyDownloadAlert}>
            <Alert
                variant={"filled"}
                color={"info"}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpenManuallyDownloadAlert(false)
                        }}
                    >
                        <Close fontSize="inherit"/>
                    </IconButton>
                }
            >
                {
                    manuallyDownloadResponse.length > 0
                    && manuallyDownloadResponse.map(item => (
                        <li key={item} className={classes.list}>
                            {item}
                        </li>
                    ))
                }
            </Alert>
        </Collapse>
    )

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="success">
                    <DownloadCircleOutlineIcon style={{color: "#fff"}}/>
                </CardIcon>
                <Typography variant="subtitle2" style={{marginRight: "45px"}}>Download changes of category tree</Typography>
                <div className={classes.headerActions}>
                    {
                        (loadingStartTask || loadingStopTask || loadingManuallyDownload)
                            ? <CircularProgress size={24}/>
                            : <>
                                <Avatar
                                    src={EtsyImg}
                                    variant={"circular"}
                                    className={classes.avatar}
                                />
                                <Avatar
                                    src={EbayImg}
                                    variant={"circular"}
                                    className={classes.avatar}
                                />
                            </>
                    }
                </div>
            </CardHeader>
            <Formik
                initialValues={
                    {
                        time: "",
                    }
                }
                validationSchema={
                    Yup.object({
                        time: Yup
                            .string()
                            .required("time value required")
                            .test({
                                name: 'timeFormat',
                                exclusive: false,
                                params: {},
                                message: `time format incorrect`,
                                test: function (value) {
                                    let timeRegExp = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
                                    return (timeRegExp.test(value))
                                }
                            })
                    })
                }
                onSubmit={(data, { setErrors, resetForm }) => {
                    startTask({
                        variables: {
                            time: data.time
                        },
                        refetchQueries: [
                            {
                                query: SCHEDULE_TASK_IS_RUNNING
                            }
                        ]
                    }).then(
                        (response) => {
                            let dataResponse = response.data.schedulerStartCategoryTreesChangesDownload

                            if (dataResponse.success) {
                                resetForm()
                                return notifySuccess(`Task has been schedule at specified time`)
                            } else {
                                return notifyError("Schedule task start failed, try again")
                            }
                        },
                        (response) => {
                            if (response.graphQLErrors.length) {
                                getFormErrors(response.graphQLErrors, notifyError, setErrors)
                            } else {
                                notifyError("Schedule task start failed, try again")
                            }
                        }
                    )
                }}
            >
                {
                    formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <CardBody>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        Download task is
                                        {
                                            task.isRunning
                                                ? <code className={classes.runningTask}>running</code>
                                                : <code className={classes.stoppedTask}>stopped</code>
                                        }
                                        {
                                            task.params
                                            && <>
                                                <span>with schedule time at</span>
                                                <code className={classes.timeInterval}>{task.params}</code>
                                            </>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            task.lastError
                                            && <Tooltip
                                                title={task.lastError}
                                                classes={{tooltip: classes.infoTooltip}}
                                            >
                                                <code className={classes.errorOccurred}>
                                                    <WarningOutlineIcon color={"#fff"} fontSize={12}/>
                                                    Error occurred during execution
                                                </code>
                                            </Tooltip>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="time"
                                            label="Schedule task at"
                                            variant={"filled"}
                                            size={"small"}
                                            disabled={task.isRunning}
                                            {...formik.getFieldProps("time")}
                                            error={formik.errors.time && formik.touched.time}
                                            helperText={(formik.errors.time && formik.touched.time) && formik.errors.time}
                                            placeholder={"hh:mm"}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <TimerOutlineIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid container item xs={12} spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Button
                                                size={"small"}
                                                variant={"contained"}
                                                color={"primary"}
                                                type={"submit"}
                                                disabled={task.isRunning || !formik.isValid}
                                            >
                                                Start
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button
                                                size={"small"}
                                                variant={"contained"}
                                                color={"secondary"}
                                                disabled={!task.isRunning}
                                                onClick={handleStopTask}
                                            >
                                                Stop
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <Button
                                            size={"small"}
                                            variant={"contained"}
                                            onClick={handleManuallyDownloadTask}
                                        >
                                            Manually download
                                        </Button>
                                    </Grid>
                                    <Grid container item xs={12}>
                                        <ResponseAlert />
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </form>
                    )
                }
            </Formik>
        </Card>
    )
}
