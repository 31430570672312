import React from "react"

// @material-ui/core
import { makeStyles, Backdrop, Grid, CircularProgress } from "@material-ui/core"

//components
import UserSellerForm from "views/Seller/UserSellerForm/UserSellerForm"
import { ErrorNotification } from "components/Notifications/Notifications"

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js"

//apollo
import { Query } from "@apollo/client/react/components"
import { AUTH_USER_VALID_ID } from "querys/auth/loginQueries"


import DashboardStats from "./DashboardStats"

const useStyles = makeStyles(styles)

export default function Dashboard(props) {
    const classes = useStyles()

    const notifyError = (message) => ErrorNotification("Seller", message)

    return (
        <Query query={AUTH_USER_VALID_ID}>
            {({ data, error, refetch }) => {
                if (error) {
                    notifyError("Unable to load seller info, try again")
                }

                if (!data)
                    return (
                        <Grid container justifyContent={"center"}>
                            <CircularProgress size={36} color={"primary"} />
                        </Grid>
                    )

                if (data && !data?.me2?.seller) {
                    return (
                        <Backdrop open={true} className={classes.backDrop}>
                            <Grid container justifyContent={"center"}>
                                <Grid item xs={11} md={8}>
                                    <UserSellerForm refetch={refetch} />
                                </Grid>
                            </Grid>
                        </Backdrop>
                    )
                }

                return <DashboardStats />
            }}
        </Query>
    )
}
