import React from "react";
import {Grid} from "@material-ui/core";

// core components
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";

//styles
import styles from "assets/jss/material-dashboard-react/components/dashboardStatsStyle";
import { makeStyles } from "@material-ui/core/styles";

//charts
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {BarChartOutlined} from "@material-ui/icons";
import {primaryColor} from "assets/jss/material-dashboard-react";

const useStyles = makeStyles(styles);

export default function UserProductsChartStats(props) {
    const classes = useStyles();

    const {sellerStats} = props

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            }
        }
    }

    const [data, setData] = React.useState({labels: [], datasets: []})

    React.useEffect(() => {
        if (sellerStats) {
            let localLabels = ["Etsy"]
            let localSeries = [
                {
                    data: [sellerStats.totalProductsEtsy],
                    label: "Products by marketplace",
                    backgroundColor: 'rgba(54, 162, 235, 0.4)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                },
                {
                    data: [sellerStats.totalProductsUploadedEtsy],
                    label: "Products uploaded",
                    backgroundColor: 'rgba(75, 192, 192, 0.4)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1
                },
                {
                    data: [sellerStats.totalProductsPublishedEtsy],
                    label: "Products published",
                    backgroundColor: 'rgba(255, 206, 86, 0.4)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    borderWidth: 1
                },
                {
                    label: "Products expired",
                    data: [sellerStats.totalProductsExpiredEtsy],
                    backgroundColor: 'rgba(255, 99, 132, 0.4)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1
                }
            ]

            if (sellerStats.amazonInformation.length > 0) {
                localLabels = [...localLabels, ...sellerStats.amazonInformation.map(marketplace => `Amazon ${marketplace.region}`)]

                localSeries[0].data = [...localSeries[0].data, ...sellerStats.amazonInformation.map(marketplace => marketplace.totalProductsAmazon)]
                localSeries[1].data = [...localSeries[1].data, ...sellerStats.amazonInformation.map(marketplace => marketplace.totalProductsUploadedAmazon)]
                localSeries[2].data = [...localSeries[2].data, ...sellerStats.amazonInformation.map(marketplace => marketplace.totalProductsPublishedAmazon)]
                localSeries[3].data = [...localSeries[3].data, ...sellerStats.amazonInformation.map(marketplace => 0)]
            }

            if (sellerStats.ebayInformation.length > 0) {
                localLabels = [...localLabels, ...sellerStats.ebayInformation.map(marketplace => `Ebay ${marketplace.region}`)]

                localSeries[0].data = [...localSeries[0].data, ...sellerStats.ebayInformation.map(marketplace => marketplace.totalProductsEbay)]
                localSeries[1].data = [...localSeries[1].data, ...sellerStats.ebayInformation.map(marketplace => marketplace.totalProductsUploadedEbay)]
                localSeries[2].data = [...localSeries[2].data, ...sellerStats.ebayInformation.map(marketplace => marketplace.totalProductsPublishedEbay)]
                localSeries[3].data = [...localSeries[3].data, ...sellerStats.ebayInformation.map(marketplace => 0)]
            }

            setData({labels: localLabels, datasets: localSeries})
        }
    }, [sellerStats])

    return (
        <Card chart>
            <CardHeader>
                <Bar type="bar" options={options} data={data} />
            </CardHeader>
            <CardBody>
                <Grid container>
                    <Grid item xs={12}>
                        <h4 className={classes.cardTitle} style={{display: "flex", alignItems: "center"}}>
                            <BarChartOutlined style={{marginRight: 5, color: primaryColor[0]}}/>
                            User products by marketplace
                        </h4>
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
    )
}