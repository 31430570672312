import gql from "graphql-tag"

//all notifications for sellerId
export const LOAD_SELLER_NOTIFICATIONS = gql`
    query allSellerNotification(
        $sellerId: ID!
        $waSeen: Boolean
        $orderBy: [String]
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allSellerNotification(
            sellerId: $sellerId
            wasSeen: $waSeen
            orderBy: $orderBy
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    seller {
                        id
                    }
                    wasSeen
                    type
                    value
                    reference
                    creationDate
                }
            }
        }
    }
`

//update notification
export const READ_SELLER_NOTIFICATION = gql`
    mutation updateSellerNotification($id: ID!, $seller: ID!, $type: Int, $value: String, $reference: String, $creationDate: Date) {
        updateSellerNotification(
            id: $id
            input: {seller: $seller, type: $type, wasSeen: true, value: $value, reference: $reference, creationDate: $creationDate }
        ) {
            sellerNotification {
                id
                seller {
                    id
                }
                wasSeen
                type
                value
                reference
                creationDate
            }
        }
    }
`