import React from "react"

import { TableCell, TableHead, TableRow, Typography, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import AddShoppingCartIcon from "mdi-react/AddShoppingCartIcon"

import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"

//assets
import styles from "assets/jss/material-dashboard-react/views/infoPageStyle"
import { primaryColor } from "../../assets/jss/material-dashboard-react"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"

//icons
import Number2CircleOutlineIcon from "mdi-react/Number2CircleOutlineIcon"
import Number1CircleOutlineIcon from "mdi-react/Number1CircleOutlineIcon"
import InformationCircleOutlineIcon from "mdi-react/InformationCircleOutlineIcon"

const useStyles = makeStyles(styles)

export default function FeatureProductAdds(props) {
    const classes = useStyles()

    //insert add
    const adsData1 = [
        {
            action: "Insert ad for a specific marketplace",
            details: "Insert the ad in a given product for every marketplace desired",
        },
    ]

    //search from amazon catalog
    const adsData2 = [
        {
            action: "Search product in Amazon's Catalog",
            details: "Searching for an equivalent product in Amazon's Catalog and creating ad from them",
        },
        {
            action: "Provided options",
            details:
                "Several options are provided depending on aspects from product in our system an product at Amazon",
        },
    ]

    //import add from marketplace
    const adsData3 = [
        {
            action: "Access Import product page",
            details: "Access import page",
        },
        {
            action: "Import from available marketplaces",
            details: "Select marketplace from available where desired product(s) exist",
        },
        {
            action: "Select the ads to import",
            details: "Select desired ads to import. Already imported ads can not be imported",
        },
        {
            action: "Import selected ads",
            details: "Use import button to complete action",
        },
    ]

    const CustomTableHead = () => (
        <TableHead>
            <TableRow>
                <TableCell>Action</TableCell>
                <TableCell>Description</TableCell>
            </TableRow>
        </TableHead>
    )

    return (
        <Card className={classes.cardContainer}>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <AddShoppingCartIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Product ads</h4>
            </CardHeader>
            <CardBody>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"body2"}>
                            Ads are the reflection of your products (at Amazon, Etsy or Ebay) in Sync2Markets. For every
                            product in the system the user can insert (also available import or search from catalog) an
                            ad for each marketplace. Once your ads are inserted, the user can sell the product in a
                            specific marketplace.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={"body2"}>Sync2Markets' users has two ways to create the ads.</Typography>
                    </Grid>

                    <Grid container item spacing={5}>
                        <Grid container item>
                            <Grid item xs={12} style={{ textAlign: "justify" }}>
                                <Typography variant={"body2"}>
                                    <Number1CircleOutlineIcon color={primaryColor[0]} />
                                    First way is inserting the ad in a product of Syc2Markets for a specific
                                    marketplace.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} lg={6}>
                                <Table>
                                    <CustomTableHead />

                                    <TableBody>
                                        {adsData1.map((item) => (
                                            <TableRow key={item.action}>
                                                <TableCell>{item.action}</TableCell>
                                                <TableCell>{item.details}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={12} style={{ textAlign: "justify" }}>
                                <Typography variant={"body2"} style={{ paddingLeft: "20px" }}>
                                    <InformationCircleOutlineIcon />
                                    Optionally you can find a product in Amazon's Catalog to relate with your product
                                    and create your ad from them.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} lg={6}>
                                <Table>
                                    <CustomTableHead />

                                    <TableBody>
                                        {adsData2.map((item) => (
                                            <TableRow key={item.action}>
                                                <TableCell>{item.action}</TableCell>
                                                <TableCell>{item.details}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>

                        <Grid container item>
                            <Grid item xs={12} style={{ textAlign: "justify" }}>
                                <Typography variant={"body2"}>
                                    <Number2CircleOutlineIcon color={primaryColor[0]} />
                                    Second way is importing a product and corresponding ad from an available
                                    marketplace.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} lg={6}>
                                <Table>
                                    <CustomTableHead />

                                    <TableBody>
                                        {adsData3.map((item) => (
                                            <TableRow key={item.action}>
                                                <TableCell>{item.action}</TableCell>
                                                <TableCell>{item.details}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
    )
}
