import gql from "graphql-tag"

//group listing using pagination and sort
export const LOAD_GROUPS = gql`
    query allGroupsPaginated(
        $orderBy: [String]!
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allGroups(
            orderBy: $orderBy
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    name
                    permissions {
                        edges {
                            node {
                                id
                                name
                                codename
                            }
                        }
                    }
                }
            }
        }
    }
`

//all groups listing, used for selecting nomenclator
export const LOAD_ALL_GROUPS = gql`
    query allGroups {
        allGroups {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

//add new group
export const CREATE_GROUP = gql`
    mutation createGroup($name: String!, $permissions: [ID]) {
        createGroup(input: { name: $name, permissions: $permissions }) {
            group {
                id
                name
            }
        }
    }
`

//update group
export const UPDATE_GROUP = gql`
    mutation updateGroup($id: ID!, $name: String!, $permissions: [ID]) {
        updateGroup(id: $id, input: { name: $name, permissions: $permissions }) {
            group {
                id
                name
            }
        }
    }
`

//delete group
export const DELETE_GROUP = gql`
    mutation deleteGroup($id: ID!) {
        deleteGroup(id: $id) {
            found
            deletedId
        }
    }
`
