import React from "react"
import { List, Paper, TablePagination } from "@material-ui/core"

//apollo
import { useLazyQuery, useQuery } from "@apollo/client"
import {
    DOWNLOAD_EBAY_MESSAGES,
    //DOWNLOAD_EBAY_MESSAGES,
    LOAD_ALL_MARKETPLACE_MESSAGES,
} from "querys/MessageCenter/messageCenterQueries"

//notifications
import { ErrorNotification } from "components/Notifications/Notifications"

//utils
import { LoadingSkeleton, TableNoData } from "utils/tableUtils"

//components
import MessageCenterTableHeader from "components/Seller/MessageCenter/MessageCenterTableHeader"

//assets
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/tableStyle"

import EbayMessageRow from "components/Seller/MessageCenter/EbayMessageRow"

import { showApiResponse } from "actions/globalComponents/apiResponseActions"
import { useDispatch, useSelector } from "react-redux"

const useStyles = makeStyles(styles)

const notifyError = (message) => ErrorNotification("Messages Center", message)

export default function EnhancedTable() {
    const classes = useStyles()
    const dispatch = useDispatch()

    //download ebay messages
    const [downloadEbayMessages] = useLazyQuery(DOWNLOAD_EBAY_MESSAGES, {
        onCompleted: (data) => {
            const apiResponse = data?.ebayDownloadMessages?.apiResponseItem
            if (apiResponse) {
                dispatch(showApiResponse({ open: true, message: apiResponse, forceOptionsBtn: false }))
            }
        },
        onError: () => {
            notifyError("Download new messages from Ebay failed, try again")
        },
    })

    const marketplaceConnectionStatus = useSelector((state) => state.marketplaceConnectionReducer)
    React.useEffect(() => {
        Object.keys(marketplaceConnectionStatus.marketplaceStatus).forEach((marketplaceSellerId) => {
            const row = marketplaceConnectionStatus.marketplaceStatus[marketplaceSellerId]

            if (row.sellerMarketplace.marketplace.name.toUpperCase() === "EBAY")
                downloadEbayMessages({ variables: { marketplaceId: row.sellerMarketplace.marketplace.id } })
        })
    }, [marketplaceConnectionStatus.marketplaceStatus, downloadEbayMessages])

    //local filters
    const [displayAllEbayMessages, setDisplayAllEbayMessages] = React.useState(true)

    //pagination
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(0)

    const setPaginationResponse = (data) => [setTotalItemsPagination(data.allMyMarketplaceMessages.totalCount)]

    //load marketplaces orders
    const { data, loading: loadingMessages } = useQuery(LOAD_ALL_MARKETPLACE_MESSAGES, {
        variables: {
            page: page + 1,
            perPage: rowsPerPage,
        },
        onCompleted: (data1) => {
            setPaginationResponse(data1)
        },
        onError: () => {
            notifyError("Unable to load platforms messages, try again")
        },
    })

    const handleChangePage = async (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <MessageCenterTableHeader
                    classes={classes}
                    loading={loadingMessages}
                    displayAllEbayMessages={displayAllEbayMessages}
                    setDisplayAllEbayMessages={setDisplayAllEbayMessages}
                />

                {loadingMessages ? (
                    <LoadingSkeleton />
                ) : !data?.allMyMarketplaceMessages?.messages?.length ? (
                    <TableNoData />
                ) : (
                    <List>
                        {data.allMyMarketplaceMessages.orders.map((row, rowIndex) => {
                            if (displayAllEbayMessages && row.ebayInfo)
                                return (
                                    <EbayMessageRow
                                        row={row.ebayInfo}
                                        rowIndex={rowIndex}
                                        key={row.rowIndex.id}
                                        classes={classes}
                                        notifyError={notifyError}
                                    />
                                )

                            return null
                        })}
                    </List>
                )}

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalItemsPagination}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}
