import React, { useEffect } from "react"
import { Switch, Route, Redirect, useLocation } from "react-router-dom"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// core components
import Navbar from "components/Navbars/Navbar.js"
import Footer from "components/Footer/Footer.js"
import Sidebar from "components/Sidebar/Sidebar.js"
import { Fab } from "@material-ui/core"

//api response alert
import ApiResponse from "components/Seller/ApiResponse"

import routes from "authRoutes.js"

//assets
import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js"

//apollo
import { useMutation, useQuery } from "@apollo/client"
import { REFRESH_TOKEN } from "querys/auth/loginQueries"
import { CHECK_API_WORKING } from "querys/seller/Product sell/listingQueries"

//redux
import { useSelector, useDispatch } from "react-redux"
import {
    addMarketplaceData,
    updateConnectionIssuesQuantity,
} from "actions/globalComponents/marketplaceConnectionActions"

import * as Cookies from "js-cookie"

//notifications
import { ErrorNotification } from "components/Notifications/Notifications"

const useStyles = makeStyles(styles)

const notifyError = (title, message) => ErrorNotification(title, message)

export default function SellerLayout({ ...rest }) {
    const { pathname } = useLocation()
    const dispatch = useDispatch()

    // styles
    const classes = useStyles()
    // ref to help us initialize PerfectScrollbar on windows devices
    const mainPanel = React.createRef()
    // states and functions
    const [color] = React.useState("blue")
    const [drawerOpen, setDrawerOpen] = React.useState(false)

    const authUser = useSelector((state) => state.loginReducer)
    const storedRefreshToken = localStorage.getItem("refreshToken")

    const [getNewToken] = useMutation(REFRESH_TOKEN, {
        variables: {
            refreshToken: storedRefreshToken,
        },
        onCompleted: (data) => {
            if (data?.refreshToken?.token && data?.refreshToken?.refreshToken) {
                const token = data.refreshToken.token
                const refreshToken = data.refreshToken.refreshToken
                Cookies.set("JWT", token, {
                    secure: true,
                    sameSite: "Strict",
                    expires: 15,
                })
                localStorage.setItem("refreshToken", refreshToken)
            }
        },
        onError: () => {
            notifyError(
                "Authentication",
                "Automatically credentials sync failed. Re authenticate to eliminate this issue"
            )
        },
    })

    React.useEffect(() => {
        if (storedRefreshToken) {
            getNewToken()
        }
        // eslint-disable-next-line
  }, []);

    //checking marketplaces connection
    useQuery(CHECK_API_WORKING, {
        onCompleted: (data) => {
            if (data?.allSellerMarketplacesApiWorking?.length) {
                let connectionIssuesQuantity = 0
                for (const sellerMarketplacesApiWorkingElement of data.allSellerMarketplacesApiWorking) {
                    const data = {
                        sellerMarketplaceId: sellerMarketplacesApiWorkingElement.sellerMarketplace.id,
                        sellerMarketplaceData: sellerMarketplacesApiWorkingElement,
                    }

                    dispatch(addMarketplaceData(data))

                    if (sellerMarketplacesApiWorkingElement.apiWorking.code !== "200") {
                        notifyError(
                            "Connection",
                            `Connection to ${sellerMarketplacesApiWorkingElement.sellerMarketplace.alias} could not be established. Some functionalities may not work correctly`
                        )

                        connectionIssuesQuantity++
                    }
                }

                dispatch(
                    updateConnectionIssuesQuantity({
                        connectionIssuesQuantity: connectionIssuesQuantity,
                    })
                )
            }
        },
    })

    //known if authenticated user is admin. a user is admin if isStaff = true or isSuperuser = true or belongs to a group named "admin"
    function isAdminUser(user) {
        if (user.isStaff || user.isSuperuser) {
            return true
        }
        return user.groups.findIndex((group) => group.name.toUpperCase() === "ADMIN") > -1
    }

    //back to top btn
    const scrollToTop = () => {
        mainPanel.current.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    }

    const BackToTop = () => {
        return (
            <div className={classes.backToTopContainer}>
                <Fab
                    color="primary"
                    size={"small"}
                    aria-label="backToTop"
                    className={classes.backToTopFab}
                    onClick={scrollToTop}
                >
                    &#8593;
                </Fab>
            </div>
        )
    }

    const switchRoutes = (
        <Switch>
            {routes.map((prop, key) => {
                if (prop.layout === "/seller" || (isAdminUser(authUser) && prop.layout === "/admin")) {
                    return <Route path={"/seller" + prop.path} component={prop.component} exact key={key} />
                }
                return null
            })}
            <Redirect from="/seller" to="/seller/dashboard" />
        </Switch>
    )

    function Scroll() {
        return mainPanel.current.scrollTo({
            top: 0,
            behavior: "auto",
        })
    }

    //when route change auto scroll to top
    useEffect(() => {
        Scroll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen)
    }

    return (
        <div className={classes.wrapper}>
            <Sidebar
                routes={routes}
                handleDrawerToggle={handleDrawerToggle}
                open={drawerOpen}
                color={color}
                {...rest}
            />

            <Navbar routes={routes} handleDrawerToggle={handleDrawerToggle} {...rest} />

            <div className={classes.mainPanel} ref={mainPanel}>
                <div className={classes.content}>
                    <ApiResponse />
                    <div className={classes.container}>{switchRoutes}</div>
                    <BackToTop />
                </div>

                <Footer />
            </div>
        </div>
    )
}
