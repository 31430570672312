import React from "react"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/tableStyle"

//material ui
import { Skeleton } from "@material-ui/lab"
import { Grid, Slide } from "@material-ui/core"
import DoneIcon from "mdi-react/DoneIcon"

const useStyles = makeStyles(styles)

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

export function getComparator(order, orderBy) {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
}

export const LoadingSkeleton = () => {
    const classes = useStyles()

    return (
        <Grid container className={classes.skeletonContainer}>
            <Grid item xs={12}>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Grid>
        </Grid>
    )
}

export const TableNoData = () => {
    const classes = useStyles()

    return <div className={classes.noDataSection}>No data to show</div>
}

export const DoneCategorySelect = () => {
    const classes = useStyles()

    return (
        <div className={classes.noDataSection}>
            <DoneIcon /> Done
        </div>
    )
}

export const NoMarketplaceSelected = () => {
    const classes = useStyles()

    return <div className={classes.noDataSection}>Select a marketplace to show data</div>
}

export const NoMarketplaceToImportDataSelected = () => {
    const classes = useStyles()

    return <div className={classes.noDataSection}>Select a marketplace to import data</div>
}

export const ConfirmTransition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})
