import { gql } from "@apollo/client"

//marketplace listing of current seller, using pagination and sort
export const LOAD_SELLER_MARKETPLACES = gql`
    query allSellersMarketplaces(
        $sellerId: ID!
        $orderBy: [String]!
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allSellersMarketplaces(
            sellerId: $sellerId
            orderBy: $orderBy
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    alias
                    marketplace {
                        id
                        name
                        fullName
                        description
                        image
                        isEnabled
                    }
                    isBlocked
                }
            }
        }
    }
`

//delete seller marketplace
export const DELETE_SELLER_MARKETPLACE = gql`
    mutation deleteSellerMarketplace($id: ID!) {
        deleteSellerMarketplace(id: $id) {
            found
            deletedId
        }
    }
`
