import React from "react"
import { Box, Typography } from "@material-ui/core"

//styles
import styles from "assets/jss/material-dashboard-react/components/DashboardMainButtonStyle"
import { makeStyles } from "@material-ui/core/styles"
import DragIcon from "mdi-react/DragIcon"

//router
import { useHistory } from "react-router-dom"

const useStyles = makeStyles(styles)

export default function MainButtonConnections() {
    const classes = useStyles()
    const history = useHistory()

    function handleButtonClick() {
        history.push("/seller/connection")
    }

    return (
        <div className={classes.outsideWrapper}>
            <div className={classes.dragIcon}>
                <DragIcon size={16} />
            </div>

            <Box className={classes.container} onClick={handleButtonClick}>
                <Typography variant={"button"}>Connections</Typography>
            </Box>
        </div>
    )
}
