import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { PersonOutline, EditOutlined } from "@material-ui/icons"
import { IconButton, Tooltip, Grid, CircularProgress } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import GridItem from "components/Grid/GridItem"
import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"

import styles from "assets/jss/material-dashboard-react/views/cardInfoStyle"

const useStyles = makeStyles(styles)

export default function SellerDetails(props) {
    const classes = useStyles()

    const { detailsItem, loading } = props

    const openEditForm = () => {
        props.toggleVisible()
    }

    const Header = (props) => {
        const { loading } = props

        return (
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <PersonOutline />
                </CardIcon>
                <h4 className={classes.cardTitle}>Seller details</h4>
                <div className={classes.cardHeaderActions}>
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <>
                            <Tooltip title="Edit" arrow>
                                <IconButton aria-label="edit" color={"inherit"} onClick={openEditForm}>
                                    <EditOutlined />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
            </CardHeader>
        )
    }

    const LoadingSkeleton = () => {
        return (
            <Grid container className={classes.skeletonContainer}>
                <GridItem item xs={12}>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </GridItem>
            </Grid>
        )
    }

    return (
        <Card>
            <Header loading={loading} />
            {loading ? (
                <LoadingSkeleton />
            ) : (
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12}>
                            <div className={classes.cardInfoSection}>
                                <GridItem container>
                                    <GridItem xs={12} container>
                                        <GridItem xs={6}>
                                            <div className={classes.leftFlex}>Country:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <div className={classes.rightFlex}>
                                                {detailsItem?.country ? detailsItem.country.name : "-"}
                                            </div>
                                        </GridItem>
                                    </GridItem>
                                    <GridItem xs={12} container>
                                        <GridItem xs={6}>
                                            <div className={classes.leftFlex}>SKU:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <div className={classes.rightFlex}>
                                                {detailsItem?.sku ? detailsItem.sku : "-"}
                                            </div>
                                        </GridItem>
                                    </GridItem>
                                    <GridItem xs={12} container>
                                        <GridItem xs={6}>
                                            <div className={classes.leftFlex}>Phone:</div>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <div className={classes.rightFlex}>
                                                {detailsItem?.phone ? detailsItem.phone : "-"}
                                            </div>
                                        </GridItem>
                                    </GridItem>
                                </GridItem>
                            </div>
                        </GridItem>
                    </GridContainer>
                </CardBody>
            )}
        </Card>
    )
}
