import React from 'react';
import {
    Toolbar,
    Typography,
    Paper,
    Grid,
    Tooltip
} from '@material-ui/core';
import {InfoOutlined, ScheduleOutlined} from "@material-ui/icons";
import SettingsIcon from "mdi-react/SettingsIcon";

//main components
import CardBody from "components/Card/CardBody";
import ColorsReset from "components/Admin/Configuration/ColorsReset";
import CountiesReset from "components/Admin/Configuration/CountiesReset";
import MaterialsReset from "components/Admin/Configuration/MaterialsReset";
import ProductIdTypeReset from "components/Admin/Configuration/ProductIdTypeReset";
import WeightUnitReset from "components/Admin/Configuration/WeightUnitReset";
import DimensionUnitsReset from "components/Admin/Configuration/DimensionUnitsReset";
import ConditionsReset from "components/Admin/Configuration/ConditionsReset";
import CategoryTreeChanges from "components/Admin/Configuration/CategoryTreeChanges";
import EtsyOrdersWatcher from "components/Admin/Configuration/EtsyOrdersWatcher";
import AmazonOrdersWatcher from "components/Admin/Configuration/AmazonOrdersWatcher";

//styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/configurationStyle";

//components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";

//pages descriptions
import {ConfigurationView} from "utils/viewsDescriptionUtils";

const useStyles = makeStyles(styles);

const CustomToolbar = (props) => {
    const classes = useStyles();

    return (
        <Toolbar className={classes.header}>
            <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                Configuration options
                <Tooltip
                    title={<ConfigurationView darkStyle/>}
                    arrow
                    interactive
                    classes={{tooltip: classes.descriptionTooltip}}
                >
                    <InfoOutlined style={{fontSize: 16}}/>
                </Tooltip>
            </Typography>
        </Toolbar>
    );
};

export default function ConfigurationPage() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <CustomToolbar />
                <CardBody>
                    <Grid container spacing={2}>
                        <Card>
                            <CardHeader>
                                <CardIcon color="warning">
                                    <SettingsIcon style={{color: "#fff"}}/>
                                </CardIcon>
                                <Typography variant="subtitle2">Reset to default values</Typography>
                            </CardHeader>
                            <CardBody>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <CountiesReset />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <MaterialsReset />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <ColorsReset />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <ConditionsReset />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <WeightUnitReset />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <DimensionUnitsReset />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <ProductIdTypeReset />
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>

                        <Card>
                            <CardHeader>
                                <CardIcon color="warning">
                                    <ScheduleOutlined style={{color: "#fff"}}/>
                                </CardIcon>
                                <Typography variant="subtitle2">Schedule task</Typography>
                            </CardHeader>
                            <CardBody>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <AmazonOrdersWatcher />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <EtsyOrdersWatcher />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <CategoryTreeChanges />
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>
                    </Grid>
                </CardBody>
            </Paper>
        </div>
    )
}
