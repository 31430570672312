import { backGroundBlueColor, whiteColor } from "assets/jss/material-dashboard-react.js"

const cookiesAgreementStyle = {
    container: {
        minHeight: 80,
        width: "100%",
        background: backGroundBlueColor,
        border: "none",
        position: "fixed",
        left: "0",
        bottom: "0",
        padding: "15px 20px",
    },
    content: {
        fontSize: "14px",
        color: whiteColor,
        textAlign: "center",
    },
}

export default cookiesAgreementStyle
