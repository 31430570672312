import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { Cancel, EditOutlined, ImageOutlined } from "@material-ui/icons"
import { TextField, Button, CircularProgress, Badge, Avatar, Grid } from "@material-ui/core"

import GridContainer from "components/Grid/GridContainer"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardFooter from "components/Card/CardFooter"
import GridItem from "components/Grid/GridItem"

import { Formik } from "formik"
import * as Yup from "yup"

import styles from "assets/jss/material-dashboard-react/views/cardFormStyle"

//apollo
import { Mutation } from "@apollo/client/react/components"
import { UPDATE_ACCOUNT } from "querys/auth/loginQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//router
import { useHistory } from "react-router-dom"

//Cookies
import * as Cookies from "js-cookie"

//errors
import { getFormErrors } from "utils/errorsUtils"

const useStyles = makeStyles(styles)

export default function AuthUserForm(props) {
    const classes = useStyles()

    const { updateItem } = props

    const notifyError = (message) => ErrorNotification("Update", message)
    const notifySuccess = () => SuccessNotification("Account", "Update succeed")

    const history = useHistory()

    const cancelForm = () => {
        props.toggleVisible()
    }

    const successForm = () => {
        notifySuccess()
        props.refetch()
        props.toggleVisible()
    }

    function updateToken(token) {
        Cookies.set("JWT", token)
    }

    const showPasswordChangeForm = () => {
        history.push("/seller/profile/password-change")
    }

    const composeUrl = (row) => {
        return row.image
            ? `${process.env.REACT_APP_API_URL}file/${row.id}`
            : ""
    }

    const [image, setImage] = React.useState("")
    const [deleteActualImage, setDeleteActualImage] = React.useState(false)

    function onImageChange({
        target: {
            validity,
            files: [file],
        },
    }) {
        if (validity.valid) {
            file.preview = URL.createObjectURL(file)
            setImage(file)
        }
    }

    const deleteUpdateImage = () => {
        setDeleteActualImage(true)
    }

    const deleteImage = () => {
        setImage("")
    }

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="warning" className={classes.cardHeaderIcon}>
                    <EditOutlined />
                </CardIcon>
                <h4 className={classes.cardTitle}>Edit user</h4>
            </CardHeader>
            <Mutation mutation={UPDATE_ACCOUNT}>
                {(register, { loading, error, data }) => (
                    <Formik
                        initialValues={{
                            username: updateItem?.username ?? "",
                            firstName: updateItem?.firstName ?? "",
                            lastName: updateItem?.lastName ?? "",
                        }}
                        validationSchema={Yup.object({
                            username: Yup.string()
                                .required("field required")
                                .min(6, "The username most contain 6 characters at least"),
                            firstName: Yup.string().required("field required"),
                            lastName: Yup.string().required("field required"),
                        })}
                        onSubmit={(data, { setErrors }) => {
                            register({
                                variables: {
                                    id: updateItem.id,
                                    username: data.username,
                                    firstName: data.firstName,
                                    lastName: data.lastName,
                                    fileIn: image,
                                    deleteActualImagen: deleteActualImage,
                                },
                            }).then(
                                (response) => {
                                    let dataResponse = response.data.updateProfile

                                    if (dataResponse.profile) {
                                        updateToken(dataResponse.profile.token)
                                        return successForm()
                                    }
                                },
                                (response) => {
                                    if (response.graphQLErrors.length) {
                                        getFormErrors(response.graphQLErrors, notifyError, setErrors)
                                    } else {
                                        notifyError("Update profile failed, try again")
                                    }
                                }
                            )
                        }}
                    >
                        {(formik) => (
                            <form onSubmit={formik.handleSubmit}>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="username"
                                                label="Username*"
                                                size={"small"}
                                                fullWidth
                                                variant={"filled"}
                                                className={classes.inputFields}
                                                {...formik.getFieldProps("username")}
                                                error={formik.errors.username && formik.touched.username}
                                                helperText={
                                                    formik.errors.username &&
                                                    formik.touched.username &&
                                                    formik.errors.username
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="firstName"
                                                label="First name*"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                className={classes.formFields}
                                                {...formik.getFieldProps("firstName")}
                                                error={formik.errors.firstName && formik.touched.firstName}
                                                helperText={
                                                    formik.errors.firstName &&
                                                    formik.touched.firstName &&
                                                    formik.errors.firstName
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <TextField
                                                name="lastName"
                                                label="Last name*"
                                                variant={"filled"}
                                                size={"small"}
                                                fullWidth
                                                className={classes.formFields}
                                                {...formik.getFieldProps("lastName")}
                                                error={formik.errors.lastName && formik.touched.lastName}
                                                helperText={
                                                    formik.errors.lastName &&
                                                    formik.touched.lastName &&
                                                    formik.errors.lastName
                                                }
                                            />
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <div className={classes.formFields}>
                                                <input
                                                    name="image"
                                                    accept="image/*"
                                                    id="contained-button-file"
                                                    type="file"
                                                    style={{ display: "none" }}
                                                    onChange={onImageChange}
                                                />
                                                <label htmlFor="contained-button-file">
                                                    <Button
                                                        variant="contained"
                                                        component="span"
                                                        startIcon={<ImageOutlined />}
                                                    >
                                                        Image
                                                    </Button>
                                                </label>
                                            </div>
                                            {updateItem && updateItem.image && image === "" && !deleteActualImage && (
                                                <Badge
                                                    overlap={"circular"}
                                                    badgeContent={
                                                        <Cancel
                                                            fontSize={"small"}
                                                            style={{ color: "rgb(255, 255, 255)", cursor: "pointer" }}
                                                        />
                                                    }
                                                    onClick={() => deleteUpdateImage()}
                                                >
                                                    <Avatar
                                                        src={composeUrl(updateItem)}
                                                        variant={"rounded"}
                                                        className={classes.avatar}
                                                    />
                                                </Badge>
                                            )}
                                            {image !== "" && (
                                                <Badge
                                                    overlap={"circular"}
                                                    badgeContent={
                                                        <Cancel
                                                            fontSize={"small"}
                                                            style={{ color: "rgb(255, 255, 255)", cursor: "pointer" }}
                                                        />
                                                    }
                                                    onClick={() => deleteImage()}
                                                >
                                                    <Avatar
                                                        src={image.preview}
                                                        variant={"rounded"}
                                                        className={classes.avatar}
                                                    />
                                                </Badge>
                                            )}
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>

                                <CardFooter chart>
                                    <Grid container>
                                        <Grid item xs={12} md={6}>
                                            <small>(*) means that fields are required</small>
                                        </Grid>

                                        <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
                                            <Button
                                                size={"small"}
                                                variant={"outlined"}
                                                style={{
                                                    marginRight: "6px",
                                                    backgroundColor: "orange",
                                                    color: "white",
                                                }}
                                                onClick={showPasswordChangeForm}
                                            >
                                                Change password
                                            </Button>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"secondary"}
                                                onClick={cancelForm}
                                                disabled={loading}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                size={"small"}
                                                variant={"text"}
                                                color={"primary"}
                                                type="submit"
                                                disabled={!formik.isValid || loading}
                                            >
                                                {loading ? <CircularProgress size={24} /> : "Update"}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardFooter>
                            </form>
                        )}
                    </Formik>
                )}
            </Mutation>
        </Card>
    )
}
