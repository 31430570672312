import gql from "graphql-tag";

//to know if task is running
export const ETSY_ORDERS_WATCHER_SCHEDULER_IS_RUNNING = gql`
  query schedulerIsRunningEtsyOrderWatcher {
    schedulerIsRunningEtsyOrderWatcher {
      isRunning
      lastError
      params
    }
  }`

//start schedule task
export const ETSY_ORDERS_WATCHER_SCHEDULER_START = gql`
  mutation schedulerStartEtsyOrderWatcher($timeInterval: Int) {
    schedulerStartEtsyOrderWatcher(timeInterval: $timeInterval) {
      success
    }
  }`

//stop schedule task
export const ETSY_ORDERS_WATCHER_SCHEDULER_STOP = gql`
  mutation schedulerStopEtsyOrderWatcher {
    schedulerStopEtsyOrderWatcher {
      success
    }
  }`
