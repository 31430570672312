import React from "react"
import { Grid } from "@material-ui/core"
import Tipography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/views/LandingPageStyle"

//reveal effects
import { Zoom, Slide } from "react-awesome-reveal"

//assets
import imgSlide3 from "assets/img/045-boxes.svg"

//parallax
import { Background, Parallax } from "react-parallax"

const useStyles = makeStyles(styles)

export default function Slide3() {
    const classes = useStyles()

    return (
        <Parallax strength={500}>
            <Background>
                <div className={classes.background3} />
            </Background>
            <Grid container spacing={2} style={{ padding: "30px 5px" }}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12} className={classes.colContainerSlide}>
                        <div className={classes.leftSide}>
                            <Slide duration={1500} direction={"left"} triggerOnce>
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} className={classes.blocks}>
                                        <Tipography variant="h6" display="inline">
                                            Easy setup
                                        </Tipography>
                                    </Grid>

                                    <Grid item xs={12} className={classes.blocks}>
                                        <Tipography variant="subtitle1" display="inline">
                                            Fast integration with your marketplaces
                                        </Tipography>
                                    </Grid>
                                </Grid>
                            </Slide>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.imgContainer}>
                        <Zoom duration={1500} triggerOnce>
                            <img src={imgSlide3} alt="imgSlide3" className={classes.img} />
                        </Zoom>
                    </Grid>
                </Grid>
            </Grid>
        </Parallax>
    )
}
