import gql from "graphql-tag"

//user listing using pagination and sort
export const LOAD_USERS = gql`
    query allUsers(
        $orderBy: [String]!
        $usernameFilter: String
        $firsNameFilter: String
        $lastNameFilter: String
        $emailFilter: String
        $firstPageItems: Int
        $lastPageItems: Int
        $beforeCursor: String
        $afterCursor: String
    ) {
        allUsers(
            orderBy: $orderBy
            username_Icontains: $usernameFilter
            firstName_Icontains: $firsNameFilter
            lastName_Icontains: $lastNameFilter
            email_Icontains: $emailFilter
            first: $firstPageItems
            last: $lastPageItems
            before: $beforeCursor
            after: $afterCursor
        ) {
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    username
                    firstName
                    lastName
                    email
                    isActive
                    isVerified
                    isStaff
                    isSuperuser
                    groups {
                        id
                        name
                    }
                }
            }
        }
    }
`

//all users listing, used for selecting nomenclator
export const LOAD_ALL_USERS = gql`
    query users {
        users {
            edges {
                node {
                    id
                    username
                    firstName
                    lastName
                    email
                }
            }
        }
    }
`

//add new user
export const CREATE_USER = gql`
    mutation registerUser($email: String!, $username: String!, $password1: String!, $password2: String!) {
        register(input: { email: $email, username: $username, password1: $password1, password2: $password2 }) {
            success
            errors
        }
    }
`

//update user
export const UPDATE_USER = gql`
    mutation updateAccountUser($firstName: String!, $lastName: String!) {
        updateAccount(input: { firstName: $firstName, lastName: $lastName }) {
            success
            errors
        }
    }
`

//delete account permanently
export const DELETE_USER = gql`
    mutation deleteAccountByAdmin($userId: ID!, $option: Int) {
        deleteAccountByAdmin(id: $userId, option: $option) {
            found
            deletedId
            apisResponse {
                marketplace
                type
                messages
            }
        }
    }
`

//active or inactive account
export const CHANGE_STATUS_USER = gql`
    mutation inactiveOrActiveAccountByAdmin($userId: ID!) {
        inactiveOrActiveAccountByAdmin(id: $userId) {
            user {
                id
                username
            }
        }
    }
`

//assign groups to users
export const ASSIGN_GROUPS_TO_USER = gql`
    mutation setGroupsToUser($userId: ID!, $groupsId: [ID]!) {
        setGroupsToUser(userId: $userId, groupsId: $groupsId) {
            user {
                id
                username
            }
        }
    }
`

//admin verify user account
export const VERIFY_ACCOUNT = gql`
    mutation verifyAccountByAdmin($userId: ID!) {
        verifyAccountByAdmin(id: $userId) {
            user {
                id
                username
            }
        }
    }
`
