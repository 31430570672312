import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {EditOutlined} from "@material-ui/icons";
import {
    Button,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography
} from '@material-ui/core';

import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import CardFooter from "components/Card/CardFooter";
import GridItem from "components/Grid/GridItem";

import {Formik} from 'formik';

import styles from "assets/jss/material-dashboard-react/views/cardFormStyle";

//apollo
import {useMutation} from "@apollo/client"
import {Query} from "@apollo/client/react/components"
import {ASSIGN_GROUPS_TO_USER} from "querys/admin/userQueries"
import {LOAD_ALL_GROUPS} from "querys/admin/authGroupQueries"

//notifications
import {ErrorNotification, SuccessNotification} from "components/Notifications/Notifications"

//router
import {useHistory} from "react-router-dom";

//errors
import {getFormErrors} from "utils/errorsUtils";

const useStyles = makeStyles(styles);

export default function UserForm(props) {
    const classes = useStyles();

    const updateItem = props.location.state?.updateItem.node ?? null

    const history = useHistory();

    const notifySuccess = message => SuccessNotification("User", message)
    const notifyError = message => ErrorNotification("User", message)

    const successForm = () => {
        notifySuccess("Groups assigned")
        history.push('/seller/admin-user')
    }

    const cancelAction = () => {
        history.push("/seller/admin-user")
    }

    function getGroupsId(groups) {
        let groupsId = ""
        groups.forEach(item => {
            groupsId = `${groupsId} ${item.node.id}`
        })
        return groupsId
    }

    //to put data from api, then populate permissions list
    const [rows, setRows] = React.useState([])
    React.useEffect(() => {
        if (rows.length) {
            setLeft(rows)
        }
    }, [rows])

    //transfer list
    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1)
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1)
    }

    const [left, setLeft] = React.useState(rows)
    const [right, setRight] = React.useState([])

    function TransferList() {
        const [selected, setSelected] = React.useState([])

        const leftSelected = intersection(selected, left)
        const rightSelected = intersection(selected, right)

        const handleToggle = (value) => () => {
            const currentIndex = selected.indexOf(value)
            const newSelected = [...selected]

            if (currentIndex === -1) {
                newSelected.push(value)
            } else {
                newSelected.splice(currentIndex, 1)
            }

            setSelected(newSelected)
        }

        const handleAllRight = () => {
            setRight(right.concat(left))
            setLeft([])
        }

        const handleAllLeft = () => {
            setLeft(left.concat(right))
            setRight([])
        }

        const handleSelectedRight = () => {
            setRight(right.concat(leftSelected))
            setLeft(not(left, leftSelected))
            setSelected(not(selected, leftSelected))
        }

        const handleSelectedLeft = () => {
            setLeft(left.concat(rightSelected))
            setRight(not(right, rightSelected))
            setSelected(not(selected, rightSelected))
        }

        const customList = (items, title) => (
            <Paper className={classes.paper}>
                <Typography variant={"subtitle2"}>{title}</Typography>
                <List
                    dense
                    component="div"
                    role="list"
                    className={classes.list}
                >
                    {items.map((item) => {
                        const labelId = `item-${item.node.id}-label`;

                        return (
                            <ListItem
                                key={item.node.id}
                                role="listitem"
                                button
                                selected={selected.indexOf(item) !== -1}
                                onClick={handleToggle(item)}
                            >
                                <ListItemText
                                    id={labelId}
                                    primary={item.node.name}
                                />
                            </ListItem>
                        )
                    })}
                    <ListItem />
                </List>
            </Paper>
        )

        return (
            <Query
                query={LOAD_ALL_GROUPS}
                onCompleted={(data) => {
                    setRows(data?.allGroups.edges ?? [])
                }}
            >
                {
                    ({ loading, error, data}) => {
                        if (error) {
                            notifyError("Load data failed")
                        }

                        return (
                            <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.formFields}>
                                <Grid item xs={12} md={5}>{customList(left, "Available groups")}</Grid>
                                <Grid item xs={12} md={2}>
                                    <Grid container direction="column" alignItems="center">
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classes.formFields}
                                            onClick={handleAllRight}
                                            disabled={left.length === 0}
                                            aria-label="move all right"
                                        >
                                            ≫
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classes.formFields}
                                            onClick={handleSelectedRight}
                                            disabled={leftSelected.length === 0}
                                            aria-label="move selected right"
                                        >
                                            &gt;
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classes.formFields}
                                            onClick={handleSelectedLeft}
                                            disabled={rightSelected.length === 0}
                                            aria-label="move selected left"
                                        >
                                            &lt;
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classes.formFields}
                                            onClick={handleAllLeft}
                                            disabled={right.length === 0}
                                            aria-label="move all left"
                                        >
                                            ≪
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={5}>{customList(right, "User groups")}</Grid>
                            </Grid>
                        )
                    }
                }
            </Query>
        )
    }

    const [assignGroups, {loading}] = useMutation(ASSIGN_GROUPS_TO_USER)

    return (
        <Card>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="success" className={classes.cardHeaderIcon}>
                    <EditOutlined/>
                </CardIcon>
                <h4 className={classes.cardTitle}>Assign groups</h4>
            </CardHeader>
            <Formik
                initialValues={
                    {
                        userId: updateItem.id,
                    }
                }
                onSubmit={(data) => {
                    assignGroups({
                        variables: {
                            userId: data.userId,
                            groupsId: getGroupsId(right).trim().split(" ")
                        }
                    }).then(
                        (response) => {
                            if (response.data.setGroupsToUser?.user) {
                                successForm()
                            }
                        },
                        (response) => {
                            if (response.graphQLErrors.length) {
                                getFormErrors(response.graphQLErrors, notifyError("Assign group failed, try again"), null)
                            } else {
                                notifyError("Assign group failed, try again")
                            }
                        }
                    )
                }}
            >
                {
                    formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <TransferList />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter chart>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <small>(*) means that fields are required</small>
                                    </Grid>

                                    <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
                                        <Button
                                            size={"small"}
                                            variant={"text"}
                                            color={"secondary"}
                                            onClick={cancelAction}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            size={"small"}
                                            variant={"text"}
                                            color={"primary"}
                                            type="submit"
                                            disabled={!right.length || loading}
                                        >
                                            {
                                                loading
                                                    ? <CircularProgress size={24}/>
                                                    : "Assign"
                                            }
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardFooter>
                        </form>
                    )
                }
            </Formik>
        </Card>
    )
};
