import gql from "graphql-tag"

//all marketplace orders
export const LOAD_ALL_MARKETPLACE_ORDERS = gql`
    query allMyMarketplaceOrders($page: Int!, $perPage: Int!) {
        allMyMarketplaceOrders(page: $page, perPage: $perPage) {
            totalCount
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
            orders {
                lastUpdateDate
                etsyInfo {
                    id
                    lastUpdateDate
                    shopId
                    receiptId
                }
                amazonInfo {
                    id
                    lastUpdateDate
                    amazonOrderId
                    buyerEmail
                    orderStatus
                    orderType
                    numberOfItemsShipped
                    numberOfItemsUnshipped
                    orderTotalAmount
                    orderTotalCurrencyCode
                    purchaseDate
                    amazonOrderProductAmazonOrder {
                        edges {
                            node {
                                id
                                product {
                                    id
                                    title
                                }
                                productVariation {
                                    id
                                    name
                                    product {
                                        id
                                        title
                                    }
                                }
                                quantityOrdered
                                quantityShipped
                            }
                        }
                    }
                }
                facebookInfo {
                    id
                    status
                    lastUpdated
                }
            }
        }
    }
`

//amazon order
export const LOAD_AMAZON_ORDER = gql`
    query amazonOrder($id: ID!) {
        amazonOrder(id: $id) {
            id
            lastUpdateDate
            amazonOrderId
            buyerEmail
            orderStatus
            orderType
            numberOfItemsShipped
            numberOfItemsUnshipped
            orderTotalAmount
            orderTotalCurrencyCode
            purchaseDate
            amazonOrderProductAmazonOrder {
                edges {
                    node {
                        id
                        product {
                            id
                            title
                        }
                        productVariation {
                            id
                            name
                            product {
                                id
                                title
                            }
                        }
                        quantityOrdered
                        quantityShipped
                    }
                }
            }
        }
    }
`

//etsy order
export const LOAD_ETSY_ORDER = gql`
    query etsyOrder($id: ID!) {
        etsyOrder(id: $id) {
            id
            lastUpdateDate
            shopId
            receiptId
        }
    }
`

//etsy order
export const LOAD_FACEBOOK_ORDER = gql`
    query facebookOrder($id: ID!) {
        facebookOrder(id: $id) {
            id
            status
            lastUpdated
        }
    }
`
