import { CircularProgress, Grid } from "@material-ui/core"
import React from "react"
import { ErrorNotification } from "../../components/Notifications/Notifications"
import { useQuery } from "@apollo/client"
import { Skeleton } from "@material-ui/lab"
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/views/infoPageStyle"
import { PUBLIC_PRODUCT } from "../../querys/public/publicQueries"
import InfoOutlineIcon from "mdi-react/InfoOutlineIcon"

import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"

const useStyles = makeStyles(styles)

export default function PublicProductFacebookDetails(props) {
    const classes = useStyles()

    const notifyError = (message) => ErrorNotification("Product", message)

    const [product, setProduct] = React.useState(null)
    const { loading } = useQuery(PUBLIC_PRODUCT, {
        variables: {
            productId: props.match?.params?.productId ?? "",
        },
        onCompleted: (data) => {
            if (data.publicProduct) {
                setProduct(data.publicProduct)
            }
        },
        onError: () => {
            notifyError("Unable to load product's details, try again")
        },
    })

    const Header = () => {
        return (
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <InfoOutlineIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Product details</h4>
                <div className={classes.cardHeaderActions}>{loading && <CircularProgress size={24} />}</div>
            </CardHeader>
        )
    }

    const LoadingSkeleton = () => {
        return (
            <Grid container>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </Grid>
        )
    }

    return (
        <Card className={classes.cardContainer}>
            <Header />
            {loading || !product ? (
                <LoadingSkeleton />
            ) : (
                <CardBody>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            Product: {product?.title ?? "-"}
                        </Grid>

                        <Grid item xs={12}>
                            Description: {product?.description ? product.description : "-"}
                        </Grid>

                        <Grid item xs={12}>
                            Quantity: {product?.quantity ? product.quantity : "-"}
                        </Grid>
                    </Grid>
                </CardBody>
            )}
        </Card>
    )
}
