import React from "react"
import {
    Typography,
    Paper,
    Tooltip,
    Button,
    Grid,
    TablePagination,
    Collapse,
    IconButton,
    ListItem,
    List,
    Avatar,
} from "@material-ui/core"

//icons
import { ArrowDownward, ArrowUpward, ExpandLessOutlined, ExpandMoreOutlined } from "@material-ui/icons"
import CalendarIcon from "mdi-react/CalendarIcon"
import WarningOutlineIcon from "mdi-react/WarningOutlineIcon"
import ExclamationBoldIcon from "mdi-react/ExclamationBoldIcon"
import LanPendingIcon from "mdi-react/LanPendingIcon"
import AlertOctagonOutlineIcon from "mdi-react/AlertOctagonOutlineIcon"
import WarningOctagonOutlineIcon from "mdi-react/WarningOctagonOutlineIcon"
import CancelCircleOutlineIcon from "mdi-react/CancelCircleOutlineIcon"
import StopAlertOutlineIcon from "mdi-react/StopAlertOutlineIcon"
import DoneIcon from "mdi-react/DoneIcon"

//apollo
import { useLazyQuery, useQuery } from "@apollo/client"
import {
    LOAD_ALL_MARKETPLACE_ORDERS,
    LOAD_AMAZON_ORDER,
    LOAD_ETSY_ORDER,
    LOAD_FACEBOOK_ORDER,
} from "querys/seller/Orders/marketplaceOrdersQueries"

//notifications
import { ErrorNotification } from "components/Notifications/Notifications"

//utils
import { LoadingSkeleton, TableNoData } from "utils/tableUtils"
import { format } from "date-fns"

//components
import CardFooter from "components/Card/CardFooter"
import MarketplaceOrdersTableHeader from "components/Seller/Orders/MarketplaceOrdersTableHeader"

//assets
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/tableStyle"
import { dangerColor, infoColor, successColor, warningColor } from "assets/jss/material-dashboard-react"
import AmazonImg from "assets/img/marketplaces/Amazon-icon.svg"
import EtsyImg from "assets/img/marketplaces/Etsy_icon.svg"
//import EbayImg from "assets/img/marketplaces/Ebay_icon.svg"

//location
import { useLocation } from "react-router-dom"

const useStyles = makeStyles(styles)

const notifyError = (message) => ErrorNotification("Orders", message)

export default function EnhancedTable(props) {
    const classes = useStyles()

    const { handleCloseModal } = props

    function useLocationFunction() {
        return new URLSearchParams(useLocation().search)
    }

    let searchParams = useLocationFunction()
    const amazonOrderIdFilter = searchParams.get("amazonOrderId")
    const etsyOrderIdFilter = searchParams.get("etsyOrderId")
    const facebookOrderIdFilter = searchParams.get("facebookOrderId")

    //local filters
    const [displayAllAmazonOrders, setDisplayAllAmazonOrders] = React.useState(true)
    const [displayAllEtsyOrders, setDisplayAllEtsyOrders] = React.useState(true)
    const [displayAllFacebookOrders, setDisplayAllFacebookOrders] = React.useState(true)
    const [displayOneAmazonOrder, setDisplayOneAmazonOrder] = React.useState(false)
    const [displayOneEtsyOrder, setDisplayOneEtsyOrder] = React.useState(false)
    const [displayOneFacebookOrder, setDisplayOneFacebookOrder] = React.useState(false)

    //pagination
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(0)

    const setPaginationResponse = (data) => [setTotalItemsPagination(data.allMyMarketplaceOrders.totalCount)]

    //load marketplaces orders
    const { data, loading: loadingOrders } = useQuery(LOAD_ALL_MARKETPLACE_ORDERS, {
        variables: {
            page: page + 1,
            perPage: rowsPerPage,
        },
        onCompleted: (data1) => {
            setPaginationResponse(data1)
        },
        onError: () => {
            notifyError("Unable to load platforms orders, try again")
        },
    })

    const [loadAmazonOrder, { data: dataAmazonOrder, loading: loadingAmazonOrder }] = useLazyQuery(LOAD_AMAZON_ORDER, {
        onCompleted: () => setDisplayOneAmazonOrder(true),
    })

    const [loadEtsyOrder, { data: dataEtsyOrder, loading: loadingEtsyOrder }] = useLazyQuery(LOAD_ETSY_ORDER, {
        onCompleted: () => setDisplayOneEtsyOrder(true),
    })

    const [loadFacebookOrder, { data: dataFacebookOrder, loading: loadingFacebookOrder }] = useLazyQuery(
        LOAD_FACEBOOK_ORDER,
        {
            onCompleted: () => setDisplayOneEtsyOrder(true),
        }
    )

    React.useEffect(() => {
        if (amazonOrderIdFilter || etsyOrderIdFilter) {
            //opened from notifications. only one param per time
            if (amazonOrderIdFilter) loadAmazonOrder({ variables: { id: amazonOrderIdFilter } })

            if (etsyOrderIdFilter) loadEtsyOrder({ variables: { id: etsyOrderIdFilter } })

            if (facebookOrderIdFilter) loadFacebookOrder({ variables: { id: facebookOrderIdFilter } })
        }
    }, [
        amazonOrderIdFilter,
        etsyOrderIdFilter,
        facebookOrderIdFilter,
        loadAmazonOrder,
        loadEtsyOrder,
        loadFacebookOrder,
    ])

    const handleChangePage = async (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = async (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const statusDescription = {
        PendingAvailability: {
            description:
                "Preorder status. The order has been placed, payment has not been authorized, and the release date of item is in the future",
            icon: <WarningOutlineIcon size={18} style={{ color: warningColor[0] }} />,
        },
        Pending: {
            description: "The order has been placed but payment has not been authorized.",
            icon: <ExclamationBoldIcon size={18} style={{ color: warningColor[1] }} />,
        },
        Unshipped: {
            description:
                "Payment has been authorized and the order is ready for shipment, but no items in the order has been shipped.",
            icon: <LanPendingIcon size={18} style={{ color: infoColor[0] }} />,
        },
        PartiallyShipped: {
            description: "One or more, but not all, items in the order have been shipped.",
            icon: <WarningOctagonOutlineIcon size={18} style={{ color: infoColor[1] }} />,
        },
        Shipped: {
            description: "All items in the order have been shipped.",
            icon: <DoneIcon size={18} style={{ color: successColor[0] }} />,
        },
        InvoiceUnconfirmed: {
            description:
                "All items in the order have been shipped. The seller has not yet given confirmation to Amazon that the invoice has been shipped to the buyer",
            icon: <AlertOctagonOutlineIcon size={18} style={{ color: warningColor[2] }} />,
        },
        Cancelled: {
            description: "The order has been cancelled.",
            icon: <CancelCircleOutlineIcon size={18} style={{ color: dangerColor[1] }} />,
        },
        Unfulfillable: {
            description: "The order can not be fulfilled. This state applies only to Multi-Channel Fulfillment orders.",
            icon: <StopAlertOutlineIcon size={18} style={{ color: dangerColor[0] }} />,
        },
    }

    //expand description
    const [expandIndex, setExpandIndex] = React.useState(null)

    function handleExpand(rowIndex) {
        if (rowIndex === expandIndex) return setExpandIndex(null)

        return setExpandIndex(rowIndex)
    }

    const RowIcon = (props) => (
        <Grid item xs={"auto"} style={{ display: "flex", alignItems: "center" }}>
            <Grid container justifyContent={"center"} alignItems={"center"}>
                {props.children}
            </Grid>
        </Grid>
    )
    const RowCell = ({ header, value }) => (
        <Grid item xs={true}>
            <Grid container spacing={1}>
                <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
                    <span
                        style={{
                            backgroundColor: "lightgray",
                            padding: "0 3px",
                            fontSize: "0.8REM",
                            borderRadius: "2px",
                            fontWeight: 400,
                        }}
                    >
                        {header}:
                    </span>
                </Grid>
                <Grid container item xs={12} justifyContent={"center"} alignItems={"center"}>
                    {value}
                </Grid>
            </Grid>
        </Grid>
    )

    const AmazonOrderRow = (props) => {
        const { row, rowIndex } = props

        return (
            <>
                <ListItem button onClick={() => handleExpand(rowIndex)}>
                    <Grid container space={1}>
                        <RowIcon>
                            <Avatar src={AmazonImg} variant={"circular"} className={classes.marketplaceAvatar} />
                        </RowIcon>

                        <RowCell
                            header={"Status"}
                            value={
                                row.orderStatus ? (
                                    <Grid style={{ display: "inline-flex" }}>
                                        <Grid item xs={"auto"} style={{ marginRight: "3px" }}>
                                            <Tooltip
                                                title={statusDescription[row.orderStatus].description}
                                                classes={{ tooltip: classes.infoTooltip }}
                                            >
                                                <span>{statusDescription[row.orderStatus].icon}</span>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={"auto"}>
                                            {row.orderStatus}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    "-"
                                )
                            }
                        />

                        <RowCell header={"Type"} value={row.orderType ?? "-"} />

                        <RowCell
                            header={"Items"}
                            value={
                                <Grid container spacing={2} justifyContent={"center"}>
                                    {row.numberOfItemsShipped !== null && (
                                        <Grid item style={{ display: "inline-flex", justifyContent: "center" }}>
                                            <Grid item xs={"auto"}>
                                                {row.numberOfItemsShipped}
                                            </Grid>
                                            <Grid container item xs={true} alignItems={"center"}>
                                                <Tooltip
                                                    title={"Items shipped"}
                                                    classes={{ tooltip: classes.infoTooltip }}
                                                >
                                                    <ArrowUpward fontSize={"small"} />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {row.numberOfItemsUnshipped !== null && (
                                        <Grid item style={{ display: "inline-flex", justifyContent: "center" }}>
                                            <Grid item xs={"auto"}>
                                                {row.numberOfItemsUnshipped}
                                            </Grid>
                                            <Grid container item xs={true} alignItems={"center"}>
                                                <Tooltip
                                                    title={"Items unshipped"}
                                                    classes={{ tooltip: classes.infoTooltip }}
                                                >
                                                    <ArrowDownward fontSize={"small"} />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            }
                        />

                        <RowCell
                            header={"Amount"}
                            value={row.orderTotalAmount ? `${row.orderTotalAmount} ${row.orderTotalCurrencyCode}` : "-"}
                        />

                        <RowCell
                            header={"Purchase"}
                            value={
                                row.purchaseDate ? (
                                    <Tooltip
                                        title={`Purchase date. Last modification in ${row.lastUpdateDate}`}
                                        classes={{ tooltip: classes.infoTooltip }}
                                    >
                                        <Grid container alignItems={"center"} justifyContent={"center"}>
                                            <CalendarIcon size={16} style={{ marginRight: "3px" }} />
                                            {row.purchaseDate}
                                        </Grid>
                                    </Tooltip>
                                ) : (
                                    "-"
                                )
                            }
                        />

                        <RowIcon>
                            <Tooltip title={"Details"}>
                                <IconButton size={"small"} sx={{ m: "3px" }} onClick={() => handleExpand(rowIndex)}>
                                    {expandIndex === rowIndex ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                                </IconButton>
                            </Tooltip>
                        </RowIcon>
                    </Grid>
                </ListItem>

                <div style={{ width: "100%" }}>
                    <Collapse in={expandIndex === rowIndex} timeout={"auto"} unmountOnExit>
                        <Grid container spacing={2} style={{ padding: "5px 8px" }}>
                            <Grid container item xs={12} spacing={1}>
                                <Grid container item spacing={1} xs={12}>
                                    <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                        Order Id:
                                    </Grid>
                                    <Grid item xs={true}>
                                        {row.amazonOrderId ?? "-"}
                                    </Grid>
                                </Grid>

                                <Grid container item spacing={1} xs={12}>
                                    <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                        Buyer email:
                                    </Grid>
                                    <Grid item xs={true}>
                                        {row.buyerEmail ?? "-"}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom component="div">
                                        System products information
                                    </Typography>
                                </Grid>

                                <Grid item container xs={12} spacing={1}>
                                    {row.amazonOrderProductAmazonOrder.edges.map((item) => (
                                        <Grid container item xs={12} key={item.node.id}>
                                            <Grid container item spacing={1} xs={12}>
                                                <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                                    {item?.node?.productVariation?.id
                                                        ? "System product variation:"
                                                        : "System product:"}
                                                </Grid>
                                                {item?.node?.product?.id && (
                                                    <Grid item xs={true}>
                                                        {item.node.product?.title ?? item.node.product?.id}
                                                    </Grid>
                                                )}
                                                {item?.node?.productVariation?.id && (
                                                    <>
                                                        <Grid item xs={true}>
                                                            {item.node.productVariation.name}
                                                        </Grid>

                                                        <Grid container item spacing={1} xs={12}>
                                                            <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                                                From System product:
                                                            </Grid>
                                                            <Grid item xs={true}>
                                                                {item.node.productVariation.product.title}
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>

                                            <Grid container item spacing={1} xs={12}>
                                                <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                                    Quantity ordered:
                                                </Grid>
                                                <Grid item xs={true}>
                                                    {item?.node?.quantityOrdered ?? "-"}
                                                </Grid>
                                            </Grid>

                                            <Grid container item spacing={1} xs={12}>
                                                <Grid item xs={"auto"} style={{ fontWeight: 600 }}>
                                                    Quantity shipped:
                                                </Grid>
                                                <Grid item xs={true}>
                                                    {item?.node?.quantityShipped ?? "-"}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Collapse>
                </div>
            </>
        )
    }

    const EtsyOrderRow = (props) => {
        const { row } = props

        return (
            <>
                <ListItem button>
                    <Grid container space={1}>
                        <RowIcon>
                            <Avatar src={EtsyImg} variant={"circular"} className={classes.marketplaceAvatar} />
                        </RowIcon>

                        <RowCell header={"Shop"} value={row.shopId ?? "-"} />

                        <RowCell header={"Receipt"} value={row.receiptId ?? "-"} />

                        <RowCell
                            header={"Updated at"}
                            value={
                                row.lastUpdateDate ? format(new Date(row.lastUpdateDate), "yyyy-MM-dd HH:mm:ss") : "-"
                            }
                        />
                    </Grid>
                </ListItem>
            </>
        )
    }

    const FacebookOrderRow = (props) => {
        const { row } = props

        return (
            <>
                <ListItem button>
                    <Grid container space={1}>
                        <RowIcon>
                            <Avatar src={EtsyImg} variant={"circular"} className={classes.marketplaceAvatar} />
                        </RowIcon>

                        <RowCell header={"Status"} value={row.status ?? "-"} />

                        <RowCell
                            header={"Updated at"}
                            value={row.lastUpdated ? format(new Date(row.lastUpdated), "yyyy-MM-dd HH:mm:ss") : "-"}
                        />
                    </Grid>
                </ListItem>
            </>
        )
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <MarketplaceOrdersTableHeader
                    classes={classes}
                    loading={loadingOrders || loadingAmazonOrder || loadingEtsyOrder || loadingFacebookOrder}
                    handleCloseModal={handleCloseModal}
                    displayAllAmazonOrders={displayAllAmazonOrders}
                    setDisplayAllAmazonOrders={setDisplayAllAmazonOrders}
                    displayAllEtsyOrders={displayAllEtsyOrders}
                    setDisplayAllEtsyOrders={setDisplayAllEtsyOrders}
                    displayAllFacebookOrders={displayAllFacebookOrders}
                    setDisplayAllFacebookOrders={setDisplayAllFacebookOrders}
                    displayOneAmazonOrder={displayOneAmazonOrder}
                    setDisplayOneAmazonOrder={setDisplayOneAmazonOrder}
                    displayOneEtsyOrder={displayOneEtsyOrder}
                    setDisplayOneEtsyOrder={setDisplayOneEtsyOrder}
                    displayOneFacebookOrder={displayOneFacebookOrder}
                    setDisplayOneFacebookOrder={setDisplayOneFacebookOrder}
                />

                {loadingOrders || loadingAmazonOrder || loadingEtsyOrder || loadingFacebookOrder ? (
                    <LoadingSkeleton />
                ) : !data?.allMyMarketplaceOrders?.orders?.length &&
                  !displayOneAmazonOrder &&
                  !displayOneEtsyOrder &&
                  !displayOneFacebookOrder ? (
                    <TableNoData />
                ) : (
                    <List>
                        {displayOneAmazonOrder && dataAmazonOrder && (
                            <AmazonOrderRow row={dataAmazonOrder.amazonOrder} rowIndex={0} />
                        )}

                        {displayOneEtsyOrder && dataEtsyOrder && <EtsyOrderRow row={dataEtsyOrder.etsyOrder} />}

                        {displayOneFacebookOrder && dataFacebookOrder && (
                            <FacebookOrderRow row={dataFacebookOrder.facebookOrder} />
                        )}

                        {!displayOneAmazonOrder &&
                            !displayOneEtsyOrder &&
                            !displayOneFacebookOrder &&
                            data.allMyMarketplaceOrders.orders.map((row, rowIndex) => {
                                if (displayAllAmazonOrders && row.amazonInfo)
                                    return (
                                        <AmazonOrderRow
                                            row={row.amazonInfo}
                                            rowIndex={rowIndex}
                                            key={row.amazonInfo.id}
                                        />
                                    )

                                if (displayAllEtsyOrders && row.etsyInfo)
                                    return <EtsyOrderRow row={row.etsyInfo} key={row.etsyInfo.id} />

                                if (displayAllFacebookOrders && row.facebookInfo)
                                    return <FacebookOrderRow row={row.facebookInfo} key={row.facebookInfo.id} />

                                return null
                            })}
                    </List>
                )}

                {!displayOneAmazonOrder && !displayOneEtsyOrder && (
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={totalItemsPagination}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}

                {handleCloseModal && (
                    <CardFooter chart>
                        <Button size={"small"} variant={"text"} color={"secondary"} onClick={handleCloseModal}>
                            Close
                        </Button>
                    </CardFooter>
                )}
            </Paper>
        </div>
    )
}
