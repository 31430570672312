import React from "react"
import { Toolbar, Typography, Paper, Grid, Card, Box } from "@material-ui/core"

//main components
import CardBody from "components/Card/CardBody"
import CustomCard from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/views/configurationStyle"

//router
import { Link } from "react-router-dom"

//icons
import HandPointingRightIcon from "mdi-react/HandPointingRightIcon"
import Number1CircleOutlineIcon from "mdi-react/Number1CircleOutlineIcon"
import Number2CircleOutlineIcon from "mdi-react/Number2CircleOutlineIcon"
import Number3CircleOutlineIcon from "mdi-react/Number3CircleOutlineIcon"
import Number4CircleOutlineIcon from "mdi-react/Number4CircleOutlineIcon"
import Number5CircleOutlineIcon from "mdi-react/Number5CircleOutlineIcon"
import Number6CircleOutlineIcon from "mdi-react/Number6CircleOutlineIcon"

//help components
import { Btn } from "utils/GeneralUsageUtils"
import HeaderTabs from "components/App/HeaderTabs"

//icons
import {
    AdminMarketplaceView,
    AmazonFieldsView, MarketplaceOrdersView,
    CategoryView,
    ColorsView,
    ConditionsView,
    ConfigurationView,
    CountriesView,
    CreateFromCatalogView,
    DimensionUnitsView,
    EbayFieldsView,
    EtsyFieldsView, FacebookFieldsView,
    IdTypesView,
    ImportView,
    MarketplaceView,
    MaterialsView, MessageCenterView,
    PermissionsGroupView,
    ProductView,
    ProfileView,
    SearchInCatalogView,
    UsersView,
    VariationFieldsView,
    VariationsInsertView,
    VariationsView, WeightUnitsView
} from "utils/viewsDescriptionUtils"

//apollo
import { useQuery } from "@apollo/client"
import { AUTH_USER_VALID_ID } from "querys/auth/loginQueries"

//notifications
import { ErrorNotification } from "components/Notifications/Notifications"

const useStyles = makeStyles(styles)

const CustomToolbar = (props) => {
    const classes = useStyles()

    return (
        <Toolbar className={classes.header}>
            <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                Usage info
            </Typography>
        </Toolbar>
    )
}

const LinkTo = (props) => (
    <Link to={props.path} style={{ color: "blue" }}>
        {props.children}
    </Link>
)

export default function SystemHelpPage() {
    const classes = useStyles()
    const notifyError = (message) => ErrorNotification("Seller", message)

    //current user
    const [user, setUser] = React.useState(null)

    const isAdminUser = (user) => {
        if (user) {
            if (user.isSuperuser || user.isStaff) {
                return true
            }
            return user.groups.findIndex((group) => group.name.toUpperCase() === "ADMIN") > -1
        }
        return false
    }

    useQuery(AUTH_USER_VALID_ID, {
        onCompleted: (data) => {
            if (data && data.me2) {
                setUser(data.me2)
            }
        },
        onError: (error) => {
            notifyError("Unable to load seller info, try again")
        },
    })

    const [tabs] = React.useState([
        {
            tabName: "Profile",
            tabContent: <ProfileView />,
        },
        {
            tabName: "Marketplace",
            tabContent: <MarketplaceView />,
        },
        {
            tabName: "Category",
            tabContent: <CategoryView />,
        },
        {
            tabName: "Import",
            tabContent: <ImportView />,
        },
        {
            tabName: "Product",
            tabContent: <ProductView />,
        },
        {
            tabName: "Variations",
            tabContent: <VariationsView />,
        },
        {
            tabName: "Create Variations",
            tabContent: <VariationsInsertView />,
        },
        {
            tabName: "Amazon Fields",
            tabContent: <AmazonFieldsView />,
        },
        {
            tabName: "Etsy Fields",
            tabContent: <EtsyFieldsView />,
        },
        {
            tabName: "Ebay Fields",
            tabContent: <EbayFieldsView />,
        },
        {
            tabName: "Facebook Fields",
            tabContent: <FacebookFieldsView />,
        },
        {
            tabName: "Search in catalog",
            tabContent: <SearchInCatalogView />,
        },
        {
            tabName: "Create from catalog",
            tabContent: <CreateFromCatalogView />,
        },
        {
            tabName: "Amazon orders",
            tabContent: <MarketplaceOrdersView />,
        },
        {
            tabName: "Messages Center",
            tabContent: <MessageCenterView />,
        },
    ])

    const [adminTabs] = React.useState([
        {
            tabName: "Admin marketplace",
            tabContent: <AdminMarketplaceView />,
        },
        {
            tabName: "Color",
            tabContent: <ColorsView />,
        },
        {
            tabName: "Condition",
            tabContent: <ConditionsView />,
        },
        {
            tabName: "Configuration",
            tabContent: <ConfigurationView />,
        },
        {
            tabName: "Country",
            tabContent: <CountriesView />,
        },
        {
            tabName: "Dimension unit",
            tabContent: <DimensionUnitsView />,
        },
        {
            tabName: "Material",
            tabContent: <MaterialsView />,
        },
        {
            tabName: "Permission group",
            tabContent: <PermissionsGroupView />,
        },
        {
            tabName: "ID Types",
            tabContent: <IdTypesView />,
        },
        {
            tabName: "User",
            tabContent: <UsersView />,
        },
        {
            tabName: "Variation fields",
            tabContent: <VariationFieldsView />,
        },
        {
            tabName: "Weight unit",
            tabContent: <WeightUnitsView />,
        },
    ])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <CustomToolbar />
                <CardBody>
                    <Grid container spacing={4}>
                        <Grid container item spacing={2} style={{ textAlign: "justify" }}>
                            <Grid item xs={12}>
                                <Typography variant={"subtitle2"}>
                                    If you are reading here, you probably need some help to use Sync2Markets.
                                    Information in next lines will lead you throughout our system.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <CustomCard>
                                    <CardHeader color={"info"} plain={false}>
                                        System usage
                                    </CardHeader>
                                    <CardBody>
                                        <Grid container spacing={2}>
                                            <Grid container item>
                                                <Grid item xs={12}>
                                                    <Card variant={"outlined"} style={{ padding: 15 }}>
                                                        <Box display={"flex"} width={"100%"}>
                                                            <Box display={"flex"} flexGrow={2} alignItems={"center"} p={1}>
                                                                <HandPointingRightIcon />
                                                            </Box>
                                                            <Box width={"100%"} alignItems={"center"} p={1}>
                                                                <Typography variant={"body1"}>
                                                                    User can check and modify all personal provided
                                                                    information in{" "}
                                                                    <Link
                                                                        to={"/seller/profile"}
                                                                        style={{ color: "blue", margin: "0 5px" }}
                                                                    >
                                                                        Profile
                                                                    </Link>{" "}
                                                                    section.
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            </Grid>
                                            {/*ToDo: reorder elements for desktop screen sizes*/}
                                            <Grid container item spacing={2}>
                                                <Grid container item xs={12} md={6} lg={4} spacing={2} direction={"column"}>
                                                    <Grid item>
                                                        <Card variant={"outlined"} style={{ padding: 15 }}>
                                                            <Typography variant={"body1"}>
                                                                <Number1CircleOutlineIcon style={{ paddingRight: 5 }} />
                                                                Sync2Markets target is to help users to easy sell it's product
                                                                at desire marketplaces. All actions over products are executed
                                                                for a selected marketplace. So, we need to integrate those
                                                                marketplaces in Sync2Markets.
                                                            </Typography>
                                                            <Typography variant={"body2"}>
                                                                <li>
                                                                    <LinkTo path={"/seller/marketplace"}>Here</LinkTo>, user can
                                                                    see all already integrated marketplaces. Use{" "}
                                                                    <Btn name={"Add marketplace"} /> button and select one to
                                                                    integrate it here.
                                                                </li>
                                                                <li>
                                                                    When user start integration process, a windows will be open.
                                                                    Fallow and complete authentication/authorization process
                                                                    prompted there. To accomplish it user must have an account
                                                                    at selected marketplace.
                                                                </li>
                                                            </Typography>
                                                        </Card>
                                                    </Grid>

                                                    <Grid item>
                                                        <Card variant={"outlined"} style={{ padding: 15 }}>
                                                            <Typography variant={"body1"}>
                                                                <Number4CircleOutlineIcon style={{ paddingRight: 5 }} />
                                                                When the user create a product from the ground can create{" "}
                                                                <em>variations</em> for it.
                                                            </Typography>
                                                            <Typography variant={"body2"}>
                                                                <li>
                                                                    Users can see <em>product's variations</em> as another
                                                                    product that variates in some <em>feature</em> around a main
                                                                    product. In our system those features are material and color
                                                                    and we known it as <em>variation fields</em>.
                                                                </li>
                                                                <li>
                                                                    To create variations go to{" "}
                                                                    <LinkTo path={"/seller/product"}>Product</LinkTo> section,
                                                                    select a product and click <Btn name={"See variations"} />{" "}
                                                                    button, then <Btn name={"Create variations"} /> button to
                                                                    create it.
                                                                </li>
                                                                <li>
                                                                    Variations can only be created, modified or deleted as a
                                                                    unique item composed by a group of variations.
                                                                </li>
                                                                <li>It is not mandatory to create variations for a product.</li>
                                                            </Typography>
                                                        </Card>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={12} md={6} lg={4} spacing={2} direction={"column"}>
                                                    <Grid item>
                                                        <Card variant={"outlined"} style={{ padding: 15 }}>
                                                            <Typography variant={"body1"}>
                                                                <Number2CircleOutlineIcon style={{ paddingRight: 5 }} />
                                                                Next step in sell process is to create categories to organize
                                                                your products.
                                                            </Typography>
                                                            <Typography variant={"body2"}>
                                                                <li>
                                                                    <LinkTo path={"/seller/category"}>Categories</LinkTo> can be
                                                                    created as a tree, meaning that a category can be a child of
                                                                    another existing category or a root category if desire.
                                                                </li>
                                                                <li>
                                                                    User can use categories with a structure of department >
                                                                    subDepartment > subSubDepartment... and so on. Use this
                                                                    structure to easy locate your products.
                                                                </li>
                                                                <li>
                                                                    Category isn't a mandatory field for product. So, you can
                                                                    have your product without any category at all.
                                                                </li>
                                                            </Typography>
                                                        </Card>
                                                    </Grid>

                                                    <Grid item>
                                                        <Card variant={"outlined"} style={{ padding: 15 }}>
                                                            <Typography variant={"body1"}>
                                                                <Number5CircleOutlineIcon style={{ paddingRight: 5 }} />
                                                                At this point, you almost done. Now user must insert some
                                                                specific information for every marketplace in which you desire
                                                                to sell in. In Sync2Markets this information is know as{" "}
                                                                <em>product' ad</em>.
                                                            </Typography>
                                                            <Typography variant={"body2"}>
                                                                <li>
                                                                    To create the <em>product' ad</em> go to{" "}
                                                                    <LinkTo path={"/seller/product"}>Product</LinkTo> section,
                                                                    select a product and unfolds the marketplaces functions at
                                                                    bottom part of the product card, then use{" "}
                                                                    <Btn name={"Insert product' ad"} /> button to create it.
                                                                </li>
                                                                <li>
                                                                    The requested data in <em>marketplace fields</em> is dynamic
                                                                    and may change from one product to another. This information
                                                                    depends on marketplace needs.
                                                                </li>
                                                                <li>
                                                                    For the specific case of Amazon, the{" "}
                                                                    <em>marketplace fields</em> can be created from one that
                                                                    exist in Amazon's catalog. Use{" "}
                                                                    <Btn name={"Search in Amazon catalog"} /> button to use the
                                                                    option.
                                                                </li>
                                                            </Typography>
                                                        </Card>
                                                    </Grid>
                                                </Grid>

                                                <Grid container item xs={12} md={6} lg={4} spacing={2} direction={"column"}>
                                                    <Grid item>
                                                        <Card variant={"outlined"} style={{ padding: 15 }}>
                                                            <Typography variant={"body1"}>
                                                                <Number3CircleOutlineIcon style={{ paddingRight: 5 }} />
                                                                The main entity at Sync2Markets is the product, all process in
                                                                our system start from there. There are two main ways to create
                                                                products here.
                                                            </Typography>
                                                            <Typography variant={"body2"}>
                                                                <li>
                                                                    Use <Btn name={"Create product"} /> button in{" "}
                                                                    <LinkTo path={"/seller/product"}>Product</LinkTo> section to
                                                                    create a product from the ground. User has to provide all
                                                                    requested information.
                                                                </li>
                                                                <li>
                                                                    Type information in digital forms is very tedious, we are
                                                                    agree of that, so we only request necessary information.
                                                                    Also, we recommend to provide all requested information,
                                                                    even if it isn't a mandatory field.
                                                                </li>
                                                                <li>
                                                                    The other way to create products at Syn2Markets is{" "}
                                                                    <Btn name={"Import"} /> Button. Choose the marketplace in
                                                                    which the product is in. User can import more than one
                                                                    product.
                                                                </li>
                                                                <li>
                                                                    The user must take into account that only its own product at
                                                                    those marketplaces can be imported.
                                                                </li>
                                                                <li>
                                                                    When a product is imported to Sync2Markets many things are
                                                                    done automatically. An associated <em>product</em> is
                                                                    created. Corresponding <em>variation fields</em> (if those
                                                                    exist for selected product) and <em>product' ad</em> are
                                                                    also created.
                                                                </li>
                                                            </Typography>
                                                        </Card>
                                                    </Grid>

                                                    <Grid item>
                                                        <Card variant={"outlined"} style={{ padding: 15 }}>
                                                            <Typography variant={"body1"}>
                                                                <Number6CircleOutlineIcon style={{ paddingRight: 5 }} />
                                                                When <em>product' ad</em> are inserted user just need to press
                                                                the button <Btn name={"Upload ad to marketplace"} />. Now your
                                                                product is in a marketplace.
                                                            </Typography>
                                                        </Card>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </CardBody>
                                </CustomCard>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12}>
                            <HeaderTabs title={"Pages description"} headerColor={"info"} tabs={tabs} />
                        </Grid>

                        {isAdminUser(user) && (
                            <Grid container item xs={12}>
                                <HeaderTabs title={"Admin pages"} headerColor={"info"} tabs={adminTabs} />
                            </Grid>
                        )}
                    </Grid>
                </CardBody>
            </Paper>
        </div>
    )
}
