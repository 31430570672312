import React from "react"
import {
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableBody,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Tooltip,
    CircularProgress,
    TextField,
    InputAdornment,
    Dialog,
    Button,
    Avatar,
    Fade,
    FormControlLabel,
    Checkbox,
    useMediaQuery,
    useTheme,
    Chip,
    Switch,
    Grid
} from "@material-ui/core"
import MuiDialogTitle from "@material-ui/core/DialogTitle"
import MuiDialogContent from "@material-ui/core/DialogContent"
import MuiDialogActions from "@material-ui/core/DialogActions"
import { EditOutlined, DeleteOutlined, InfoOutlined } from "@material-ui/icons"
import SearchAddIcon from "mdi-react/SearchAddIcon"
import SearchIcon from "mdi-react/SearchIcon"
import CancelIcon from "mdi-react/CancelIcon"
import AddIcon from "mdi-react/AddIcon"
import PostOutlineIcon from "mdi-react/PostOutlineIcon"
import TagOutlineIcon from "mdi-react/TagOutlineIcon"

//styles
import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/components/tableStyle"

//assets
import AmazonImg from "assets/img/marketplaces/Amazon-icon.svg"
import EtsyImg from "assets/img/marketplaces/Etsy_icon.svg"
import EbayImg from "assets/img/marketplaces/Ebay_icon.svg"
import FacebookImg from "assets/img/marketplaces/Facebook_icon.svg"
import { grayColor, successColor } from "assets/jss/material-dashboard-react"

//router
import { useHistory } from "react-router-dom"

//apollo
import { Query, Mutation } from "@apollo/client/react/components"
import { LOAD_MARKETPLACES, DELETE_MARKETPLACE, PATCH_MARKETPLACE } from "querys/admin/marketplaceQueries"

//notifications
import { ErrorNotification, SuccessNotification } from "components/Notifications/Notifications"

//table utils
import { getComparator, stableSort, LoadingSkeleton, TableNoData, ConfirmTransition } from "utils/tableUtils"

//redux
import { showApiResponse } from "actions/globalComponents/apiResponseActions"
import { useDispatch } from "react-redux"

//components
import GridItem from "components/Grid/GridItem"
import MarketplaceServicesEditForm from "components/Admin/MarketplaceServicesEditForm"

//page description
import { AdminMarketplaceView } from "utils/viewsDescriptionUtils"

const useStyles = makeStyles(styles)

const notifyError = (message) => ErrorNotification("Marketplace", message)
const notifySuccess = (message) => SuccessNotification("Marketplace", message)

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property)
    }

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

    //not to permit sort by columns of equal values. server sorting does not work properly
    let cells = [
        { id: "image", label: "", align: "left", sort: false },
        { id: "name", label: "Name", align: "left", sort: true },
        { id: "region", label: "Region", align: "left", sort: true },
        { id: "description", label: "Description", align: "left", sort: true },
        { id: "services", label: "Services", align: "left", sort: false },
        { id: "isEnabled", label: "Enabled", align: "left", sort: true },
    ]

    const headCells = () => {
        if (mobileScreen) {
            cells.unshift({ id: "select", label: "", align: "left", sort: false })
        }
        return cells
    }

    return (
        <TableHead>
            <TableRow>
                {headCells().map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.head}
                        align={headCell.align ? headCell.align : "center"}
                    >
                        {headCell.sort ? (
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                                hideSortIcon={!headCell.sort}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            <span>{headCell.label}</span>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

const EnhancedTableToolbar = (props) => {
    const classes = useStyles()

    const history = useHistory()

    const { selected, updateItem, deleteItem } = props

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

    const { loading, handleSearchInputChange } = props

    const [showSearchInput, setShowSearchInput] = React.useState(false)

    const toggleSearchInput = () => {
        setShowSearchInput(!showSearchInput)
    }

    const [searchValue, setSearchValue] = React.useState("")

    const handleSearchValueChange = (e) => {
        setSearchValue(e.target.value)
    }

    const handlePressEnter = (e) => {
        if (e.keyCode === 13) {
            applySearch()
        }
    }

    const applySearch = () => {
        handleSearchInputChange(searchValue)
        toggleSearchInput()
    }

    const cancelFilters = () => {
        setSearchValue("")
        handleSearchInputChange("")
        toggleSearchInput()
    }

    const openForm = () => {
        history.push("/seller/admin-marketplace/form")
    }

    return (
        <Toolbar className={classes.header}>
            {!showSearchInput ? (
                <>
                    <Typography variant="h6" id="tableTitle" component="div" className={classes.headerTitle}>
                        List of available platforms
                        <Tooltip
                            title={<AdminMarketplaceView darkStyle />}
                            arrow
                            interactive
                            classes={{ tooltip: classes.descriptionTooltip }}
                        >
                            <InfoOutlined style={{ fontSize: 16 }} />
                        </Tooltip>
                    </Typography>

                    <div>
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <>
                                <Tooltip title="Filter data">
                                    <IconButton
                                        aria-label="filter data"
                                        color={searchValue ? "secondary" : "inherit"}
                                        onClick={toggleSearchInput}
                                    >
                                        {searchValue ? <SearchAddIcon /> : <SearchIcon />}
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Add marketplace">
                                    <IconButton aria-label="add marketplace" color={"inherit"} onClick={openForm}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>

                                {mobileScreen && selected && (
                                    <>
                                        <br />
                                        <Tooltip title="Edit marketplace">
                                            <IconButton
                                                aria-label="edit marketplace"
                                                color={"inherit"}
                                                onClick={() => updateItem(selected)}
                                            >
                                                <EditOutlined fontSize={"small"} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete marketplace">
                                            <IconButton
                                                aria-label="delete marketplace"
                                                color={"inherit"}
                                                onClick={() => deleteItem(selected)}
                                            >
                                                <DeleteOutlined fontSize={"small"} />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </>
            ) : (
                <Fade in={showSearchInput}>
                    <TextField
                        placeholder="Search..."
                        autoFocus
                        size={"small"}
                        className={classes.searchInput}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end"
                                    onClick={cancelFilters}
                                    className={classes.adornmentSearch}
                                >
                                    <CancelIcon />
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment
                                    position="start"
                                    onClick={applySearch}
                                    className={classes.adornmentSearch}
                                >
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        onKeyDown={handlePressEnter}
                    />
                </Fade>
            )}
        </Toolbar>
    )
}

export default function EnhancedTable() {
    const classes = useStyles()

    const history = useHistory()
    const dispatch = useDispatch()

    //table variables
    const [order, setOrder] = React.useState("asc")
    const [orderBy, setOrderBy] = React.useState("name")
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    const handleRequestSort = (event, property) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        const isAsc = orderBy === property && order === "asc"
        setOrder(isAsc ? "desc" : "asc")
        setOrderBy(property)
    }

    const handleChangeRowsPerPage = (event) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    //search input field
    const [searchInput, setSearchInput] = React.useState("")

    const handleSearchInputChange = (value) => {
        setItemsVariable("firstPageItems")
        setBeforeCursorVariable("")
        setAfterCursorVariable("")
        setPage(0)

        setSearchInput(value)
    }

    //save server response data (page info)
    const [totalItemsPagination, setTotalItemsPagination] = React.useState(-1)
    const [startCursorPagination, setStartCursorPagination] = React.useState("")
    const [endCursorPagination, setEndCursorPagination] = React.useState("")

    const setPaginationResponse = (data) => [
        setTotalItemsPagination(data.allMarketplaces.totalCount),
        setStartCursorPagination(data.allMarketplaces.pageInfo.startCursor),
        setEndCursorPagination(data.allMarketplaces.pageInfo.endCursor),
    ]

    //bind query variables to this variables
    const [beforeCursorVariable, setBeforeCursorVariable] = React.useState("")
    const [afterCursorVariable, setAfterCursorVariable] = React.useState("")
    const [itemsVariable, setItemsVariable] = React.useState("firstPageItems")

    let queryVariables = {
        orderBy: order === "asc" ? [orderBy] : [`-${orderBy}`],
        nameFilter: searchInput,
        [itemsVariable]: rowsPerPage,
        beforeCursor: beforeCursorVariable,
        afterCursor: afterCursorVariable,
    }

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setAfterCursorVariable(endCursorPagination)
            setBeforeCursorVariable("")

            setItemsVariable("firstPageItems")
        } else {
            setBeforeCursorVariable(startCursorPagination)
            setAfterCursorVariable("")

            setItemsVariable("lastPageItems")
        }
        setPage(newPage)
    }

    const updateItem = (item) => {
        history.push("/seller/admin-marketplace/form", { updateItem: item })
        setSelected(null)
    }

    //edit services dialog
    const [openServicesDialog, setOpenServicesDialog] = React.useState(false)
    const [selectedServicesMarketplace, setSelectedServicesMarketplace] = React.useState(false)

    function handleCloseServicesDialog() {
        setOpenServicesDialog(false)
    }

    const updateServices = (item) => {
        setSelectedServicesMarketplace(item)
        setOpenServicesDialog(true)
    }

    const theme = useTheme()
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"))

    //table selected item (for small screens)
    const [selected, setSelected] = React.useState(null)
    const handleSelectItem = (item) => {
        if (selected && selected.node.id === item.node.id) {
            setSelected(null)
        } else {
            setSelected(item)
        }
    }

    //toggle dialog
    const [openToggleDialog, setOpenToggleDialog] = React.useState(false)
    const [selectedMarketplace, setSelectedMarketplace] = React.useState(false)

    const handleOpenDialog = (marketplace) => {
        setSelectedMarketplace(marketplace.node)
        setOpenToggleDialog(true)
    }

    function ToggleEnableDialog(props) {
        const { open, setOpen, register, loading, selectedMarketplace } = props

        const handleClose = () => {
            setOpen(false)
        }

        const toggle = () =>
            register({
                variables: {
                    id: selectedMarketplace.id,
                    isEnabled: !selectedMarketplace.isEnabled,
                },
            }).then(
                (response) => {
                    if (response?.data?.patchMarketplace?.marketplace?.id) {
                        notifySuccess("Marketplace enabled state changed")
                        handleClose()
                    } else {
                        notifyError("Marketplace toggle state failed, try again")
                    }
                },
                () => {
                    notifyError("Marketplace toggle state failed, try again")
                    handleClose()
                }
            )

        return (
            <div>
                <Dialog
                    open={open}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle disableTypography>
                        <Typography variant="h6">Toggle state</Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        <Typography gutterBottom>Do you confirm to toggle marketplace state?</Typography>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Button onClick={toggle} color="primary">
                                Confirm
                            </Button>
                        )}
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }

    const ServiceTag = ({ service }) => (
        <Grid
            style={{
                display: "inline-flex",
                margin: "5px",
                backgroundColor: "rgba(170, 170, 170, 0.8)",
                padding: "2px 4px",
                borderRadius: "5px 2px 2px 2px",
                border: "1px solid rgba(130, 130, 130)",
                fontSize: 14,
            }}
        >
            <Grid item>
                <TagOutlineIcon size={14} style={{ color: "white", marginRight: "3px" }} />
            </Grid>
            <Grid item style={{ marginRight: "3px" }}>
                <Typography variant={"caption"} style={{ color: "white", fontWeight: "600" }}>
                    {service}
                </Typography>
            </Grid>
        </Grid>
    )

    const TableRows = (props) => {
        const { row, index } = props

        function getMarketplaceDefaultImage(marketplace) {
            switch (marketplace.toUpperCase()) {
                case "AMAZON":
                    return AmazonImg
                case "ETSY":
                    return EtsyImg
                case "EBAY":
                    return EbayImg
                case "FACEBOOK":
                    return FacebookImg
                default:
                    return ""
            }
        }

        const composeUrl = (row) => {
            return row.node.image
                ? `${process.env.REACT_APP_API_URL}file/${row.node.id}`
                : getMarketplaceDefaultImage(row.node.name)
        }

        return (
            <TableRow hover tabIndex={-1} key={`row-${index}`} className={classes.row}>
                {mobileScreen && (
                    <TableCell align="left">
                        <Checkbox
                            color={"primary"}
                            size={"small"}
                            checked={selected && row.node.id === selected.node.id}
                            onClick={() => handleSelectItem(row)}
                        />
                    </TableCell>
                )}
                <TableCell align="right" style={{ padding: "7px 15px" }}>
                    <Avatar variant={"rounded"} alt={row.node.name} src={composeUrl(row)} className={classes.avatar} />
                </TableCell>
                <TableCell align="left">{row.node.name}</TableCell>
                <TableCell align="left">{row.node.region ? row.node.region : "-"}</TableCell>
                <TableCell align="left">{row.node.description}</TableCell>
                <TableCell align="left">
                    {row.node.marketplaceMs.edges.map(item => (
                        <ServiceTag key={item.node.id} service={item.node.service.name}/>
                    ))}
                </TableCell>
                <TableCell align="left">
                    <Chip
                        variant="outlined"
                        size={"small"}
                        style={
                            row.node.isEnabled
                                ? { color: successColor[0], borderColor: successColor[0] }
                                : { color: grayColor[0], borderColor: grayColor[0] }
                        }
                        label={row.node.isEnabled ? "yes" : "no"}
                    />

                    <div className={classes.actionColumn}>
                        <Switch
                            color={"primary"}
                            size={"small"}
                            checked={row.node.isEnabled}
                            onClick={() => handleOpenDialog(row)}
                        />
                        <Tooltip title="Edit marketplace">
                            <IconButton
                                aria-label="edit marketplace"
                                className={classes.editBtn}
                                onClick={() => updateItem(row)}
                            >
                                <EditOutlined fontSize={"small"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit marketplace services">
                            <IconButton
                                aria-label="marketplace services"
                                className={classes.editBtn}
                                onClick={() => updateServices(row)}
                            >
                                <PostOutlineIcon fontSize={"small"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete marketplace">
                            <IconButton
                                aria-label="delete marketplace"
                                className={classes.deleteBtn}
                                onClick={() => deleteItem(row)}
                            >
                                <DeleteOutlined fontSize={"small"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </TableCell>
            </TableRow>
        )
    }

    const [openDialog, setOpenDialog] = React.useState(false)
    const [deleteItemIndex, setDeleteItemIndex] = React.useState(null)
    const deleteItem = (item) => {
        setDeleteItemIndex(item.node.id)
        setOpenDialog(true)
        setSelected(null)
    }

    function ConfirmDialog(props) {
        const { register, refetch, loading } = props

        const handleClose = () => {
            setOpenDialog(false)
        }

        //true for option = 2
        const [optionValue, setOptionValue] = React.useState(false)
        const handleSelectCheck = (e) => {
            setOptionValue(e.target.checked)
        }

        const deleteItem = () =>
            register({
                variables: {
                    id: deleteItemIndex,
                    option: optionValue ? 2 : 1,
                },
            }).then(
                (response) => {
                    if (response.data.deleteMarketplace.found) {
                        let apiResponse = response.data.deleteMarketplace.apisResponse
                        if (apiResponse.length) {
                            dispatch(showApiResponse({ open: true, message: apiResponse, forceOptionsBtn: false }))
                        }
                        notifySuccess("Marketplace deleted")
                        refetch()
                    }
                    handleClose()
                },
                () => {
                    notifyError("Delete marketplace failed, try again")
                    handleClose()
                }
            )

        return (
            <div>
                <Dialog
                    open={openDialog}
                    TransitionComponent={ConfirmTransition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <MuiDialogTitle disableTypography>
                        <Typography variant="h6">Delete marketplace</Typography>
                    </MuiDialogTitle>
                    <MuiDialogContent dividers>
                        <Typography gutterBottom>Do you confirm to delete the marketplace?</Typography>
                        <GridItem xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={"optionValue"}
                                        color="primary"
                                        checked={optionValue}
                                        onChange={handleSelectCheck}
                                    />
                                }
                                label="Also, delete listings in this marketplace"
                            />
                        </GridItem>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <Button onClick={handleClose} color="secondary" disabled={loading}>
                            Cancel
                        </Button>
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Button onClick={deleteItem} color="primary">
                                Confirm
                            </Button>
                        )}
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }

    return (
        <>
            {selectedServicesMarketplace && (
                <MarketplaceServicesEditForm
                    openDialog={openServicesDialog}
                    handleClose={handleCloseServicesDialog}
                    marketplace={selectedServicesMarketplace}
                />
            )}

            <Query
                query={LOAD_MARKETPLACES}
                fetchPolicy={"network-only"}
                variables={queryVariables}
                onCompleted={(data) => {
                    setPaginationResponse(data)
                }}
            >
                {({ loading, error, data, refetch }) => {
                    if (error) {
                        notifyError("Load data failed")
                    }

                    let rows = data?.allMarketplaces.edges ?? []
                    return (
                        <Mutation mutation={DELETE_MARKETPLACE}>
                            {(register, { loading: loadingDelete }) => (
                                <Mutation mutation={PATCH_MARKETPLACE}>
                                    {(toggleEnabled, { loading: loadingToggle }) => (
                                        <div className={classes.root}>
                                            <ConfirmDialog
                                                register={register}
                                                refetch={refetch}
                                                loading={loadingDelete}
                                            />
                                            <ToggleEnableDialog
                                                open={openToggleDialog}
                                                setOpen={setOpenToggleDialog}
                                                register={toggleEnabled}
                                                loading={loadingToggle}
                                                selectedMarketplace={selectedMarketplace}
                                            />
                                            <Paper className={classes.paper}>
                                                <EnhancedTableToolbar
                                                    loading={loading || loadingDelete}
                                                    handleSearchInputChange={handleSearchInputChange}
                                                    selected={selected}
                                                    updateItem={updateItem}
                                                    deleteItem={deleteItem}
                                                />
                                                {loading ? (
                                                    <LoadingSkeleton />
                                                ) : !rows.length ? (
                                                    <TableNoData />
                                                ) : (
                                                    <TableContainer>
                                                        <Table
                                                            className={classes.table}
                                                            aria-labelledby="tableTitle"
                                                            aria-label="enhanced table"
                                                        >
                                                            <EnhancedTableHead
                                                                classes={classes}
                                                                order={order}
                                                                orderBy={orderBy}
                                                                onRequestSort={handleRequestSort}
                                                                rowCount={rows.length}
                                                            />
                                                            <TableBody>
                                                                {stableSort(rows, getComparator(order, orderBy)).map(
                                                                    (row, index) => {
                                                                        return (
                                                                            <TableRows
                                                                                row={row}
                                                                                index={index}
                                                                                key={index}
                                                                            />
                                                                        )
                                                                    }
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                )}
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    component="div"
                                                    count={totalItemsPagination}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            </Paper>
                                        </div>
                                    )}
                                </Mutation>
                            )}
                        </Mutation>
                    )
                }}
            </Query>
        </>
    )
}
