import React from "react";

import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";

import SphereDiameterOutlineIcon from "mdi-react/SphereDiameterOutlineIcon";

import {Button, Checkbox, CircularProgress, FormControlLabel, Typography} from "@material-ui/core";

//apollo
import {useMutation} from "@apollo/client";
import {RESET_DIMENSION_UNIT_VALUES} from "querys/admin/resetValuesQueries";

//forms
import * as Yup from "yup";
import {Formik} from "formik";

//notifications
import {ErrorNotification, SuccessNotification} from "components/Notifications/Notifications";

//errors
import {getFormErrors} from "utils/errorsUtils";

export default function DimensionUnitReset() {
    //reset dimension units
    const [resetDimensionUnits, {loading: loadingReset}] = useMutation(RESET_DIMENSION_UNIT_VALUES)

    const notifySuccess = message => SuccessNotification("Dimension units", message)
    const notifyError = message => ErrorNotification("Dimension units", message)

    const successForm = (othersRemovedQuantity) => {
        notifySuccess(`Values reset to default. Removed: ${othersRemovedQuantity}`)
    }

    return (
        <Card>
            <CardHeader>
                <CardIcon color="success">
                    <SphereDiameterOutlineIcon style={{color: "#fff"}}/>
                </CardIcon>
                <Typography variant="subtitle2">Reset dimension units to default values</Typography>
            </CardHeader>
            <Formik
                initialValues={
                    {
                        keepOthers: false,
                    }
                }
                validationSchema={
                    Yup.object({
                        keepOthers: Yup.boolean()
                    })
                }
                onSubmit={(data, { setErrors, resetForm }) => {
                    resetDimensionUnits({
                        variables: {
                            keepOthers: data.keepOthers
                        }
                    }).then(
                        (response) => {
                            let dataResponse = response.data.resetDefaultDimensionUnitList

                            if (dataResponse.success) {
                                resetForm()
                                successForm(dataResponse.othersRemovedQuantity)
                            }
                        },
                        (response) => {
                            if (response.graphQLErrors.length) {
                                getFormErrors(response.graphQLErrors, notifyError, setErrors)
                            } else {
                                notifyError("Reset dimension units to default failed, try again")
                            }
                        }
                    )
                }}
            >
                {
                    formik => (
                        <form onSubmit={formik.handleSubmit}>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name={"keepOthers"}
                                                    checked={formik.values.keepOthers}
                                                    {...formik.getFieldProps("keepOthers")}
                                                    color="primary"
                                                />
                                            }
                                            label="Preserve other inserted values"
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter chart>
                                <Button
                                    size={"small"}
                                    variant={"text"}
                                    color={"secondary"}
                                    type="submit"
                                >
                                    {
                                        loadingReset
                                            ? <CircularProgress size={24}/>
                                            : "Reset"
                                    }
                                </Button>
                            </CardFooter>
                        </form>
                    )
                }
            </Formik>
        </Card>
    )
}
