function tryParseErrorsObj(jsonString) {
    try {
        const obj = JSON.parse(jsonString.replace(/'/g, '"'))

        if (obj && typeof obj === "object") {
            return obj
        }
    } catch (e) {}

    return false
}

export function getFormErrors(errors, notifyError, setErrors = null) {
    const errorsObj = tryParseErrorsObj(errors[0].message)

    if (errorsObj) {
        let fieldErrors = {}

        for (const errorsObjKey in errorsObj) {
            if (errorsObjKey === "general" || errorsObjKey === "generals") {
                notifyError(errorsObj[errorsObjKey])
            } else {
                fieldErrors[errorsObjKey] = `${errorsObj[errorsObjKey]}`.replace(/,/g, ". ")
            }
        }

        if (setErrors) {
            setErrors(fieldErrors)
        }
    } else {
        notifyError(errors[0].message)
    }
}
