import React from "react"

//components
import UserProductsStats from "components/Stats/UserProductsStats"
import UserMarketplacesStats from "components/Stats/UserMarketplacesStats"
import SystemUsersStats from "components/Stats/SystemUsersStats"
import SystemAdminUsersStats from "components/Stats/SystemAdminUsersStats"
import SellersStats from "components/Stats/SellersStats"
import UserProductsChartStats from "components/Stats/UserProductsChartStats"
import SystemProductsChartStats from "components/Stats/SystemProductsChartStats"
//import SystemTaskStats from "components/Stats/SystemTaskStats";
//import UsersByCountryStats from "components/Stats/UsersByCountryStats";

//apollo
import { useLazyQuery, useQuery } from "@apollo/client"
import { SELLER_STATS, ADMIN_STATS } from "querys/admin/appStatsQueries"

//notifications
import { ErrorNotification } from "components/Notifications/Notifications"

//redux
import { useSelector } from "react-redux"

//adsense
import { Adsense } from "@ctrl/react-adsense"
import { Grid } from "@material-ui/core"
import MessageCenterStats from "../../../components/Stats/MessageCenterStats"

//drag list
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import MainButtons from "../../../components/Dashboard/MainButtons"

export default function DashboardStats(props) {
    const notifyError = (message) => ErrorNotification("Seller", message)

    const CURRENT_USER = useSelector((state) => state.loginReducer)

    const [sellerStats, setSellerStats] = React.useState(null)

    useQuery(SELLER_STATS, {
        onCompleted: (data) => {
            if (data.dashboardSellerInfoType) {
                setSellerStats(data.dashboardSellerInfoType)
            }
        },
        onError: () => {
            notifyError("Unable to load seller stats, try again")
        },
        fetchPolicy: "network-only",
    })

    const [adminStats, setAdminStats] = React.useState(null)

    const [loadAdminStats] = useLazyQuery(ADMIN_STATS, {
        onCompleted: (data) => {
            if (data.dashboardAdminInfoType) {
                setAdminStats(data.dashboardAdminInfoType)
            }
        },
        onError: () => {
            notifyError("Unable to load admin stats, try again")
        },
        fetchPolicy: "network-only",
    })

    //a user is considered admin if it is isSuperuser, isStaff or has a permissions group named Admin
    const isAdminUser = (user) => {
        if (user) {
            if (user.isSuperuser || user.isStaff) {
                return true
            }
            return user.groups.findIndex((group) => group.name.toUpperCase() === "ADMIN") > -1
        }
        return false
    }

    React.useEffect(() => {
        if (
            CURRENT_USER &&
            (CURRENT_USER.isSuperuser ||
                CURRENT_USER.isStaff ||
                CURRENT_USER.groups.edges.findIndex((group) => group.node.name.toUpperCase() === "ADMIN") > -1)
        ) {
            loadAdminStats()
        }
    }, [CURRENT_USER, loadAdminStats])

    const [defaultUserStatsOrder, setDefaultUserStatsOrder] = React.useState([
        { component: <UserProductsStats sellerStats={sellerStats} />, key: "UserProductsStats" },
        { component: <UserMarketplacesStats sellerStats={sellerStats} />, key: "UserMarketplacesStats" },
        { component: <MessageCenterStats />, key: "MessageCenterStats" },
    ])

    // Function to update list on drop
    const handleDrop = (droppedItem) => {
        if (droppedItem.destination) {
            let updatedList = [...defaultUserStatsOrder]

            const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1)

            updatedList.splice(droppedItem.destination.index, 0, reorderedItem)

            setDefaultUserStatsOrder(updatedList)
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid
                container
                item
                xs={12}
                style={{ display: "flex", border: "1px solid black", height: "150px", marginBottom: "20px" }}
            >
                adsense goes here...
                <Adsense client="ca-pub-8905707027218694" slot="6551125279" format={"auto"} />
            </Grid>

            <Grid item xs={12}>
                <MainButtons/>
            </Grid>

            <Grid container item spacing={3}>
                <DragDropContext onDragEnd={(droppedItem) => handleDrop(droppedItem)}>
                    <Droppable droppableId="list-container" direction={"horizontal"}>
                        {(provided) => (
                            <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                justifyContent={"space-around"}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {defaultUserStatsOrder.map((item, index) => (
                                    <Draggable key={item.key} draggableId={item.key} index={index}>
                                        {(provided) => (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                key={item.key}
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                            >
                                                {item.component}
                                            </Grid>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>

                {isAdminUser(CURRENT_USER) && (
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <SystemUsersStats adminStats={adminStats} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SystemAdminUsersStats adminStats={adminStats} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SellersStats adminStats={adminStats} />
                        </Grid>
                    </Grid>
                )}
            </Grid>

            <Grid container item justifyContent={"center"} spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    {sellerStats && <UserProductsChartStats sellerStats={sellerStats} />}
                </Grid>
                {isAdminUser(CURRENT_USER) && adminStats && (
                    <Grid item xs={12} sm={12} md={6}>
                        <SystemProductsChartStats adminStats={adminStats} />
                    </Grid>
                )}
            </Grid>

            {/*<GridContainer>*/}
            {/*    <GridItem xs={12} sm={12} md={6}>*/}
            {/*        <SystemTaskStats/>*/}
            {/*    </GridItem>*/}
            {/*    {*/}
            {/*        isAdminUser(user)*/}
            {/*        && <GridItem xs={12} sm={12} md={6}>*/}
            {/*            <UsersByCountryStats adminStats={adminStats}/>*/}
            {/*        </GridItem>*/}
            {/*    }*/}
            {/*</GridContainer>*/}
        </Grid>
    )
}
