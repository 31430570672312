import { backGroundGrayColor } from "assets/jss/material-dashboard-react.js"

const publicLayoutStyle = theme => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "80px 15px 10px 15px",
        backgroundColor: backGroundGrayColor,
        minHeight: "calc(100vh - 152px)",
    }
});

export default publicLayoutStyle;
