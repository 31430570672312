import React from "react";

import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import CardIcon from "components/Card/CardIcon";

import {
    Avatar,
    Button,
    CircularProgress,
    Grid,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from "@material-ui/core";

//apollo
import {useMutation, useQuery} from "@apollo/client";
import {
    AMAZON_ORDERS_WATCHER_SCHEDULER_IS_RUNNING,
    AMAZON_ORDERS_WATCHER_SCHEDULER_START,
    AMAZON_ORDERS_WATCHER_SCHEDULER_STOP
} from "querys/admin/amazonOrdersWatcherSchedulerQueries";

//styles
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/views/configurationStyle";

//notifications
import {ErrorNotification, SuccessNotification} from "components/Notifications/Notifications";

//icon
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";

//errors
import {getFormErrors} from "utils/errorsUtils";

//forms
import * as Yup from "yup";
import {Formik} from "formik";

//images
import AmazonImg from "assets/img/marketplaces/Amazon-icon.svg";
import TimerOutlineIcon from "mdi-react/TimerOutlineIcon";
import WarningOutlineIcon from "mdi-react/WarningOutlineIcon";


const useStyles = makeStyles(styles);

export default function AmazonOrdersWatcher() {
    const classes = useStyles();

    const notifySuccess = message => SuccessNotification("Schedule task", message)
    const notifyError = message => ErrorNotification("Schedule task", message)

    //to know if schedule task is running
    const {data, error} = useQuery(
        AMAZON_ORDERS_WATCHER_SCHEDULER_IS_RUNNING,
        {
            fetchPolicy: "network-only"
        }
    )

    if (error) {
        notifyError("Unable to load status of schedule task to Amazon orders watcher, try again")
    }

    let task = data?.schedulerIsRunningAmazonOrderWatcher ?? {isRunning: false}

    //start task
    const [startTask, {loading: loadingStartTask}] = useMutation(AMAZON_ORDERS_WATCHER_SCHEDULER_START)
    //stop task
    const [stopTask, {loading: loadingStopTask}] = useMutation(AMAZON_ORDERS_WATCHER_SCHEDULER_STOP)

    function handleStartTask(data, resetForm) {
        startTask({
            variables: {
                timeInterval: data.timeInterval ? data.timeInterval : null
            },
            refetchQueries: [
                {
                    query: AMAZON_ORDERS_WATCHER_SCHEDULER_IS_RUNNING
                }
            ]
        }).then(
            (response) => {
                let dataResponse = response.data.schedulerStartAmazonOrderWatcher

                if (dataResponse.success) {
                    resetForm()
                    return notifySuccess("Amazon orders watchers scheduler has been started")
                } else {
                    return  notifyError("Amazon orders watchers scheduler failed, try again")
                }
            },
            (response) => {
                if (response.graphQLErrors.length) {
                    getFormErrors(response.graphQLErrors, notifyError, null)
                } else {
                    notifyError("Amazon orders watchers scheduler failed, try again")
                }
            }
        )
    }

    function handleStopTask() {
        stopTask({
            refetchQueries: [
                {
                    query: AMAZON_ORDERS_WATCHER_SCHEDULER_IS_RUNNING
                }
            ]
        }).then(
            (response) => {
                let dataResponse = response.data.schedulerStopAmazonOrderWatcher

                if (dataResponse.success) {
                    return notifySuccess("Amazon orders watchers scheduler has been stopped")
                } else {
                    return  notifyError("Amazon orders watchers scheduler stop failed, try again")
                }
            },
            (response) => {
                if (response.graphQLErrors.length) {
                    getFormErrors(response.graphQLErrors, notifyError, null)
                } else {
                    notifyError("Amazon orders watchers scheduler stop failed, try again")
                }
            }
        )
    }

    const validationSchema = Yup.object({
        timeInterval: Yup
            .number()
            .typeError("interval must be an positive integer number")
            .integer()
            .positive("interval must be an positive integer number")
            .max(59)
    })

    return (
        <Formik
            initialValues={{
                timeInterval: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(data, {resetForm}) => {
                handleStartTask(data, resetForm)
            }}
        >
            {
                formik => (
                    <form onSubmit={formik.handleSubmit}>
                        <Card>
                            <CardHeader className={classes.cardHeader}>
                                <CardIcon color="success">
                                    <EyeOutlineIcon style={{color: "#fff"}}/>
                                </CardIcon>
                                <Typography variant="subtitle2" style={{marginRight: "45px"}}>Amazon orders watcher</Typography>
                                <div className={classes.headerActions}>
                                    {
                                        (loadingStartTask || loadingStopTask)
                                            ? <CircularProgress size={24}/>
                                            : <Avatar
                                                src={AmazonImg}
                                                variant={"circular"}
                                                className={classes.avatar}
                                            />
                                    }
                                </div>
                            </CardHeader>
                            <CardBody>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        Orders watcher is
                                        {
                                            task.isRunning
                                                ? <code className={classes.runningTask}>running</code>
                                                : <code className={classes.stoppedTask}>stopped</code>
                                        }
                                        {
                                            task.params
                                            && <>
                                                <span>with time interval</span>
                                                <code className={classes.timeInterval}>{task.params}</code>
                                                <span>minutes</span>
                                            </>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            task.lastError
                                            && <Tooltip
                                                title={task.lastError}
                                                classes={{tooltip: classes.infoTooltip}}
                                            >
                                                <code className={classes.errorOccurred}>
                                                    <WarningOutlineIcon color={"#fff"} fontSize={12}/>
                                                    Error occurred during execution
                                                </code>
                                            </Tooltip>
                                        }
                                    </Grid>
                                    <Grid container item spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="timeInterval"
                                                label="Time interval"
                                                variant={"filled"}
                                                size={"small"}
                                                type={"number"}
                                                disabled={task.isRunning}
                                                {...formik.getFieldProps("timeInterval")}
                                                error={formik.errors.timeInterval && formik.touched.timeInterval}
                                                helperText={(formik.errors.timeInterval && formik.touched.timeInterval) && formik.errors.timeInterval}
                                                placeholder={"minutes interval"}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <TimerOutlineIcon />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button
                                                size={"small"}
                                                variant={"contained"}
                                                color={"primary"}
                                                disabled={task.isRunning || !formik.isValid}
                                                type={"submit"}
                                            >
                                                Start
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button
                                                size={"small"}
                                                variant={"contained"}
                                                color={"secondary"}
                                                disabled={!task.isRunning}
                                                onClick={handleStopTask}
                                            >
                                                Stop
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardBody>
                        </Card>
                    </form>
                )
            }
        </Formik>
    )
}
