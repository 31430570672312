export default function apiResponseReducer(
    state = {
        open: false,
        message: "",
        forceOptionsBtn: false
    },
    action
) {
    switch (action.type) {
        case 'SHOW_API_RESPONSE':
            state = action.payload
            return state
        case 'CLOSE_API_RESPONSE':
            state = action.payload
            return state
        default:
            return state
    }
}
