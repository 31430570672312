import React from "react"

import { TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ImportIcon from "mdi-react/ImportIcon"

import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CardHeader from "components/Card/CardHeader"

import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"

//assets
import styles from "assets/jss/material-dashboard-react/views/infoPageStyle"
import Grid from "@material-ui/core/Grid"
import { primaryColor } from "../../assets/jss/material-dashboard-react"

//icons
import Number1CircleOutlineIcon from "mdi-react/Number1CircleOutlineIcon"
import Number2CircleOutlineIcon from "mdi-react/Number2CircleOutlineIcon"

const useStyles = makeStyles(styles)

export default function FeatureImportProduct(props) {
    const classes = useStyles()

    const importData1 = [
        {
            action: "Access Import product page",
            details: "Access import page",
        },
        {
            action: "Import from available marketplaces",
            details: "Select marketplace from available where desired product(s) exist",
        },
        {
            action: "Select the ads to import",
            details: "Select desired ads to import. Already imported ads can not be imported",
        },
        {
            action: "Import selected ads",
            details: "Use import button to complete action",
        },
    ]

    const importData2 = [
        {
            action: "Search product in Amazon's Catalog",
            details: "Search an existing product in Amazon's Catalog",
        },
        {
            action: "Select a product to relate",
            details: "Select an equivalent product from there to relate with one in Sync2Markets",
        },
        {
            action: "Relate product and variations if decided",
            details: "Decide if relate product and variations",
        },
    ]

    return (
        <Card className={classes.cardContainer}>
            <CardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <ImportIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Import product</h4>
            </CardHeader>
            <CardBody>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ textAlign: "justify" }}>
                        <Typography variant={"body1"}>
                            Sync2Markets' users has two ways to import products and/or ads from integrated marketplaces.
                        </Typography>
                    </Grid>

                    <Grid container item spacing={5}>
                        <Grid container item>
                            <Grid item xs={12} style={{ textAlign: "justify" }}>
                                <Typography variant={"body2"}>
                                    <Number1CircleOutlineIcon color={primaryColor[0]} />
                                    First one. User can import existing ads from any related marketplace. Once these ads
                                    are successfully imported, the corresponding products, product variations (if exist)
                                    and ads are created in Sync2Markets. Then, user has full control over them.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} lg={6}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Action</TableCell>
                                            <TableCell>Description</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {importData1.map((item) => (
                                            <TableRow key={item.action}>
                                                <TableCell>{item.action}</TableCell>
                                                <TableCell>{item.details}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>

                        <Grid item container>
                            <Grid item xs={12} style={{ textAlign: "justify" }}>
                                <Typography variant={"body1"}>
                                    <Number2CircleOutlineIcon color={primaryColor[0]} />
                                    Second way is to use a Sync2Markets' product (also variations associated to product
                                    if user decided to) related with a product in Amazon marketplace (searching in
                                    Amazon Catalog). When products are successfully related, Amazon' ad is created in
                                    our system and related to one used from catalog.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} lg={6}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Action</TableCell>
                                            <TableCell>Description</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {importData2.map((item) => (
                                            <TableRow key={item.action}>
                                                <TableCell>{item.action}</TableCell>
                                                <TableCell>{item.details}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardBody>
        </Card>
    )
}
