import React from "react"

import { Avatar, Divider, Card, CardHeader, CardContent, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ShopOutlineIcon from "mdi-react/ShopOutlineIcon"
import LinkIcon from "mdi-react/LinkIcon"

import CustomCard from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon"
import CustomCardHeader from "components/Card/CardHeader"

//assets
import styles from "assets/jss/material-dashboard-react/views/infoPageStyle"
import { grayColor } from "assets/jss/material-dashboard-react"
import AmazonImg from "assets/img/marketplaces/Amazon-icon.svg"
import EtsyImg from "assets/img/marketplaces/Etsy_icon.svg"
import EbayImg from "assets/img/marketplaces/Ebay_icon.svg"

const useStyles = makeStyles(styles)

export default function MarketplacesInfo(props) {
    const classes = useStyles()

    const marketplacesInformation = [
        {
            title: "Amazon",
            description:
                "Amazon.com is a vast Internet-based enterprise that sells books, music, movies, housewares, " +
                "electronics, toys, and many other goods, either directly or as the middleman between other retailers " +
                "and Amazon.com’s, to millions of customers.",
            icon: AmazonImg,
            altText: "Amazon",
            link: "https://www.amazon.com",
        },
        {
            title: "Etsy",
            description:
                "Etsy is an online marketplace that connects sellers with buyers. It is primarily used for " +
                "selling vintage items, handmade goods, art, and crafts.",
            icon: EtsyImg,
            altText: "Etsy",
            link: "https://www.etsy.com",
        },
        {
            title: "EBay",
            description:
                "eBay was one of the first companies to create an Internet Web site as a market to " +
                "match buyers and sellers of goods and services. The company, which caters to individual sellers and " +
                "small businesses, is a market leader in e-commerce worldwide.",
            icon: EbayImg,
            altText: "EBay",
            link: "https://www.ebay.com",
        },
    ]

    const MarketplaceInfo = (title, description, icon, altText, link) => {
        return (
            <Card variant={"outlined"}>
                <CardHeader
                    title={<Typography variant={"subtitle1"}>{title}</Typography>}
                    avatar={<Avatar variant={"rounded"} alt={altText} src={icon} className={classes.avatar} />}
                />
                <Divider flexItem style={{ height: "1px", backgroundColor: grayColor[4], width: "100%" }} />
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid item style={{ textAlign: "justify" }}>
                            {description}
                        </Grid>
                        <Grid container item alignItems={"center"} className={classes.linkContainer}>
                            <LinkIcon className={classes.linkIcon} />
                            <a href={link} title={link} rel="noopener noreferrer" target={"_blank"}>
                                {" "}
                                {link}
                            </a>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }

    return (
        <CustomCard className={classes.cardContainer}>
            <CustomCardHeader className={classes.cardHeader}>
                <CardIcon color="info" className={classes.cardHeaderIcon}>
                    <ShopOutlineIcon />
                </CardIcon>
                <h4 className={classes.cardTitle}>Marketplaces</h4>
            </CustomCardHeader>
            <CardBody>
                <Grid container spacing={2}>
                    {marketplacesInformation.map((marketplace) => (
                        <Grid key={marketplace.title} item xs={12} sm={6} md={4}>
                            {MarketplaceInfo(
                                marketplace.title,
                                marketplace.description,
                                marketplace.icon,
                                marketplace.altText,
                                marketplace.link
                            )}
                        </Grid>
                    ))}
                </Grid>
            </CardBody>
        </CustomCard>
    )
}
