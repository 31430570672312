import {
    primaryColor,
    whiteColor,
    warningColor,
    grayColor,
    dangerColor,
    successColor,
} from "assets/jss/material-dashboard-react.js"
import tooltipStyle from "assets/jss/material-dashboard-react/tooltipStyle"

const tableStyle = (theme) => ({
    header: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: primaryColor[1],
        color: whiteColor,
        fontSize: "16px",
        position: "relative",
    },
    headerTitle: {
        fontSize: "16px",
    },
    root: {
        width: "100%",
    },
    head: {
        fontWeight: 600,
        color: "rgba(0, 0, 0, 0.7)",
    },
    paper: {
        width: "100%",
    },
    table: {
        minWidth: 750,
    },
    row: {
        backgroundColor: whiteColor,
        transition: "background-color 500ms ease",
        "&:hover": {
            "& td div": {
                opacity: 1,
            },
        },
        "&>td:last-child": {
            position: "relative",
        },
    },
    actionColumn: {
        opacity: 0,
        transition: "opacity 0.3s linear",

        backgroundImage: "linear-gradient(to left, rgb(245, 245, 245) 85%, rgba(0, 0, 0, 0))",
        height: "100%",
        display: "flex",
        alignItems: "center",
        paddingLeft: "25px",
        paddingRight: "20px",

        position: "absolute",
        zIndex: 1,
        right: 0,
        top: 0,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    checkBtn: {
        color: successColor[0],
        paddingRight: "0 5px",
        "&:hover": {
            backgroundColor: grayColor[5],
        },
    },
    editBtn: {
        color: warningColor[0],
        paddingRight: "0 5px",
        "&:hover": {
            backgroundColor: grayColor[5],
        },
    },
    deleteBtn: {
        color: dangerColor[0],
        margin: "0 5px",
        "&:hover": {
            backgroundColor: grayColor[5],
        },
    },
    addBtn: {
        color: successColor[0],
        margin: "0 5px",
        "&:hover": {
            backgroundColor: grayColor[5],
        },
    },
    //skeleton loader
    skeletonContainer: {
        padding: "15px",
    },
    noDataSection: {
        width: "100%",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#a9afbb",
    },
    searchInput: {
        position: "absolute",
        left: "2%",
        bottom: "10px",
        width: "96%",
        "& .MuiInputBase-root": {
            color: whiteColor,
        },
    },
    adornmentSearch: {
        color: whiteColor,
        cursor: "pointer",
        paddingBottom: "3px",
    },

    //table head search fields
    fieldSearch: {
        width: "150px",
        maxWidth: "150px",
    },

    //marketplace avatar
    avatar: {
        width: 60,
        height: 60,
        border: `1px solid ${primaryColor[2]}`,
        "& .MuiAvatar-img": {
            width: "auto",
            height: "90%",
        },
    },

    //marketplace avatar
    smallAvatar: {
        width: 35,
        height: 35,
        marginTop: "3px",
        marginBottom: "3px",
        border: `1px solid ${primaryColor[2]}`,
        "& .MuiAvatar-img": {
            width: "auto",
            height: "90%",
        },
    },

    //info tooltip
    infoTooltip: {
        ...tooltipStyle.tooltip,
    },

    //description tooltip
    descriptionTooltip: {
        ...tooltipStyle.descriptionTooltip,
    },

    //speed dial
    speedDialBtn: {
        position: "absolute",
        right: "5px",
        top: "10px",
        "& .MuiFab-root": {
            height: "40px",
            width: "40px",
            backgroundColor: primaryColor[1],
            color: whiteColor,
        },
    },

    //product import
    marketplaceAvatar: {
        width: 25,
        height: 25,
        "& .MuiAvatar-img": {
            width: "auto",
            height: "90%",
        },
    },
})

export default tableStyle
