import React from "react"

//icons
import ShoppingBasketOutlineIcon from "mdi-react/ShoppingBasketOutlineIcon"
import ChevronDownIcon from "mdi-react/ChevronDownIcon"
import ChevronRightIcon from "mdi-react/ChevronRightIcon"

//styles
import styles from "assets/jss/material-dashboard-react/components/dashboardStatsStyle"
import { makeStyles } from "@material-ui/core/styles"

//link
import { NavLink } from "react-router-dom"

//material ui
import { Avatar, Card, CardContent, Fade, Grid } from "@material-ui/core"

//apollo
import { useQuery } from "@apollo/client"
import { LOAD_ALL_CATEGORIES } from "../../querys/seller/Category/categoryQueries"

//redux
import { useSelector } from "react-redux"
import { DragHandle } from "@material-ui/icons"

const useStyles = makeStyles(styles)

export default function UserProductsStats(props) {
    const classes = useStyles()

    const CURRENT_USER = useSelector((state) => state.loginReducer)

    const { sellerStats } = props

    const [userCategories, setUserCategories] = React.useState("-")
    const [userCategoriesRoot, setUserCategoriesRoot] = React.useState("-")

    useQuery(LOAD_ALL_CATEGORIES, {
        variables: {
            sellerId: CURRENT_USER?.seller?.id,
        },
        onCompleted: (data) => {
            if (data?.allCategories?.edges?.length >= 0) {
                setUserCategories(data.allCategories.edges.length)
                setUserCategoriesRoot(data.allCategories.edges.filter((item) => !item.node.parent).length)
            }
        },
    })

    const [displayProductDetails, setDisplayProductDetails] = React.useState(false)
    const [displayCategoryDetails, setDisplayCategoryDetails] = React.useState(false)

    const ProductBlock = () => (
        <Grid container item xs={12}>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={11} justifyContent={"center"}>
                    <h3 className={classes.cardTitle}>
                        <NavLink to={"/seller/product"} style={{ textDecoration: "solid underline black 1px" }}>
                            {sellerStats?.totalProducts ?? "-"} <small>Products</small>
                        </NavLink>
                    </h3>
                </Grid>
                <Grid container item xs={1} alignItems={"center"}>
                    {displayProductDetails ? (
                        <ChevronDownIcon onClick={() => setDisplayProductDetails(!displayProductDetails)} />
                    ) : (
                        <ChevronRightIcon onClick={() => setDisplayProductDetails(!displayProductDetails)} />
                    )}
                </Grid>
            </Grid>

            {displayProductDetails && (
                <Fade in={displayProductDetails}>
                    <>
                        <Grid container item xs={12} justifyContent={"flex-end"}>
                            <h4 className={classes.cardSubTitle}>
                                {sellerStats?.totalProductsUploaded ?? "-"} <small>uploaded</small> |{" "}
                                {sellerStats?.totalProductsPublished ?? "-"} <small>published</small>
                            </h4>
                        </Grid>
                        <Grid container item xs={12} justifyContent={"flex-end"}>
                            <h4 className={classes.cardSubTitle}>
                                {sellerStats?.totalProductsQuantityLess10 ?? "-"} <small>stocks in danger</small> |{" "}
                                {sellerStats?.totalProductsQuantityBetween10And19 ?? "-"}{" "}
                                <small>stocks in warning</small>
                            </h4>
                        </Grid>
                    </>
                </Fade>
            )}
        </Grid>
    )

    const CategoryBlock = () => (
        <Grid container item xs={12}>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={11} justifyContent={"center"}>
                    <h3 className={classes.cardTitle}>
                        <NavLink to={"/seller/category"} style={{ textDecoration: "solid underline black 1px" }}>
                            {userCategories} <small>Categories</small>
                        </NavLink>
                    </h3>
                </Grid>
                <Grid container item xs={1} alignItems={"center"}>
                    {displayCategoryDetails ? (
                        <ChevronDownIcon onClick={() => setDisplayCategoryDetails(!displayCategoryDetails)} />
                    ) : (
                        <ChevronRightIcon onClick={() => setDisplayCategoryDetails(!displayCategoryDetails)} />
                    )}
                </Grid>
            </Grid>

            {displayCategoryDetails && (
                <Fade in={displayCategoryDetails}>
                    <Grid container item xs={12} justifyContent={"flex-end"}>
                        <h4 className={classes.cardSubTitle}>
                            {userCategoriesRoot} <small>root categories</small>
                        </h4>
                    </Grid>
                </Fade>
            )}
        </Grid>
    )

    return (
        <Card variant={"outlined"}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid container item>
                        <Grid item xs={6}>
                            <DragHandle fontSize={"small"} />
                        </Grid>
                        <Grid container item xs={6} justifyContent={"flex-end"} className={classes.cardCategory}>
                            Products
                        </Grid>
                    </Grid>
                    <Grid container item xs={2} alignItems={"center"}>
                        <Avatar variant={"rounded"} className={classes.cardAvatar}>
                            <ShoppingBasketOutlineIcon size={"55"} />
                        </Avatar>
                    </Grid>
                    <Grid container item xs={10} alignItems={"center"} spacing={1}>
                        <ProductBlock />

                        <CategoryBlock />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
