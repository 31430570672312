import {whiteColor} from "assets/jss/material-dashboard-react.js";

export const dividerBase = {
    height: "1px",
    width: "100%",
    margin: "3px 0"
}

const notificationStyle = {
    container: {
        width: "100%",
        height: "100%",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    },
    title: {
        fontSize: "14px",
        color: whiteColor
    },
    dividerInfo: {
        ...dividerBase,
        backgroundImage: `linear-gradient(to right, ${whiteColor} 45%, #3498db)`,
    },
    dividerSuccess: {
        ...dividerBase,
        backgroundImage: `linear-gradient(to right, ${whiteColor} 45%, #07bc0c)`,
    },
    dividerError: {
        ...dividerBase,
        backgroundImage: `linear-gradient(to right, ${whiteColor} 45%, #e74c3c)`,
    },
    dividerWarning: {
        ...dividerBase,
        backgroundImage: `linear-gradient(to right, ${whiteColor} 45%, #f1c40f)`,
    },
    message: {
        fontSize: "12px"
    },
};

export default notificationStyle;
