import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";

//components
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";

//styles
import styles from "assets/jss/material-dashboard-react/components/customTabsStyle.js";
import {Box} from "@material-ui/core";

//SwipeableViews
import SwipeableViews from "react-swipeable-views";

const useStyles = makeStyles(styles);

export default function HeaderTabs(props) {
    const classes = useStyles();

    const {title, headerColor, tabs} = props

    const [value, setValue] = React.useState(0);
    const handleChange = (event, value) => {
        setValue(value);
    };

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                    </Box>
                )}
            </div>
        )
    }

    return (
        <Card>
            <CardHeader color={headerColor} plain={false}>
                {title !== undefined ? <div className={classes.cardTitle}>{title}</div> : null}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    classes={{
                        root: classes.tabsRoot,
                        indicator: classes.displayNone
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="system pages description"
                >
                    {tabs.map((prop, key) => {
                        let icon = {};
                        if (prop.tabIcon) {
                            icon = {
                                icon: <prop.tabIcon />
                            };
                        }
                        return (
                            <Tab
                                classes={{
                                    root: classes.tabRootButton,
                                    selected: classes.tabSelected,
                                    wrapper: classes.tabWrapper
                                }}
                                key={key}
                                label={prop.tabName}
                                {...icon}
                            />
                        );
                    })}
                </Tabs>
            </CardHeader>
            <CardBody>
                <SwipeableViews
                    index={value}
                    onChangeIndex={handleChangeIndex}
                >
                    {
                        tabs.map((prop, key) =>
                            <TabPanel value={value} index={key} key={key}>{prop.tabContent}</TabPanel>
                        )
                    }
                </SwipeableViews>
            </CardBody>
        </Card>
    )
}