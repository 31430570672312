import { gql } from "@apollo/client"

//product public details
export const PUBLIC_PRODUCT = gql`
    query publicProduct($productId: ID!) {
        publicProduct(productId: $productId) {
            title
            description
            quantity
        }
    }
`
