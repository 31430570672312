import React from "react"
import { Button, Grid } from "@material-ui/core"
import Tipography from "@material-ui/core/Typography"

import { useHistory } from "react-router-dom"

import { makeStyles } from "@material-ui/core/styles"
import styles from "assets/jss/material-dashboard-react/views/LandingPageStyle"

//reveal effects
import { Zoom, Slide } from "react-awesome-reveal"

//assets
import imgSlide1 from "assets/img/035-online shopping.svg"

//animations
import { Background, Parallax } from "react-parallax"

const useStyles = makeStyles(styles)

export default function Slide1() {
    const classes = useStyles()

    const history = useHistory()
    const openLoginForm = () => {
        history.push("/login")
    }

    return (
        <Parallax strength={500}>
            <Background>
                <div className={classes.background1} />
            </Background>
            <Grid container spacing={2} style={{ padding: "30px 5px" }}>
                <Grid item xs={12} md={6} className={classes.colContainerSlide}>
                    <div className={classes.leftSide}>
                        <Slide duration={1500} direction={"left"} triggerOnce>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} className={classes.blocks}>
                                    <Tipography variant="h3" display="inline">
                                        Sync2Markets
                                    </Tipography>
                                    <Tipography variant="h6" display="inline">
                                        {" "}
                                        | Sell made easy
                                    </Tipography>
                                </Grid>

                                <Grid item xs={12} className={classes.blocks}>
                                    <Tipography variant="subtitle1" display="inline">
                                        Easy Multichannel Sell Manager
                                    </Tipography>
                                </Grid>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={openLoginForm}
                                >
                                    Start now
                                </Button>
                            </Grid>
                        </Slide>
                    </div>
                </Grid>
                <Grid item xs={12} md={6} className={classes.imgContainer}>
                    <Zoom duration={1500} triggerOnce>
                        <img src={imgSlide1} alt="imgSlide1" className={classes.img} />
                    </Zoom>
                </Grid>
            </Grid>
        </Parallax>
    )
}
