const backToTopButtonStyle = {
    container: {
        position: "fixed",
        bottom: "20px",
        right: "20px",
        zIndex: 99999
    },

    fabButton: {
        color: "#fff",
        backgroundColor: "rgba(63,81,181,0.7)",
    }
};

export default backToTopButtonStyle;
