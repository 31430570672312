import {
    whiteColor
} from "assets/jss/material-dashboard-react.js";
import cardInfoStyle from "assets/jss/material-dashboard-react/views/cardInfoStyle";

const newAccountStyle = theme => ({
    ...cardInfoStyle,
    formContainer: {
        backgroundColor: whiteColor,
        height: "auto",
        [theme.breakpoints.up("md")]: {
            width: "65%"
        },
        [theme.breakpoints.down("sm")]: {
            width: "95%"
        },
    },
    inputFields: {
        marginBottom: "10px",
        marginTop: "10px"
    },
    actionButtonsContainer: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        marginTop: "10px",
        padding: "0 10px"
    },
    actionButton: {
        marginLeft: "10px",
        minWidth: "87px"
    }
});

export default newAccountStyle;
